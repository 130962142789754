import * as actions from "components/local/gallery/redux/types";
const initialState = {
  album: [],
  album_loading: false,
  albumSingle: [],
  albumSingle_loading: false,
};

const albumReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_ALBUM_BEGIN:
      return { ...state, album_loading: true };

    case actions.GET_ALBUM_SUCCESS:
      return { ...state, album_loading: false, album: action.payload };

    case actions.GET_ALBUM_ERROR:
      return { ...state, album_loading: false };

    // album single

    case actions.GET_ALBUM_SINGLE_BEGIN:
      return { ...state, albumSingle_loading: true };

    case actions.GET_ALBUM_SINGLE_SUCCESS:
      return {
        ...state,
        albumSingle_loading: false,
        albumSingle: action.payload,
      };

    case actions.GET_ALBUM_SINGLE_ERROR:
      return { ...state, albumSingle_loading: false };
    default:
      return state;
  }
};

export default albumReducer;
