import * as actions from "./types";

const initialState = {
  status: "idle",
  data: [],
  userImage: JSON.parse(localStorage.getItem("userImage")) || null,
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_PROFILE_PENDING:
      return { ...state, status: "pending" };
    case actions.FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        status: "completed",
        data: action.payload,
        userImage: action?.payload?.membership_detail?.image,
      };
    case actions.FETCH_PROFILE_ERROR:
      return { ...state, status: "failed" };
    default:
      return state;
  }
};

export default profileReducer;
