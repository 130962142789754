import * as actions from "./types";

import {
  deleteAppliedEventApi,
  fetchEventsApi,
  fetchSingleEventApi,
  filterEventsByDateApi,
  getAppliedEventsApi,
  postEventApi,
} from "apis/events";
import { errorToast, successToast } from "utils/toast";

export const fetchEventsData = (page) => (dispatch) => {
  dispatch({ type: actions.FETCH_EVENT_PENDING });
  fetchEventsApi(page)
    .then((response) => {
      dispatch({
        type: actions.FETCH_EVENT_SUCCESS,
        payload: response.data.data,
      });
    })
    .catch((error) => {
      console.log("error", error);
      dispatch({ type: actions.FETCH_EVENT_ERROR });
    });
};

export const fetchSingleEventData = (eventId) => (dispatch) => {
  dispatch({ type: actions.FETCH_EVENT_PENDING });
  fetchSingleEventApi(eventId)
    .then((response) => {
      dispatch({
        type: actions.FETCH_SINGLE_EVENT_SUCCESS,
        payload: response.data.data,
      });
    })
    .catch((error) => {
      console.log("error", error);
      dispatch({ type: actions.FETCH_SINGLE_EVENT_ERROR });
    });
};

export const postEventData = (data, slug) => (dispatch) => {
  dispatch({ type: actions.POST_EVENT_PENDING });

  postEventApi(data, slug)
    .then((response) => {
      console.log("response", response);
      successToast(response.data.message);
      dispatch({ type: actions.POST_EVENT_SUCCESS });
    })
    .catch((error) => {
      errorToast(error);
      dispatch({ type: actions.POST_EVENT_ERROR });
    });
};

export const getEventsOfCurrentMonth = (data) => (dispatch) => {
  dispatch({ type: actions.FETCH_CURRENT_MONTHS_EVENT_BEGIN });

  filterEventsByDateApi(data)
    .then((response) => {
      dispatch({
        type: actions.FETCH_CURRENT_MONTHS_EVENT_SUCCESS,
        payload: response.data.data.event,
      });
    })
    .catch((error) => {
      dispatch({ type: actions.FETCH_CURRENT_MONTHS_EVENT_ERROR });
      console.log("error", error);
    });
};

export const setCurrentDate = (data) => (dispatch) => {
  dispatch({ type: actions.GET_CURRENT_DATE, payload: data });
};

export const getAppliedEvents = () => (dispatch) => {
  dispatch({ type: actions.FETCH_APPLIED_EVENTS_PENDING });
  getAppliedEventsApi()
    .then((response) => {
      // console.log("response", response.data.data.event_application);
      dispatch({
        type: actions.FETCH_APPLIED_EVENTS,
        payload: response.data.data.event_application,
      });
    })
    .catch((error) => {
      console.log("error", error);
      dispatch({ type: actions.FETCH_APPLIED_EVENT_ERROR });
    });
};

export const deleteAppliedEvent = (id) => (dispatch) => {
  deleteAppliedEventApi(id)
    .then((response) => {
      console.log("response", response);
      dispatch({ type: actions.RESET_APPLIED_EVENT_STATUS });
      successToast(response.data.message);
    })
    .catch((error) => {
      console.log("error", error);
      errorToast(error);
    });
};
