import * as actions from "./types";

const initialState = {
  status: "idle",
};

const signUpReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SIGNUP_PENDING:
      return { ...state, status: "pending" };
    case actions.SIGNUP_SUCCESS:
      return { ...state, status: "completed" };
    case actions.SIGNUP_ERROR:
      return { ...state, status: "failed" };
    case actions.CHANGE_STATUS:
      return { ...state, status: action.payload };
    default:
      return state;
  }
};

export default signUpReducer;
