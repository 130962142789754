import React from 'react'
import style from './style'

const VerticalLine = () => {
  const classes = style();

  return (
    <div className={classes.verticalLine}>
      
    </div>
  )
}

export default VerticalLine
