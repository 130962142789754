import OurTeams from 'components/local/ourTeams';
import React from 'react'

const OurTeamsPage = () => {
  return (
    <>
      <OurTeams />
    </>
  );
};

export default OurTeamsPage;
