import { Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CustomTable from "components/UI/Table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { deleteAppliedJob, getAppliedJobs } from "redux/vacancy/actions";

const Vacancy = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const vacancyState = useSelector((state) => state?.vacancy);
  const rows = vacancyState?.data;
  const [status, setStatus] = useState("idle");

  console.log({ vacancyState });

  const handleClick = (row) => {
    console.log({ row });
    navigate(`/jobs/${row?.job?.slug ?? ""}`);
  };

  useEffect(() => {
    if (status === "idle") {
      dispatch(getAppliedJobs());
      setStatus("completed");
    }
  }, [status]);
  // alert("Yes");
  const columns = [
    { accessor: "jobTitle", label: "Job Title" },
    { accessor: "location", label: "Location" },
    { accessor: "name", label: "Name" },
    { accessor: "email", label: "Email" },
    { accessor: "phone", label: "Phone" },
    { accessor: "cv", label: "CV" },
    { accessor: "delete", label: "Delete" },
  ];

  const handleDelete = (id) => {
    dispatch(deleteAppliedJob(id));
    setStatus("idle");
  };

  return (
    <Box
      component="section"
      sx={{
        bgcolor: "#F5F5F5",
        padding: { xs: "10px 10px", md: "44px 45px", lg: "44px 65px" },
        height: "100%",
      }}
    >
      <Box sx={{ mb: "30px" }}>
        <Typography
          component="h1"
          sx={{
            fontWeight: "500 !important",
            fontSize: "26px !important",
            lineHeight: "1.2 !important",
            color: "#201F37",
          }}
        >
          Vaccancy
        </Typography>
      </Box>
      {vacancyState.status === "pending" ? (
        <Skeleton variant="rectangular" height={400} />
      ) : (
        <Box sx={{ cursor: "pointer" }}>
          <CustomTable
            handleClick={handleClick}
            columns={columns}
            rows={rows}
            handleDelete={handleDelete}
          />
        </Box>
      )}
    </Box>
  );
};

export default Vacancy;
