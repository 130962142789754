export const POST_THREAD_BEGIN = "POST_THREAD_BEGIN";
export const POST_THREAD_SUCCESS = "POST_THREAD_SUCCESS";
export const POST_THREAD_ERROR = "POST_THREAD_ERROR";

export const GET_THREAD_BEGIN = "GET_THREAD_BEGIN";
export const GET_THREAD_SUCCESS = "GET_THREAD_SUCCESS";
export const GET_THREAD_ERROR = "GET_THREAD_ERROR";

export const PUT_THREAD_BEGIN = "PUT_THREAD_BEGIN";
export const PUT_THREAD_SUCCESS = "PUT_THREAD_SUCCESS";
export const PUT_THREAD_ERROR = "PUT_THREAD_ERROR";

export const DELETE_THREAD_BEGIN = "DELETE_THREAD_BEGIN";
export const DELETE_THREAD_SUCCESS = "DELETE_THREAD_SUCCESS";
export const DELETE_THREAD_ERROR = "DELETE_THREAD_ERROR";

export const POST_COMMENT_BEGIN = "POST_COMMENT_BEGIN";
export const POST_COMMENT_SUCCESS = "POST_COMMENT_SUCCESS";
export const POST_COMMENT_ERROR = "POST_COMMENT_ERROR";

export const PUT_COMMENT_BEGIN = "PUT_COMMENT_BEGIN";
export const PUT_COMMENT_SUCCESS = "PUT_COMMENT_SUCCESS";
export const PUT_COMMENT_ERROR = "PUT_COMMENT_ERROR";

export const DELETE_COMMENT_BEGIN = "DELETE_COMMENT_BEGIN";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_ERROR = "DELETE_COMMENT_ERROR";

export const POST_UPVOTE_BEGIN = "POST_UPVOTE_BEGIN";
export const POST_UPVOTE_SUCCESS = "POST_UPVOTE_SUCCESS";
export const POST_UPVOTE_ERROR = "POST_UPVOTE_ERROR";

export const POST_DOWNVOTE_BEGIN = "POST_DOWNVOTE_BEGIN";
export const POST_DOWNVOTE_SUCCESS = "POST_DOWNVOTE_SUCCESS";
export const POST_DOWNVOTE_ERROR = "POST_DOWNVOTE_ERROR";

export const DELETE_UPVOTE_BEGIN = "DELETE_UPVOTE_BEGIN";
export const DELETE_UPVOTE_SUCCESS = "DELETE_UPVOTE_SUCCESS";
export const DELETE_UPVOTE_ERROR = "DELETE_UPVOTE_ERROR";

export const DELETE_DOWNVOTE_BEGIN = "DELETE_DOWNVOTE_BEGIN";
export const DELETE_DOWNVOTE_SUCCESS = "DELETE_DOWNVOTE_SUCCESS";
export const DELETE_DOWNVOTE_ERROR = "DELETE_DOWNVOTE_ERROR";
