import Complain from "components/local/complain";

const ComplainPage = () => {
  return (
    <div>
      <Complain />
    </div>
  );
};

export default ComplainPage;
