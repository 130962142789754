import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  newsSectionContainer: {
    padding: "40px 0",
  },
  newsSectionTitleBox: {
    marginBottom: "20px",
  },
  newsSectionTitle: {
    color: "#121127",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    fontSize: "17px !important",
    fontWeight: "700 !important",
  },
  newsSectionBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  newsSectionCardBox: {
    width: "100%",
    border: "1px solid #E5E5EB",
    cursor: "pointer",
    height: "100%",
    // minHeight: " 425px",
  },

  newsSectionImgBox: {
    // width: "auto",
    // height: "240px",
    position: "relative",
    paddingTop: "70%",
  },
  newsSectionImgBoxNarrow: {
    // width: "auto",
    // height: "350px",
    position: "relative",
    paddingTop: "141%",
  },
  newsSectionImg: {
    width: "100%",
    height: "100%",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    position: "absolute",
    objectFit: "cover",
  },
  newsSectionCardImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  newsSectionInfoWrap: {
    padding: "0 10px",
  },
  bottomElement: {
    padding: "0 10px",
    marginTop: "10px",
  },
  newsSectionMetaTitle: {
    color: "#F65C5C",
    fontSize: "11px !important",
    fontWeight: "600 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    textTransform: "uppercase",
    margin: "5px 0 !important",
  },
  newsSectionInfoMetaTitle: {
    color: "#8B5CF6",
    fontSize: "14px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    marginTop: "8px !important",
    textTransform: "uppercase",
  },
  newsSectionInfoTitle: {
    color: "#121127",
    fontSize: "18px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    marginTop: "10px !important",
    lineHeight: "1 !important",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    minHeight: "36px",
  },
  newsSectionSubTitle: {
    color: "rgba(18, 17, 39, 0.56)",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],

    // paddingBottom: "20px",
  },
  newsSectionBtnBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  newsSectionBtn: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    padding: "0 10px",
    border: "none",
    backgroundColor: "#fff",
    cursor: "pointer",
    color: "#4C7CE5",
    fontSize: "12px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    textTransform: "underline !important",
  },
  newsSectionBtnIcon: {
    width: "17px",
    height: "17px",
  },
  newsSectionDate: {
    color: "#6C6B80",
    fontSize: "13px !important",
    fontWeight: "400 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },
  newsSeeMoreBtnBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  newsSeeMoreBtn: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#4C7CE5",
    fontSize: "15px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },
  newsSeeMoreArrow: {
    display: "flex",
    alignItems: "center",
  },

  // new single page

  newsSingleContainer: {
    // backgroundColor: 'pink',
    padding: "20px 0",
  },
  backBtnBox: {
    marginBottom: "20px",
  },
  backBtn: {
    border: "none",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    gap: 3,
    padding: "5px",
    cursor: "pointer",
    color: "#858689",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },
  backArrow: {
    width: "16px",
    height: "16px",
  },
  newsSingleCardBox: {
    width: "100%",
    border: "1px solid #E5E5EB",
  },
  newsSingleImgBox: {
    width: "100%",
    height: "450px",
  },
  newsSingleImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  newsSingleInfoWrap: {
    padding: "10px 0",
  },
  newsSingleMetaTitle: {
    color: "#F65C5C",
    fontSize: "11px !important",
    fontWeight: "600 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    textTransform: "uppercase",
    margin: "5px 0 !important",
  },
  newsSingleInfoTitle: {
    color: "#121127",
    fontSize: "23px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    // marginBottom: "12px !important",
  },
  newsSingleSubTitle: {
    color: "rgba(18, 17, 39, 0.72)",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    paddingBottom: "20px",
  },
  newsSingleInfoDateBoxGrid: {
    color: " rgba(18, 17, 39, 0.56)",
    display: "flex",
    alignItems: "center",
    gap: 2,
    marginBottom: "20px",
  },
  newsSingleInfoDateBoxPDF: {
    justifyContent: "space-between",
  },
  newsSingleInfoDateBox: {
    display: "flex",
  },
  newsSingleInfoDate: {
    fontSize: "15px !important",
    fontWeight: "400 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },
  newsSingleInfoTime: {
    fontSize: "15px !important",
    fontWeight: "400 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },
  newsSectionName: {
    fontSize: "12px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    color: "#121127",
  },
  newsSectionInfoDateBox: {
    color: " rgba(18, 17, 39, 0.56)",
    display: "flex",
    alignItems: "center",
    // marginTop: "10px",
    marginBottom: "20px",
  },
  newsSectionInfoDate: {
    fontSize: "12px !important",
    fontWeight: "400 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },
  newsSectionInfoTime: {
    fontSize: "12px !important",
    fontWeight: "400 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },

  //  more news section

  newsMoreSectionWrap: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  newsMoreSection: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  newsMoreSectionTitle: {
    display: "flex",
    justifyContent: "center",
    color: "#726464",
    fontSize: "15px !important",
    fontWeight: "600 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    marginBottom: "7px !important",
  },
  sectionTitlePublication: {
    display: "flex",
    // justifyContent: "center",
    color: "#000000",
    fontSize: "25px !important",
    fontWeight: "600 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    marginBottom: "7px !important",
    borderBottom: "3px solid  #D9D9D9",
  },
  newsMoreSectionArticle: {
    marginBottom: "20px",
    // marginTop: "20px",
  },
  newsMoreSectionImgBox: {
    width: "auto",
    height: "150px",
    [theme.breakpoints.down("md")]: {
      height: "150px",
    },
  },
  newsMoreSectionImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  newsMoreSectionInfoWrap: {
    // padding: "10px 0",
  },
  newsMoreSectionMetaTitle: {
    color: "#8B5CF6",
    fontSize: "14px !important",
    fontWeight: "600 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    textTransform: "uppercase",
    margin: "5px 0 !important",
  },
  newsMoreSectionInfoTitle: {
    color: "#121127",
    fontSize: "16px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    marginTop: "10px !important",
    lineHeight: '1 !important',
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  newsMoreSectionInfoParagraph: {
    color: "#857F7A",
    fontSize: "12px !important",
    fontWeight: "400 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    marginTop: "50px",
  },
  bottomNarrowNameSection: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
  },
  bottomNarrowElement: {
    display: "flex",
    justifyContent: "space-between",
  },
  fileBox: {
    backgroundColor: "#F2F2F2",
    width: "43px",
    height: "43px",
    minWeight: "43px",
    maxWeight: "43px",
    display: "flex",
    borderRadius: "50%",
  },
  fileDownloadIcon: {
    width: "14px",
    height: "16px",
    margin: "auto",
  },
  dayTimeDot: {
    display: "flex",
    aliginItems: "center",
    padding: "8px",
  },

  newsMoreSectionDate: {
    color: "#EF4444",
    fontSize: "10px !important",
    fontWeight: "600 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },

  publicationDownloadBtnBox: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "0.5rem !important",
    },
  },
  publicationDownloadBtn: {
    color: "#fff !important",
    display: "flex",
    justifyContent: "center",
    padding: "auto",
    height: "40px",
    gap: 4,
    backgroundColor: "#DB143C !important",
    borderRadius: "0 !important",
    [theme.breakpoints.down("md")]: {
      paddingTop: "1rem",
    },
    "& .MuiButtonBase-root-MuiButton-root": {
      padding: "9px 3px !important",
    },
  },
  downloadBtn: {
    color: "#fff",
    fontSize: "14px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },
  lineClamp: {
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    color: "#6C6B80 !important",
  },
  lineClampDesc: {
    display: "-webkit-box",
    "-webkit-line-clamp": 8,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    color: "#6C6B80 !important",
  },
}));

export default style;
