import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  quickLinksContainer: {
    height: "auto",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    padding: "10px 0",
    // backgroundColor: '#F1F1F1',
    [theme.breakpoints.down("md")]: {
      height: "auto",
      padding: "20px 0",
    },
  },
  // quickLinkWrapper:{
  //   backgroundColor: '#F1F1F1 !important',
  // },
  qucikLinkTypography: {
    display: "flex",
    flexDirection: "column ",
    justifyContent: "center",
    alignItems: "center",
  },
  quickLinkHead: {
    color: "#201F37 !important",
    fontSize: "28px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    textTransform: "uppercase !important",
    paddingBottom: "30px !important",
  },

  paperContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "1rem !important",
  },
  paperBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    width: "200px",
    height: "150px",
    padding: "10px",
    cursor: "pointer",
    transition: "0.2s ease",
    "&:hover": {
      backgroundColor: "#fff",
      boxShadow: "1px 1px 15px -1px rgba(179,179,179,1)",
    },
    [theme.breakpoints.down("md")]: {
      width: "130px",
    },
  },
  quickIconBox: {
    height: "50px",
    width: "50px",
    margin: "0 auto",
    marginBottom: "5px",
  },
  quickIcon: {
    height: "100%",
    width: "100%",
    objectFit: "scale-down",
  },
  linkText: {
    fontSize: "18px",
    fontWeight: "600",
    color: "#DC2626",
  },
  quickLinkBox: {
    padding: "20px 0 5px",
    // backgroundColor: "#F1F1F1",
  },
}));

export default style;
