import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  header: {
    fontWeight: "700 !important",
    fontSize: "26px !important",
    lineHeight: "1.2 !important",
    color: "#000",
  },
  imageContainer: {
    width: "108px",
    height: "108px",
    borderRadius: "50%",
    overflow: "hidden",
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  subHeader: {
    fontWeight: "600 !important",
    textTransform: "uppercase",
    lineHeight: "1.2 !important",
    color: "#111010",
  },
  lightText: {
    lineHeight: "1.2 !important",
    color: "#797A7B",
  },
}));

export default style;
