import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
   SocialLinksContainer:{
      display: 'flex !important',
      flexDirection: 'row',
      gap:"8px"

   },
   socialIcons:{
      width: '15px !important',
      height: '15px !important',
   }
  
}));

export default style;
