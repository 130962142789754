import * as actions from "components/local/dashboard/blog/redux/types";
const initialState = {
  blog: [],
  blog_loading: false,
};

const blogDashReducer = (state = initialState, action) => {
  switch (action.type) {
    // post, update, delete job

    case actions.POST_BLOG_BEGIN:
    case actions.DELETE_BLOG_BEGIN:
    case actions.PUT_BLOG_BEGIN:
      return { ...state, blog_loading: true };

    case actions.POST_BLOG_SUCCESS:
    case actions.DELETE_BLOG_SUCCESS:
    case actions.PUT_BLOG_SUCCESS:
      return { ...state, blog_loading: false };

    case actions.POST_BLOG_ERROR:
    case actions.DELETE_BLOG_ERROR:
    case actions.PUT_BLOG_ERROR:
      return { ...state, blog_loading: false };

    // get job

    case actions.GET_BLOG_BEGIN:
      return { ...state, blog_loading: true };

    case actions.GET_BLOG_SUCCESS:
      return { ...state, blog_loading: false, blog: action.payload };

    case actions.GET_BLOG_ERROR:
      return { ...state, blog_loading: false };

    // get job applications

    // case actions.GET_JOB_APPLICATIONS_BEGIN:
    //   return { ...state, jobApplications_loading: true };

    // case actions.GET_JOB_APPLICATIONS_SUCCESS:
    //   return {
    //     ...state,
    //     jobApplications_loading: false,
    //     jobApplications: action.payload,
    //   };

    // case actions.GET_JOB_APPLICATIONS_ERROR:
    //   return { ...state, jobApplications_loading: false };
    default:
      return state;
  }
};

export default blogDashReducer;
