import { AddOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import style from "components/UI/DashboardTitle/style";
import { useNavigate } from "react-router-dom";

const DashboardTitle = ({ title, link }) => {
  const classes = style();

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(link);
  };
  return (
    <>
      <Box className={classes.titleSection}>
        <Typography className={classes.title}>Add {title}</Typography>
        <Button onClick={handleNavigate} className={classes.addButton}>
          <AddOutlined className={classes.buttonIcon} />
          Create New {title}
        </Button>
      </Box>
    </>
  );
};

export default DashboardTitle;
