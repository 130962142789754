import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import Loader from "components/global/loader/Loader";
import style from "components/local/contactUs/style";
import { HookForm } from "components/UI/Form/HookForm";
import { HookFormInput } from "components/UI/Form/HookFormInput";
import { HookFormProvider } from "components/UI/Form/HookFormProvider";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { postContactus } from "./redux/actions";

const ContactForm = () => {
  const dispatch = useDispatch();

  const classes = style();

  const { contactus_loading } = useSelector((state) => state.contact);

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    subject: yup.string().required("Subject is required"),
    message: yup.string().required("Description is required"),
    phone_no: yup.string().required("Phone is required"),
    email: yup.string().required("Email is required"),
  });

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  console.log("errrrrrrrrr", errors);
  const refetch = () => {
    const array = ["name", "message", "subject", "phone_no", "email"];
    array?.map((item) => setValue(item, ""));
  };
  const onSubmit = (values) => {
    const data = {
      ...values,
    };
    dispatch(postContactus(data, refetch));
  };

  console.log({ watch: watch() });

  return (
    // <Container maxWidth="lg">
    <Box className={classes.contactFormContainer}>
      <Paper elevation={0} className={classes.contactFormPaperContainer}>
        <Box className={classes.contactFormWrap}>
          <Box className={classes.formHeading}>
            <Typography className={classes.formHead}>Contact Us</Typography>
            <Typography className={classes.formTitle}>
              We'd love to hear from you! Feel free to reach out to us with any
              questions, concerns, or feedback.
            </Typography>
          </Box>
          <Box className={classes.formSection}>
            <HookFormProvider>
              <HookForm onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <HookFormInput
                      required={true}
                      type="text"
                      name="name"
                      // label="Name"
                      placeholder="Name"
                      control={control}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <HookFormInput
                      required={true}
                      type="text"
                      name="phone_no"
                      // label="Name"
                      placeholder="Phone"
                      control={control}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <HookFormInput
                      required={true}
                      type="email"
                      name="email"
                      // label="Name"
                      placeholder="Email"
                      control={control}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <HookFormInput
                      required={true}
                      type="text"
                      name="subject"
                      // label="Name"
                      placeholder="Subject"
                      control={control}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <HookFormInput
                      required={true}
                      type="text"
                      name="message"
                      // label="Name"
                      placeholder="Say something you want to say"
                      control={control}
                      errors={errors}
                      multiline
                      rows={4}
                      sx={{
                        "& .css-17cvodc-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            height: "82px",
                          },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button className={classes.formSubmitBtn} type="submit">
                      {contactus_loading ? <Loader /> : "Send"}
                    </Button>
                  </Grid>
                </Grid>
              </HookForm>
            </HookFormProvider>
          </Box>
        </Box>
      </Paper>
    </Box>
    // </Container>
  );
};

export default ContactForm;
