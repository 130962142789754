import { Box, Container, Grid, Typography } from "@mui/material";
import SkeletonLoader from "components/global/loader/skeletonLoader/SkeletonCard";
import BlogsCard from "components/local/home/blogsSection/blogs/BlogsCard";
import Jobs from "components/local/home/blogsSection/jobs";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import style from "./style";

const Blogs = ({ homeData }) => {
  const { homepage_loading } = useSelector((state) => state.home);

  const classes = style();
  return (
    <Box className={classes.blogsContainer}>
      <Container maxWidth="lg">
        {/* <Box className={[classes.blogsWrap, "container-wrap-secondary"]}> */}
        <Grid container sx={{ display: "flex" }}>
          <Grid items md={8} className={classes.blogsGrid}>
            <Box className={classes.blogsTypography}>
              <Typography className={classes.blogsTitle}>OUR BLOGS</Typography>
              <Typography className={classes.blogsHead}>
                Whether you are a brand new church planter with a dream to
                change the world or a seasoned ministry
              </Typography>
            </Box>
            {/* {homeData?.blog && (
                <Box className={classes.blogsGridSeeBtnBox}>
                  <NavLink to="/blogs" className={classes.blogsGridSeeBtn}>
                    see more
                  </NavLink>
                </Box>
              )} */}
            <Box>
              <Grid container spacing={1}>
                {homepage_loading
                  ? [...Array(3)]?.map((item) => (
                      <Grid item md={6} sm={6} xs={12} lg={4}>
                        <SkeletonLoader />
                      </Grid>
                    ))
                  : homeData?.blog?.slice(0, 3)?.map((data) => {
                      return <BlogsCard key={data?.id} data={data} />;
                    })}
              </Grid>
              
            </Box>
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <Jobs homeData={homeData} />
          </Grid>
        </Grid>
        {/* </Box> */}
      </Container>
    </Box>
  );
};

export default Blogs;
