import { InsertDriveFileOutlined } from "@mui/icons-material";
import * as actions from "./types";

const initialState = {
  status: "idle",
  data: [],
};

const vacancyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_VACANCY_PENDING:
      return { ...state, status: "pending" };
    case actions.FETCH_VACANCY:
      return {
        ...state,
        status: "completed",
        data: action?.payload?.map((item) => {
          return {
            ...item,
            id: item?.id,
            jobTitle: item?.job?.title,
            name: item?.name,
            email: item?.email,
            phone: item?.phone,
            location: item?.job?.location,
            cv: (
              <a href={item?.cv} target="_blank" rel="noreferrer">
                <InsertDriveFileOutlined />hj
                gbh
              </a>
            ),
            delete: "Delete",
          };
        }),
      };
    case actions.FETCH_VACANCY_ERROR:
      return { ...state, status: "failed" };
    case actions.RESET_VACANCY_STATUS:
      return { ...state, status: "idle" };
    default:
      return state;
  }
};

export default vacancyReducer;
