import * as actions from "components/local/aboutUs/redux/types";
const initialState = {
  aboutPage: [],
  about_loading: false,
};

const initialStateSingle = {
  aboutSinglePage: [],
  aboutSingle_loading: false,
};

const aboutReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_ABOUT_BEGIN:
      return { ...state, about_loading: true };

    case actions.GET_ABOUT_SUCCESS:
      return { ...state, about_loading: false, aboutPage: action.payload };

    case actions.GET_ABOUT_ERROR:
      return { ...state, about_loading: false };
    default:
      return state;
  }
};

const aboutSingleReducer = (state = initialStateSingle, action) => {
  switch (action.type) {
    case actions.GET_ABOUT_SINGLE_BEGIN:
      return { ...state, aboutSingle_loading: true };

    case actions.GET_ABOUT_SINGLE_SUCCESS:
      return {
        ...state,
        aboutSingle_loading: false,
        aboutSinglePage: action.payload,
      };

    case actions.GET_ABOUT_SINGLE_ERROR:
      return { ...state, aboutSingle_loading: false };
    default:
      return state;
  }
};

export { aboutReducer, aboutSingleReducer };
