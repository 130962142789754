import { Box } from "@mui/material";
import Marquee from "react-fast-marquee";

function AssociationMarqee({ homeData }) {
  return (
    <>
      <Box sx={{ backgroundColor: "#EF4444", }}>
        <Marquee speed={100} gradient={false}>
          {homeData?.news?.map((item) => {
            return (
              <p style={{ marginRight: " 5rem", color: "#fff", margin: '5px 80px 5px 0px' }}>{item?.title}</p>
            );
          })}
        </Marquee>
      </Box>
    </>
  );
}

export default AssociationMarqee;
