import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import style from "./style";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();
  const classes = style();

  const { handleSubmit, register } = useForm();

  const onSubmit = (data) => {
    console.log("data", data);
  };

  return (
    <div className={classes.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.formContainer}>
        <Box sx={{ mb: "23px" }}>
          <Typography component="h1" className={classes.header}>
            Reset Password
          </Typography>
        </Box>
        <Box className={classes.inputField}>
          <Box>
            <label htmlFor="password" className={classes.label}>
              New Password
            </label>
          </Box>
          <Box sx={{ position: "relative" }}>
            <input
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter Your Password"
              className={classes.input}
              {...register("oldPassword")}
              //   aria-invalid={errors.password ? true : false}
            />
            {showPassword ? (
              <VisibilityOutlined
                className={classes.toggleIcon}
                onClick={() => setShowPassword(false)}
              />
            ) : (
              <VisibilityOffOutlined
                className={classes.toggleIcon}
                onClick={() => setShowPassword(true)}
              />
            )}
          </Box>
        </Box>
        <Box className={classes.inputField}>
          <Box>
            <label htmlFor="password" className={classes.label}>
              Confirm Password
            </label>
          </Box>
          <Box sx={{ position: "relative" }}>
            <input
              id="password"
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Enter Your Password"
              className={classes.input}
              {...register("newPassword")}
              //   aria-invalid={errors.password ? true : false}
            />
            {showConfirmPassword ? (
              <VisibilityOutlined
                className={classes.toggleIcon}
                onClick={() => setShowConfirmPassword(false)}
              />
            ) : (
              <VisibilityOffOutlined
                className={classes.toggleIcon}
                onClick={() => setShowConfirmPassword(true)}
              />
            )}
          </Box>
        </Box>
        <Box className={classes.btnContainer} sx={{ mt: "38px" }}>
          <Button type="submit">Submit</Button>
        </Box>
      </form>
    </div>
  );
};

export default ResetPassword;
