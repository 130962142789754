import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import { TimeAgo } from "components/global/date/Date";
import ReadMoreBtn from "components/global/readMoreBtn";
import CommentBoxSecondary from "components/local/dashboard/community/CommentBoxSecondary";
import ModalMenu from "components/local/dashboard/community/ModalMenu";
import style from "components/local/dashboard/community/style";
import { useState } from "react";
import { useSelector } from "react-redux";
import ReplySection from "./ReplySection";

const ThreadModal = ({ data, loading, setThreadListData }) => {
  //   console.log("fffffffffffffffff", { data });

  //   return (
  //     <>
  //       {loading
  //         ? [...Array(3)]?.map((item) => (
  //             <Grid item md={12} sm={12} sx={{ marginBottom: "1rem" }}>
  //               <ThreadLoader />
  //             </Grid>
  //           ))
  //         : data?.map((item) => {
  //             return (
  //               <ThreadModalComonent
  //                 setThreadListData={setThreadListData}
  //                 key={item?.id}
  //                 data={item}
  //               />
  //             );
  //           })}
  //     </>
  //   );
  // };

  // const ThreadModalComonent = ({ data, setThreadListData }) => {
  const profileState = useSelector((state) => state.profile);
  const authState = useSelector((state) => state.auth);

  const classes = style();
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [row, setRow] = useState({});

  console.log("ddddddddddddddddddddd", { row });

  const handleShow = () => {
    setShowCommentBox(!showCommentBox);
    setRow(data);
  };

  console.log("lllllllllllllllllllll", data?.description?.substring().length);
  return (
    <>
      <Box className={classes.commentSectionBox}>
        <Grid container>
          <Grid item md={1}>
            <Box>
              <Avatar
                src={data?.user?.image}
                alt=""
                className={classes.profileImg}
                sx={{
                  width: "55px",
                  height: "55px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  background: "#EF4444",
                  color: "#fff",
                }}
              >
                {data?.user?.image
                  ? data?.user?.image
                  : data?.user?.name?.charAt(0)}
              </Avatar>
            </Box>
            {/* <Box className={classes.commentProfileImgBox}>
              <img src={profilePic2} alt="" className={classes.profileImg} />
            </Box> */}
          </Grid>
          <Grid item md={11}>
            <Box className={classes.userCommmentBox}>
              <Box className={classes.userNameMenu}>
                <Box className={classes.timeAgo}>
                  <Typography className={classes.userProfileName}>
                    {data?.user?.name}
                  </Typography>

                  <FiberManualRecordIcon
                    fontSize="small"
                    className={classes.dotTime}
                  />

                  <Typography className={classes.userProfileName}>
                    <TimeAgo time={data?.updated_at} />
                  </Typography>
                </Box>
                {/* <Typography className={classes.userProfileName}>
                  {data?.user?.name}
                </Typography> */}
                {authState?.user?.id === data?.user?.id ? (
                  <ModalMenu
                    data={data}
                    row={row}
                    setThreadListData={setThreadListData}
                  />
                ) : (
                  ""
                )}
                {/* <MoreHorizIcon sx={{ color: "#616161" }} /> */}
              </Box>
              <Typography className={classes.userCommentTitle}>
                {data?.title?.substring().length < 59
                  ? data?.title
                  : `${data?.title.substring(0, 60)}...`}
              </Typography>
              <Typography className={classes.userCommentBody}>
                {data?.description?.substring().length < 179 ? (
                  data?.description
                ) : (
                  <ReadMoreBtn data={data?.description} length={180} />
                )}
              </Typography>
              <Box className={classes.userCommentBtnBox}>
                <Button
                  startIcon={
                    <ChatBubbleOutlineIcon
                      sx={{
                        width: "14px",
                        height: "14px",
                        color: "#4C4B63",
                      }}
                    />
                  }
                  className={classes.likeBtn}
                  onClick={handleShow}
                >
                  Comment
                </Button>
                <Typography className={classes.commentNum}>
                  {data?.comment?.length} comments
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        {/* {showCommentBox && (
          <Grid container>
            <Grid item md={1}></Grid>
            <Grid item md={11}>
              <CommentBox row={row} />
            </Grid>
          </Grid>
        )} */}
        {showCommentBox && (
          <Grid container>
            <Grid item md={1}></Grid>
            <Grid item md={11}>
              <CommentBoxSecondary
                setThreadListData={setThreadListData}
                row={row}
                commentModal
              />
            </Grid>
          </Grid>
        )}

        <Grid container>
          <Grid item md={1}></Grid>
          <Grid item md={11}>
            <ReplySection
              sx={{ overflowY: "scroll" }}
              data={data}
              commentModal
            />
          </Grid>
        </Grid>
      </Box>
      {/* <Divider sx={{ marginBottom: "15px" }} /> */}
      <Box>
        {/* <CustomModal
        open={openModal}
        handleClose={openFunction}
        height="auto"
        // width
        variant="light"
        className={classes.formSubmitModal}
      >
        <Box sx={{ padding: "20px" }}>
          
            <CommentBoxSecondary
              commentData={commentData}
              handleClose={openFunction}
              edit
              row={row}
            />
         
        </Box>
      </CustomModal> */}
      </Box>
    </>
  );
};

export default ThreadModal;
