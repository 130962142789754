import { axiosInstance } from "apis/_axios";

export const getCourseApi = (data) => {
  return axiosInstance().get(`/course?cap_level=${data?.cap_level ?? ''}`);
};

export const getCourseSingleApi = (data) => {
  return axiosInstance().get(`/course/${data?.slug}`);
};

export const getCourseNoteApi = (data) => {
  return axiosInstance().get(`/course/${data?.slug}/coursenotes`);
};

export const getCourseSingleNoteApi = (data) => {
  return axiosInstance().get(`/course-note/${data?.slug}`);
};

export const getCourseVideoApi = (params) => {
  const config = { params: params };
  return axiosInstance().get(`/course-video`, config);
};

export const getSingleCourseVideoApi = (videoId) => {
  return axiosInstance().get(`/course-video/${videoId}`);
};