import {
  ArticleOutlined,
  EventOutlined,
  Login,
  ManageSearchOutlined,
  People,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import { Button, Drawer, Modal, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import useCheckRole from "hooks/useCheckRole";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { logout } from "redux/auth/actions";
import Clipboard from "utils/icons/Clipboard";
import Unlock from "utils/icons/Unlock";
import User from "utils/icons/User";
import style from "./style";

const SideBar = () => {
  const classes = style();
  const { checkUserRole } = useCheckRole();
  const navBarHeight = 60;
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  // console.log("is medium screen", isMediumScreen);

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const { user } = useSelector((state) => state.auth);
  console.log("user", user?.role?.includes("Member"));

  console.log("uuuuuuuuuuuuuuuuu", {
    "user?.role": user?.role,
    user: checkUserRole(["General", "Member", "Admin", "SuperAdmin"]),
  });

  const handleLogOut = () => {
    dispatch(logout());
  };

  return (
    <Drawer
      className={classes.sidebar}
      variant="permanent"
      anchor={isMediumScreen ? "top" : "left"}
      open
      sx={{
        display: { md: "block" },
        height: "100%",
        width: "auto",
        // minWidth: "184px",
        "& .MuiDrawer-paper": {
          minHeight: { md: `calc(100vh - ${navBarHeight}px)` },
          width: { xs: "100vw", md: "initial" },
          position: "relative",
          top: 0,
          left: 0,
          padding: { xs: "0", md: "99px 11px" },
          overflowY: "auto",
          "& div": {
            flexDirection: { xs: "row", md: "column" },
            justifyContent: { xs: "center" },
          },
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <NavLink to="/dashboard/profile">
          <User /> Profile
        </NavLink>
        {checkUserRole(["Member"]) && (
          <NavLink to="/dashboard/courses">
            <Clipboard /> Courses
          </NavLink>
        )}
        <NavLink to="/dashboard/change-password">
          <Unlock /> Password
        </NavLink>
        {checkUserRole(["Member", "General"]) && (
          <NavLink to="/dashboard/booked-event">
            <EventOutlined sx={{ width: "14px", height: "14px" }} /> Event{" "}
          </NavLink>
        )}
        {checkUserRole(["General", "Member"]) && (
          <NavLink to="/dashboard/vacancy">
            <ManageSearchOutlined sx={{ width: "14px", height: "14px" }} />{" "}
            Vaccancy
          </NavLink>
        )}
        {checkUserRole(["Company"]) && (
          <NavLink to="/dashboard/job">
            <WorkOutlineOutlined sx={{ width: "14px", height: "14px" }} />{" "}
            Company Job
          </NavLink>
        )}
        {checkUserRole(["Member"]) && (
          <NavLink to="/dashboard/blog">
            <ArticleOutlined sx={{ width: "14px", height: "14px" }} /> Blog
          </NavLink>
        )}

        <NavLink to="/dashboard/community">
          <People sx={{ width: "14px", height: "14px" }} /> Community
        </NavLink>
        <Button
          // onClick={handleLogOut}
          // onClick={setOpenModal(true)}
          onClick={handleOpen}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            color: "#121127",
            background: "none",
            border: "none",
            justifyContent: "flex-start",
            padding: "8px 15px",
            fontSize: "14px",
            // lineHeight: "1.2",
          }}
        >
          <Login fontSize="small" sx={{ width: "15px", height: "15px" }} />
          Logout
        </Button>
        <Modal open={openModal} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              backgroundColor: "white",
              borderRadius: "4px",
              p: "15px",
              "&: focus-visible": {
                outline: "transparent",
              },
            }}
          >
            <Box sx={{ mb: "15px" }}>Are you sure you want to Logout?</Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "15px",
                "& button": {
                  padding: "5px 10px",
                  fontSize: "16px",
                  lineHeight: "1.2",
                },
              }}
            >
              <Button variant="contained" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="outlined" color="error" onClick={handleLogOut}>
                Yes
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Drawer>
  );
};

export default SideBar;
