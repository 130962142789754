import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  homeNewsContainer: {
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "60px 0",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      padding: "20px 0",
    },
  },
  homeNewsWrap: {
    width: "100%",
  },
  homeNewsTypographyMain: {
    marginBottom: "30px",
  },
  homeNewsTypography: {
    display: "flex",
    flexDirection: "column ",
    justifyContent: "center",
    alignItems: "center",
    // marginBottom: "30px !important",
  },
  homeNewsTitle: {
    color: "#DC2626",
    fontSize: "13px",
    fontWeight: "500",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    textTransform: "uppercase",
    marginBottom: "8px !important",
  },
  homeNewsHead: {
    color: "#201F37 !important",
    fontSize: "32px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    textTransform: "uppercase !important",
  },
  homeNewsLinks: {
    [theme.breakpoints.down("md")]: {
      margin: "auto",
    },
  },
  homeNewsLinkBox: {
    width: "90%",
    //  backgroundColor: "red",
    // margin: "1px 0",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 0,
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: "10px 0",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "10px 0",
    },
  },
  homeNewsLinkImgBox: {
    height: "110px",
    width: "220px",
    [theme.breakpoints.down("lg")]: {
      width: "90%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  homeNewsLinkImg: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  homeNewsLinkTypography: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
    paddingLeft: "20px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "10px",
    },
  },
  homeNewsTypoTitle: {
    color: " #383751 !important",
    // fontSize: "22px !important",
    fontSize: "18px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    lineHeight: "1 !important",
    // marginBottom: "5px !important",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      fontSize: "13px !important",
    },
  },
  homeNewsTypoSubtitle: {
    color: "#6C6B80 !important",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    marginBottom: "5px !important",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px !important",
    },
  },
  newsTimeBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  clockImgBox: {
    width: "15px",
    height: "15px",
    [theme.breakpoints.down("md")]: {
      width: "12px",
      height: "12px",
    },
  },
  clockImg: {
    width: "100%",
    height: "100%",
  },
  homeNewsTime: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "5px",
    color: "#4C4B63 !important",
    fontSize: "10px !important",
    fontWeight: "400 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },
  newsSeemoreBtn: {
    marginLeft: "2px",
    color: "#4E8AF4 !important",
    fontSize: "12px !important",
    fontWeight: "400 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],

    [theme.breakpoints.down("md")]: {
      fontSize: "10px !important",
    },
  },
  lineClamp: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    color: "#6C6B80 !important",
  },
  newsGridSeeBtnBox: {
    margin: "15px 0",
    display: "flex",
    justifyContent: "center",
  },
  newsGridSeeBtn: {
    border: "none",
    color: "#4C7CE5",
    backgroundColor: "#fff !important",
    fontSize: "13px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    textTransform: "capitalized !important",
    cursor: "pointer",
  },
  newsSectionBtnIcon: {
    width: "15px",
    height: "13px",
  },
}));

export default style;
