import Banner from "components/global/banner";
// import SingleCardMain from "components/common/cardMain";
// import NewsSection from "components/news/newsSection";
import style from "components/common/style";
import { useEffect, useState } from "react";

import { Box, Container, Grid, Typography } from "@mui/material";
import chevronDown from "assets/home/chevronDown.png";
import CardMainComponent from "components/common/cardMain";
import SkeletonLoaderTernory from "components/global/loader/skeletonLoader/SkeletonCardTernory";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getPublicatioinspage } from "./redux/actions";
// import PublicationsSingle from "components/local/publications/PublicationsSingle";

const Publications = () => {
  const pathname = window.location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const classes = style();

  const [singleNews, setSingleNews] = useState(false);
  const { publicationsPage, publications_loading } = useSelector(
    (state) => state.publications
  );
  const [publicationsData, setPublicationsData] = useState([]);

  console.log({ publicationsData });

  const dispatch = useDispatch();

  useEffect(() => {
    publicationsPage?.data &&
      setPublicationsData([
        ...(publicationsData || []),
        ...(publicationsPage?.data?.magazine_data?.magazine || []),
      ]);
  }, [publicationsPage?.data?.magazine_data?.pagination?.current_page]);

  useEffect(() => {
    dispatch(getPublicatioinspage());
  }, []);

  const narrowCard = useState(true);

  const handleMore = () => {
    const data = {
      page: publicationsPage?.data?.magazine_data?.pagination?.current_page + 1,
    };
    dispatch(getPublicatioinspage(data));
  };

  return (
    <>
      <Banner
        data={publicationsPage?.data?.magazine_banner}
        title={"Publications"}
      />

      <Box className={classes.newsSectionContainer}>
        <Container maxWidth="lg">
          <Box className={classes.newsSectionTitleBox}>
            <Typography className={classes.newsSectionTitle}>
              {/* {mainTitle} */}
              Latest Publications
            </Typography>
          </Box>
          <Box className={classes.newsSectionBox}>
            <Grid container spacing={8}>
              {/* {publications_loading
                ? [...Array(4)]?.map((item) => (
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                      <SkeletonLoaderTernory />
                    </Grid>
                  ))
                : publicationsData?.map((item) => (
                    <>
                      <CardMainComponent
                        item={item}
                        key={item?.id}
                        name={"publications"}
                      />
                    </>
                  ))} */}

              {publicationsData?.map((item) => (
                <>
                  <CardMainComponent
                    item={item}
                    key={item?.id}
                    name={"publications"}
                  />
                </>
              ))}
              {publications_loading &&
                [...Array(4)]?.map((item) => (
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <SkeletonLoaderTernory />
                  </Grid>
                ))}
            </Grid>
          </Box>

          {!publications_loading &&
            publicationsData?.length <
              publicationsPage?.data?.magazine_data?.pagination?.total && (
              <Box className={classes.newsSeeMoreBtnBox} onClick={handleMore}>
                <NavLink className={classes.newsSeeMoreBtn}>See More</NavLink>
                <Box className={classes.newsSeeMoreArrow}>
                  <img src={chevronDown} alt="" />
                </Box>
              </Box>
            )}
        </Container>
      </Box>
    </>
    //   )}
    // </div>
  );
};

export default Publications;
