// import Protected from "components/global/protected";

import BlogAddPage from "pages/dashboard/blogAddPage/BlogAddPage";
import BlogEditPage from "pages/dashboard/blogEditPage/BlogEditPage";
import BlogPage from "pages/dashboard/blogPage/BlogPage";
import BookedEventPage from "pages/dashboard/bookedEventPage/BookedEventPage";
import ChangePasswordPage from "pages/dashboard/changePasswordPage/ChangePasswordPage";
import CommunityPage from "pages/dashboard/communityPage/CommunityPage";
import CourseLevelPage from "pages/dashboard/courseLevelPage/CourseLevelPage";
import NotePage from "pages/dashboard/courseSinglePage/notesPage/NotePage";
import VideoPage from "pages/dashboard/courseSinglePage/videoPage/VideoPage";
import CoursesPage from "pages/dashboard/coursesPage/CoursesPage";
import JobAddPage from "pages/dashboard/jobAddPage/JobAddPage";
import JobEditPage from "pages/dashboard/jobEditPage/JobEditPage";
import JobPage from "pages/dashboard/jobPage/JobPage";
import ProfilePage from "pages/dashboard/profilePage/ProfilePage";
import VacancyPage from "pages/dashboard/vacancyPage/vacancyPage";

const dashboardRoutes = [
  // {
  //   path: "/dashboard",
  //   component: (
  //     <Protected>
  //       <Dashboard />
  //     </Protected>
  //   ),
  // },
  {
    path: "profile",
    component: <ProfilePage />,
  },
  {
    path: "change-password",
    component: <ChangePasswordPage />,
  },
  { path: "courses/cap", component: <CoursesPage /> },
  { path: "courses/cap/:id", component: <CoursesPage /> },
  { path: "courses/cap/videos/:id", component: <VideoPage /> },
  { path: "courses/cap/notes/:id", component: <NotePage /> },
  { path: "booked-event", component: <BookedEventPage /> },
  { path: "vacancy", component: <VacancyPage /> },
  { path: "job", component: <JobPage /> },
  {
    path: "job/:slug",
    component: <JobEditPage />,
  },
  { path: "job/add-job", component: <JobAddPage /> },
  { path: "blog", component: <BlogPage /> },
  {
    path: "blog/:slug",
    component: <BlogEditPage />,
  },
  { path: "blog/add-blog", component: <BlogAddPage /> },
  { path: "community", component: <CommunityPage /> },
  { path: "courses", component: <CourseLevelPage /> },
];

export default dashboardRoutes;
