import ContactUs from 'components/local/contactUs'
import React from 'react'

const ContactUsPage = () => {
  return (
    <>
      <ContactUs />
    </>
  );
};

export default ContactUsPage;
