import AboutComponent from "components/global/aboutComponent/AboutComponent";
import Banner from "components/global/banner";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAboutSinglepage } from "./redux/actions";

const AboutUs = () => {
  const { slug } = useParams();

  console.log({ slug });

  const pathname = window.location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { aboutSinglePage, aboutSingle_loading } = useSelector(
    (state) => state.aboutSingle
  );

  console.log({ aboutSingle_loading });

  const { aboutPage, about_loading } = useSelector((state) => state.about);

  console.log({ about_loading });

  const [aboutSingleData, setAboutSingleData] = useState([]);

  console.log({ aboutPage });
  const dispatch = useDispatch();

  useEffect(() => {
    aboutSinglePage?.data && setAboutSingleData(aboutSinglePage);
  }, [aboutSinglePage]);

  useEffect(() => {
    const data = {
      slug: slug ?? "",
    };
    dispatch(getAboutSinglepage(data));
  }, [slug]);
  return (
    <>
      <Banner data={aboutPage?.data} title={aboutSingleData} />
      <AboutComponent data={aboutSingleData} />
    </>
  );
};

export default AboutUs;
