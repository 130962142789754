import ComplainIcon from "components/local/home/complainIcon";
import Footer from "layouts/footer";
import Navbar from "layouts/navbar";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Layout = ({ children }) => {
  const { siteSetting, siteSetting_loading } = useSelector(
    (state) => state.siteSetting
  );
  const [siteData, setSiteData] = useState([]);

  console.log({ siteData });

  useEffect(() => {
    siteSetting?.data && setSiteData(siteSetting?.data);
  }, [siteSetting]);

  return (
    <>
      <Navbar data={siteData} />
      <ComplainIcon />

      <main>{children}</main>
      <Footer data={siteData} />
    </>
  );
};

export default Layout;
