import { Box, Container, Grid, Skeleton, Typography } from "@mui/material";
import style from "components/local/home/quickLinks/style";
import { useSelector } from "react-redux";

const QuickLinks = ({ homeData }) => {
  const { homepage_loading } = useSelector((state) => state.home);

  const classes = style();
  return (
    <Box className={classes.quickLinksContainer}>
      {/* <Box className={classes.quickLinkTypography}>
        <Typography className={classes.quickLinkHead}>Quick Links</Typography>
      </Box> */}

      <Container maxWidth="lg">
        <Box className={classes.quickLinkBox}>
          <Grid container>
            {homepage_loading
              ? [...Array(5)]?.map((item) => (
                  <Grid item lg={2.4} md={4} sm={4} xs={6}>
                    <Skeleton variant="rectangular" width={180} height={180} />
                  </Grid>
                ))
              : homeData?.quick_links?.map((item) => {
                  return <QuickLinksComponent key={item.id} item={item} />;
                })}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

const QuickLinksComponent = ({ item }) => {
  const classes = style();

  return (
    <Grid item lg={2} md={4} sm={4} xs={6} className={classes.paperContainer}>
      <a
        href={item?.link}
        // target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none" }}
      >
        <Box className={classes.paperBox}>
          <Box className={classes.quickIconBox}>
            <img src={item?.icon} alt="" className={classes.quickIcon} />
          </Box>
          <Typography className={classes.linkText}>{item?.title}</Typography>
        </Box>
      </a>
    </Grid>
  );
};

export default QuickLinks;
