import { getContactUsApi, postContactUsApi } from "apis";
import * as actions from "components/local/contactUs/redux/types";
import { errorToast, successToast } from "utils/toast";

export const postContactus =
  (Data, refetch = () => {}) =>
  async (dispatch) => {
    dispatch({ type: actions.POST_CONTACT_US_BEGIN });

    postContactUsApi(Data)
    .then((res) => {
      console.log("dataaa", Data);
      refetch && refetch();
      dispatch({
        type: actions.POST_CONTACT_US_SUCCESS,
        payload: "",
      });
      successToast(res?.data?.message);
    }) .catch ((error) => {
      dispatch({ type: actions.POST_CONTACT_US_ERROR });
      console.log(error);
      errorToast(error);
    })
  };

export const getContactus = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_CONTACT_US_BEGIN });

  try {
    const { data } = await getContactUsApi(Data);
    dispatch({
      type: actions.GET_CONTACT_US_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_CONTACT_US_ERROR });
    console.log(error);
    errorToast(error);
  }
};
