import * as actions from "./types";

const initialState = {
  status: "idle",
  submitStatus: "idle",
  data: [],
  eventDates: [],
  singleEventData: [],
  banner: null,
  pagination: {},
  currentMonthEvent: [],
  appliedEvent: {
    status: "idle",
    data: [],
  },
  date: new Date(),
  event_loading: false,
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_EVENT_PENDING:
      return { ...state, status: "pending" };
    case actions.FETCH_EVENT_SUCCESS:
      return {
        ...state,
        status: "completed",
        data: action.payload?.event_data?.event,
        pagination: action.payload?.event_data?.pagination,
        banner: action.payload?.event_banner,
        singleEventData: [],
      };
    case actions.FETCH_EVENT_ERROR:
      return { ...state, status: "failed" };
    case actions.FETCH_SINGLE_EVENT_SUCCESS:
      return { ...state, singleEventData: action.payload, state: "completed" };
    case actions.FETCH_SINGLE_EVENT_ERROR:
      return { ...state, status: "failed" };
    case actions.POST_EVENT_PENDING:
      return { ...state, submitStatus: "pending" };
    case actions.POST_EVENT_SUCCESS:
      return { ...state, submitStatus: "completed" };
    case actions.POST_EVENT_ERROR:
      return { ...state, submitStatus: "failed" };
    case actions.RESET_STATUS:
      return { ...state, submitStatus: "idle" };
    case actions.FETCH_CURRENT_MONTHS_EVENT_BEGIN:
      return { ...state, event_loading: true };
    case actions.FETCH_CURRENT_MONTHS_EVENT_SUCCESS:
      return {
        ...state,
        currentMonthEvent: action.payload,
        eventDates: action?.payload?.map((item) => item.date),
        event_loading: false,
      };
    case actions.FETCH_CURRENT_MONTHS_EVENT_ERROR:
      return { ...state, event_loading: false };
    case actions.FETCH_APPLIED_EVENTS:
      return {
        // ...state,
        // appliedEvent: {
        // ...state,
        ...state,
        status: "completed",
        data: action?.payload?.map((item) => {
          console.log({ item });
          return {
            value: item,
            id: item?.id,
            event: item?.event?.title,
            name: item?.name,
            email: item?.email,
            phone: item?.phone,
            location: item?.event?.location,
            date: item?.event?.date,
            time: item?.event?.time,
            delete: "Delete",
          };
        }),
        // },
      };
    case actions.FETCH_APPLIED_EVENT_ERROR:
      return {
        ...state,
        appliedEvent: { ...state.appliedEvent, status: "failed" },
      };
    case actions.RESET_APPLIED_EVENT_STATUS:
      return {
        ...state,
        appliedEvent: { ...state.appliedEvent, status: "idle" },
      };
    case actions.FETCH_APPLIED_EVENTS_PENDING:
      return {
        ...state,
        appliedEvent: { ...state.appliedEvent, status: "pending" },
      };
    case actions.GET_CURRENT_DATE:
      return {
        ...state,
        date: action.payload,
      };
    default:
      return state;
  }
};

export default eventReducer;
