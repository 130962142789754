import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

export default function TeamSingleLoading() {
  return (
    <Box sx={{ marginBottom: "40px" }}>
      <Box sx={{ display: "flex", gap: "10px", marginBottom: "40px" }}>
        <Skeleton variant="rectangular" width={200} height={180} />

        <Box sx={{ width: "80%", marginLeft: "50px" }}>
          {/* <Skeleton variant="text" width={80} /> */}
          <Skeleton variant="text" width={250} />
          <Skeleton variant="text" width={150} />
          <Skeleton variant="text" width={280} />
          <Skeleton variant="text" width={300} />
          <Box sx={{ display: "flex", width: "80%", marginTop: "20px" }}>
            {/* <Skeleton variant="text" width={80} /> */}
            <Skeleton
              variant="rounded"
              width={30}
              height={30}
              sx={{ marginRight: "20px" }}
            />
            <Skeleton
              variant="rounded"
              width={30}
              height={30}
              sx={{ marginRight: "20px" }}
            />
            <Skeleton
              variant="rounded"
              width={30}
              height={30}
              sx={{ marginRight: "20px" }}
            />
          </Box>
        </Box>

        {/* <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rounded" width={210} height={60} /> */}
      </Box>
      <Box sx={{ width: "80%", marginTop: "40px" }}>
        {/* <Skeleton variant="text" width={80} /> */}
        <Skeleton variant="text" width={150} />
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="100%" />
      </Box>
    </Box>
  );
}
