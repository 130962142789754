import { getVolunteerApi, postVolunteerApi } from "apis";
import * as actions from "components/local/volunteer/redux/types";
import { errorToast, successToast } from "utils/toast";

export const postVolunteer =
  (Data, refetch = () => {}) =>
  async (dispatch) => {
    dispatch({ type: actions.POST_VOLUNTEER_BEGIN });
    postVolunteerApi(Data)
    .then((res) => {
      console.log("dataaa", Data);
      refetch && refetch();
      dispatch({
        type: actions.POST_VOLUNTEER_SUCCESS,
        payload: "",
      });
      successToast(res?.data?.message);
    }) 
    .catch ((error) => {
      dispatch({ type: actions.POST_VOLUNTEER_ERROR });
      console.log(error);
      errorToast(error);
    })
  };

  export const getVolunteer = (Data) => async (dispatch) => {
    dispatch({ type: actions.GET_VOLUNTEER_BEGIN });
  
    try {
      const { data } = await getVolunteerApi(Data);
      dispatch({
        type: actions.GET_VOLUNTEER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({ type: actions.GET_VOLUNTEER_ERROR });
      console.log(error);
      errorToast(error);
    }
  };