import {
  Box,
  Card,
  CardMedia,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import chevronDown from "assets/home/chevronDown.png";
import BackButton from "components/global/buttons/backButton/BackButton";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { getAlbumSingle } from "../gallery/redux/actions";
import ImageModal from "./ImageModal";
import style from "./style";

const GallerySingle = () => {
  const pathname = window.location.pathname;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const dispatch = useDispatch();
  const { id } = useParams();
  console.log({ id });
  const { albumSingle, albumSingle_loading } = useSelector(
    (state) => state.album
  );
  const [albumSingleData, setAlbumSingleData] = useState([]);
  console.log("ewewewewewewewewe", { albumSingleData });

  useEffect(() => {
    albumSingle?.data &&
      setAlbumSingleData([
        ...(albumSingleData || []),
        ...(albumSingle?.data?.album_gallery_data?.gallery || []),
      ]);
  }, [albumSingle?.data?.album_gallery_data?.pagination?.current_page]);

  useEffect(() => {
    const data = {
      id: id ?? "",
    };
    dispatch(getAlbumSingle(data));
  }, [id]);

  const handleMore = () => {
    const data = {
      page: albumSingle?.data?.album_gallery_data?.pagination?.current_page + 1,
      id: id ?? "",
    };
    dispatch(getAlbumSingle(data));
  };

  const classes = style();
  const [image, setImage] = useState({});
  console.log("image", image);

  // const getAlbumTitle = (category_id) => {
  //   const filterData = albumSingleData?.filter(
  //     (item) => item?.album?.id === category_id
  //   );

  //   console.log({ filterData });

  //   return filterData || [];
  // };

  console.log("wqwqwqwqwqwqwqw", albumSingleData);

  const unique = [
    ...new Set(albumSingleData?.map((item) => item?.album?.title)),
  ];

  console.log("wqwqdsawqwqwqwqwqw", unique);
  return (
    <div>
      <Container>
        <Box sx={{ padding: "32px 0" }}>
          <BackButton />
          <Box>
            <Typography component="h1" className={classes.header}>
              {/* {albumSingleData && */}
              {albumSingleData?.length === 0
                ? "No photos available"
                : `Photos - ${unique?.map((item) => item)}`}
              {/* Photos */}
            </Typography>
          </Box>
          <Grid container spacing={4}>
            {/* {albumSingle_loading
              ? [...Array(3)]?.map((item) => (
                  <Grid item md={4} sm={6} xs={12} mb="1rem">
                    <Skeleton variant="rectangular" width="100%" height={250} />
                  </Grid>
                ))
              : albumSingleData?.map((card, index) => {
                  return (
                    <GallerySingleComponent
                      key={card?.id}
                      card={{ index, ...card }}
                      setImage={setImage}
                    />
                  );
                })} */}

            {albumSingleData?.map((card, index) => {
              return (
                <GallerySingleComponent
                  key={card?.id}
                  card={{ index, ...card }}
                  setImage={setImage}
                />
              );
            })}
            {albumSingle_loading &&
              [...Array(3)]?.map((item) => (
                <Grid item md={4} sm={6} xs={12} mb="1rem">
                  <Skeleton variant="rectangular" width="100%" height={250} />
                </Grid>
              ))}
          </Grid>
          {/* <ButtonTertiary>See More</ButtonTertiary> */}
          {!albumSingle_loading &&
            albumSingleData?.length <
              albumSingle?.data?.album_gallery_data?.pagination?.total && (
              <Box className={classes.newsSeeMoreBtnBox} onClick={handleMore}>
                <NavLink className={classes.newsSeeMoreBtn}>See More</NavLink>
                <Box className={classes.newsSeeMoreArrow}>
                  <img src={chevronDown} alt="" />
                </Box>
              </Box>
            )}
        </Box>
        {Object.keys(image)?.length !== 0 && (
          <ImageModal
            data={albumSingleData}
            image={image}
            setImage={setImage}
          />
        )}
      </Container>
    </div>
  );
};

const GallerySingleComponent = ({ card, setImage }) => {
  const classes = style();

  return (
    <>
      <Grid item md={4} sm={6} xs={12} onClick={() => setImage(card)}>
        <Card className={classes.card} elevation={0} square>
          <CardMedia image={card?.image} sx={{ height: "240px" }} />
        </Card>
      </Grid>
    </>
  );
};

export default GallerySingle;
