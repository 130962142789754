import { Box, Container, Grid, Typography } from "@mui/material";
// import homeAboutImg from "assets/home/homeAboutImg.png";
// import homeAboutImg2 from "assets/home/homeAboutImg2.png";
import style from "./style";
import presidentIMG from "assets/home/presidentIMG.png"
import ButtonPrimary from "components/global/buttons/buttonPrimary/ButtonPrimary";
import { useNavigate } from "react-router-dom";
import ReadMoreBtn from "components/global/readMoreBtn";
import ReadMoreButton from "components/global/buttons/readMoreButton/ReadMoreButton";
import AboutPrDesc from "components/global/description/AboutPrDesc";

const AboutPresident = ({ homeData }) => {
  const classes = style();

  // const dispatch = useDispatch();
  // const authState = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const presidentNavigate = () => {
    navigate("/president-message");
  };

  // const eventsNavigate = () => {
  //   navigate("/events");
  // };
  // const loginModalDisplay = () => {
  //   dispatch({ type: actions.SHOW_LOGIN_FORM });
  // };

  return (
    <Box className={classes.homeAboutContainer}>
      {/* <Box className={[classes.homeAboutWrap, "container-wrap-secondary"]}> */}
      <Container>
        <Grid container className={classes.aboutContainer}>
          <Grid item md={3} xs={12}>
            <Box className={classes.imageContainerMain}>
              <Box className={classes.imageContainer}>
                {/* <Box className={classes.borderBox}></Box> */}
                <Box className={classes.borderBoxImg}>
                  {/* <img src={homeAboutImg} alt="" className={classes.boxImg} /> */}
                  <img
                    // src={homeData?.site_setting?.about_us_image}
                    src={homeData?.site_setting?.president_image}
                    alt=""
                    className={classes.boxImg}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={9} xs={12} className={classes.aboutBox}>
            <Typography className={classes.aboutTitle}>
              Our President
            </Typography>
            <Typography className={classes.aboutHead}>Message from our president</Typography>
            <Typography className={classes.aboutPara}>
              {/* <Description about length={false} description={homeData?.site_setting?.about} /> */}
              <AboutPrDesc
                description={homeData?.site_setting?.president_message}
              />
              
            </Typography>
            <Box>
            <ReadMoreButton
              onClick={presidentNavigate}
              title={"Read More"}
              className={classes.aboutBtn}
              border={"border"}
              sx={{width: '20%'}}
            />
            </Box>
            {/* <ButtonPrimary
              // onClick={aboutNavigate}
              title={"Read More"}
              className={classes.aboutBtn}
              border={"border"}
            /> */}
          </Grid>
          {/* <Grid item md={3} xs={12} className={classes.grid2}>
            <Box className={classes.aboutImg2Box}>
              <Box>
                <Box className={classes.homeAboutTextWrap}>
                  <Typography className={classes.homeAboutText}>
                    Wants to know more about our Events?
                  </Typography>
                  {authState.isUserLoggedIn ? (
                    <ButtonSecondary
                      title={"About us"}
                      sx={{ margin: "0 auto !important" }}
                      onClick={aboutNavigate}
                    />
                  ) : (
                    <ButtonSecondary
                      title={"Login"}
                      sx={{ margin: "0 auto !important" }}
                      onClick={loginModalDisplay}
                    />
                  )}

                  <Typography className={classes.orText}>or</Typography>

                  <ButtonSecondary
                    onClick={eventsNavigate}
                    title={"Go to events"}
                    border={"border"}
                  />
                </Box>
                <img src={homeAboutImg2} alt="" className={classes.aboutImg2} />
              </Box>
            </Box>
          </Grid> */}
        </Grid>
      </Container>
      {/* </Box> */}
    </Box>
  );
};

export default AboutPresident;
