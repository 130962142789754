import { getAboutApi, getAboutSingleApi } from "apis";
import * as actions from "components/local/aboutUs/redux/types";

export const getAboutpage = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_ABOUT_BEGIN });

  try {
    const { data } = await getAboutApi(Data);
    dispatch({
      type: actions.GET_ABOUT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_ABOUT_ERROR });
    console.log(error);
  }
};

export const getAboutSinglepage = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_ABOUT_SINGLE_BEGIN });

  try {
    const { data } = await getAboutSingleApi(Data);
    dispatch({
      type: actions.GET_ABOUT_SINGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_ABOUT_SINGLE_ERROR });
    console.log(error);
  }
};
