import { Box, Grid, Typography } from "@mui/material";
import style from "components/common/style";
import Description from "components/global/description/Description";
import moment from "moment";
import { NavLink } from "react-router-dom";

const SingleCardArticle = ({ data: item, bannerData, articleHead, name }) => {
  const classes = style();

  console.log({ item });

  const getFormattedDate = (date) => {
    const dates = new Date(date);
    return moment(dates)?.format("MMM-DD");
  };

  // const { title } = bannerData;

  return (
    <Grid item xs={12} sm={6} md={12}>
      <NavLink to={`/${name}/${item?.slug}`} style={{ textDecoration: "none" }}>
        <Box className={classes.newsMoreSectionArticle}>
          {item?.image && (
            <Box className={classes.newsMoreSectionImgBox}>
              <img
                src={item?.image}
                alt=""
                className={classes.newsMoreSectionImg}
              />
            </Box>
          )}
          <Box className={classes.newsMoreSectionInfoWrap}>
            {/* {item?.type && (
              <Typography className={classes.newsMoreSectionMetaTitle}>
                {item?.type}
              </Typography>
            )} */}

            <Typography className={classes.newsMoreSectionInfoTitle}>
              {item?.title}
            </Typography>
            {name === 'news' ? '' : 
            
            <Typography className={classes.newsMoreSectionInfoParagraph}>
              <Description length={false} description={item?.description}/>
            </Typography>
            }
             {name === 'news' ? '' : 
             
            <Typography className={classes.newsMoreSectionDate}>
              {getFormattedDate(item?.updated_at)}
            </Typography>
            }
          </Box>
        </Box>
      </NavLink>
    </Grid>
  );
};


export default SingleCardArticle;
