import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  imageContainer: {
    width: "168px",
    height: "114px",
  },
  header: {
    fontFamily: ["Sofia sans", "sans-serif", "!important"],
    fontSize: "25px !important",
    fontWeight: "800 !important",
    color: "#383751",
    lineHeight: "1.25 !important",
  },
  text: {
    fontFamily: ["Sofia sans", "sans-serif", "!important"],
    fontSize: "16px !important",
    fontWeight: "500 !important",
    color: theme.palette.grey[1],
    lineHeight: "1.1 !important",
  },
  textSmall: {
    fontFamily: ["Sofia sans", "sans-serif", "!important"],
    fontSize: "16px !important",
    fontWeight: "400 !important",
    color: "#514B4B",
    lineHeight: "1.4 !important",
  },
  backBtnBox: {
    marginBottom: "20px",
  },
  backBtn: {
    border: "none",
    backgroundColor: "rgb(255, 255, 255, .0)",
    display: "flex",
    alignItems: "center",
    gap: 3,
    padding: "5px",
    cursor: "pointer",
    color: "#858689",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },
  backArrow: {
    width: "16px",
    height: "16px",
  },
}));

export default style;
