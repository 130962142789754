import * as actions from "components/local/dashboard/job/redux/types";
const initialState = {
  job: [],
  jobApplications: [],
  jobApplications_loading: false,
  job_loading: false,
};

const jobDashReducer = (state = initialState, action) => {
  switch (action.type) {
    // post,put,delete job

    case actions.POST_JOB_BEGIN:
    case actions.PUT_JOB_BEGIN:
    case actions.DELETE_JOB_BEGIN:
      return { ...state, job_loading: true };

    case actions.POST_JOB_SUCCESS:
    case actions.PUT_JOB_SUCCESS:
    case actions.DELETE_JOB_SUCCESS:
      return { ...state, job_loading: false };

    case actions.POST_JOB_ERROR:
    case actions.PUT_JOB_ERROR:
    case actions.DELETE_JOB_ERROR:
      return { ...state, job_loading: false };

    // get job

    case actions.GET_JOB_BEGIN:
      return { ...state, job_loading: true };

    case actions.GET_JOB_SUCCESS:
      return { ...state, job_loading: false, job: action.payload };

    case actions.GET_JOB_ERROR:
      return { ...state, job_loading: false };

    // get job applications

    case actions.GET_JOB_APPLICATIONS_BEGIN:
      return { ...state, jobApplications_loading: true };

    case actions.GET_JOB_APPLICATIONS_SUCCESS:
      return {
        ...state,
        jobApplications_loading: false,
        jobApplications: action.payload,
      };

    case actions.GET_JOB_APPLICATIONS_ERROR:
      return { ...state, jobApplications_loading: false };
    default:
      return state;
  }
};

export default jobDashReducer;
