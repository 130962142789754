import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  dashboardTitleBox: {
    padding: "44px 65px 0rem",
  },

  // form
  addForm: {
    height: "auto",
    padding: "2rem",
  },
  backBtnBox: {
    marginBottom: "20px",
  },
  backBtn: {
    border: "none",
    backgroundColor: "rgb(255, 255, 255, .0)",
    display: "flex",
    alignItems: "center",
    gap: 3,
    padding: "5px",
    cursor: "pointer",
    color: "#858689",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },
  backArrow: {
    width: "16px",
    height: "16px",
  },
  submitButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  formSubmitBtn: {
    backgroundColor: "#1B55E2 !important",
    color: "#fff !important",
    fontSize: "16px !important",
    fontWeight: "400 !important",
    padding: "5px 14px  !important",
  },
  applicantHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  closeIcon: {
    width: "16px !important",
    height: "16px !important",
  },
  closeBtn: {
    backgroundColor: "#EF4444 !important",
    color: "#fff !important",
    fontSize: "16px !important",
    fontWeight: "400 !important",
    padding: "2px 14px  !important",
  },
  applicantTable: {
    marginTop: "2rem",
  },
  applicantTableHead: {},

  // image view btn
  imageViewBtnBox: {
    display: "flex",
    flexDirection: "space-between",
  },

  imageViewBtn: {
    padding: "2px !important",
    color: "#1B55E2 !important",
    fontSize: "14px !important",
    display: "flex",
    flexDirection: "space-between",
  },
}));

export default style;
