import newsImg from "assets/news/newsImg.png";
import Banner from "components/global/banner";
import ContactForm from "components/local/contactUs/ContactForm";
import MapsSection from "components/local/contactUs/MapsSection";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ComplainForm from "./ComplainForm";
import { getComplain } from "./redux/actions";


const Complain = () => {

  const pathname = window.location.pathname;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const dispatch = useDispatch()
  const { getcomplain, getcomplain_loading } = useSelector(
    (state) => state.complain
  );

  const [complainData, setComplainData] = useState([]);

  console.log({complainData})

    useEffect(() => {
      getcomplain?.data && setComplainData(getcomplain?.data)
    },[getcomplain?.data])

    useEffect(() => {
      dispatch(getComplain())
    },[])

  // const [getcontactusData, setGetcontactusData] = useState([]);

  // console.log({ getcontactusData });

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   getcontactus?.data && setGetcontactusData(getcontactus?.data);
  // }, [getcontactus]);

  // useEffect(() => {
  //   dispatch(getContactus());
  // }, []);

  // const bannerData = {
  //   title: "Contact us",
  //   picture: newsImg,
  // };

  return (
    <>
      <Banner data={complainData?.complain_banner}  title={"Complain"} />
      <ComplainForm />
      
    </>
  );
};

export default Complain;
