import {
  deleteCommentApi,
  deleteDownvotevoteApi,
  deleteThreadApi,
  deleteUpvoteApi,
  getThreadApi,
  postCommentApi,
  postDownvoteApi,
  postThreadApi,
  postUpvoteApi,
  putCommentApi,
  putThreadApi,
} from "apis/community";
import * as actions from "components/local/dashboard/community/redux/types";
import { errorToast, successToast } from "utils/toast";

export const postThread =
  (Data, refetch = () => {}) =>
  async (dispatch) => {
    dispatch({ type: actions.POST_THREAD_BEGIN });

    try {
      await postThreadApi(Data);
      console.log("dataaa", Data);
      refetch && refetch();
      dispatch({
        type: actions.POST_THREAD_SUCCESS,
        payload: "",
      });
      successToast();
    } catch (error) {
      dispatch({ type: actions.POST_THREAD_ERROR });
      console.log(error);
      errorToast(error);
    }
  };

export const getThread = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_THREAD_BEGIN });

  try {
    const { data } = await getThreadApi(Data);
    dispatch({
      type: actions.GET_THREAD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_THREAD_ERROR });
    console.log(error);
    errorToast(error);
  }
};

export const putThread =
  (Data, refetch = () => {}) =>
  async (dispatch) => {
    dispatch({ type: actions.PUT_THREAD_BEGIN });

    try {
      await putThreadApi(Data);
      refetch && refetch();
      dispatch({
        type: actions.PUT_THREAD_SUCCESS,
        payload: "",
      });
      successToast();
    } catch (error) {
      dispatch({ type: actions.PUT_THREAD_ERROR });
      console.log(error);
      errorToast(error);
    }
  };

export const deleteThread =
  (Data, refetch = () => {}) =>
  async (dispatch) => {
    dispatch({ type: actions.DELETE_THREAD_BEGIN });

    try {
      await deleteThreadApi(Data);
      refetch && refetch();
      dispatch({
        type: actions.DELETE_THREAD_SUCCESS,
        payload: "",
      });
      successToast();
    } catch (error) {
      dispatch({ type: actions.DELETE_THREAD_ERROR });
      console.log(error);
      errorToast(error);
    }
  };

export const postComment =
  (Data, refetch = () => {}) =>
  async (dispatch) => {
    dispatch({ type: actions.POST_COMMENT_BEGIN });

    try {
      await postCommentApi(Data);
      console.log("dataaa", Data);
      refetch && refetch();
      dispatch({
        type: actions.POST_COMMENT_SUCCESS,
        payload: "",
      });
      successToast();
    } catch (error) {
      dispatch({ type: actions.POST_COMMENT_ERROR });
      console.log(error);
      errorToast(error);
    }
  };

export const putComment =
  (Data, refetch = () => {}) =>
  async (dispatch) => {
    dispatch({ type: actions.PUT_COMMENT_BEGIN });

    try {
      await putCommentApi(Data);
      refetch && refetch();
      dispatch({
        type: actions.PUT_COMMENT_SUCCESS,
        payload: "",
      });
      successToast();
    } catch (error) {
      dispatch({ type: actions.PUT_COMMENT_ERROR });
      console.log(error);
      errorToast(error);
    }
  };

export const deleteComment =
  (Data, refetch = () => {}) =>
  async (dispatch) => {
    dispatch({ type: actions.DELETE_COMMENT_BEGIN });

    try {
      await deleteCommentApi(Data);
      refetch && refetch();
      dispatch({
        type: actions.DELETE_COMMENT_SUCCESS,
        payload: "",
      });
      successToast();
    } catch (error) {
      dispatch({ type: actions.DELETE_COMMENT_ERROR });
      console.log(error);
      errorToast(error);
    }
  };

export const postUpvote =
  (Data, refetch = () => {}) =>
  async (dispatch) => {
    dispatch({ type: actions.POST_UPVOTE_BEGIN });

    try {
      await postUpvoteApi(Data);
      console.log("dataaa", Data);
      dispatch({
        type: actions.POST_UPVOTE_SUCCESS,
        payload: "",
      });
      refetch && refetch();
      successToast();
    } catch (error) {
      dispatch({ type: actions.POST_UPVOTE_ERROR });
      console.log(error);
      errorToast(error);
    }
  };

export const postDownvote =
  (Data, refetch = () => {}) =>
  async (dispatch) => {
    dispatch({ type: actions.POST_DOWNVOTE_BEGIN });

    try {
      await postDownvoteApi(Data);
      console.log("dataaa", Data);
      dispatch({
        type: actions.POST_DOWNVOTE_SUCCESS,
        payload: "",
      });
      refetch && refetch();
      successToast();
    } catch (error) {
      dispatch({ type: actions.POST_DOWNVOTE_ERROR });
      console.log(error);
      errorToast(error);
    }
  };

export const deleteUpvote =
  (Data, refetch = () => {}) =>
  async (dispatch) => {
    dispatch({ type: actions.DELETE_UPVOTE_BEGIN });

    try {
      await deleteUpvoteApi(Data);
      refetch && refetch();
      dispatch({
        type: actions.DELETE_UPVOTE_SUCCESS,
        payload: "",
      });
      successToast();
    } catch (error) {
      dispatch({ type: actions.DELETE_UPVOTE_ERROR });
      console.log(error);
      errorToast(error);
    }
  };

export const deleteDownvote =
  (Data, refetch = () => {}) =>
  async (dispatch) => {
    dispatch({ type: actions.DELETE_DOWNVOTE_BEGIN });

    try {
      await deleteDownvotevoteApi(Data);
      refetch && refetch();
      dispatch({
        type: actions.DELETE_DOWNVOTE_SUCCESS,
        payload: "",
      });
      successToast();
    } catch (error) {
      dispatch({ type: actions.DELETE_DOWNVOTE_ERROR });
      console.log(error);
      errorToast(error);
    }
  };
