import * as actions from "./types";

const initialState = {
    isLoading: false,
    bannerData: [],
};

const bannerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.FETCH_BANNER_BEGIN:
            return { ...state, isLoading: true };

        case actions.FETCH_BANNER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                suchana: action.payload,
            };

        case actions.FETCH_BANNER_ERROR:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};

export default bannerReducer;
