import { Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Description from "components/global/description/Description";
import { useDispatch, useSelector } from "react-redux";
import { getSingleCourseVideo } from "../courses/redux/actions";

const VideoList = ({ data, loading }) => {
  const dispatch = useDispatch();
  const { coursesSingleVideo } = useSelector((state) => state.courses);
  const [currentVideoId, setCurrentVideoId] = useState("");

  const loadVideo = (videoId, slug) => {
    // only dispatch action if the currently loaded video is different
    if (videoId === coursesSingleVideo?.id) return;

    dispatch(getSingleCourseVideo(slug));
  };

  useEffect(() => {
    if (!currentVideoId) {
      setCurrentVideoId(coursesSingleVideo?.id);
    }
  }, [currentVideoId, coursesSingleVideo.id]);

  return (
    <Box>
      <Box sx={{ mb: "17px" }}>
        <Typography
          component="h2"
          fontWeight={"700"}
          fontSize="22px"
          color="#000"
        >
          All Videos
        </Typography>
      </Box>
      {loading
        ? [...Array(3)]?.map((item, index) => (
            // <Grid item lg={3} md={4} sm={4} xs={12} mb="1rem">
            <Skeleton
              key={index}
              variant="rounded"
              sx={{ marginBottom: "2rem" }}
              height={110}
            />
            // </Grid>
          ))
        : data?.map((item) => {
            return (
              <Box
                key={item.id}
                sx={{
                  display: "flex",
                  gap: "25px",
                  mb: "30px",
                  cursor: "pointer",
                  padding: "10px",
                  background: currentVideoId === item.id ? "white" : "",
                }}
                onClick={() => {
                  setCurrentVideoId(item.id);
                  loadVideo(item.id, item.slug);
                }}
              >
                <Box
                  sx={{ width: "165px", flexShrink: "0", position: "relative" }}
                >
                  <img
                    src={item?.extra?.pictures?.base_link}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "4px",
                      right: "4px",
                      borderRadius: "4px",
                      paddingInline: "2px",
                      background: "rgba(0, 0, 0, 0.7)",
                    }}
                  >
                    <Typography
                      component="h4"
                      fontWeight="500"
                      fontSize={"14px"}
                      color="#fff"
                    >
                      {item?.extra?.duration}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    component="h3"
                    textTransform="capitalize"
                    fontSize="17px"
                    fontWeight="700"
                    color="#383751"
                    marginBottom="6.5px"
                  >
                    {item?.title}
                  </Typography>
                  <Box fontSize={"12px"} color="#5A5551">
                    <Description
                      length={false}
                      description={item?.description}
                      styles={{ minHeight: "initial", height: "100%" }}
                    />
                  </Box>
                </Box>
              </Box>
            );
          })}
    </Box>
  );
};

export default VideoList;
