import { Box, Container, Grid, Typography } from "@mui/material";
import backArrow from "assets/news/backArrow.png";
import SingleCardArticle from "components/common/singlePageSection/SingleCardArticle";
import SingleCardMain from "components/common/singlePageSection/SingleCardMain";
import SkeletonArticle from "components/global/loader/skeletonLoader/SkeletonArticle";
import SkeletonSingleArticle from "components/global/loader/skeletonLoader/SkeletonSingleArticle";
import style from "components/local/news/style";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getBlogspage, getBlogsSinglepage } from "../redux/actions";

const BlogsSingle = ({ setSingleNews, singleNews, bannerData }) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const classes = style();
  const { slug } = useParams();
  console.log(slug);

  const pathname = window.location.pathname;

  const { blogsSinglePage, blogsSingle_loading } = useSelector(
    (state) => state.blogsSingle
  );

  const [blogsSingleData, setBlogsSingleData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    blogsSinglePage?.data && setBlogsSingleData(blogsSinglePage?.data);
  }, [blogsSinglePage]);

  console.log({ blogsSingleData });

  useEffect(() => {
    const data = {
      slug: slug ?? "",
    };
    dispatch(getBlogsSinglepage(data));
  }, [slug]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // articles data

  const { blogsPage, blogs_loading } = useSelector((state) => state.blogs);
  const [blogsData, setBlogsData] = useState([]);

  console.log(blogsData?.blogs_data?.news);

  useEffect(() => {
    blogsPage?.data && setBlogsData(blogsPage?.data);
  }, [blogsPage]);

  useEffect(() => {
    dispatch(getBlogspage());
  }, []);

  return (
    <Box className={classes.newsSingleContainer}>
      <Container maxWidth="lg">
        <Box className={classes.newsSingleWrap}>
          <Box className={classes.backBtnBox}>
            <button
              onClick={goBack}
              className={classes.backBtn}
              style={{ textDecoration: "none" }}
            >
              <img src={backArrow} alt="" className={classes.backArrow} />
              Back
            </button>
          </Box>
          <Grid container>
            <Grid item md={8} xs={12}>
              {blogs_loading ? (
                <SkeletonSingleArticle image={true} />
              ) : (
                <SingleCardMain time={true} data={blogsSingleData} />
              )}
            </Grid>
            <Grid item md={4} xs={12}>
              <Box className={classes.newsMoreSectionWrap}>
                <Box className={classes.newsMoreSection}>
                  <Typography className={classes.newsMoreSectionTitle}>
                    See more Blogs here
                  </Typography>
                  {/* )} */}
                  <Grid container spacing={1}>
                    {blogs_loading
                      ? [...Array(3)]?.map((item) => (
                          <Grid item xs={12} sm={6} md={12} mb="1rem">
                            <SkeletonArticle image={true} />
                          </Grid>
                        ))
                      : blogsData?.blog_data?.blog
                          ?.slice(0, 5)
                          ?.map((item) => (
                            <SingleCardArticle
                              data={item}
                              key={item.id}
                              name={"blogs"}
                            />
                          ))}
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default BlogsSingle;
