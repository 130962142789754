import Note from "components/local/dashboard/note";
import React from "react";

const NotePage = () => {
  return (
    <>
      <Note />
    </>
  );
};

export default NotePage;
