import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  blogsContainer: {
    backgroundColor: "#F9FAFB",
    height: "auto",
    padding: "60px 0",
    " & .css-11lq3yg-MuiGrid-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    [theme.breakpoints.down("md")]: {
      height: "auto",
      padding: "1rem 0",
    },
  },
  blogsGrid: {
    // backgroundColor: "red",
  },
  blogsWrap: {
    width: "100%",
  },
  blogsTypography: {
    display: "flex",
    flexDirection: "column ",
    // justifyContent: "center",
    justifyContent: "flex-start",
    // alignItems: "center",
    marginBottom: "30px !important",
  },
  blogsTitle: {
    color: " #000000",
    fontSize: "32px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    textTransform: "uppercase",
    marginBottom: "8px !important",
    display: "flex",
    // alignItems: "center",
    justifyContent: "center",
  },
  blogsHead: {
    color: "#827971 !important",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  blogsCardBox: {
    width: "210px",
    [theme.breakpoints.down("lg")]: {
      width: "90%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "10px",
      marginBottom: "15px",
    },
  },
  blogsCardImgBox: {
    position: "relative",
    paddingTop: "80%",
    [theme.breakpoints.down("lg")]: {
      width: "90%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  blogsCardImg: {
    top: "0",
    bottom: "0",
    right: "0",
    left: "0",
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    [theme.breakpoints.down("lg")]: {
      // padding: "10px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "10px",
    },
  },
  blogsCardMetaTitle: {
    color: "#F65C5C",
    fontSize: "11px !important",
    fontWeight: "600 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    textTransform: "uppercase",
    margin: "5px 0 !important",
  },
  blogsCardTitle: {
    color: "#121127",
    fontSize: "18px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    // marginBottom: "5px !important",
    lineHeight: "1 !important",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    minHeight: "32px",
  },
  blogsCardSubTitle: {
    color: "rgba(18, 17, 39, 0.56)",
    fontSize: "12px !important",
    fontWeight: "400 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },
  blogsCardBtnBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center !important",
    cursor: "pointer",
    padding: "10px",
  },
  blogsCardBtn: {
    display: "flex",
    alignItems: "center",
    gap: 3,
    color: "#4C7CE5",
    fontSize: "12px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },
  newsSectionBtnIcon: {
    width: "15px",
    height: "13px",
  },
  blogsCardBtnIcon: {
    width: "14px",
    height: "14px",
  },
  lineClamp: {
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    color: "#6C6B80 !important",
  },
  blogsGridSeeBtnBox: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "15px 0",
    width: "95%",
    [theme.breakpoints.down("lg")]: {
      width: "90%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  blogsGridSeeBtn: {
    border: "none",
    color: "#4C7CE5",
    backgroundColor: "#F9FAFB !important",
    fontSize: "13px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    textTransform: "capitalized !important",
    cursor: "pointer",
  },
}));

export default style;
