import homeAboutImg2 from "assets/home/homeAboutImg2.png";
import ButtonSecondary from "components/global/buttons/buttonsSecondary/ButtonSecondary";
// import style from "./style";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import style from "components/local/home/aboutUs/style";
import * as actions from "components/local/home/redux/types";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const KnowMoreCard = ({ title }) => {
  const classes = style();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Box className={classes.aboutImg2Box}>
      <Box>
        <Box className={classes.homeAboutTextWrap}>
          <Typography className={classes.homeAboutText}>{title}</Typography>

          <ButtonSecondary
            title={"Login"}
            sx={{ margin: "0 auto !important" }}
            onClick={() => dispatch({ type: actions.SHOW_LOGIN_FORM })}
          />
          <Typography className={classes.orText}>or</Typography>
          <ButtonSecondary
            title={"Sign Up"}
            border={"border"}
            onClick={() => dispatch({ type: "SHOW_SIGNUP_FORM" })}
          />
        </Box>
        <img src={homeAboutImg2} alt="" className={classes.aboutImg2} />
      </Box>
    </Box>
  );
};

export default KnowMoreCard;
