import { Box, Button, Container } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { verification } from "redux/auth/actions";
import { getQuery } from "utils/Helper";

const Verification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { verifiedLoading } = useSelector((state) => state.auth);

  console.log({ verifiedLoading });

  const handleNavigate = () => {
    navigate(`/`);
  };

  const data = {
    user_id: getQuery("user_id"),
    token: getQuery("token"),
  };

  console.log("qqqqqqqqqqqqqqqq", getQuery("token"), getQuery("user_id"));

  useEffect(() => {
    if (data?.token && data?.user_id) {
      dispatch(verification(data));
    }
  }, [data?.token, data?.user_id]);

  return (
    <Box>
      <Container maxWidth="lg">
        {verifiedLoading ? (
          <h1>Verifying your email...</h1>
        ) : (
          <>
            <h1>Email verified successfully</h1>
            <Button
              onClick={handleNavigate}
              sx={{
                marginBottom: "1rem",
                backgroundColor: "#DC2626  !important",
                color: "#fff !important",
              }}
            >
              Go to homepage
            </Button>
          </>
        )}
      </Container>
    </Box>
  );
};

export default Verification;
