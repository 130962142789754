import AboutPresident from "components/local/home/aboutPresident";
import AboutUs from "components/local/home/aboutUs";
import Blogs from "components/local/home/blogsSection/blogs";
import Hero from "components/local/home/hero";
import News from "components/local/home/news";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Events from "./events/Events";
import AssociationMarqee from "./marqee/AssociationMarqee";
import QuickLinks from "./quickLinks";

const Home = () => {
  const pathname = window.location.pathname;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { homepage, homepage_loading } = useSelector((state) => state.home);
  const [homeData, setHomeData] = useState([]);

  useEffect(() => {
    homepage?.data && setHomeData(homepage?.data);
  }, [homepage]);

  console.log({ homeData });

  return (
    <>
      <Hero homeData={homeData} loading={homepage_loading} />
      <AssociationMarqee homeData={homeData} />
      <QuickLinks homeData={homeData} />
      <AboutUs homeData={homeData} />
      <AboutPresident homeData={homeData} />
      <Events eventData={homeData?.event} />
      <News homeData={homeData} />
      <Blogs homeData={homeData} />
    </>
  );
};

export default Home;
