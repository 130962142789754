import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  contentContainer: {
    "& p, li": {
      fontWeight: "500",
      fontSize: "18px",
      lineHeight: "1.5",
      color: "#857F7A",
    },
  },
  imageContainer: {
    maxWidth: "640px",
    marginBottom: "27.5px",
    "& img": {
      width: "100%",
      height: "auto",
    },
  },
  header: {
    textTransform: "uppercase",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "1.2 !important",
    color: "#000",
  },
  subHeader: {
    fontWeight: "600 !important",
    fontSize: "20px !important",
    lineHeight: "1.5 !important",
    color: "#000",
  },
  backBtnBox:{
    paddingTop: '20px',
    marginBottom: '20px'
  },
  backBtn:{
    border: 'none',
    backgroundColor: 'rgb(255, 255, 255, .0)',
    display: 'flex',
    alignItems: 'center',
    gap: 3,
    padding: '5px',
    cursor: 'pointer',
    color: '#858689',
    fontSize: "14px !important",
    fontWeight: "400 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },
  backArrow:{
    width: '16px',
    height: '16px',
  },
  pdfIframe:{
    width: '715px',
    height: '820px',
    [theme.breakpoints.down("md")]: {
      width: '400px',
      padding: '10px'
    },
    [theme.breakpoints.down("xs")]: {
      width: '200px',
    },
  }
}));

export default style;
