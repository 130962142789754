import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: "#F5F5F5",
    minHeight: "calc(100vh - 70px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "40px",
  },
  formContainer: {
    backgroundColor: "#fff",
  },
  formBody: {
    padding: "30px 37px",
    borderTop: "solid 1px #DEDEDE",
    borderBottom: "solid 1px #DEDEDE",
    width: "535px",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
    "& input": {
      border: "solid 1px #C1BBBB",
      borderRadius: "10px",
      fontSize: "16px",
      padding: "10px",
      width: "100%",
    },
  },
  header: {
    fontSize: "20px !important",
    fontWeight: "600 !important",
    color: "#000",
  },
  subHeader: {
    fontSize: "16px !important",
    fontWeight: "300 !important",
    color: "#000",
  },
  btnContainer: {
    textAlign: "center",
    marginTop: "40px",
    padding: "24px 37px",
    display: "flex",
    justifyContent: "flex-end",
    gap: "19px",
    "& button": {
      padding: "10px 19px",
      borderRadius: "5px",
      fontSize: "16px",
      fontWeight: "600",
      lineHeight: "1",
    },
  },
  btnPrimary: {
    backgroundColor: "#D9D9D9 !important",
    color: "#000 !important",
  },
  btnSecondary: {
    backgroundColor: "#DC2626 !important",
    color: "#fff !important",
  },
  messageContainer: {
    backgroundColor: "#fff",
    padding: "60px 35.5px 68px",
    textAlign: "center",
    "& h1": {
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "1.32",
      color: "#101010",
    },
    "& h2": {
      fontSize: "13px",
      color: "#3F3B3B",
    },
    "& h3": {
      fontSize: "14px",
      fontWeight: "500",
      color: "#9D9CAF",
      "& span": {
        color: "#DC2626",
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
  },
}));

export default style;
