import { useSelector } from "react-redux";

// custom hooks that checks roles in it
const useCheckRole = () => {
  const { user } = useSelector((state) => state.auth);
  const checkUserRole = (array = []) => {
    return array?.includes(user?.role?.[0]);
  };
  return { checkUserRole };
};

export default useCheckRole;
