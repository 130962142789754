import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  navBtn: {
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    backgroundColor: "#DC2626 !important",
    borderRadius: "0 !important",
    color: "#fff !important",
    fontSize: "13px !important",
    fontWeight: "600 !important",
    display: "flex",
    alignItems: 'center',
    margin: "0 auto !important",
    height: "30px",
    // padding: '1px 22px !important',
    width: "150px",
    // marginLeft: '50px !important',
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: '16px !important',
    //   width: 'auto',
    //   height: 'auto',
    // },
    "& :hover": {
      backgroundColor: "#DC2626 !important",
    },
  },
  navBtnBorder: {
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    backgroundColor: "none !important",
    borderRadius: "0 !important",
    color: "#DC2626 !important",
    border: "1px solid #DC2626 !important",
    fontSize: "13px !important",
    fontWeight: "600 !important",
    // padding: '1px 22px !important',
    height: "30px",
    width: "150px",
    margin: "0 auto !important",
    // marginLeft: '50px !important',
    "& :hover": {
      backgroundColor: "#DC2626 !important",
    },
  },
}));

export default style;
