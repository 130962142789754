export const FETCH_EVENT_PENDING = "FETCH_EVENT_PENDING";

export const FETCH_EVENT_SUCCESS = "FETCH_EVENT_SUCCESS";

export const FETCH_EVENT_ERROR = "FETCH_EVENT_ERROR";

export const FETCH_SINGLE_EVENT_SUCCESS = "FETCH_SINGLE_EVENT_SUCCESS";

export const FETCH_SINGLE_EVENT_ERROR = "FETCH_SINGLE_EVENT_ERROR";

export const POST_EVENT_PENDING = "POST_EVENT_PENDING";

export const POST_EVENT_SUCCESS = "POST_EVENT_SUCCESS";

export const POST_EVENT_ERROR = "POST_EVENT_ERROR";

export const RESET_STATUS = "RESET_STATUS";

export const FETCH_CURRENT_MONTHS_EVENT_BEGIN =
  "FETCH_CURRENT_MONTHS_EVENT_BEGIN";
export const FETCH_CURRENT_MONTHS_EVENT_SUCCESS =
  "FETCH_CURRENT_MONTHS_EVENT_SUCCESS";
export const FETCH_CURRENT_MONTHS_EVENT_ERROR =
  "FETCH_CURRENT_MONTHS_EVENT_ERROR";

export const FETCH_APPLIED_EVENTS = "FETCH_APPLIED_EVENTS";

export const FETCH_APPLIED_EVENT_ERROR = "FETCH_APPLIED_EVENT_ERROR";

export const RESET_APPLIED_EVENT_STATUS = "RESET_APPLIED_EVENT_STATUS";

export const FETCH_APPLIED_EVENTS_PENDING = "FETCH_APPLIED_EVENTS_PENDING";

export const GET_CURRENT_DATE = "GET_CURRENT_DATE";
