import Banner from "components/global/banner";
// import SingleCardMain from "components/common/cardMain";
// import NewsSection from "components/news/newsSection";
import { useEffect, useState } from "react";
// import BlogsSingle from "components/blogs/blogsSingle";
import { Box, Container, Grid, Typography } from "@mui/material";
import chevronDown from "assets/home/chevronDown.png";
import CardMainComponent from "components/common/cardMain";
import style from "components/common/style";
import SkeletonLoaderTernory from "components/global/loader/skeletonLoader/SkeletonCardTernory";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getBlogspage } from "./redux/actions";

// import BlogsSingle from "components/local/blogs/blogsSingle";

const Blogs = () => {
  const pathname = window.location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const classes = style();
  const [singleNews, setSingleNews] = useState(false);

  const { blogsPage, blogs_loading } = useSelector((state) => state.blogs);
  const [blogsData, setNewsData] = useState([]);

  console.log({ blogsData });

  const dispatch = useDispatch();

  // useEffect(() => {
  //   blogsPage?.data && setNewsData(blogsPage?.data);
  // }, [blogsPage]);

  useEffect(() => {
    blogsPage?.data &&
      setNewsData([
        ...(blogsData || []),
        ...(blogsPage?.data?.blog_data?.blog || []),
      ]);
  }, [blogsPage?.data?.blog_data?.pagination?.current_page]);

  useEffect(() => {
    dispatch(getBlogspage());
  }, []);

  const handleMore = () => {
    const data = {
      page: blogsPage?.data?.blog_data?.pagination?.current_page + 1,
    };
    dispatch(getBlogspage(data));
  };

  return (
    <>
      <Banner data={blogsPage?.data?.blog_banner} title={"Blogs"} />
      <Box className={classes.newsSectionContainer}>
        <Container maxWidth="lg">
          <Box className={classes.newsSectionTitleBox}>
            <Typography className={classes.newsSectionTitle}>
              Latest Blogs
            </Typography>
          </Box>
          <Box className={classes.newsSectionBox}>
            <Grid container spacing={8}>
              {/* {blogs_loading
                ? [...Array(4)]?.map((item) => (
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                      <SkeletonLoaderTernory />
                    </Grid>
                  ))
                : blogsData?.map((item) => (
                    <CardMainComponent
                      item={item}
                      key={item?.id}
                      name={"blogs"}
                    />
                  ))} */}
              {blogsData?.map((item) => (
                <>
                  <CardMainComponent
                    item={item}
                    key={item?.id}
                    name={"blogs"}
                  />
                </>
              ))}
              {blogs_loading &&
                [...Array(4)]?.map((item) => (
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <SkeletonLoaderTernory />
                  </Grid>
                ))}
            </Grid>
          </Box>
          {!blogs_loading &&
            blogsData?.length <
              blogsPage?.data?.blog_data?.pagination?.total && (
              <Box className={classes.newsSeeMoreBtnBox} onClick={handleMore}>
                <NavLink className={classes.newsSeeMoreBtn}>See More</NavLink>
                <Box className={classes.newsSeeMoreArrow}>
                  <img src={chevronDown} alt="" />
                </Box>
              </Box>
            )}
          {/* <Box className={classes.newsSeeMoreBtnBox}>
            <NavLink className={classes.newsSeeMoreBtn}>See More</NavLink>
            <Box className={classes.newsSeeMoreArrow}>
              <img src={chevronDown} alt="" />
            </Box>
          </Box> */}
        </Container>
      </Box>
    </>
  );
};

export default Blogs;
