import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import SkeletonCardJob from "components/global/loader/skeletonLoader/SkeletonCardJob";
import moment from "moment";
import { useSelector } from "react-redux";
import style from "./style";

const Header = ({ data }) => {
  const classes = style();
  const timeago = moment(data?.created_at).fromNow();
const {jobsSingle_loading } = useSelector(
  (state) => state.jobSingle
);

  return (
    <>
    
    <Box className={classes.headerContainer}>
    {jobsSingle_loading ? (
      <SkeletonCardJob jobMain={'jobMain'}/>
    ) : (
      <>
      
      <img src={data?.image} alt="" className={classes.headerImage} />
      <Box>
        <Typography component={"h1"} className={classes.headerTitle}>
          {/* {data?.company?.name} */}
          {data?.company?.name ?? "NCASA"}          
        </Typography>
        <Box
          sx={{
            display: "flex",
            paddingBottom: "10px",
            borderBottom: "solid 1px #F0F0F0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
              paddingRight: "10px",
              borderRight: "solid 1px #D1D1DB",
            }}
          >
            <Typography>{data?.location}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
              paddingLeft: "10px",
            }}
          >
            <Typography>{timeago}</Typography>
          </Box>
        </Box>
        <Box sx={{ paddingTop: "8px", paddingLeft: "18px" }}>
          <Typography component={"h2"} className={classes.headerBody}>
            {data?.position}
          </Typography>
        </Box>
        {/* <Box sx={{ paddingTop: "8px", paddingLeft: "18px" }}>
          <Typography component={"h2"} className={classes.headerBody}>
          <div
                className={classes.lineClamp}
                dangerouslySetInnerHTML={{
                  __html: data?.company?.short_description,
                }}
              />
           
          </Typography>
        </Box> */}
      </Box>
      </>
    )} 
    </Box>
    </>
  );
};

export default Header;
