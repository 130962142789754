import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  header: {
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    fontSize: "32px !important",
    fontWeight: "600 !important",
    lineHeight: "2 !important",
    color: theme.palette.grey[7],
  },
  card: {
    border: `solid 1px ${theme.palette.grey[3]}`,
    cursor: "pointer",
  },
  cardTitle: {
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    fontSize: "14px !important",
    fontWeight: "700 !important",
    lineHeight: "1.8 !important",
    color: theme.palette.grey[7],
    textAlign: "center",
  },
  titleContainer: {
    padding: "14px 0 !important",
  },
  btnContainer: {
    textAlign: "center",
    marginTop: "40px",
  },
  btn: {
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    fontSize: "15px !important",
    lineHeight: "1.2 !important",
    fontWeight: "700 !important",
    color: "#4C7CE5",
  },
  btnIcon: {
    width: "14px",
    height: "14px",
  },
  // see more btn
  newsSeeMoreBtnBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  newsSeeMoreBtn: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#4C7CE5",
    fontSize: "15px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },
  newsSeeMoreArrow: {
    display: "flex",
    alignItems: "center",
  },

  // modal slider

  arrowIconLeft: {
    left: "6%",
    top: "50%",
    zIndex: 10,

    display: "block",
    background: "#fff",
    position: "absolute",
    borderRadius: "50%",
    bottom: "50%",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "none !important",
    },
    "&.MuiSvgIcon-root": {
      width: "35px !important",
      height: "35px !important",
    },
  },

  arrowIconRight: {
    right: "6%",
    display: "block",
    background: "#fff",
    position: "absolute",
    zIndex: 10,
    borderRadius: "50%",
    top: "50%",
    bottom: "50%",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "none !important",
    },
    "&.MuiSvgIcon-root": {
      width: "35px !important",
      height: "35px !important",
    },
  },
}));

export default style;
