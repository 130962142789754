import JobsSingle from "components/local/jobsSingle/JobsSingle";

const JobsSinglePage = () => {
  return (
    <div>
      <JobsSingle />
    </div>
  );
};

export default JobsSinglePage;
