import * as actions from "components/local/jobs/redux/types";
const initialState = {
  jobsPage: [],
  jobs_loading: false,
  jobsSinglePage: [],
  jobsSingle_loading: false,
  applyJobs: [],
  applyJobs_loading: false
};

const jobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_JOBS_BEGIN:
      return { ...state, jobs_loading: true };

    case actions.GET_JOBS_SUCCESS:
      return { ...state, jobs_loading: false, jobsPage: action.payload };

    case actions.GET_JOBS_ERROR:
      return { ...state, jobs_loading: false };
    default:
      return state;
  }
};

const jobsSingleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_JOBS_SINGLE_BEGIN:
      return { ...state, jobsSingle_loading: true };

    case actions.GET_JOBS_SINGLE_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        jobsSingle_loading: false,
        jobsSinglePage: action.payload,
      };

    case actions.GET_JOBS_SINGLE_ERROR:
      return { ...state, jobsSingle_loading: false };
    default:
      return state;
  }
};

const applyJobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.POST_APPLY_JOBS_BEGIN:
      return { ...state, applyJobs_loading: true };

    case actions.POST_APPLY_JOBS_SUCCESS:
      return { ...state, applyJobs_loading: false, applyJobs: action.payload };

    case actions.POST_APPLY_JOBS_ERROR:
      return { ...state, applyJobs_loading: false };
    default:
      return state;
  }
};

export { jobsReducer, jobsSingleReducer, applyJobsReducer };

