import style from "components/global/description/style";

const AboutPrDesc = ({ description, length }) => {
  const classes = style();
  const styles = {
    fontSize: "14px !important",
    color: "blue !important",
    //  backgroundColor: 'red'
  };

  return (
    <>
      <div
        style={{ ...styles }}
      //   className={`${length ? classes.lineClampDesc : classes.lineClamp}`}
        className={ classes.lineClampDescAboutPrHome}
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    </>
  );
};

export default AboutPrDesc;
