import * as actions from "components/local/publications/redux/types";
const initialState = {
  publicationsPage: [],
  publications_loading: false,
};

const initialStateSingle = {
  publicationsSinglePage: [],
  publicationsSingle_loading: false,
};

const publicationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_PUBLICATIONS_BEGIN:
      return { ...state, publications_loading: true };

    case actions.GET_PUBLICATIONS_SUCCESS:
      return {
        ...state,
        publications_loading: false,
        publicationsPage: action.payload,
      };

    case actions.GET_PUBLICATIONS_ERROR:
      return { ...state, publications_loading: false };
    default:
      return state;
  }
};

const publicationsSingleReducer = (state = initialStateSingle, action) => {
  switch (action.type) {
    case actions.GET_PUBLICATIONS_SINGLE_BEGIN:
      return { ...state, publicationSingle_loading: true };

    case actions.GET_PUBLICATIONS_SINGLE_SUCCESS:
      return {
        ...state,
        publicationsSingle_loading: false,
        publicationsSinglePage: action.payload,
      };
    case actions.GET_PUBLICATIONS_SINGLE_ERROR:
      return { ...state, publicationsSingle_loading: false };
    default:
      return state;
  }
};

export default publicationsSingleReducer;

export { publicationsReducer, publicationsSingleReducer };
