import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Typography } from "@mui/material";
import ncasaLogo from "assets/home/ncasaLogo.png";
import { HookFormInput } from "components/UI/Form/HookFormInput";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { signUpAction } from "redux/signUp/actions";
import * as actions from "redux/signUp/types";
import * as yup from "yup";
import style from "../loginForm/style";

const SignUpForm = () => {
  const state = useSelector((state) => state.signUp);
  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup.string().email().required("Email is required"),
    ican_reg_no: yup.string().required("ICAN Registration no. is required"),
    password: yup
      .string()
      .matches(
        /(?=^.{6,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*/,
        "Password must be at least 6 characters long, must include at least 1 uppercase character, 1 lowercase character, 1 digit and 1 special character."
      )
      .required("Password field is required"),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm password field is required"),
  });

  const dropData = [
    { value: "cap_i", label: "Cap I" },
    { value: "cap_ii", label: "Cap II" },
    { value: "cap_iii", label: "Cap III" },
  ];

  // const defaultValues = {
  //   cap_level: dropData?.[0]?.value,
  // };

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm({
    resolver: useYupValidationResolver(validationSchema),
    // defaultValues,
  });

  const classes = style();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useDispatch();
  const { showSignUpForm } = useSelector((state) => state.home);

  useEffect(() => {
    if (state.status === "completed") {
      dispatch({ type: "HIDE_SIGNUP_FORM" });
      dispatch({ type: actions.CHANGE_STATUS, payload: "idle" });
    }
  }, [state.status]);

  const handleLogIn = (data) => {
    dispatch(signUpAction(data));
    console.log("data", data);
  };

  // console.log("errors", errors);

  return (
    <>
      {showSignUpForm && (
        <Box
          className={classes.overlay}
          onClick={() => dispatch({ type: "HIDE_SIGNUP_FORM" })}
        >
          <form
            className={classes.signUpForm}
            onClick={(e) => e.stopPropagation()}
            onSubmit={handleSubmit(handleLogIn)}
          >
            <Box className={classes.imageContainer}>
              <img
                src={ncasaLogo}
                alt="NCASA Logo"
                style={{ width: "100%", height: "auto" }}
              />
              <div
                onClick={() => dispatch({ type: "HIDE_SIGNUP_FORM" })}
                style={{
                  position: "absolute",
                  right: "15px",
                  top: "10px",
                  cursor: "pointer",
                }}
              >
                <ClearIcon />
              </div>
            </Box>
            <Typography component="h2" className={classes.title}>
              Sign Up
            </Typography>

            <Box className={classes.inputField}>
              <label htmlFor="name" className={classes.label}>
                Name
              </label>
              <input
                id="name"
                type="text"
                placeholder="Enter Your Name"
                className={classes.input}
                {...register("name")}
                aria-invalid={errors.name ? true : false}
              />
              {errors.name && (
                <Typography color="#DC2626">{errors.name?.message}</Typography>
              )}
            </Box>

            <Box className={classes.inputField}>
              <label htmlFor="email" className={classes.label}>
                Email
              </label>
              <input
                id="email"
                type="email"
                placeholder="Enter Your Email"
                className={classes.input}
                {...register("email")}
                aria-invalid={errors.email ? true : false}
              />
              {errors.email && (
                <Typography color="#DC2626">{errors.email?.message}</Typography>
              )}
            </Box>
            <Box className={classes.inputField}>
              <label htmlFor="email" className={classes.label}>
                ICAN Registration No.
              </label>
              <input
                id="ican_reg_no"
                type="text"
                placeholder="Enter Your ICAN Registration no."
                className={classes.input}
                {...register("ican_reg_no")}
                aria-invalid={errors.ican_reg_no ? true : false}
              />
              {errors.email && (
                <Typography color="#DC2626">{errors.email?.message}</Typography>
              )}
            </Box>
            {/* <Grid item xs={12} md={12}> */}
            <Box sx={{ marginBottom: "20px" }}>
              <HookFormInput
                select
                placeholder="Enter Your ICAN Registration no."
                // helperText="Select level"
                label="CAP LEVEL"
                name="cap_level"
                data={dropData ?? []}
                control={control}
                errors={errors}
              />
            </Box>
            {/* </Grid> */}

            <Box className={classes.inputField}>
              <label htmlFor="password" className={classes.label}>
                Password
              </label>
              <Box sx={{ position: "relative" }}>
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Your Password"
                  className={classes.input}
                  {...register("password")}
                  aria-invalid={errors.password ? true : false}
                />
                {showPassword ? (
                  <VisibilityOutlined
                    className={classes.toggleIcon}
                    onClick={() => setShowPassword(false)}
                  />
                ) : (
                  <VisibilityOffOutlined
                    className={classes.toggleIcon}
                    onClick={() => setShowPassword(true)}
                  />
                )}
              </Box>
              {errors.password && (
                <Typography color="#DC2626">
                  {errors.password?.message}
                </Typography>
              )}
            </Box>

            <Box className={classes.inputField}>
              <label htmlFor="password-confirmation" className={classes.label}>
                Password
              </label>
              <Box sx={{ position: "relative" }}>
                <input
                  id="password-confirmation"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Your Password"
                  className={classes.input}
                  {...register("password_confirmation")}
                  aria-invalid={errors.password_confirmation ? true : false}
                />
                {showConfirmPassword ? (
                  <VisibilityOutlined
                    className={classes.toggleIcon}
                    onClick={() => setShowConfirmPassword(false)}
                  />
                ) : (
                  <VisibilityOffOutlined
                    className={classes.toggleIcon}
                    onClick={() => setShowConfirmPassword(true)}
                  />
                )}
              </Box>
              {errors.password_confirmation && (
                <Typography color="#DC2626">
                  {errors.password_confirmation?.message}
                </Typography>
              )}
            </Box>

            <Box>
              <button type="submit" className={classes.button}>
                {state.status === "pending" ? "Signing Up" : "Sign Up"}
              </button>
            </Box>
          </form>
        </Box>
      )}
    </>
    // <Box
    //   className={classes.overlay}
    //   onClick={() => dispatch({ type: "HIDE_SIGNUP_FORM" })}
    // >
    //   <form
    //     className={classes.signUpForm}
    //     onClick={(e) => e.stopPropagation()}
    //     onSubmit={handleSubmit(handleLogIn)}
    //   >
    //     <Box className={classes.imageContainer}>
    //       <img
    //         src={ncasaLogo}
    //         alt="NCASA Logo"
    //         style={{ width: "100%", height: "auto" }}
    //       />
    //     </Box>
    //     <Typography component="h2" className={classes.title}>
    //       Sign Up
    //     </Typography>

    //     <Box className={classes.inputField}>
    //       <label htmlFor="name" className={classes.label}>
    //         Name
    //       </label>
    //       <input
    //         id="name"
    //         type="text"
    //         placeholder="Enter Your Name"
    //         className={classes.input}
    //         {...register("name")}
    //         aria-invalid={errors.name ? true : false}
    //       />
    //       {errors.name && (
    //         <Typography color="#DC2626">{errors.name?.message}</Typography>
    //       )}
    //     </Box>

    //     <Box className={classes.inputField}>
    //       <label htmlFor="email" className={classes.label}>
    //         Email
    //       </label>
    //       <input
    //         id="email"
    //         type="email"
    //         placeholder="Enter Your Email"
    //         className={classes.input}
    //         {...register("email")}
    //         aria-invalid={errors.email ? true : false}
    //       />
    //       {errors.email && (
    //         <Typography color="#DC2626">{errors.email?.message}</Typography>
    //       )}
    //     </Box>
    //     <Box className={classes.inputField}>
    //       <label htmlFor="email" className={classes.label}>
    //         ICAN Registration No.
    //       </label>
    //       <input
    //         id="ican_reg_no"
    //         type="text"
    //         placeholder="Enter Your ICAN Registration no."
    //         className={classes.input}
    //         {...register("ican_reg_no")}
    //         aria-invalid={errors.ican_reg_no ? true : false}
    //       />
    //       {errors.email && (
    //         <Typography color="#DC2626">{errors.email?.message}</Typography>
    //       )}
    //     </Box>
    //     {/* <Grid item xs={12} md={12}> */}
    //     <Box sx={{ marginBottom: "20px" }}>
    //       <HookFormInput
    //         select
    //         placeholder="Enter Your ICAN Registration no."
    //         // helperText="Select level"
    //         label="CAP LEVEL"
    //         name="cap_level"
    //         data={dropData ?? []}
    //         control={control}
    //         errors={errors}
    //       />
    //     </Box>
    //     {/* </Grid> */}

    //     <Box className={classes.inputField}>
    //       <label htmlFor="password" className={classes.label}>
    //         Password
    //       </label>
    //       <Box sx={{ position: "relative" }}>
    //         <input
    //           id="password"
    //           type={showPassword ? "text" : "password"}
    //           placeholder="Enter Your Password"
    //           className={classes.input}
    //           {...register("password")}
    //           aria-invalid={errors.password ? true : false}
    //         />
    //         {showPassword ? (
    //           <VisibilityOutlined
    //             className={classes.toggleIcon}
    //             onClick={() => setShowPassword(false)}
    //           />
    //         ) : (
    //           <VisibilityOffOutlined
    //             className={classes.toggleIcon}
    //             onClick={() => setShowPassword(true)}
    //           />
    //         )}
    //       </Box>
    //       {errors.password && (
    //         <Typography color="#DC2626">{errors.password?.message}</Typography>
    //       )}
    //     </Box>

    //     <Box className={classes.inputField}>
    //       <label htmlFor="password-confirmation" className={classes.label}>
    //         Password
    //       </label>
    //       <Box sx={{ position: "relative" }}>
    //         <input
    //           id="password-confirmation"
    //           type={showConfirmPassword ? "text" : "password"}
    //           placeholder="Confirm Your Password"
    //           className={classes.input}
    //           {...register("password_confirmation")}
    //           aria-invalid={errors.password_confirmation ? true : false}
    //         />
    //         {showConfirmPassword ? (
    //           <VisibilityOutlined
    //             className={classes.toggleIcon}
    //             onClick={() => setShowConfirmPassword(false)}
    //           />
    //         ) : (
    //           <VisibilityOffOutlined
    //             className={classes.toggleIcon}
    //             onClick={() => setShowConfirmPassword(true)}
    //           />
    //         )}
    //       </Box>
    //       {errors.password_confirmation && (
    //         <Typography color="#DC2626">
    //           {errors.password_confirmation?.message}
    //         </Typography>
    //       )}
    //     </Box>

    //     <Box>
    //       <button type="submit" className={classes.button}>
    //         {state.status === "pending" ? "Signing Up" : "Sign Up"}
    //       </button>
    //     </Box>
    //   </form>
    // </Box>
  );
};

export default SignUpForm;
