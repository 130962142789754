import Verification from 'components/local/verifiedUser'
import React from 'react'

const VerificationPage = () => {
  return (
    <>
      <Verification/>
    </>
  )
}

export default VerificationPage
