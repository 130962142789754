import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import ncasaLogo from "assets/home/ncasaLogo.png";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login } from "redux/auth/actions";
import * as actions from "redux/auth/types";
import * as yup from "yup";
import style from "./style";

import ClearIcon from "@mui/icons-material/Clear";

const LoginForm = () => {
  const navigate = useNavigate();
  const state = useSelector((state) => state.auth);
  const validationSchema = yup.object({
    email: yup.string().email().required("Email is required"),
    password: yup.string().required("Password field is required"),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: useYupValidationResolver(validationSchema) });

  const classes = style();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const { showLoginForm } = useSelector((state) => state.home);

  useEffect(() => {
    if (state.status === "completed") {
      // setShowForm(false);
      dispatch({ type: "HIDE_LOGIN_FORM" });
      dispatch({ type: actions.CHANGE_STATUS, payload: "idle" });
      navigate("/dashboard/profile");
    }
  }, [state.status]);

  const handleLogIn = (data) => {
    dispatch(login(data));
  };

  // console.log("errors", errors);
  // const [isOpen, setIsOpen] = useState(false);

  // const popoverRef = useRef(null);
  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (popoverRef.current && !popoverRef.current.contains(event.target)) {
  //       setIsOpen(false);
  //     }
  //   }
  //   //
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);
  // const handleModal = () => {
  //   setIsOpen(!isOpen);
  // };

  return (
    <>
      {showLoginForm && (
        <Box
          className={classes.overlay}
          onClick={() => dispatch({ type: "HIDE_LOGIN_FORM" })}
          sx={{ position: "relative" }}
        >
          <form
            className={classes.formContainer}
            onClick={(e) => e.stopPropagation()}
            onSubmit={handleSubmit(handleLogIn)}
          >
            <Box className={classes.imageContainer}>
              <img
                src={ncasaLogo}
                alt="NCASA Logo"
                style={{ width: "100%", height: "auto" }}
              />
              <div
                // ref={popoverRef}
                onClick={() => dispatch({ type: "HIDE_LOGIN_FORM" })}
                style={{
                  position: "absolute",
                  right: "15px",
                  top: "10px",
                  cursor: "pointer",
                }}
              >
                <ClearIcon />
              </div>
            </Box>
            <Typography component="h2" className={classes.title}>
              Login
            </Typography>
            <Box className={classes.inputField}>
              <label htmlFor="email" className={classes.label}>
                Email
              </label>
              <input
                id="email"
                type="ema"
                placeholder="Enter Your Email"
                className={classes.input}
                {...register("email")}
                aria-invalid={errors.email ? true : false}
              />
              {errors.email && (
                <Typography color="#DC2626">{errors.email?.message}</Typography>
              )}
            </Box>
            <Box className={classes.inputField}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <label htmlFor="password" className={classes.label}>
                  Password
                </label>
                <Link
                  to="/forgot-password"
                  onClick={() => dispatch({ type: "HIDE_LOGIN_FORM" })}
                  className={classes.forgotPassText}
                >
                  FORGOT PASSWORD?
                </Link>
              </Box>
              <Box sx={{ position: "relative" }}>
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Your Password"
                  className={classes.input}
                  {...register("password")}
                  aria-invalid={errors.password ? true : false}
                />
                {showPassword ? (
                  <VisibilityOutlined
                    className={classes.toggleIcon}
                    onClick={() => setShowPassword(false)}
                  />
                ) : (
                  <VisibilityOffOutlined
                    className={classes.toggleIcon}
                    onClick={() => setShowPassword(true)}
                  />
                )}
              </Box>
              {errors.password && (
                <Typography color="#DC2626">
                  {errors.password?.message}
                </Typography>
              )}
            </Box>
            <Box>
              <button type="submit" className={classes.button}>
                {state.status === "pending" ? "Logging In" : "Login"}
              </button>
            </Box>
            <Box sx={{ mt: "10px" }}>
              Don't have an account?
              <button
                type="button"
                className={classes.secondaryButton}
                onClick={() => dispatch({ type: "SHOW_SIGNUP_FORM" })}
              >
                Sign up now.
              </button>
            </Box>
          </form>
        </Box>
      )}
    </>
  );
};

export default LoginForm;
