import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import HistoryIcon from "@mui/icons-material/History";
import { Box, Button, Grid, Typography } from "@mui/material";
import style from "components/common/style";
import { TimeAgo } from "components/global/date/Date";
import Description from "components/global/description/Description";
import moment from "moment";

const getFormattedDate = (date) => {
  const dates = new Date(date);
  return moment(dates)?.format("MMM-DD-YYYY");
};
const SingleCardMain = ({ data: item, time }) => {
  // const timeago = moment(item?.created_by?.created_at).fromNow();
  // const dateNow = moment(item?.created_by?.created_at).toDateString();
  const classes = style();
  return (
    <Box className={classes.newsSingleArticle}>
      {item?.image && (
        <Box className={classes.newsSingleImgBox}>
          <img
            src={item?.image}
            alt=""
            className={classes.newsSectionCardImg}
          />
        </Box>
      )}
      <Box className={classes.newsSingleInfoWrap}>
        <Typography className={classes.newsSingleInfoTitle}>
          {item?.title}
        </Typography>
        {item?.image && (
          <Box className={classes.newsSingleInfoDateBox}>
            <Typography className={classes.newsSingleInfoTime}>
              {getFormattedDate(item?.updated_at)}
            </Typography>
            {time && (
              <>
                <span style={{ margin: "0 5px" }}>.</span>
                <Typography className={classes.newsSingleInfoTime}>
                  <TimeAgo time={item?.updated_at} />
                </Typography>
              </>
            )}
          </Box>
        )}
        <Typography className={classes.newsSingleSubTitle}>
          <Description length={true} description={item?.description} />
        </Typography>
        {item?.cover_image && (
          <Grid
            container
            className={`${classes.newsSingleInfoDateBoxGrid} ${classes.newsSingleInfoDateBoxPDF}`}
          >
            <Grid item className={classes.newsSingleInfoDateBox}>
              <Typography className={classes.newsSingleInfoDate}>
                {item?.created_by?.name}
              </Typography>
              <span style={{ margin: "0 5px" }}>.</span>
              <HistoryIcon fontSize="small" />
              <Typography className={classes.newsSingleInfoTime}>
                <TimeAgo time={item?.updated_at} />
              </Typography>
            </Grid>
            <Grid item className={classes.publicationDownloadBtnBox}>
              <a
                href={item?.file}
                rel="noreferrer"
                target="_blank"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                <Button className={classes.publicationDownloadBtn}>
                  <CloudDownloadOutlinedIcon />
                  <Typography className={classes.downloadBtn}>
                    Download File
                  </Typography>
                </Button>
              </a>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default SingleCardMain;
