import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  overlay: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    zIndex: "20",
    backgroundColor: "rgba(0,0,0,0.7)",
  },
  signUpForm: {
    overflowY: "scroll",

    "& .inputField": {
      marginBottom: "10px",
    },
    padding: "15px 30px 55px 37px",
    backgroundColor: "#fff",
    boxShadow: "0px 4px 19px rgba(0, 0, 0, 0.07)",
    width: "392px",
    position: "fixed",
    zIndex: "20",
    top: "50%",
    left: "50%",
    maxHeight: "600px",
    transform: "translate(-50%,-50%)",
  },
  formContainer: {
    padding: "15px 30px 55px 37px",
    backgroundColor: "#fff",
    boxShadow: "0px 4px 19px rgba(0, 0, 0, 0.07)",
    width: "392px",
    position: "fixed",
    zIndex: "20",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  imageContainer: {
    width: "57px",
    height: "57px",
    marginInline: "auto",
    marginBottom: "23px",
  },
  title: {
    fontFamily: ["Sofia sans", "sans-serif", "!important"],
    color: "#201F37",
    fontWeight: "800 !important",
    fontSize: "19px !important",
    lineHeight: "1.4 !important",
    marginBottom: "30px !important",
    textTransform: "uppercase",
    textAlign: "center",
  },
  inputField: {
    marginBottom: "21px",
  },
  label: {
    fontFamily: ["Sofia sans", "sans-serif"],
    fontWeight: "600",
    display: "block",
    lineHeight: "1.4",
    marginBottom: "6px",
    textTransform: "uppercase",
  },
  input: {
    padding: "8px 12px",
    fontFamily: ["Sofia sans", "sans-serif"],
    fontSize: "14px",
    lineHeight: "1.4",
    border: "solid 1px #E5E5EB",
    borderRadius: "4px",
    width: "100%",
    "&[aria-invalid = true]": {
      border: "solid 1px red",
    },
  },
  button: {
    width: "100%",
    backgroundColor: "#DC2626",
    color: "#fff",
    textAlign: "center",
    padding: "7.5px 0",
    border: "transparent",
    borderRadius: "4px",
    fontFamily: ["Sofia sans", "sans-serif"],
    fontSize: "15px",
    lineHeight: "1.2",
    fontWeight: "700",
    cursor: "pointer",
  },
  secondaryButton: {
    backgroundColor: "transparent",
    border: "transparent",
    padding: "0",
    paddingLeft: "5px",
    textDecoration: "underline",
    cursor: "pointer",
    color: "blue",
  },
  toggleIcon: {
    position: "absolute",
    right: "11.5px",
    top: "11.5px",
    color: "#6C6B80",
    cursor: "pointer",
  },
  forgotPassText: {
    color: "blue",
    fontSize: "13px",
  },
}));

export default style;
