import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import dot from "assets/home/dot.png";
import loaction from "assets/home/location.png";
import SkeletonCardJob from "components/global/loader/skeletonLoader/SkeletonCardJob";
import style from "components/local/home/blogsSection/jobs/style";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const Jobs = ({ homeData }) => {
  const classes = style();
  const { homepage_loading } = useSelector((state) => state.home);

  return (
    <Box className={classes.jobsContainer}>
      <Box className={classes.jobsWrap}>
        <Box className={classes.jobsTypography}>
          <Typography className={classes.jobsTitle}>Jobs</Typography>

          {/* {homeData?.job && (
            <Box className={classes.jobsGridSeeBtnBox}>
              <NavLink to="/jobs" className={classes.jobsGridSeeBtn}>
                see more
              </NavLink>
            </Box>
          )} */}
        </Box>
        <Box className={classes.jobsCardBox}>
          <Grid container spacing={1}>
            {homepage_loading
              ? [...Array(3)]?.map((item) => (
                  <Grid item md={12} sm={6} xs={12} lg={12}>
                    <SkeletonCardJob />
                  </Grid>
                ))
              : homeData?.job?.slice(0, 3)?.map((data) => {
                  return <JobComponent key={data.id} data={data} />;
                })}
          </Grid>
        </Box>
        {/* <Box className={classes.jobsSeemeore}> */}
        {homeData?.job && (
          <Box className={classes.jobsGridSeeBtnBox}>
            <NavLink to="/jobs" className={classes.jobsGridSeeBtn}>
              see more
            </NavLink>
          </Box>
        )}
        {/* </Box> */}
      </Box>
    </Box>
  );
};

const JobComponent = ({ data }) => {
  const classes = style();
  return (
    <Grid item md={12} sm={6} xs={12} lg={12}>
      <NavLink to={`/jobs/${data?.slug}`} style={{ textDecoration: "none" }}>
        <Paper elevation={0} className={classes.jobsCardPaper}>
          <Box className={classes.jobsCardImgBox}>
            <img src={data?.image} alt="" className={classes.jobsCardImgBox} />
          </Box>
          <Box className={classes.jobsCardTypography}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Typography className={classes.jobsCardTitle}>
                {data?.company?.name ?? "NCASA"}
              </Typography>
              <Typography className={classes.jobsCardHead}>
                <img src={loaction} alt="" className={classes.loactionIcon} />
                {/* Malasiya */}
                {data?.location}
              </Typography>
              <Divider sx={{ display: "flex", justifyContent: "center" }} />
              <Typography className={classes.jobsCardSubTitle}>
                {" "}
                <img src={dot} alt="" className={classes.jobsCardDot} />
                {/* Security Guard */}
                {data?.position}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </NavLink>
    </Grid>
  );
};
export default Jobs;
