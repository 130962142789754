import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "redux/changePassword/actions";
import * as yup from "yup";
import style from "./style";

const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState({
    showOldPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  });
  const classes = style();
  const dispatch = useDispatch();
  const passwordState = useSelector((state) => state.changePassword);

  const validationSchema = yup.object({
    old_password: yup.string().required("Old password is required"),
    new_password: yup
      .string()
      .matches(
        /(?=^.{6,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*/,
        "Password must be at least 6 characters long, must include at least 1 uppercase character, 1 lowercase character, 1 digit and 1 special character."
      )
      .required("New password is required"),
    new_password_confirmation: yup
      .string()
      .oneOf([yup.ref("new_password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({ resolver: useYupValidationResolver(validationSchema) });

  useEffect(() => {
    if (passwordState.status === "completed") {
      reset({
        old_password: "",
        new_password: "",
        new_password_confirmation: "",
      });
    }
  }, [passwordState.status]);

  function handlePasswordChange(data) {
    // console.log("data", data);
    dispatch(changePassword(data));
  }

  return (
    <Box
      component="section"
      sx={{
        bgcolor: "#F5F5F5",
        padding: { xs: "50px", md: "60px 75px", lg: "60px 124px" },
        height: "100%",
      }}
    >
      <form
        className={classes.formContainer}
        onSubmit={handleSubmit(handlePasswordChange)}
      >
        <Box sx={{ mb: "30px" }}>
          <Typography component="h1" className={classes.header}>
            Change Password
          </Typography>
        </Box>

        <Box className={classes.inputField}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <label htmlFor="old-password" className={classes.label}>
              Old Password
            </label>
          </Box>
          <Box sx={{ position: "relative" }}>
            <input
              id="old-password"
              type={showPassword.showOldPassword ? "text" : "password"}
              placeholder="Enter Old Password"
              className={classes.input}
              {...register("old_password")}
              aria-invalid={errors.old_password ? true : false}
            />
            {showPassword.showOldPassword ? (
              <VisibilityOutlined
                className={classes.toggleIcon}
                onClick={() =>
                  setShowPassword((showPassword) => ({
                    ...showPassword,
                    showOldPassword: false,
                  }))
                }
              />
            ) : (
              <VisibilityOffOutlined
                className={classes.toggleIcon}
                onClick={() =>
                  setShowPassword((showPassword) => ({
                    ...showPassword,
                    showOldPassword: true,
                  }))
                }
              />
            )}
          </Box>
          {errors.old_password && (
            <Typography color="#DC2626">
              {errors.old_password?.message}
            </Typography>
          )}
        </Box>

        <Box className={classes.inputField}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <label htmlFor="new-password" className={classes.label}>
              New Password
            </label>
          </Box>
          <Box sx={{ position: "relative" }}>
            <input
              id="new-password"
              type={showPassword.showNewPassword ? "text" : "password"}
              placeholder="Enter New Password"
              className={classes.input}
              {...register("new_password")}
              aria-invalid={errors.new_password ? true : false}
            />

            {showPassword.showNewPassword ? (
              <VisibilityOutlined
                className={classes.toggleIcon}
                onClick={() =>
                  setShowPassword((showPassword) => ({
                    ...showPassword,
                    showNewPassword: false,
                  }))
                }
              />
            ) : (
              <VisibilityOffOutlined
                className={classes.toggleIcon}
                onClick={() =>
                  setShowPassword((showPassword) => ({
                    ...showPassword,
                    showNewPassword: true,
                  }))
                }
              />
            )}
          </Box>
          {errors.new_password && (
            <Typography color="#DC2626">
              {errors.new_password?.message}
            </Typography>
          )}
        </Box>

        <Box className={classes.inputField} sx={{ mb: "45px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <label htmlFor="confirm-password" className={classes.label}>
              Confirm Password
            </label>
          </Box>
          <Box sx={{ position: "relative" }}>
            <input
              id="confirm-password"
              type={showPassword.showConfirmPassword ? "text" : "password"}
              placeholder="Type Your New Password Again"
              className={classes.input}
              {...register("new_password_confirmation")}
              aria-invalid={errors.new_password_confirmation ? true : false}
            />

            {showPassword.showConfirmPassword ? (
              <VisibilityOutlined
                className={classes.toggleIcon}
                onClick={() =>
                  setShowPassword((showPassword) => ({
                    ...showPassword,
                    showConfirmPassword: false,
                  }))
                }
              />
            ) : (
              <VisibilityOffOutlined
                className={classes.toggleIcon}
                onClick={() =>
                  setShowPassword((showPassword) => ({
                    ...showPassword,
                    showConfirmPassword: true,
                  }))
                }
              />
            )}
          </Box>
          {errors.new_password_confirmation && (
            <Typography color="#DC2626">
              {errors.new_password_confirmation?.message}
            </Typography>
          )}
        </Box>

        <Box>
          <button className={classes.button}>
            {passwordState?.status === "pending"
              ? "Logging In"
              : "Change Password"}
          </button>
        </Box>
      </form>
    </Box>
  );
};

export default ChangePassword;
