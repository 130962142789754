import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Container } from "@mui/material";
import HeroSection from "components/local/home/hero/HeroSection";
import { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import style from "./style";

const Hero = ({ homeData, loading }) => {
  function SampleNextArrow(props) {
    const { style, onClick } = props;
    return (
      <Container maxWidth="lg">
        <KeyboardArrowRightIcon
          className={`${classes.arrowIconRight}`}
          style={{
            ...style,
          }}
          onClick={onClick}
        />
      </Container>
    );
  }
  function SamplePrevArrow(props) {
    const { style, onClick } = props;
    return (
      <Container maxWidth="lg">
        <KeyboardArrowLeftIcon
          className={`${classes.arrowIconLeft}`}
          style={{
            ...style,
          }}
          onClick={onClick}
        />
      </Container>
    );
  }
  const classes = style();
  const sliderRef = useRef();

  console.log("sliderrrrrr", sliderRef.current);
  console.log("dataaaaaaa", homeData);

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    pauseOnHover: false,
    autoplaySpeed: 4000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <>
      {loading ? (
        <Box className={classes.heroLoading}></Box>
      ) : (
        <Slider {...settings} ref={sliderRef}>
          {homeData?.banner?.map((data) => {
            return <HeroSection key={data?.id} data={data} />;
          })}
        </Slider>
      )}
    </>
  );
};

export default Hero;
