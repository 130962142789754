import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  dropdownBox: {
    // padding: "5px 20px",
    // marginTop: '-10px'
    width: "170px",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },
  },
  dropdownItems: {
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    fontSize: "15px",
    fontWeight: "500 ",
    cursor: "pointer",
    padding: "6px 16px",

    //   [theme.breakpoints.down("sm")]: {
    //     "& .css-cgkdb4-MuiTypography-root": {
    //     fontSize: "22px !important",
    //   },
    // },
  },
  dropdownItemsLink: {
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    fontSize: "14px",
    fontWeight: "500 !important",
    //  color: "#121127 !important",
    textDecoration: "none",
    color: "#504F64",
    width: "100%",

    "& .MuiTypography-root": {
      [theme.breakpoints.down("lg")]: {
        fontSize: "18px",
      },
    },

    // textDecoration: "none",

    // padding: "30px 20px",
    //  "& a:-webkit-any-link":{
    //      color: "#121127",
    //      // backgroundColor: "#fff",
    //      textDecoration: "none",
    //      padding: "30px 20px",
    //    },
    "& a.active": {
      height: "100%",
      width: "100%",
      padding: "30px 15px",
      // padding: '100% 10px',
      backgroundColor: "#EF4444",
      color: "#fff",
      textDecoration: "none",
    },
  },
  divider: {
    "&:last-child": {
      display: "none",
    },
  },
  aboutOpenMenuBox: {
    paddingInline: 0,
    backgroundColor: "#fffafa",
  },
}));

export default style;
