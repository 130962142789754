import { getPublicationsApi, getPublicationsSingleApi } from "apis";
import * as actions from "components/local/publications/redux/types";

export const getPublicatioinspage = (Data) => async (dispatch) => {
dispatch({ type: actions.GET_PUBLICATIONS_BEGIN });

try {
   const { data } = await getPublicationsApi(Data);
   dispatch({
      type:  actions.GET_PUBLICATIONS_SUCCESS,
      payload: data,
   });
} catch (error) {
   dispatch({ type:  actions.GET_PUBLICATIONS_ERROR });
   console.log(error);
}
};

export const getPublicatioinSinglepage = (Data) => async (dispatch) => {
   dispatch({ type: actions.GET_PUBLICATIONS_SINGLE_BEGIN });
 
   try {
     const { data } = await getPublicationsSingleApi(Data);
     dispatch({
       type: actions.GET_PUBLICATIONS_SINGLE_SUCCESS,
       payload: data,
     });
   } catch (error) {
     dispatch({ type: actions.GET_PUBLICATIONS_SINGLE_ERROR });
     console.log(error);
   }
 };