// import EmailIcon from "@mui/icons-material/Email";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import style from "./style";

const ComplainIcon = () => {
  const classes = style()
  const naviate = useNavigate();
  const handleNavigate = () => {
    naviate(`/complain`);
  };
  return (
    <Box
      className={classes.complainBoxContainer}
    >
      <Tooltip title={"Go to complain section"}>
        <Box
          onClick={handleNavigate}
          className={classes.complainBox}
        >
          <Box
           className={classes.complainBoxIcon}
          >
            <ContactSupportIcon color="white" style={{ color: "#fff" }} />
          </Box>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default ComplainIcon;
