import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import style from "./style";

const Maps = () => {
  const classes = style();

  const { siteSetting, siteSetting_loading } = useSelector(
    (state) => state.siteSetting
  );
  const [siteData, setSiteData] = useState([]);

  console.log("ooooooooooooooo", { siteData });

  useEffect(() => {
    siteSetting?.data && setSiteData(siteSetting?.data);
  }, [siteSetting]);

  return (
    <Box sx={{ marginBottom: "22px" }}>
      <Box className={classes.textContainer}>
        <Typography component={"h2"} className={classes.title}>
          Find us on Google Maps
        </Typography>
        {/* <Typography className={classes.lightText}>
          Lorem ipsum dolor sit amet consectetur. Arcu elementum maecenas ac
          suspendisse sodales.
        </Typography> */}
      </Box>
      <Box>
        <iframe
          title="maps"
          // src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14132.440101977943!2d85.3332304!3d27.6829941!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19d91dbdf1eb%3A0xe3f288a459c7ed2d!2sNepal%20Chartered%20Accountant%20Students%E2%80%99%20Association%20(NCASA)!5e0!3m2!1sen!2snp!4v1681041593332!5m2!1sen!2snp"
          src={siteData?.map}
          style={{ border: "0", width: "100%", height: "320px" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Box>
    </Box>
  );
};

export default Maps;
