import { getProfileApi } from "apis/profile";
import * as actions from "./types";

export const fetchProfileData = () => (dispatch) => {
  dispatch({ type: actions.FETCH_PROFILE_PENDING });
  getProfileApi()
    .then((response) => {
      dispatch({
        type: actions.FETCH_PROFILE_SUCCESS,
        payload: response.data.data,
      });
      localStorage.setItem(
        "userImage",
        JSON.stringify(response.data.data.membership_detail.image)
      );
    })
    .catch((error) => {
      console.log("error", error);
      dispatch({ type: actions.FETCH_PROFILE_ERROR });
    });
};
