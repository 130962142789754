import * as actions from "components/local/blogs/redux/types";
const initialState = {
  blogsPage: [],
  blogs_loading: false,
};

const initialStateSingle = {
  blogsSinglePage: [],
  blogsSingle_loading: false,
};

const blogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_BLOGS_BEGIN:
      return { ...state, blogs_loading: true };

    case actions.GET_BLOGS_SUCCESS:
      return { ...state, blogs_loading: false, blogsPage: action.payload };

    case actions.GET_BLOGS_ERROR:
      return { ...state, blogs_loading: false };
    default:
      return state;
  }
};

const blogsSingleReducer = (state = initialStateSingle, action) => {
  switch (action.type) {
    case actions.GET_BLOGS_SINGLE_BEGIN:
      return { ...state, blogsSingle_loading: true };

    case actions.GET_BLOGS_SINGLE_SUCCESS:
      return {
        ...state,
        blogsSingle_loading: false,
        blogsSinglePage: action.payload,
      };

    case actions.GET_BLOGS_SINGLE_ERROR:
      return { ...state, blogsSingle_loading: false };
    default:
      return state;
  }
};

export {blogsReducer, blogsSingleReducer};
