import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import Description from "components/global/description/Description";
import LogoFooter from "components/global/logo/LogoFooter";
import SocialLinks from "components/global/socialLinks/SocialLinks";
import VerticalLine from "components/global/verticalLine/VerticalLine";
import FooterSearch from "layouts/footer/FooterSearch";
import { NavLink, useNavigate } from "react-router-dom";
import style from "./style";

const Footer = ({ data }) => {
  const classes = style();
  const navigate = useNavigate();
  const handleTermsNavigate = () => {
    navigate(`/terms-and-conditions`);
  };
  const handlePrivacyPolicy = () => {
    navigate(`/privacy-policy`);
  };
  return (
    <Box className={classes.footerContainer}>
      <Container maxWidth="lg">
        {/* <Box className={[classes.footerWrap, "container-wrap"]}> */}
        <Grid container>
          <Grid
            item
            md={3.9}
            sx={12}
            xs={12}
            className={`${classes.footerGrid} ${classes.grid1}`}
          >
            <LogoFooter data={data} />
            <Typography className={classes.logoSubtext}>
              <Description length={false} description={data?.about} />
            </Typography>
            <SocialLinks data={data} />

            <Divider
              sx={{
                margin: "20px 0",
                borderColor: "#FEC3B2",
                opacity: "60%",
                display: { lg: "none", md: "block" },
              }}
            />
          </Grid>

          <VerticalLine />

          <Grid
            item
            md={3.9}
            sx={12}
            xs={12}
            className={`${classes.footerGrid} ${classes.grid2}`}
          >
            <Typography className={classes.linkHead}>Quick Links</Typography>
            <Grid container>
              <Grid item md={6} sm={4} xs={6}>
                <NavLink to="/" className={classes.footerNavLink}>
                  Home
                </NavLink>
              </Grid>
              <Grid item md={6} sm={4} xs={6}>
                <NavLink to="/news" className={classes.footerNavLink}>
                  News
                </NavLink>
              </Grid>
              <Grid item md={6} sm={4} xs={6}>
                <NavLink to="/jobs" className={classes.footerNavLink}>
                  Jobs
                </NavLink>
              </Grid>
              <Grid item md={6} sm={4} xs={6}>
                <NavLink to="/blogs" className={classes.footerNavLink}>
                  Blogs
                </NavLink>
              </Grid>
              <Grid item md={6} sm={4} xs={6}>
                <NavLink to="/gallery" className={classes.footerNavLink}>
                  Gallery
                </NavLink>
              </Grid>
              <Grid item md={6} sm={4} xs={6}>
                <NavLink to="/events" className={classes.footerNavLink}>
                  Events
                </NavLink>
              </Grid>
              <Grid item md={6} sm={4} xs={6}>
                <NavLink to="/publications" className={classes.footerNavLink}>
                  Publications
                </NavLink>
              </Grid>
              <Grid item md={6} sm={4} xs={6}>
                <NavLink to="/volunteer" className={classes.footerNavLink}>
                  Volunteer
                </NavLink>
              </Grid>
              {/* <Grid item md={6} sm={4} xs={6}>
                <NavLink to="/volunteer" className={classes.footerNavLink}>
                  Volunteer
                </NavLink>
              </Grid> */}
              <Grid item md={6} sm={4} xs={6}>
                <NavLink to="/contact-us" className={classes.footerNavLink}>
                  Contact us
                </NavLink>
              </Grid>
            </Grid>
            <Divider
              sx={{
                margin: "20px 0",
                borderColor: "#FEC3B2",
                opacity: "60%",
                display: { lg: "none", md: "block" },
              }}
            />
          </Grid>

          <VerticalLine />

          <Grid
            item
            md={3.9}
            sx={12}
            xs={12}
            className={`${classes.footerGrid} ${classes.grid3}`}
          >
            <Typography className={classes.searchHead}>
              Subscribe to our newsletter
            </Typography>
            <Typography className={classes.searchSubtext}>
              The latest news, articles, and resources, sent to your inbox
              weekly
            </Typography>
            <FooterSearch />
          </Grid>
        </Grid>
        {/* <HorizontalLine /> */}
        <Divider
          sx={{ margin: "20px 0", borderColor: "#FEC3B2", opacity: "60%" }}
        />
        {/* <Box className={classes.footerBottomTextBox}> */}
        <Grid container className={classes.footerBottomText}>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{ display: "flex", justifyContent: " center" }}
          >
            <Description length={true} description={data?.copyright} />
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            xs={6}
            sx={{ display: "flex", justifyContent: " center" }}
          >
            <Typography
              onClick={handleTermsNavigate}
              sx={{
                color: "#4C80E6 !important",
                cursor: "pointer",
                fontSize: "12px !important",
              }}
            >
              Terms and conditions
            </Typography>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            xs={6}
            sx={{ display: "flex", justifyContent: " center" }}
          >
            <Typography
              onClick={handlePrivacyPolicy}
              sx={{
                color: "#4C80E6 !important",
                cursor: "pointer",
                fontSize: "12px !important",
              }}
            >
              Privacy Policy
            </Typography>
          </Grid>
        </Grid>
        {/* </Box> */}
        {/* </Box> */}
      </Container>
    </Box>
  );
};

export default Footer;
