import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, Box, Container, Typography } from "@mui/material";
import LoginForm from "components/form/loginForm/LoginForm";
import ButtonSecondary from "components/global/buttons/buttonsSecondary/ButtonSecondary";
import Logo from "components/global/logo/Logo";
import MobileNavbar from "layouts/navbar/MobileNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
// import profileImage from "assets/dashboard/profile-pic.jpg";
import SignUpForm from "components/form/signUpForm/signUpForm";
import * as actions from "components/local/home/redux/types.js";
import DropDown from "./dropdown/Dropdown";
import style from "./style";

const Navbar = ({ data }) => {
  const classes = style();
  const [barOpen, setBarOpen] = useState(false);
  const [windowSize, setWindowSize] = useState([window?.innerWidth]);
  const authState = useSelector((state) => state.auth);
  const profileState = useSelector((state) => state.profile);
  const homeState = useSelector((state) => state.home);
  const dispatch = useDispatch();

  // const handleLogOut = () => {
  //   dispatch(logout());
  // };

  console.log({ profileState });
  console.log({ authState });

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window?.innerWidth]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  console.log({ windowSize });

  return (
    <>
      <Box className={classes.navbarContainer}>
        <Container maxWidth="lg">
          <Box className={classes.navbarWrap}>
            <Logo data={data} />

            <Box className={classes.navMenu}>
              <NavLink to="/">Home</NavLink>
              {window?.innerWidth >= 1200 && (
                <DropDown barOpen={barOpen} setBarOpen={setBarOpen} />
              )}
              <NavLink to="/events">Events</NavLink>

              <NavLink to="/our-team">Our Teams</NavLink>

              <NavLink to="/news">News</NavLink>

              <NavLink to="/gallery">Gallery</NavLink>

              <NavLink to="/publications">Publications</NavLink>

              <NavLink to="/jobs">Jobs</NavLink>

              <NavLink to="/blogs">Blogs</NavLink>

              <NavLink to="/volunteer">Volunteer</NavLink>

              <NavLink to="/contact-us">Contact us</NavLink>
              {authState.isUserLoggedIn ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "7px",
                    ml: "10px",
                  }}
                >
                  <Box>
                    <Typography
                      fontSize={"15px"}
                      lineHeight="1.2"
                      fontWeight={"500"}
                      color="#1E1919"
                    >
                      {authState?.user?.name}
                    </Typography>
                    {/* <Button
                      onClick={handleLogOut}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        color: "#7C7C7C",
                        background: "transparent",
                        border: "none",
                        padding: "0",
                        fontSize: "12px",
                        lineHeight: "1.2",
                      }}
                    >
                      <Login
                        fontSize="small"
                        sx={{ width: "15px", height: "15px" }}
                      />
                      Logout
                    </Button> */}
                  </Box>

                  <Box>
                    <Link to="/dashboard/profile" style={{ padding: 0 }}>
                      <Avatar
                        src={profileState?.data?.image}
                        alt=""
                        sx={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          objectFit: "cover",
                          background: "#EF4444",
                          color: "#fff",
                        }}
                      >
                        {profileState?.data?.image
                          ? profileState?.data?.image
                          : authState?.user?.name?.charAt(0)}
                      </Avatar>
                    </Link>
                  </Box>
                </Box>
              ) : (
                <Box display="flex" alignItems="center" ml="2rem">
                  <ButtonSecondary
                    className={classes.navMemberLoginBtn}
                    title={"Login"}
                    onClick={() => dispatch({ type: actions.SHOW_LOGIN_FORM })}
                  />
                </Box>
              )}
            </Box>
            {/* {console.log({ Window: window?.innerWidth })} */}
            {window?.innerWidth < 1200 && (
              <Box className={classes.hamburgerMenuIcon}>
                <MenuIcon fontSize="large" onClick={() => setBarOpen(true)} />
                <MobileNavbar barOpen={barOpen} setBarOpen={setBarOpen} />
              </Box>
            )}
          </Box>
        </Container>
      </Box>
      {homeState.showLoginForm && <LoginForm />}
      {homeState.showSignUpForm && <SignUpForm />}
    </>
  );
};

export default Navbar;
