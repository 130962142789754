import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { TimeAgo } from "components/global/date/Date";
import { Link, useNavigate } from "react-router-dom";
import style from "./style";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";


const CourseCard = ({ course }) => {
  const classes = style();
  console.log({ course });
  const navigate = useNavigate();

  const handleNavigate = () => {
    course?.course_notes?.length &&
      navigate(`/dashboard/courses/cap/notes/${course?.slug}`);
  };

  return (
    <Card
      elevation={0}
      sx={{
        boxShadow:
          "0px 1px 1px rgb(0 0 0 / 10%),  0px 0px 1px rgb(70 79 96 / 16%)",
        width: "220px",
        marginInline: "auto",
      }}
    >
      <CardMedia sx={{ height: "118px" }} image={course.image} />

      <CardContent
        sx={{ padding: "10px 17px 12px", border: "solid 1px #EDECEC" }}
      >
        <Box sx={{ mb: "8px" }}>
          <Typography component={"h2"} className={classes.header}>
            {course?.title}
          </Typography>
        </Box>
        <Box sx={{ pl: "14px" }}>
          <Typography component={"h3"} className={classes.subHeader}>
            Uploaded <TimeAgo time={course?.updated_at} />
          </Typography>
        </Box>
      </CardContent>

      <CardActions>
        <Box className={classes.btnContainer}>
          {/* <Link to={`/dashboard/courses/cap/videos/${course.id}`}>
            <Button variant="text" className={classes.btn}>
              Videos
            </Button>
          </Link> */}
          <Button
            variant="text"
            className={
              course?.total_course_videos ? classes.btn : classes.btnSecondary
            }
            disabled={!course?.total_course_videos}
            onClick={() =>
              navigate(`/dashboard/courses/cap/videos/${course?.slug}`)
            }
          >
            <PlayCircleOutlineIcon
              className={
                course?.total_course_videos
                  ? classes.btnIcon
                  : classes.btnIconSecondary
              }
            />
            {`${course?.total_course_videos} Videos`}
          </Button>

          {/* <Link to={`/dashboard/courses/notes/${course?.slug}`}> */}
          <Button
            variant="text"
            disabled={!course?.course_notes?.length && true}
            onClick={() => handleNavigate()}
            className={
              course?.course_notes?.length ? classes.btn : classes.btnSecondary
            }
          >
            {/* <img src={fileIcon} alt="" className={classes.btnIcon} /> */}
            <NoteAddOutlinedIcon
              className={
                course?.course_notes?.length
                  ? classes.btnIcon
                  : classes.btnIconSecondary
              }
            />
            {`${course?.course_notes?.length ?? ""} Notes`}
          </Button>
          {/* </Link> */}
        </Box>
      </CardActions>
    </Card>
  );
};

export default CourseCard;
