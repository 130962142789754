import { Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Description from "components/global/description/Description";

const NoteList = ({
  coursesNoteData,
  handleNoteLink,
  data: selectedItem,
  loader,
}) => {
  return (
    <Box>
      <Box sx={{ mb: "35px", pb: "5px", borderBottom: "3px solid #D9D9D9" }}>
        <Typography
          component="h2"
          fontWeight="600"
          fontSize="32px"
          lineHeight="1.2"
          color="#000"
          sx={{
            display: "inline-block",
            position: "relative",
            "&:after": {
              content: '" "',
              display: "block",
              width: "100%",
              height: "3px",
              backgroundColor: "#000",
              position: "absolute",
              bottom: "-7px",
            },
          }}
        >
          Other Notes
        </Typography>
      </Box>
      <Box
        sx={{
          height: "730px",
          borderRadius: "5px",
          overflowY: "scroll",
          padding: "15px",
          boxShadow: " 0px 8px 20px rgba(18, 17, 39, 0.1)",
          "&::-webkit-scrollbar-track": {
            background: "#E5E5EB",
          },
          "&::-webkit-scrollbar": {
            width: "7px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#4C4B63",
            borderRadius: "16px",
          },
        }}
      >
        {loader
          ? [...Array(3)]?.map((item) => (
              // <Grid item lg={3} md={4} sm={4} xs={12} mb="1rem">
              <Skeleton
                variant="rounded"
                sx={{ marginBottom: "2rem" }}
                height={130}
              />
              // </Grid>
            ))
          : coursesNoteData?.map((data, index) => {
              return (
                <Box
                  key={data?.id}
                  sx={{
                    display: "flex",
                    gap: "30px",
                    mb: "27px",
                    p: "1rem",
                    borderRadius: "5px",
                    backgroundColor: data?.id === selectedItem?.id && "#fff",
                    cursor: "pointer",
                  }}
                  onClick={() => handleNoteLink(data)}
                >
                  <Box>
                    <Typography
                      fontWeight={"500"}
                      fontSize="50px"
                      color={"#686868"}
                    >
                      {String(index + 1).length === 1
                        ? `0${index + 1}`
                        : index + 1}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      fontWeight={"500"}
                      fontSize="14px"
                      lineHeight={"1.2"}
                      color="#000"
                      marginBottom={"10px"}
                    >
                      {data?.title}
                    </Typography>
                    <Typography
                      fontWeight={"500"}
                      fontSize={"10px"}
                      color="#857F7A"
                      marginBottom={"7px"}
                    >
                      {/* {note.description} */}
                      <Description
                        length={false}
                        description={data?.description}
                      />
                    </Typography>
                    <Typography
                      fontWeight={"600"}
                      fontSize={"10px"}
                      lineHeight="1.2"
                      color="#655C54"
                    >
                      {data?.date}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
      </Box>
    </Box>
  );
};

export default NoteList;
