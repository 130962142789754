import { userLogin, userLogout, userRegister } from "apis/auth";
import { errorToast, successToast } from "utils/toast";
import * as actions from "./types";

export const login = (data) => (dispatch) => {
  dispatch({ type: actions.LOGIN_PENDING });
  userLogin(data)
    .then((response) => {
      console.log("response", response);
      dispatch({
        type: actions.LOGIN_SUCCESS,
        payload: response.data.data,
      });
      localStorage.setItem("token", response.data.data.token);
      localStorage.setItem("refresh_token", response.data.data.refresh_token);
      localStorage.setItem("userInfo", JSON.stringify(response.data.data.user));
    })
    .catch((error) => {
      console.log("error", error);
      dispatch({ type: actions.LOGIN_ERROR });
      errorToast(error);
    });
};

export const logout = (data) => (dispatch) => {
  userLogout()
    .then((response) => {
      dispatch({ type: actions.LOGOUT_SUCCESS });
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("userImage");
    })
    .catch((error) => {
      console.log("error", error);
      errorToast(error);
    });
};

export const verification = (data) => (dispatch) => {
  dispatch({ type: actions.VERIFICATION_PENDING });
  userRegister(data)
    .then((response) => {
      console.log("response", response);
      dispatch({
        type: actions.VERIFICATION_SUCCESS,
      });
      successToast(response.data.message);
    })
    .catch((error) => {
      console.log("error", error);
      dispatch({ type: actions.VERIFICATION_ERROR });
      errorToast(error);
    });
};
