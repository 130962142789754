import TermsAndConditions from 'components/local/termsAndConditions/TermsAndConditions'
import Verification from 'components/local/verifiedUser'
import React from 'react'

const TermsAndConditionsPage = () => {
  return (
    <>
      <TermsAndConditions/>
    </>
  )
}

export default TermsAndConditionsPage
