import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Loader from "components/global/loader/Loader";
import DropZone from "components/UI/FileDrop/DropZone";
import { HookFormInput } from "components/UI/Form/HookFormInput";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { applyJobs } from "../jobs/redux/actions";
import style from "./style";

const ApplicationForm = () => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const classes = style();
  const [file, setFile] = useState();
  const { applyJobs_loading } = useSelector((state) => state.applyJobs);
  const { user } = useSelector((state) => state.auth);

  const onFileChange = (e) => {
    // if (e.target.files?.[0] && e.target.files) {
    setFile(e.target.files?.[0]);
    // }
  };

  console.log({ filesssssssssssssssss: file });
  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    // phone: yup
    //   .string()
    //   .typeError("Must be a number")
    //   .required("Phone number is required")
    //   .min(10),
    phone: yup
      .string()
      .typeError("It must be a number")
      .required("Phone number is required")
      .min(10)
      .max(10),
    address: yup.string().required("Address is required"),
    email: yup.string().required("Email is required"),
    // resume: yup.string().required("Resume is required"),
    cv: yup.mixed().required("File is required"),
  });

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    register,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  console.log("errrrrrrrrr", errors);

  const refetch = () => {
    const array = ["cv"];
    array?.map((item) => setValue(item, ""));
    reset();
  };

  const onSubmit = (values) => {
    console.log({ values });
    const formData = new FormData();
    Object.keys(values)?.map((keys) => formData.append(keys, values[keys]));
    // formData.append("cv", values.cv);
    for (const value of formData.values()) {
      console.log("Hello", value);
    }
    dispatch(applyJobs(formData, slug, refetch));
  };

  console.log({ watch: watch() });

  return (
    <Box className={classes.formContainer} style={{ maxWidth: "366px" }}>
      <Typography component="h2" className={classes.title}>
        Application Form
      </Typography>
      {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}> */}
      {/* <HookFormProvider> */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} md={12} sx={{ marginBottom: "1rem" }}>
            <HookFormInput
              required={true}
              type="text"
              name="name"
              label="Name"
              control={control}
              errors={errors}
              {...register("name")}
            />
          </Grid>
          <Grid item xs={12} md={12} sx={{ marginBottom: "1rem" }}>
            <HookFormInput
              required={true}
              type="number"
              name="phone"
              label="Phone"
              control={control}
              errors={errors}
              {...register("phone")}
            />
          </Grid>
          <Grid item xs={12} md={12} sx={{ marginBottom: "1rem" }}>
            <HookFormInput
              required={true}
              type="email"
              name="email"
              label="Email"
              control={control}
              errors={errors}
              {...register("email")}
            />
          </Grid>
          <Grid item xs={12} md={12} sx={{ marginBottom: "1rem" }}>
            <HookFormInput
              required={true}
              type="text"
              name="address"
              label="Address"
              control={control}
              errors={errors}
              {...register("address")}
            />
          </Grid>
        </Grid>
        <DropZone
          watch={watch}
          setValue={setValue}
          name="cv"
          errors={errors}
          key={"cv"}
        />
        {/* <DragDrop onFileChange={onFileChange} /> */}
        <Box sx={{ marginTop: "1rem" }}>
          {user?.role?.includes("Company") ? (
            <Tooltip
            style={{ fontSize: "12px !important", color: "red" }}
            title="Company cannot apply for Jobs"
          >
            <Box>
            <Button disabled type="submit" className={classes.buttonDisable}>
              Submit
            </Button>
            </Box>
          </Tooltip>
          ) : (
            <Button type="submit" className={classes.button}>
              {applyJobs_loading ? <Loader /> : "Submit"}
            </Button>
          )}
        </Box>
      </form>
      {/* </HookFormProvider> */}
    </Box>
  );
};

export default ApplicationForm;
