import * as actions from "./types";

const initialState = {
  status: "idle",
  user: JSON.parse(localStorage.getItem("userInfo")) || {},
  isUserLoggedIn: Boolean(localStorage.getItem("token")) || false,
  // bearerToken: localStorage.getItem("token") || null,
  // refreshToken: localStorage.getItem("refresh_token") || null,
  verifiedLoading: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGIN_PENDING:
      return { ...state, status: "pending" };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        status: "completed",
        isUserLoggedIn: true,
        user: action.payload.user,
        // bearerToken: action.payload.token,
        // refreshToken: action.payload.refresh_token,refreshtoken
      };
    case actions.LOGIN_ERROR:
      return { ...state, status: "failed" };
    case actions.CHANGE_STATUS:
      return { ...state, status: action.payload };
    case actions.LOGOUT_SUCCESS:
      return {
        ...state,
        status: "idle",
        user: {},
        isUserLoggedIn: false,
        // bearerToken: null,
        // refreshToken: null,
      };

    // verification
    case actions.VERIFICATION_PENDING:
      return { ...state, verifiedLoading: true };
    case actions.VERIFICATION_SUCCESS:
    case actions.VERIFICATION_ERROR:
      return { ...state, verifiedLoading: false };

    default:
      return state;
  }
};

export default authReducer;
