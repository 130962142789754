import { makeStyles } from '@mui/styles';

const usestyles = makeStyles((theme) => ({
  root: {
    background: '#ddd',
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding: '1.2rem 0 ',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: '24',
    backgroundColor: '#fff !important',
  borderRadius: '4px !important',
    p: '4'
  },
  modalHeader: {
    background: theme.palette.primary.main,
    alignItems: 'center',
    padding: '2rem 2.5rem 2rem 3.8rem',
    height: '4.2rem',
    color: theme.palette.primaryDark,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '4px 4px 0 0  !important'
  },
  modalHeaderText: {
    color: theme.palette.primaryDark,
    fontWeight: '600',
    fontSize: '22px',
    fontFamily: 'Euclid',
    // marginTop: '1rem',
      // borderRadius: '10px !important'
  },
  cancelItem: {
    cursor: 'pointer'
  },
  modalBody: {
    // background: theme.palette.primary.main,
    borderRadius: '0 0 4px 4px !important'
  },
  modalFooter: {
    width: '100%',
    marginTop: 'auto',
    marginLeft: 'auto',
    background: '#fff',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem 3rem 2rem 3rem',
    height: '6rem'
  },
  modalFooterButtons: {
    fontFamily: 'Euclid',
    ':&>*': {
      margin: '0 1rem'
    }
  },
  modalFooterCancel: {
    '&:hover': {
      background: '#ddd'
    }
  }
}));

export default usestyles;
