import { Box, Button, Grid, TextField } from "@mui/material";
import Loader from "components/global/loader/Loader";
import style from "layouts/footer/style";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { postNewsletter } from "./redux/actions";

const FooterSearch = () => {
  const classes = style();
  const dispatch = useDispatch();
  const { newsletter_loading } = useSelector((state) => state.newsletter);

  // const validationSchema = yup.object().shape({
  //   name: yup.string().required("Name is required"),
  //   subject: yup.string().required("Email is required"),
  //   message: yup.string().required("Description is required"),
  // });

  const {
    control,
    watch,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({});

  console.log("errrrrrrrrr", errors);

  const refetch = () => {
    const array = ["email"];
    array?.map((item) => setValue(item, ""));
  };
  const onSubmit = (values) => {
    const data = {
      ...values,
    };
    dispatch(postNewsletter(data, refetch));
  };

  console.log({ watch: watch() });

  return (
    <Box className={classes.footerSearch}>
      <Grid container>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.searchBox}>
          <Grid item md={8} xs={8} className={classes.searchGrid}>
            <TextField
              className={classes.searchField}
              id="outlined-basic"
              placeholder="Enter your email"
              variant="outlined"
              required={true}
              type="email"
              name="email"
              control={control}
              errors={errors}
              {...register("email")}
            />
          </Grid>
          <Grid item md={4} xs={4} className={classes.searchBtnGrid}>
            <Button type="submit" className={classes.submitBtn}>
              {newsletter_loading ? <Loader /> : "Submit"}
            </Button>
          </Grid>
        </form>
      </Grid>
    </Box>
  );
};

export default FooterSearch;
