import { changePasswordApi, resetPasswordMailApi } from "apis/changePassword";
import * as actions from "./types";
import { errorToast, successToast } from "utils/toast";

export const changePassword = (data) => (dispatch) => {
  dispatch({ type: actions.CHANGE_PASSWORD_PENDING });
  changePasswordApi(data)
    .then((response) => {
      console.log("response", response);
      dispatch({ type: actions.CHANGE_PASSWORD_SUCCESS });
      successToast(response.data.message);
    })
    .catch((error) => {
      console.log("error", error);
      errorToast(error);
    });
};

export const resetPasswordMail = (data) => (dispatch) => {
  dispatch({ type: actions.RESET_PASSWORD_MAIL_PENDING });
  resetPasswordMailApi(data)
    .then((response) => {
      // console.log("response", response);
      dispatch({ type: actions.RESET_PASSWORD_MAIL_SUCCESS });
    })
    .catch((error) => {
      console.log("error", error);
      dispatch({ type: actions.RESET_PASSWORD_MAIL_ERROR });
      errorToast(error);
    });
};
