import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

export default function SkeletonNewsHomeLoader() {
  return (
    <Box sx={{ display: "flex", gap: "10px" }}>
      <Skeleton variant="rectangular" width={145} height={100} />
      <Box>
        {/* <Skeleton variant="text" width={80} /> */}
        <Skeleton variant="text" width={130} />
        <Skeleton variant="text" width={230} />
        <Skeleton variant="text" width={230} />
        <Skeleton variant="text" width={50} />
      </Box>

      {/* <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rounded" width={210} height={60} /> */}
    </Box>
  );
}
