import * as actions from "layouts/redux/types";
const initialState = {
  siteSetting: {},
  siteSetting_loading: false,
};

const siteSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_SITE_SETTING_BEGIN:
      return { ...state, siteSetting_loading: true };

    case actions.GET_SITE_SETTING_SUCCESS:
      return {
        ...state,
        siteSetting_loading: false,
        siteSetting: action.payload,
      };

    case actions.GET_SITE_SETTING_ERROR:
      return { ...state, siteSetting_loading: false };

    default:
      return state;
  }
};

export default siteSettingReducer;
