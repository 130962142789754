import { yupResolver } from "@hookform/resolvers/yup";
import { Avatar, Box, Button, Grid, TextField } from "@mui/material";
import Loader from "components/global/loader/Loader";
import style from "components/local/dashboard/community/style";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { getThread, postComment, putComment } from "./redux/actions";

const CommentBoxSecondary = ({
  commentModal,
  row,
  edit,
  commentData,
  handleClose,
  setThreadListData,
}) => {
  const classes = style();

  console.log("iiiiiiiiiiiiiiiiiiiiiiiiii", { row });
  console.log("ppppppppppppppppppppppp", { commentData });

  const dispatch = useDispatch();

  const { comment_loading } = useSelector((state) => state.thread);

  const validationSchema = yup.object().shape({
    comment: yup.string().required("Comment is required before submitting"),
  });
  const authState = useSelector((state) => state.auth);
  const profileState = useSelector((state) => state.profile);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { comment: commentData?.description },
    // description: commentData?.description,
  });
  console.log("errrrrrrrrr", errors);
  const refetch = () => {
    const array = ["comment"];
    array?.map((item) => setValue(item, ""));
    handleClose && handleClose();
    // await setThreadListData([]);
    dispatch(getThread());
  };

  const onSubmit = (values) => {
    const data = {
      description: values?.comment,
      thread_id: row?.id,
    };
    console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx", { data });
    // dispatch(postComment(data, refetch));
    edit
      ? dispatch(putComment(data, refetch))
      : dispatch(postComment(data, refetch));
  };

  console.log({ watch: watch() });
  console.log({ row });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box className={classes.commentBoxSecondaryWrap}>
        <Grid container>
          <Grid item md={1} sx={{ margin: "auto" }}>
            <Box className={classes.profileImgBox}>
              {/* <img src={tankaProfile} alt="" className={classes.profileImg} /> */}
              {/* <img
                src={authState?.user?.image}
                alt=""
                className={classes.profileImg}
              /> */}

              <Avatar
                src={profileState?.data?.image}
                alt=""
                sx={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  background: "#EF4444",
                  color: "#fff",
                }}
              >
                {profileState?.data?.image
                  ? profileState?.data?.image
                  : authState?.user?.name?.charAt(0)}
              </Avatar>
            </Box>
          </Grid>

          <Grid item md={9} sx={{ padding: "0 10px 0 0" }}>
            <TextField
              className={classes.commentBoxSecondarySearch}
              placeholder={`Say Something to @${row?.user?.name}`}
              variant="outlined"
              required={true}
              type="comment"
              name="comment"
              control={control}
              errors={errors}
              {...register("comment")}
            />
          </Grid>

          <Grid item md={2}>
            <Button type="submit" className={classes.submitBtnSecondary}>
              {comment_loading ? <Loader /> : edit ? "Update" : "Submit"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default CommentBoxSecondary;
