import { Grid } from "@mui/material";
import Community from "components/local/dashboard/community";
import SideBar from "components/UI/SideBar/SideBar";

const CommunityPage = () => {
  return (
    <Grid container sx={{ backgroundColor: "#F5F5F5" }}>
      <Grid item md={2}>
        <SideBar />
      </Grid>
      <Grid item md={10}>
        <Community />
      </Grid>
    </Grid>
  );
};

export default CommunityPage;
