import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import Banner from "components/global/banner";
import Loader from "components/global/loader/Loader";
import style from "components/local/volunteer/style";
import { HookForm } from "components/UI/Form/HookForm";
import { HookFormInput } from "components/UI/Form/HookFormInput";
import { HookFormProvider } from "components/UI/Form/HookFormProvider";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { getVolunteer, postVolunteer } from "./redux/actions";

const VolunteerForm = () => {
  const pathname = window.location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const classes = style();

  const dispatch = useDispatch();

  const { postVolunteer_loading } = useSelector(
    (state) => state.volunteer
  );

 

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().required("Email is required"),
    address: yup.string().required("Address is required"),
    phone: yup
      .string()
      .typeError("It must be a number")
      .required("Phone number is required")
      .min(10)
      .max(10),
    level: yup.string().required("Level is required"),
    registration_no: yup.string().required("Registration Number is required"),
  });
  const dropData = [
    { value: "cap1", label: "Cap I" },
    { value: "cap2", label: "Cap II" },
    { value: "cap3", label: "Cap III" },
  ];

  const defaultValues = {
    level: dropData?.[0]?.value,
  };

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  console.log("errrrrrrrrr", errors);
  const refetch = () => {
    const array = ["name", "email", "phone", "address", "level", "registration_no"];
    array?.map((item) => setValue(item, ""));
  };
  const onSubmit = (values) => {
    // alert("submited");
    const data = {
      ...values,
    };
    dispatch(postVolunteer(data, refetch));
  };

  // useEffect(() => {
  //   dispatch(postVolunteer())
  // },[])

  console.log({ watch: watch() });
  return (
    <Box>
      <Container maxWidth="lg">
        <Box className={classes.contactFormContainer}>
          <Paper elevation={0} className={classes.contactFormPaperContainer}>
            <Box className={classes.contactFormWrap}>
              <Box className={classes.formHeading}>
                <Typography className={classes.formHead}>Volunteer</Typography>
                <Typography className={classes.formTitle}>
                  Please fill this form to continue.
                </Typography>
              </Box>
              <Box className={classes.formSection}>
                <HookFormProvider>
                  <HookForm onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <HookFormInput
                          required={true}
                          type="text"
                          name="name"
                          // label="Name"
                          placeholder="Name"
                          control={control}
                          errors={errors}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <HookFormInput
                          required={true}
                          type="text"
                          name="phone"
                          // label="Name"
                          placeholder="Phone"
                          control={control}
                          errors={errors}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <HookFormInput
                          required={true}
                          type="email"
                          name="email"
                          // label="Name"
                          placeholder="Email"
                          control={control}
                          errors={errors}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <HookFormInput
                          required={true}
                          type="address"
                          name="address"
                          // label="Name"
                          placeholder="Address"
                          control={control}
                          errors={errors}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <HookFormInput
                          required={true}
                          type="text"
                          name="registration_no"
                          // label="Name"
                          placeholder="Registration Number"
                          control={control}
                          errors={errors}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <HookFormInput
                          select
                          placeholder="Level"
                          helperText="Select level"
                          // label="Level"
                          name="level"
                          data={dropData ?? []}
                          control={control}
                          errors={errors}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Button className={classes.formSubmitBtn} type="submit">
                          {postVolunteer_loading ? <Loader /> : "Submit"}
                        </Button>
                      </Grid>
                    </Grid>
                  </HookForm>
                </HookFormProvider>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

export default VolunteerForm;
