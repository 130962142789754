export const GET_JOBS_BEGIN = "GET_JOBS_BEGIN";
export const GET_JOBS_SUCCESS = "GET_JOBS_SUCCESS";
export const GET_JOBS_ERROR = "GET_JOBS_ERROR";

export const GET_JOBS_SINGLE_BEGIN = "GET_JOBS_SINGLE_BEGIN";
export const GET_JOBS_SINGLE_SUCCESS = "GET_JOBS_SINGLE_SUCCESS";
export const GET_JOBS_SINGLE_ERROR = "GET_JOBS_SINGLE_ERROR";

export const POST_APPLY_JOBS_BEGIN = "POST_APPLY_JOBS_BEGIN";
export const POST_APPLY_JOBS_SUCCESS = "POST_APPLY_JOBS_SUCCESS";
export const POST_APPLY_JOBS_ERROR = "POST_APPLY_JOBS_ERROR";
