import { axiosInstance } from "apis/_axios";

export const postThreadApi = (data) => {
  return axiosInstance().post("/user-thread", data);
};

export const getThreadApi = (data) => {
  const params = {
    limit: data?.limit ?? 5,
    page: data?.page,
  };
  return axiosInstance().get(`/thread`, { params });
};

export const putThreadApi = (data) => {
  return axiosInstance().put(`/user-thread/${data?.slug}`, data);
};

export const deleteThreadApi = (data) => {
  return axiosInstance().delete(`/user-thread/${data}`);
};

// comment

export const postCommentApi = (data) => {
  return axiosInstance().post("/comment", data);
};

export const putCommentApi = (data) => {
  return axiosInstance().put(`/comment/${data?.thread_id}`, data);
};

export const deleteCommentApi = (data) => {
  return axiosInstance().delete(`/comment/${data}`);
};

export const postUpvoteApi = (data) => {
  return axiosInstance().post("/upvote", data);
};

export const deleteUpvoteApi = (data) => {
  return axiosInstance().delete(`/upvote/${data?.comment_id}`, data);
};

export const postDownvoteApi = (data) => {
  return axiosInstance().post("/downvote", data);
};

export const deleteDownvotevoteApi = (data) => {
  return axiosInstance().delete(`/downvote/${data?.comment_id}`, data);
};
