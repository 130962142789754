import Banner from "components/global/banner";
// import SingleCardMain from "components/common/cardMain";
// import NewsSection from "components/news/newsSection";
import { Box, Container, Grid, Typography } from "@mui/material";
import chevronDown from "assets/home/chevronDown.png";
import CardMainComponent from "components/common/cardMain";
import style from "components/common/style";
import SkeletonLoaderTernory from "components/global/loader/skeletonLoader/SkeletonCardTernory";
import { getNewspage } from "components/local/news/redux/actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const News = () => {
  const pathname = window.location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const classes = style();
  const { newsPage, news_loading } = useSelector((state) => state.news);
  const [newsData, setNewsData] = useState([]);
  const [singleNews, setSingleNews] = useState(false);

  console.log({ newsData });

  const dispatch = useDispatch();

  // useEffect(() => {
  //   newsPage?.data && setNewsData(newsPage?.data);
  // }, [newsPage]);

  useEffect(() => {
    newsPage?.data &&
      setNewsData([
        ...(newsData || []),
        ...(newsPage?.data?.news_data?.news || []),
      ]);
  }, [newsPage?.data?.news_data?.pagination?.current_page]);

  useEffect(() => {
    dispatch(getNewspage());
  }, []);

  const handleMore = () => {
    const data = {
      page: newsPage?.data?.news_data?.pagination?.current_page + 1,
    };
    dispatch(getNewspage(data));
  };

  return (
    <>
      <Banner data={newsPage?.data?.news_banner} title={"News"} />
      {/* {newsData?.news?.data((data) => { */}
      {/* <> */}
      <Box className={classes.newsSectionContainer}>
        <Container maxWidth="lg">
          <Box className={classes.newsSectionTitleBox}>
            <Typography className={classes.newsSectionTitle}>
              {/* {mainTitle} */}
              Latest News
            </Typography>
          </Box>
          <Box className={classes.newsSectionBox}>
            <Grid container spacing={3}>
              {newsData?.map((item) => (
                <>
                  <CardMainComponent item={item} key={item?.id} name={"news"} />
                </>
              ))}
              {news_loading &&
                [...Array(4)]?.map((item) => (
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <SkeletonLoaderTernory />
                  </Grid>
                ))}
            </Grid>
          </Box>
          {!news_loading &&
            newsData?.length < newsPage?.data?.news_data?.pagination?.total && (
              <Box className={classes.newsSeeMoreBtnBox} onClick={handleMore}>
                <NavLink className={classes.newsSeeMoreBtn}>See More</NavLink>
                <Box className={classes.newsSeeMoreArrow}>
                  <img src={chevronDown} alt="" />
                </Box>
              </Box>
            )}
        </Container>
      </Box>
    </>
  );
};

export default News;
