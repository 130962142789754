import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  jobHeader: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "81px",
    paddingBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      paddingTop: "30px",
    },
  },
  titleBox: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "15px",
    },
  },
  title: {
    fontFamily: ["Sofia sans", "sans-serif", "!important"],
    color: theme.palette.grey[7],
    fontWeight: "600 !important",
    fontSize: "20px !important",
    lineHeight: "1 !important",
  },
  label: {
    fontFamily: ["Sofia sans", "sans-serif"],
    color: theme.palette.grey[1],
    fontWeight: "500 !important",
    fontSize: "12px !important",
    lineHeight: "1.67 !important",
  },
  newsSeeMoreBtnBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
    marginBottom: "20px",
  },
  newsSeeMoreBtn: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#4C7CE5",
    fontSize: "15px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },
  newsSeeMoreArrow: {
    display: "flex",
    alignItems: "center",
  },
}));

export default style;
