import * as actions from "components/local/complain/redux/types";
const initialState = {
  complain_loading: false,
  getcomplain: [],
  getcomplain_loading: false,
};

export const complainReducer = (state = initialState, action) => {
  switch (action.type) {
    // post complain
    case actions.POST_COMPLAIN_BEGIN:
      return { ...state, complain_loading: true };

    case actions.POST_COMPLAIN_SUCCESS:
    case actions.POST_COMPLAIN_ERROR:
      return { ...state, complain_loading: false };

    // get complain
    case actions.GET_COMPLAIN_BEGIN:
      return { ...state, getcomplain_loading: true };

    case actions.GET_COMPLAIN_SUCCESS:
      return {
        ...state,
        getcomplain_loading: false,
        getcomplain: action.payload,
      };

    case actions.GET_COMPLAIN_ERROR:
      return { ...state, getcomplain_loading: false };
    default:
      return state;
  }
};
