import { Grid } from "@mui/material";
import { Container } from "@mui/system";
// import Banner from "components/local/banner/Banner";
import Banner from "components/global/banner";
import Calendar from "components/local/calendar/Calendar";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEventsData, getEventsOfCurrentMonth } from "redux/events/actions";
import Events from "../home/events/Events";
import VolunteerForm from "../volunteer/VolunteerForm";
import EventList from "./EventList";
// import bannerImage from "assets/event/eventBannerImage.png";

const Event = () => {
  const pathname = window.location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const eventState = useSelector((state) => state.event);
  const dispatch = useDispatch();

  console.log({eventState})

  useEffect(() => {
    dispatch(fetchEventsData());
  }, []);

  useEffect(() => {
    dispatch(getEventsOfCurrentMonth());
  }, []);

  const breadcrumbData = [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "Events",
      link: "",
    },
  ];

  return (
    <div>
      {/* <Banner
        title="Events"
        image={eventState?.banner}
        breadcrumbData={breadcrumbData}
        hasBorder={true}
      /> */}
      <Banner
        data={eventState?.banner}
        title={"Events"}
      />
      {/* <Container maxWidth="xl">
        <Grid
          container
          // spacing={5}
          sx={{
            padding: "50px 0",
          }}
        >
          <Grid
            item
            lg={5}
            md={6}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: { md: "1rem", lg: "0" },
              paddin: "40px 0",
            }}
          >
            <Calendar eventData={eventState} />
          </Grid>
          <Grid
            item
            lg={7}
            md={6}
            xs={12}
            sx={{ height: "600px", overflowY: "auto" }}
          >
            <EventList />
          </Grid>
        </Grid>
      </Container> */}
      <Events eventPage/>
      <VolunteerForm/>
    </div>
  );
};

export default Event;
