import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  header: {
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    fontSize: "20px !important",
    fontWeight: "600 !important",
    lineHeight: "1.8 !important",
    color: theme.palette.grey[7],
  },
  card: {
    border: `solid 1px ${theme.palette.grey[3]}`,
  },
  cardTitle: {
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    fontSize: "14px !important",
    fontWeight: "700 !important",
    lineHeight: "1.8 !important",
    color: theme.palette.grey[7],
    textAlign: "center",
  },
  // cardPhotoCountBox:{
  //   display: 'felx',
  //   flexDirection: 'flex-end'
  // },
  cardPhotoCount:{
    fontSize: '14px !important',
    color: '#6C6B80 !important',

  },
  titleContainer: {
    padding: "14px 10px !important",
    display: 'flex',
    justifyContent: 'space-between',
  
  },
  btnContainer: {
    textAlign: "center",
    marginTop: "40px",
  },
  btn: {
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    fontSize: "15px !important",
    lineHeight: "1.2 !important",
    fontWeight: "700 !important",
    color: "#4C7CE5",
  },
  btnIcon: {
    width: "14px",
    height: "14px",
  },
  // see more btn
  newsSeeMoreBtnBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  newsSeeMoreBtn: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#4C7CE5",
    fontSize: "15px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },
  newsSeeMoreArrow: {
    display: "flex",
    alignItems: "center",
  },
}));

export default style;
