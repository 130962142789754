import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  bannerWrap: {
    width: "100% !important",
    height: "275px !important",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "225px !important",
    },
  },
  bannerImgContainer: {
    height: "100%",
    width: "100%",
    position: "relative",
    background: "#201F37",
    top: 0,
    left: 0,
    display: "flex",
    aliginItems: "center",
  },
  bannerTextWrap: {
    color: "#fff",
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center",
    background: "rgb(55, 55, 72, .65)",
  },
  bannerText: {
    color: "#fff",
    textItems: "center",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    fontSize: "70px !important",
    display: "flex !important",
    lineHeight: "normal !important",
    fontWeight: "800 !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "50px !important",
      width: "80%",
      textAlign: "center",
      justifyContent: "center",
    },
  },
  bannerImg: {
    width: "100% !important",
    height: "100% !important",
    objectFit: "cover",
    objectPosition: "top",
  },
}));

export default style;
