import style from "components/global/description/style";

const Description = ({ description, length, about, styles }) => {
  const classes = style();
  const textStyles = {
    ...styles,
    fontSize: "14px !important",
    color: "blue !important",
    //  backgroundColor: 'red'
  };

  return (
    <>
      <div
        style={{ ...styles   }}
        className={`  ${length ? classes.lineClampDesc : classes.lineClamp}`}
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    </>
  );
};

export default Description;
