import { MoreHoriz } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import style from "./style";

const Card = ({ data, order, status }) => {
  const classes = style();

  console.log("ggggggggggggggggggggggggg", { data });

  return (
    <>
      <Box
        className={classes.wrapper}
        sx={{
          borderLeftColor:
            order % 4 === 0
              ? "#059669"
              : order % 3 === 0
              ? "#FF9129"
              : order % 2 === 0
              ? "#DC2626"
              : "#4C7CE5",
          position: "relative",
        }}
      >
        <Box>
          <Typography component="h3" className={classes.dayText}>
            {moment(data?.date).format("DD")}
          </Typography>
          <Typography component="h4" className={classes.monthText}>
            {moment(data?.date).format("MMMM")}
          </Typography>
        </Box>
        <Box>
          <Link to={`/events/${data.id}`} style={{ textDecoration: "none" }}>
            <Box sx={{ marginBottom: "6px" }}>
              <Typography component="h3" className={classes.header}>
                {data?.title}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "8px", marginBottom: "6px" }}>
              <Typography className={classes.text}>
                {moment(data?.time, ["HH:mm"]).format("hh:mm a")}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "8px", marginBottom: "6px" }}>
              <Typography className={classes.text}>{data?.venue}</Typography>
            </Box>
            <Box>
              <Typography
                className={classes.status}
                sx={{
                  color:
                    data.event_status === "Closed"
                      ? "#FF1818"
                      : data.event_status === "Open"
                      ? "#2DB83E"
                      : data.event_status === "Upcoming"
                      ? "#3893D0"
                      : "#3893D1",
                }}
              >
                {data?.event_status}
              </Typography>
            </Box>
          </Link>
        </Box>
        <MoreHoriz
          sx={{
            position: "absolute",
            top: "14px",
            right: "22px",
            cursor: "pointer",
          }}
        />
      </Box>
    </>
  );
};

export default Card;
