import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function SkeletonLoader({course}) {
  return (
    <Stack spacing={1} sx={{ padding: { md: "0", lg: "10px" } }}>
      {/* For variant="text", adjust the height via font-size */}
      {course && (

      <Skeleton variant="rectangular" width="100%" height={100} />
      )}
      {!course && 
      
      <Skeleton variant="rectangular" width="100%" height={170} />
      }
      <Skeleton variant="text" width="40%" />
      {/* <Skeleton variant="text" width={210} /> */}
      <Skeleton variant="text" width="100%" />
      <Skeleton variant="text" width="100%" />

      {/* For other variants, adjust the size with `width` and `height` */}
      {/* <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rounded" width={210} height={60} /> */}
    </Stack>
  );
}
