import ResetPassword from "components/local/resetPassword";
import React from "react";

const ResetPasswordPage = () => {
  return (
    <div>
      <ResetPassword />
    </div>
  );
};

export default ResetPasswordPage;
