import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  verticalLine:{
   borderRight: '1px solid #FEC3B2 !important',
   height: '150px',
   margin: 'auto 0',
   opacity: '60%',
   [theme.breakpoints.down("md")]: {
      display: 'none',
    }
  }
}));

export default style;
