import { Button } from "@mui/material";
import style from "./style";

const ButtonSecondary = ({ title, border, nav, onClick }) => {
  const classes = style();

  return (
    <>
      <Button
        className={` ${!border ? classes.navBtn : classes.navBtnBorder}`}
        onClick={onClick}
      > 
        {title}
      </Button>
    </>
  );
};

export default ButtonSecondary;
