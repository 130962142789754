import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import messageIcon from "assets/message.svg";
import style from "./style";
import * as yup from "yup";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordMail } from "redux/changePassword/actions";

const ForgotPassword = () => {
  const [showMessage, setShowMessage] = useState(false);
  const navigate = useNavigate();
  const classes = style();
  const dispatch = useDispatch();
  const resetState = useSelector((state) => state.changePassword);

  const validationSchema = yup.object({
    email: yup.string().email().required("Email is required"),
  });

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({ resolver: useYupValidationResolver(validationSchema) });

  useEffect(() => {
    if (resetState?.resetStatus === "completed") {
      setShowMessage(true);
    }
  }, [resetState?.resetStatus]);

  const onSubmit = (data) => {
    // console.log("data", data);
    dispatch(resetPasswordMail(data));
  };

  return (
    <div className={classes.wrapper}>
      {showMessage ? (
        <Box className={classes.messageContainer}>
          <Box sx={{ mb: "35.5px" }}>
            <img src={messageIcon} alt="" />
          </Box>
          <Box sx={{ mb: "10px", maxWidth: "354px" }}>
            <Typography component="h1">
              Your account has been found and link has been sent to your email
              address.
            </Typography>
          </Box>
          <Box sx={{ mb: "30px" }}>
            <Typography component="h2">Check your mail</Typography>
          </Box>
          <Box>
            <Typography component="h3">
              If you didn’t received any link.{" "}
              <span
                onClick={() =>
                  dispatch(resetPasswordMail({ email: watch("email") }))
                }
              >
                {resetState?.resetStatus === "pending" ? "Resending" : "Resend"}
              </span>
            </Typography>
          </Box>
        </Box>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classes.formContainer}
        >
          <Box sx={{ padding: "32px 37px" }}>
            <Typography component="h1" className={classes.header}>
              Find Your Account
            </Typography>
          </Box>
          <Box className={classes.formBody}>
            <Box sx={{ mb: "25px" }}>
              <Typography className={classes.subHeader}>
                Please enter your email to search your account.
              </Typography>
            </Box>
            <Box>
              <input
                type="email"
                placeholder="Enter email here"
                {...register("email")}
              />
              {errors.email && (
                <Typography color="#DC2626">{errors.email?.message}</Typography>
              )}
            </Box>
          </Box>
          <Box className={classes.btnContainer}>
            <Button
              className={classes.btnPrimary}
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button className={classes.btnSecondary} type="submit">
              {resetState?.resetStatus === "pending" ? "Searching" : "Search"}
            </Button>
          </Box>
        </form>
      )}
    </div>
  );
};

export default ForgotPassword;
