import * as actions from "components/local/ourTeams/redux/types";
const initialState = {
  teams: [],
  teams_loading: false,
  teamsSingle: [],
  teamsSingle_loading: false,
  teamsCategory: [],
  teamsCategory_loading: false,
};

const teamsReducer = (state = initialState, action) => {
  switch (action.type) {
    // get teams
    case actions.GET_TEAMS_BEGIN:
      return { ...state, teams_loading: true };

    case actions.GET_TEAMS_SUCCESS:
      return {
        ...state,
        teams_loading: false,
        teams: action.payload,
      };

    case actions.GET_TEAMS_ERROR:
      return { ...state, teams_loading: false };

    // teams single

    case actions.GET_TEAMS_SINGLE_BEGIN:
      return { ...state, teamsSingle_loading: true };

    case actions.GET_TEAMS_SINGLE_SUCCESS:
      return {
        ...state,
        teamsSingle_loading: false,
        teamsSingle: action.payload,
      };

    case actions.GET_TEAMS_SINGLE_ERROR:
      return { ...state, teamsSingle_loading: false };

    // get teams category

    case actions.GET_TEAMS_CATEGORY_BEGIN:
      return { ...state, teamsCategory_loading: true };

    case actions.GET_TEAMS_CATEGORY_SUCCESS:
      return {
        ...state,
        teamsCategory_loading: false,
        teamsCategory: action.payload,
      };

    case actions.GET_TEAMS_CATEGORY_ERROR:
      return { ...state, teamsCategory_loading: false };

    default:
      return state;
  }
};

export default teamsReducer;
