import Event from "components/local/event";

const EventPage = () => {
  return (
    <div>
      <Event />
    </div>
  );
};

export default EventPage;
