import { UploadFile } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Error from "components/UI/Form/Error";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import style from "./styles";

const DropZone = ({
  link,
  setValue,
  name,
  errors,
  message = "",
  watch,
  image,
}) => {
  const classes = style();

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: !image
      ? { "application/pdf": [".pdf", ".doc", ".docx"] }
      : { "image/jpg": [".jpg", ".jpeg", ".png", ".svg"] },
  });
  const [selectedFile, setSelectedFile] = useState("");

  useEffect(() => {
    setSelectedFile(acceptedFiles[0]);
    setValue(name, acceptedFiles[0]);
  }, [acceptedFiles]);
  useEffect(() => {
    !watch(name) && setSelectedFile("");
  }, [watch(name)]);

  console.log({ acceptedFiles });
  // console.log("accepted files", acceptedFiles);

  return (
    <>
      {image && (
        <Box className={classes.imageViewLabelBox}>
          <Box className={classes.imageViewBtnBox}>
            <a
              style={{ textDecoration: "none" }}
              href={link}
              target="_blank"
              rel="noreferrer"
            >
              <Button className={classes.imageViewBtn}>View</Button>
            </a>
          </Box>
          <Box>
            {image && (
              <label className={classes.dragDropLabel}>
                {" "}
                {image ? "Image" : "Resume"}
              </label>
            )}
          </Box>
        </Box>
      )}
      {!image && (
        <label className={classes.dragDropLabel}>
          {" "}
          {image ? "Image" : "Resume"}
        </label>
      )}
      <Box {...getRootProps()}>
        <input {...getInputProps()} />
        <Box
          className={`${image} ? ${classes.dropZoneImage} : ${classes.dropZone}`}
        >
          {selectedFile ? (
            <Typography>{selectedFile.path ?? ""}</Typography>
          ) : (
            <Box className={classes.fileTextBox}>
              <UploadFile />
              {image ? (
                <Typography>
                  Drag and drop a image here or click here
                </Typography>
              ) : (
                <Typography>
                  {message || "Drag and drop your CV here"}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>
      <Error>{errors?.[name]?.message}</Error>
    </>
  );
};

export default DropZone;
