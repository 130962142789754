import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import React from "react";
// import heroImage from "assets/dashboard/videoCourses/hero.png";
// import icon from "assets/dashboard/videoCourses/icon.png";
import style from "./style";
import { useSelector } from "react-redux";

const MainContent = () => {
  const classes = style();
  const { coursesSingleVideo } = useSelector((state) => state.courses);

  return (
    <Box className={classes.contentContainer}>
      <iframe
        src={coursesSingleVideo?.video_info?.data?.player_embed_url}
        title="Video"
        allowFullScreen
      ></iframe>

      <Box sx={{ mt: "20px" }}>
        <Typography component={"h1"} className={classes.header}>
          {coursesSingleVideo?.title}
        </Typography>
      </Box>
      <Box className={classes.textContainer} sx={{ mt: "15px" }}>
        <Box
          dangerouslySetInnerHTML={{
            __html: coursesSingleVideo?.description,
          }}
        ></Box>
      </Box>
      {/* <Box className={classes.imageContainer}>
        <img src={heroImage} alt="" />
      </Box> */}
      {/* <Box sx={{ mb: "22px" }}>
        <Typography component={"h1"} className={classes.header}>
          Start a blog to reach your creative peak.
        </Typography>
      </Box>
      <Box>
        <Box className={classes.courseTitle} sx={{ mb: "22px" }}>
          <Box>
            <img src={icon} alt="" />
          </Box>
          <Box>
            <Typography component={"h2"}>CA Course</Typography>
            <Typography>Jan 24, 2023</Typography>
          </Box>
        </Box>
        <Box className={classes.textContainer}>
          {paragraph.map((text, index) => {
            return <Typography key={index}>{text}</Typography>;
          })}
        </Box>
      </Box>*/}
    </Box>
  );
};

export default MainContent;
