import { Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import style from "./style";
import location from "assets/home/location.png";
import dot from "assets/home/dot.png";

const Card = ({ data }) => {
  const classes = style();

  return (
    <Paper elevation={0} className={classes.jobsCardPaper}>
      <Box className={classes.jobsCardImgBox}>
        <img src={data.image} alt="" className={classes.jobsCardImgBox} />
      </Box>
      <Box className={classes.jobsCardTypography}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Typography className={classes.jobsCardTitle}>
            {data?.position}
          </Typography>
          <Typography className={classes.jobsCardHead}>
            <img src={location} alt="" className={classes.loactionIcon} />
            {data?.location}
          </Typography>
          <Divider sx={{ display: "flex", justifyContent: "center" }} />
          <Typography className={classes.jobsCardSubTitle}>
            <img src={dot} alt="" className={classes.jobsCardDot} />
            {data?.title}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default Card;
