import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  contactFormContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "70px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "30px 0",
    },
    [theme.breakpoints.down("md")]: {
      padding: "40px 0",
    },
  },
  contactFormPaperContainer: {
    width: "550px",
  },
  contactFormWrap: {
    // backgroundColor: "pink",
    boxShadow: " 0px 8px 20px rgba(18, 17, 39, 0.1)",
    padding: "60px",
    [theme.breakpoints.down("md")]: {
      padding: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
    },
  },
  formHeading: {
    marginBottom: "30px",
  },
  formHead: {
    fontSize: "30px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },
  formTitle: {
    fontSize: "14px !important",
    fontWeight: "400 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    color: "#8D8D8D",
  },
  formSubmitBtn: {
    backgroundColor: "#EF4444 !important",
    color: "#fff !important",
    width: "100%",
    // minHeight: '2rem',
    marginTop: "30px !important",
    borderRadius: "0 !important",
    textTransform: "uppercase !important",
    fontSize: "18px !important",
    fontWeight: "800 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },

  // maps section
  mapsSectionContainer: {
    marginBottom: "35px",
  },
  mapsSectionTypography: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "40%",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  mapsSectionHead: {
    fontSize: "30px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px !important",
    },
  },
  mapsSectionTitle: {
    fontSize: "14px !important",
    fontWeight: "400 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    color: "#8D8D8D",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
    },
  },
}));

export default style;
