import { Box, Skeleton } from "@mui/material";
import style from "components/local/dashboard/blog/style";
import DashboardTitle from "components/UI/DashboardTitle";
import CustomTable from "components/UI/Table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteBlog, getBlog } from "./redux/actions";

const Blog = () => {
  const classes = style();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { blog, blog_loading } = useSelector((state) => state.blogDash);
  const [blogData, setBlogData] = useState([]);

  console.log({ blogData });

  useEffect(() => {
    blog?.data && setBlogData(blog?.data);
  }, [blog]);

  useEffect(() => {
    dispatch(getBlog());
  }, []);

  const rows = [
    {
      id: 0,
      name: "Blog 1",
      description: "Ktm",
      date: "08-09-2023",
      status: "active",
    },
    {
      id: 2,
      name: "Blog 2",
      description: "Ktm",
      date: "08-09-2023",
      status: "inactive",
    },
  ];

  const columns = [
    { accessor: "title", label: "Blog" },
    { accessor: "description", label: "Description" },
    { accessor: "created_at", label: "Date" },
    // { accessor: "status", label: "Status" },
    { accessor: "edit", label: "Edit", onClick: "" },
    { accessor: "delete", label: "Delete", onClick: "" },
  ];

  // const handleClick = (row) => {
  //   console.log({ row });
  //   navigate(`/gallery`);
  // };

  // const handleEdit = () => {
  //   navigate(`/dashboard/blog/add-blog`);
  // };

  const handleEdit = (row) => {
    navigate(`/dashboard/blog/${row?.slug}`, { state: row });
  };

  const refetch = () => {
    dispatch(getBlog());
  };

  const handleDelete = (id) => {
    dispatch(deleteBlog(id, refetch));
  };

  return (
    <>
      <Box className={classes.dashboardTitleBox}>
        <DashboardTitle title={"blog"} link={"add-blog"} />
      </Box>
      <Box
        component="section"
        sx={{
          bgcolor: "#F5F5F5",
          padding: { xs: "10px 10px", md: "44px 45px", lg: "44px 65px" },
          height: "auto",
        }}
      >
        {blog_loading ? (
          <Skeleton variant="rectangular" width="100%" height={400} />
        ) : (
          <Box sx={{ cursor: "pointer" }}>
            <CustomTable
              // handleClick={handleClick}
              columns={columns}
              rows={blogData}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default Blog;
