/* eslint-disable */

import {
  aboutReducer,
  aboutSingleReducer,
} from "components/local/aboutUs/redux/reducers";
import {
  blogsReducer,
  blogsSingleReducer,
} from "components/local/blogs/redux/reducers";
import { complainReducer } from "components/local/complain/redux/reducers";
import {
  contactReducer,
  getcontactReducer,
} from "components/local/contactUs/redux/reducers";
import blogDashReducer from "components/local/dashboard/blog/redux/reducers";
import threadReducer from "components/local/dashboard/community/redux/reducers";
import { coursesReducer } from "components/local/dashboard/courses/redux/reducers";
import jobDashReducer from "components/local/dashboard/job/redux/reducers";
import albumReducer from "components/local/gallery/redux/reducers";
import homeReducer from "components/local/home/redux/reducers";
import {
  applyJobsReducer,
  jobsReducer,
  jobsSingleReducer,
} from "components/local/jobs/redux/reducers";
import {
  newsReducer,
  newsSingleReducer,
} from "components/local/news/redux/reducers";
import teamsReducer from "components/local/ourTeams/redux/reducers";
import publicationsSingleReducer, {
  publicationsReducer,
} from "components/local/publications/redux/reducers";
import volunteerReducer from "components/local/volunteer/redux/reducers";
import newsletterReducer from "layouts/footer/redux/reducers";
import siteSettingReducer from "layouts/redux/reducers";
import { combineReducers } from "redux";
import bannerReducer from "redux/banner/reducers";
import authReducer from "./auth/reducers";
import passwordReducer from "./changePassword/reducers";
import eventReducer from "./events/reducers";
import profileReducer from "./profile/reducers";
import signUpReducer from "./signUp/reducers";
import vacancyReducer from "./vacancy/reducers";

export default combineReducers({
  auth: authReducer,
  home: homeReducer,
  banner: bannerReducer,
  event: eventReducer,
  profile: profileReducer,
  about: aboutReducer,
  aboutSingle: aboutSingleReducer,
  news: newsReducer,
  newsSingle: newsSingleReducer,
  blogs: blogsReducer,
  blogsSingle: blogsSingleReducer,
  publications: publicationsReducer,
  publicationsSingle: publicationsSingleReducer,
  jobs: jobsReducer,
  jobSingle: jobsSingleReducer,
  applyJobs: applyJobsReducer,
  contact: contactReducer,
  getContact: getcontactReducer,
  changePassword: passwordReducer,
  signUp: signUpReducer,
  vacancy: vacancyReducer,
  siteSetting: siteSettingReducer,
  courses: coursesReducer,
  album: albumReducer,
  newsletter: newsletterReducer,
  volunteer: volunteerReducer,
  thread: threadReducer,
  team: teamsReducer,
  jobDash: jobDashReducer,
  blogDash: blogDashReducer,
  complain: complainReducer,
});
