import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

export default function SkeletonCardJob({ jobMain }) {
  return (
    <Box sx={{ display: "flex", gap: "10px" }}>
      {jobMain ? (
        <Skeleton variant="rectangular" width={170} height={100} />
      ) : (
        <Skeleton variant="rectangular" width={70} height={55} />
      )}
      {jobMain ? (
        <Box sx={{ width: "100%" }}>
          {/* <Skeleton variant="text" width={80} /> */}
          <Skeleton variant="text" width={180} />
          <Skeleton variant="text" width={180} />
          <Skeleton variant="text" width={150} />
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          {/* <Skeleton variant="text" width={80} /> */}
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="100%" />
        </Box>
      )}

      {/* <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rounded" width={210} height={60} /> */}
    </Box>
  );
}
