import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import Banner from "components/global/banner";
import Loader from "components/global/loader/Loader";
import style from "components/local/volunteer/style";
import { HookForm } from "components/UI/Form/HookForm";
import { HookFormInput } from "components/UI/Form/HookFormInput";
import { HookFormProvider } from "components/UI/Form/HookFormProvider";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { getVolunteer, postVolunteer } from "./redux/actions";
import VolunteerForm from "./VolunteerForm";

const Volunteer = () => {
  const pathname = window.location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const classes = style();

  const dispatch = useDispatch();

  const { volunteer, postVolunteer_loading } = useSelector(
    (state) => state.volunteer
  );

  const [volunteerData, setVolunteerData] = useState([]);

  console.log({ volunteerData });

  useEffect(() => {
    volunteer?.data && setVolunteerData(volunteer?.data);
  }, [volunteer]);

  useEffect(() => {
    dispatch(getVolunteer());
  }, []);

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().required("Email is required"),
    address: yup.string().required("Address is required"),
    phone: yup
      .string()
      .typeError("It must be a number")
      .required("Phone number is required")
      .min(10)
      .max(10),
    level: yup.string().required("Level is required"),
  });
  const dropData = [
    { value: "cap1", label: "Cap I" },
    { value: "cap2", label: "Cap II" },
    { value: "cap3", label: "Cap III" },
  ];

  const defaultValues = {
    level: dropData?.[0]?.value,
  };

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  console.log("errrrrrrrrr", errors);
  const refetch = () => {
    const array = ["name", "email", "phone", "address", "level"];
    array?.map((item) => setValue(item, ""));
  };
  const onSubmit = (values) => {
    // alert("submited");
    const data = {
      ...values,
    };
    dispatch(postVolunteer(data, refetch));
  };

  // useEffect(() => {
  //   dispatch(postVolunteer())
  // },[])

  console.log({ watch: watch() });
  return (
    <Box>
      <Banner title={"Volunteer"} data={volunteerData?.volunteer_banner} />
      <VolunteerForm/>
    </Box>
  );
};

export default Volunteer;
