import { Button } from "@mui/material";
import React from "react";
import backIcon from "assets/back-icon.svg";
import { useNavigate } from "react-router-dom";
import style from "./style";

const BackButton = ({handleClick}) => {
  const navigate = useNavigate();
  const classes = style();

  return (
    <Button
      variant="text"
      className={classes.btn}
      onClick={() => (handleClick ? handleClick() : navigate(-1))}
    >
      <img src={backIcon} alt="previous" className={classes.btnIcon} />
      Back
    </Button>
  );
};

export default BackButton;
