import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import Loader from "components/global/loader/Loader";
import { HookForm } from "components/UI/Form/HookForm";
import { HookFormInput } from "components/UI/Form/HookFormInput";
import { HookFormProvider } from "components/UI/Form/HookFormProvider";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { postComplain } from "./redux/actions";
import style from "./style";

const ComplainForm = () => {
  const dispatch = useDispatch();

  const classes = style();

  const { complain_loading } = useSelector((state) => state.complain);

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().required("Email is required"),
    message: yup.string().required("Description is required"),
    to: yup.string().required("Complain to is required"),
    registration_no: yup.string().required("Registration No to is required"),
    cap_level: yup.string().required("Cap Level to is required"),
  });

  const dropData = [
    { value: "NCASA", label: "NCASA" },
    { value: "ICAN", label: "ICAN" },
  ];
  const dropCapData = [
    { value: "cap_i", label: "Cap I" },
    { value: "cap_ii", label: "Cap II" },
    { value: "cap_iii", label: "Cap III" },
  ];

  const defaultValues = {
    to: dropData?.[0]?.value,
    cap_level: dropCapData?.[0]?.value,
  };
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  console.log("errrrrrrrrr", errors);
  const refetch = () => {
    const array = [
      "name",
      "email",
      "to",
      "message",
      "cap_level",
      "registration_no",
    ];
    array?.map((item) => setValue(item, ""));
  };
  const onSubmit = (values) => {
    const data = {
      ...values,
    };
    dispatch(postComplain(data, refetch));
  };

  console.log({ watch: watch() });

  return (
    <Container maxWidth="lg">
      <Box className={classes.contactFormContainer}>
        <Paper elevation={0} className={classes.contactFormPaperContainer}>
          <Box className={classes.contactFormWrap}>
            <Box className={classes.formHeading}>
              <Typography className={classes.formHead}>Complain</Typography>
              {/* <Typography className={classes.formTitle}>
                We want to assure you that the information provided in this
                complain box, including your name and registration number, will
                be treated with strict confidentiality. Your privacy is
                important to us, and we will take every measure to protect your
                identity throughout the complaint resolution process.{" "}
              </Typography> */}
            </Box>
            <Box className={classes.formSection}>
              <HookFormProvider>
                <HookForm onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <HookFormInput
                        required={true}
                        type="text"
                        name="name"
                        // label="Name"
                        placeholder="Name"
                        control={control}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <HookFormInput
                        required={true}
                        type="email"
                        name="email"
                        // label="Name"
                        placeholder="Email"
                        control={control}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <HookFormInput
                        required={true}
                        type="text"
                        name="registration_no"
                        // label="Name"
                        placeholder="Registration Number"
                        control={control}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <HookFormInput
                        select
                        placeholder="Complain to"
                        helperText="Complain to"
                        // label="Level"
                        name="to"
                        data={dropData ?? []}
                        control={control}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <HookFormInput
                        select
                        placeholder="Select Level"
                        helperText="Select Level"
                        // label="Level"
                        name="cap_level"
                        data={dropCapData ?? []}
                        control={control}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <HookFormInput
                        required={true}
                        type="text"
                        name="message"
                        // label="Name"
                        placeholder="Say something you want to say"
                        control={control}
                        errors={errors}
                        multiline
                        rows={4}
                        sx={{
                          "& .css-17cvodc-MuiInputBase-input-MuiOutlinedInput-input":
                            {
                              height: "82px",
                            },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Button className={classes.formSubmitBtn} type="submit">
                        {complain_loading ? <Loader /> : "Send"}
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography className={classes.formTitle}>
                        <span className={classes.formNote}>NOTE</span>: We want
                        to assure you that the information provided in this
                        complain box, including your name and registration
                        number, will be treated with strict confidentiality.
                        Your privacy is important to us, and we will take every
                        measure to protect your identity throughout the
                        complaint resolution process.
                      </Typography>
                    </Grid>
                  </Grid>
                </HookForm>
              </HookFormProvider>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default ComplainForm;
