import { axiosInstance } from "apis/_axios";
import moment from "moment";

export const fetchEventsApi = (pageNumber = 1) => {
  return axiosInstance().get(`/event?page=${pageNumber}`);
};

export const fetchSingleEventApi = (id) => {
  return axiosInstance().get(`/event/by/${id}`);
};

export const postEventApi = (data, slug) => {
  return axiosInstance().post(`/event/${slug}/event-apply`, data);
};

export const filterEventsByDateApi = (data) => {
  // start date of the month
  let startDate = moment(data?.date).startOf("month").format("YYYY-MM-DD");
  // end date of the month
  let endDate = moment(data?.date).endOf("month").format("YYYY-MM-DD");

  return axiosInstance().get(
    `event/filter/by-dates?start_date=${startDate}&end_date=${endDate}`
  );
};

export const getAppliedEventsApi = () => {
  return axiosInstance().get("/event/applied-by/user");
};

export const deleteAppliedEventApi = (id) => {
  return axiosInstance().delete(`/event/applied-by/user/delete/${id}`);
};
