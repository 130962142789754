import * as actions from "./types";
import { deleteAppliedJobsApi, getAppliedJobsApi } from "apis";
import { errorToast, successToast } from "utils/toast";

export const getAppliedJobs = () => (dispatch) => {
  dispatch({ type: actions.FETCH_VACANCY_PENDING });
  getAppliedJobsApi()
    .then((response) => {
      console.log("response", response);
      dispatch({
        type: actions.FETCH_VACANCY,
        payload: response.data.data.job_application,
      });
    })
    .catch((error) => {
      console.log("error", error);
      dispatch({ type: actions.FETCH_VACANCY_ERROR });
    });
};

export const deleteAppliedJob = (id) => (dispatch) => {
  deleteAppliedJobsApi(id)
    .then((response) => {
      console.log("response", response);
      dispatch({ type: actions.RESET_VACANCY_STATUS });
      successToast(response.data.message);
    })
    .catch((error) => {
      console.log("error", error);
      errorToast(error);
    });
};
