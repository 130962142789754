import React from 'react';

const Error = ({ children }) => {
  return (
    <>
      <p style={{ color: '#FF3B3B', fontSize: '12px' }}>{children}</p>
    </>
  );
};

export default Error;
