import { applyJobsApi, getJobsApi, getJobsSingleApi } from "apis";
import * as actions from "components/local/jobs/redux/types";
import { errorToast, successToast } from "utils/toast";

export const getJobspage = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_JOBS_BEGIN });

  try {
    const { data } = await getJobsApi(Data);
    dispatch({
      type: actions.GET_JOBS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_JOBS_ERROR });
    console.log(error);
  }
};

export const getJobsSinglepage = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_JOBS_SINGLE_BEGIN });

  try {
    const { data } = await getJobsSingleApi(Data);
    dispatch({
      type: actions.GET_JOBS_SINGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_JOBS_SINGLE_ERROR });
    console.log(error);
  }
};

export const applyJobs = (Data, slug, refetch = () => {}) => async (dispatch) => {
  dispatch({ type: actions.POST_APPLY_JOBS_BEGIN });

  console.log("dataaa", Data);
  Data.forEach((element) => {
    console.log("element", element);
  });
  try {
    await applyJobsApi(Data, slug);
    refetch && refetch();
    dispatch({
      type: actions.POST_APPLY_JOBS_SUCCESS,
      payload: "",
    });
    successToast("Job applied");
  } catch (error) {
    dispatch({ type: actions.POST_APPLY_JOBS_ERROR });
    console.log(error);
    errorToast(error);
  }
};
