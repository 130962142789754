import { axiosInstance } from "apis/_axios";

export const postJobApi = (data) => {
  return axiosInstance().post(`/job`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getJobApi = (data) => {
  return axiosInstance().get(`/job-by-company`, data);
};

export const putJobApi = (data, slug) => {
  return axiosInstance().post(`/job/${slug}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const deleteJobApi = (data) => {
  return axiosInstance().delete(`/job/${data}`);
};

export const getJobApplicationsApi = (data) => {
  return axiosInstance().get(`/job-by-company/job-applications`, data);
};
