import { getNewsApi, getNewsSingleApi } from "apis";
import * as actions from "components/local/news/redux/types";

export const getNewspage = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_NEWS_BEGIN });

  try {
    const { data } = await getNewsApi(Data);
    dispatch({
      type: actions.GET_NEWS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_NEWS_ERROR });
    console.log(error);
  }
};

export const getNewsSinglepage = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_NEWS_SINGLE_BEGIN });

  try {
    const { data } = await getNewsSingleApi(Data);
    dispatch({
      type: actions.GET_NEWS_SINGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_NEWS_SINGLE_ERROR });
    console.log(error);
  }
};
