import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  complainBoxContainer: {
    position: "fixed",
    zIndex: "999",
    top: "30%",
    right: "0",
    padding: "5px",

    //   [theme.breakpoints.down("sm")]: {
    //     marginRight: '10px'
    //   },
  },
  complainBox: {
    backgroundColor: "#EF4444",
    padding: "5px",
    width: "40px",
    height: "40px",
    borderRadius: "10px",
    cursor: "pointer",
  },
  complainBoxIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "2px",
  },
}));

export default style;
