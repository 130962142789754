import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  // aboutSectionGrid1: {
  //   [theme.breakpoints.down("md")]: {
  //     order: "2",
  //   },
  // },
  aboutSectionGrid2: {
    [theme.breakpoints.down("md")]: {
      marginBottom: "15px !important",
    },
  },
  aboutCompContainer: {
    height: "600px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      // padding: "10px",
      overflow: "none",
      margin: "1rem 0",
    },
  },
  aboutCompWrap: {
    // backgroundColor: 'pink'
  },
  aboutCompTypography: {},
  aboutCompTitle: {
    color: "#EF4444",
    fontSize: "13px !important",
    fontWeight: "700 !important",
    textTransform: "uppercase",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },
  aboutCompHead: {
    width: "70%",
    color: "#121127",
    fontSize: "36px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    textTransform: "capitalize",
    // marginBottom: "20px !important",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: "10px !important",
    },
  },
  aboutCompPara: {
    color: "rgba(18, 17, 39, 0.72)",
    fontSize: "14px !important",
    fontWeight: "400",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },
  imageGrid: {
    //  backgroundColor: "pink",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  imageBox: {
    width: "404px",
    height: "490px",
    marginBottom: "10px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "120px",
      position: "relative",
      paddingTop: "70%",
      marginTop: "1rem",
    },
  },
  gridImg: {
    height: "100%",
    width: "100%",
    objectFit: " cover",
    borderRadius: "16px",
    [theme.breakpoints.down("md")]: {
      height: "100%",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      position: "absolute",
      width: "100%",
      objectFit: " cover",
      // borderRadius: "16px",
    },
  },
  imageGridCaption: {
    display: "flex",
    alignItems: "flex-start",
    color: "rgba(18, 17, 39, 0.4)",
    fontSize: "12px",
    fontWeight: "600",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },
}));

export default style;
