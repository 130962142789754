import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  footerContainer: {
    padding: "2rem 1rem !important",
    paddingBottom: "1rem !important",
    backgroundColor: "rgb(248, 196, 180, .30)",
    [theme.breakpoints.down("md")]: {
      padding: "2rem 0rem !important",
    },
    // position: 'fixed',
    // width: '100%',
    // bottom: 0,
    // display: 'flex',
    // height: '100%'
  },
  footerGrid: {
    padding: "20px 0",
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  },
  grid1: {
    paddingRight: "55px",
    [theme.breakpoints.down("md")]: {
      paddingRight: "0",
    },
  },
  grid2: {
    padding: "0 55px",
    marginTop: "2rem !important",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      marginTop: "0 !important",
    },
  },
  grid3: {
    paddingLeft: "55px",
    marginTop: "1rem !important",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      marginTop: "0 !important",
    },
  },
  linkHead: {
    color: "#201F37",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    fontSize: "19px !important",
    fontWeight: "700 !important",
    textDecoration: "underline",
    marginBottom: "10px !important",
  },

  footerNavLink: {
    textDecoration: "none !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    color: "#383751",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px !important",
      margin: "5rem 0",
    },
  },
  logoSubtext: {
    fontSize: "13px !important",
    fontWeight: "400 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    margin: "20px 0 !important",
  },
  footerBottomTextBox: {
    //  padding: "5px 0",
    display: "flex",
    justifyContent: "center",
  },
  footerBottomText: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "center",
    gap: 5,
    color: "#4C4B63 !important",
    fontSize: "13px !important",
    fontWeight: "400 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },
  searchHead: {
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    fontSize: "13px !important",
    fontWeight: "700 !important",
    textTransform: "uppercase !important",
    color: "#201F37",
    marginBottom: "8px !important",
  },
  searchSubtext: {
    color: "#4C4B63",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    fontSize: "13px !important",
    fontWeight: "400 !important",
    marginBottom: "25px !important",
  },

  //  footer search section
  footerSearch: {
    width: "100% !important",
  },
  searchBox: {
    width: "100% !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  searchGrid: {
    height: "32px !important",
  },
  searchField: {
    width: "95% !important",
    height: "100% !important",
    color: "red",
    "& .MuiOutlinedInput-root": {
      padding: "7.2px 14px",
    },
    "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input ": {
      padding: "6px 14px",
      fontSize: "13px",
      fontFamily: ["Sofia Sans", "sans-serif", "!important"],
      color: "#5A5551",
      fontWeight: "400",
    },
    "& fieldset": {
      borderRadius: "3px !important",
      backgroundColor: "rgb(248, 196, 180, .3)",
      color: "red",
    },
    "& .css-j62s1x-MuiInputBase-root-MuiOutlinedInput-root input": {
      color: "#5A5551",
      fontFamily: ["Sofia Sans", "sans-serif", "!important"],
      fontSize: "13px !important",
      fontWeight: "400 !important",
      // padding: "6px 14px",
    },
  },
  searchBtnGrid: {
    height: "31px !important",
    // width: '30px !important',
  },
  submitBtn: {
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    color: "#fff !important",
    backgroundColor: "#DB143C !important",
    fontSize: "13px !important",
    fontWeight: "500 !important",
    padding: "2px 22px !important",
    height: "100% !important",
    width: "100% !important",
    fontStyle: "normal !important",
  },
}));

export default style;
