import { useState } from "react";

const ReadMoreBtn = ({ data, length }) => {
  const [readMore, setReadMore] = useState(false);
  return (
    <>
      {readMore ? data : `${data?.substring(0, length)}...`}
      <buttton
        style={{
          color: "#496AD0",
          cursor: "pointer",
          marginLeft: "10px",
          fontSize: "15px",
        }}
        onClick={() => setReadMore(!readMore)}
      >
        {readMore ? "see less" : "see more"}
      </buttton>
    </>
  );
};

export default ReadMoreBtn;
