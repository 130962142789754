import { getTeamsApi, getTeamsCategoryApi, getTeamsSingleApi } from "apis";
import * as actions from "components/local/ourTeams/redux/types";


// teams

export const getTeams = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_TEAMS_BEGIN });

  try {
    const { data } = await getTeamsApi(Data);
    dispatch({
      type: actions.GET_TEAMS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_TEAMS_ERROR });
    console.log(error);
  }
};

export const getTeamsSingle = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_TEAMS_SINGLE_BEGIN });

  try {
    const { data } = await getTeamsSingleApi(Data);
    dispatch({
      type: actions.GET_TEAMS_SINGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_TEAMS_SINGLE_ERROR });
    console.log(error);
  }
};

// teams category

export const getTeamsCategory = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_TEAMS_CATEGORY_BEGIN });

  try {
    const { data } = await getTeamsCategoryApi(Data);
    dispatch({
      type: actions.GET_TEAMS_CATEGORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_TEAMS_CATEGORY_ERROR });
    console.log(error);
  }
};


