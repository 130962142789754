import { postNewsletterApi } from "apis";
import * as actions from "layouts/footer/redux/types";
import { errorToast, successToast } from "utils/toast";

export const postNewsletter =
  (Data, refetch = () => {}) =>
  async (dispatch) => {
    dispatch({ type: actions.POST_NEWSLETTER_BEGIN });

    try {
      await postNewsletterApi(Data);
      console.log("dataaa", Data);
      refetch && refetch();
      dispatch({
        type: actions.POST_NEWSLETTER_SUCCESS,
        payload: "",
      });
      successToast();
    } catch (error) {
      dispatch({ type: actions.POST_NEWSLETTER_ERROR });
      console.log(error);
      errorToast(error);
    }
  };

