import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  communityContainer: {
    padding: "30px 110px 0",
  },
  communityTitleBox: {
    marginBottom: "20px",
  },

  // see more
  seeMoreBtnBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  seeMoreBtn: {
    color: "#496AD0 !important",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    cursor: "pointer",
    backgroundColor: "#fff !important",
  },

  searchWrapper: {
    // "&.MuiInput-root": {
    //   "&:hover, &:before, &:after, &:focus": {
    //     borderBottom: "1px solid #484848 !important",
    //   },
    // },

    "& .MuiInput-root": {
      borderBottom: "1px solid #f3f3f3 !important",
    },
    "& .MuiInput-root:hover": {
      borderBottom: "0.2px solid #f3f3f3 !important",
    },
    
    "& .MuiInput-root:after": {
      borderBottom: "1px solid #f3f3f3 !important",
    },

    "& .MuiInput-root:before": {
      borderBottom: "none !important",
    },

    "& .MuiInput-root:focus": {
      borderBottom: "none !important",
    },
  },
  // textfield
  searchField: {
    width: "100%",
    backgroundColor: "#fff",

    "& .Mui-focused": {
      borderBottom: "0px !important",
    },

    "& .MuiInputBase-root:invalid": {
      padding: "10px 40px",
      color: "#6C6B80",
    },
    "& .MuiInputBase-root": {
      padding: "10px 40px",
      color: "#6C6B80",
    },
  },
  communityTitle: {
    fontSize: "27px !important",
    fontWeight: "700 !important",
    color: "#EF4444",
  },
  buttonBox: {
    backgroundColor: "#fff",
    padding: "15px 40px",
    display: "flex",
    justifyContent: "space-between",
  },
  profileBox: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  profileImgBox: {
    width: "30px",
    height: "30px",
  },
  profileImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "50%",
  },
  profileName: {
    fontSize: "15px !important",
    fontWeight: "400 !important",
    color: "#6C6B80",
  },
  submitBtn: {
    backgroundColor: "#F87171 !important",
    color: "#fff !important",
    padding: " 4px 18px !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
    width: "80px",
  },
  timeAgo:{
    display: 'flex',

  },

  // comment section
  dropdownItems: {
    color: "#4C4B63",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  dropdownEditHover: {
    padding: "5px 10px !important",
    // "& .css-ez0x5p-MuiButtonBase-root-MuiMenuItem-root:hover":{
    //   color: '#496AD0'
    // },
    // "& :hover":{
    //   color: '#496AD0'
    // }
  },
  dropdownDeleteHover: {
    padding: "5px 10px !important",
    // "& .css-ez0x5p-MuiButtonBase-root-MuiMenuItem-root:hover":{
    //   color: '#DC2626'
    // },
    // "& :hover":{
    //   color: '#DC2626'
    // }
  },

  commentSectionContainer: {
    backgroundColor: "#fff",
    padding: "35px 35px 20px 35px",
    margin: "35px 0",
  },
  commentSectionBox: {
    marginBottom: "8px",
  },
  commentProfileImgBox: {
    width: "55px",
    height: "55px",
    border: "1px solid #FCA5A5",
    padding: "3px",
    borderRadius: "50%",
  },
  userNameMenu: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  userCommmentBox: {
    display: "grid",
    flexDirection: "column",
  },
  userProfileName: {
    fontSize: "13px !important",
    fontWeight: "400 !important",
    color: "#a4a3ad !important",
  },
  userCommentTitle: {
    fontSize: "22px !important",
    fontWeight: "700 !important",
    color: "#201F37 !important",
    // marginBottom: "5px !important",
  },
  userCommentBody: {
    fontSize: "16px !important",
    fontWeight: "400 !important",
    color: "#6C6B80 !important",
    marginBottom: "5px !important",
  },
  userCommentBtnBox: {
    display: "flex",
    gap: 13,
    alignItems: "center",
  },
  likeBtn: {
    backgroundColor: "#F9F9FB !important",
    color: "#4C4B63 !important",
    fontSize: "14px !important",
    padding: "3px 12px !important",
  },
  commentNum: {
    fontSize: "14px !important",
    fontWeight: "700 !important",
    color: "#A4A4A4 !important",
  },
  dotTime:{
    fontSize: "5px !important",
    fontWeight: "700 !important",
    color: "#a4a3ad !important",
    margin: 'auto 5px'
  },

  // reply section
  replySectionBox: {
    marginBottom: "10px",
    backgroundColor: "#FFFCFB",
    padding: " 10px 15px",
  },
  replyProfileImgBox: {
    width: "30px",
    height: "30px",
  },
  upBtn: {
    border: "1px solid #E5E5EB",
    borderRadius: "4px 0 0 4px",
    backgroundColor: "#fff",
    padding: "8px 10px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  upBtnIcon: {
    color: "red",
    width: "13.5",
    height: "14.5",
  },
  downBtn: {
    border: "1px solid #E5E5EB",
    borderRadius: "0 4px 4px 0",
    backgroundColor: "#fff",
    padding: "8px 10px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  downBtnIcon: {
    color: "red !important",
    backgroundColor: "red",
    width: "13.5",
    height: "14.5",
  },
  linkNumBox: {
    borderTop: "1px solid #E5E5EB",
    borderBottom: "1px solid #E5E5EB",
    backgroundColor: "#fff",
    padding: "4px 8px",
    display: "flex",
    alignItems: "center",
  },
  userReplyBtnBox: {
    display: "flex",
    alignItems: "center",
  },
  linkNum: {
    fontSize: "16px",
    fontWidth: "700",
    color: "#4C4B63 ",
  },

  // reply box

  // comment box

  commentBoxWrap: {
    backgroundColor: "#FCFCFC",
    width: "60%",
    marginBottom: "20px",
    border: "1px solid #E5E5EB",
    borderRadius: "5px",
  },
  commentBoxTextfield: {
    backgroundColor: "#FCFCFC",
    borderRadius: "5px",
  },
  submitBtnCommentBox: {
    backgroundColor: "#F87171 !important",
    color: "#fff !important",
    padding: " 2px 14px !important",
    fontSize: "12px !important",
    fontWeight: "500 !important",
  },
  commentButtonBox: {
    backgroundColor: "#FCFCFC",
    padding: "10px 20px",
    display: "flex",
    justifyContent: "flex-end",
    borderRadius: "5px",
  },

  // comment box secondary

  commentBoxSecondaryWrap: {
    margin: "10px 0 30px 0 !important",
    display: "flex",
    alignItems: "center",
  },
  commentBoxSecondarySearch: {
    width: "100%",
    borderRadius: "5px",
    backgroundColor: "#FCFCFC",
    // "& .MuiInputBase-root":{
    //   color: 'red',
    // },
    "& .MuiOutlinedInput-root input": {
      backgroundColor: "#fff",
      borderRadius: "5px",
      padding: "1px 10px ",
      color: "#9D9CAF",
    },
    "& .MuiOutlinedInput-root": {
      fontSize: "14px",
      color: "#9D9CAF",
      borderRadius: "5px",
    },
  },
  submitBtnSecondary: {
    backgroundColor: "#F87171 !important",
    color: "#fff !important",
    padding: " 4px 14px !important",
    fontSize: "16px !important",
    fontWeight: "500 !important",
    width: "100%",
  },

  moreCommentBtn: {
    border: "none",
    backgroundColor: "#fff",
    color: "#4CA2EC",
    cursor: " pointer",
  },
  // comment modal
  formSubmitModal: {
    // backgroundColor: 'red',
    overflowY: "scroll",
  },
  commentModalScroll: {
    padding: "20px",
    overflowY: "scroll",
    maxHeight: "500px",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#D1D1DB",
      borderRadius: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#383751",
      outline: "none",
      borderRadius: "8px",
    },
  },
}));

export default style;
