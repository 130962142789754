import GallerySingle from "components/local/gallerySingle/GallerySingle";

const GallerySinglePage = () => {
  return (
    <div>
      <GallerySingle />
    </div>
  );
};

export default GallerySinglePage;
