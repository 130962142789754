import { Box, Container, Skeleton } from "@mui/material";
import TermsAndConditionsDesc from "components/global/description/TermsAndConditionsDesc";
import { getSiteSetting } from "layouts/redux/actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const TermsAndConditions = () => {
  const pathname = window.location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const dispatch = useDispatch();
  const { siteSetting, siteSetting_loading } = useSelector(
    (state) => state.siteSetting
  );

  const [siteData, setSiteData] = useState({});
  useEffect(() => {
    siteSetting?.data && setSiteData(siteSetting?.data);
  }, [siteSetting]);

  useEffect(() => {
    dispatch(getSiteSetting());
  }, []);

  return (
    <Box sx={{ padding: "2rem 0" }}>
      <Container maxWidth="lg">
        {siteSetting_loading ? (
          <Skeleton variant="rectangular" width="100%" height={400} />
        ) : (
          <TermsAndConditionsDesc description={siteData?.terms_and_condition} />
        )}
      </Container>
    </Box>
  );
};

export default TermsAndConditions;
