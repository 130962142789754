import { Grid } from "@mui/material";
import BookedEvent from "components/local/dashboard/bookedEvent";
import SideBar from "components/UI/SideBar/SideBar";

const BookedEventPage = () => {
  return (
    <Grid container sx={{ backgroundColor: "#F5F5F5" }}>
      <Grid item md={2}>
        <SideBar />
      </Grid>
      <Grid
        item
        md={10}
        sm={10}
        xs={12}
        sx={{ marginInline: { sm: "auto", md: "initial" } }}
      >
        <BookedEvent />
      </Grid>
    </Grid>
  );
};

export default BookedEventPage;
