import { getHomepageApi, getQuickLinksApi } from "apis";
import * as actions from "components/local/home/redux/types";

export const getHomepage = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_HOMEPAGE_BEGIN });

  try {
    const { data } = await getHomepageApi(Data);
    dispatch({
      type: actions.GET_HOMEPAGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_HOMEPAGE_ERROR });
    console.log(error);
  }
};

