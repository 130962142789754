export const GET_TEAMS_BEGIN = "GET_TEAMS_BEGIN";
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";
export const GET_TEAMS_ERROR = "GET_TEAMS_ERROR";

export const GET_TEAMS_SINGLE_BEGIN = "GET_TEAMS_SINGLE_BEGIN";
export const GET_TEAMS_SINGLE_SUCCESS = "GET_TEAMS_SINGLE_SUCCESS";
export const GET_TEAMS_SINGLE_ERROR = "GET_TEAMS_SINGLE_ERROR";

export const GET_TEAMS_CATEGORY_BEGIN = "GET_TEAMS_CATEGORY_BEGIN";
export const GET_TEAMS_CATEGORY_SUCCESS = "GET_TEAMS_CATEGORY_SUCCESS";
export const GET_TEAMS_CATEGORY_ERROR = "GET_TEAMS_CATEGORY_ERROR";
