import { Avatar, Grid, Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useMemo } from "react";
// import profilePic from "assets/dashboard/profile-pic.jpg";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfileData } from "redux/profile/actions";
import style from "./style";

const Profile = () => {
  const classes = style();
  const profileState = useSelector((state) => state.profile);
  const authState = useSelector((state) => state.auth);

  console.log({ authState });
  console.log({ profileState });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProfileData());
  }, []);

  const profileData = useMemo(() => {
    const membershipDetail = profileState?.data?.membership_detail;
    console.log({ membershipDetail });
    return [
      {
        title: "Name",
        info:
          membershipDetail?.member_name ||
          authState?.user?.name ||
          profileState?.data?.name ||
          "N/A",
      },
      {
        title: "Email",
        info:
          membershipDetail?.member_email ||
          authState?.user?.email ||
          profileState?.data?.email ||
          "N/A",
      },
      { title: "Phone No", info: membershipDetail?.phone || "N/A" },
      { title: "Designation ", info: membershipDetail?.designation || "N/A" },
      {
        title: "Memeber Type",
        info:
          membershipDetail?.member_type ||
          authState?.user?.role ||
          profileState?.data?.role ||
          "N/A",
      },
      {
        title: "Description",
        info: membershipDetail?.description || "<p>N/A</p>",
      },
    ];
  }, [profileState?.data?.membership_detail]);

  /* const profileData = [
    { title: "Name", info: "Shreesha Aryal" },
    { title: "Email", info: "shree432@gmail.coml" },
    { title: "Phone No", info: "9854712536" },
    { title: "Designation ", info: "Manager" },
    { title: "Memeber Type", info: "General" },
    {
      title: "Description",
      info: "financial reporting, taxation, corporate finance,",
    },
  ]; */

  /* if (profileState.status === "pending") {
    return <Skeleton variant="rectangular" width={877} height={430} />;
  } */

  return (
    <Box
      component="section"
      sx={{
        bgcolor: "#F5F5F5",
        padding: { xs: "50px", md: "75px", lg: "97px 124px" },
        height: "100%",
      }}
    >
      <>
        <Box sx={{ mb: "35px" }}>
          {profileState.status === "pending" ? (
            <Skeleton
              variant="text"
              width={"157px"}
              sx={{ fontSize: "26px" }}
            />
          ) : (
            <Typography component="h1" className={classes.header}>
              Profile Details
            </Typography>
          )}
        </Box>
        <Box className={classes.imageContainer} sx={{ mb: "35px" }}>
          {profileState.status === "pending" ? (
            <Skeleton variant="circular" width={"100%"} height={"100%"} />
          ) : (
            <Avatar
              // src={profileState?.data?.membership_detail?.image}
              src={
                profileState?.data?.image
                  ? profileState?.data?.image
                  : profileState?.data?.membership_detail?.image
              }
              alt=""
              sx={{
                height: "100%",
                width: "100%",
                background: "#EF4444",
                color: "#fff",
              }}
            >
              {/* {profileState?.data?.image
                ? profileState?.data?.image
                : authState?.user?.name?.charAt(0)} */}
            </Avatar>
          )}
        </Box>
        <Box>
          <Grid container>
            {profileData.map((item, index) => {
              return (
                <Grid
                  key={index}
                  item
                  md={5}
                  sm={6}
                  xs={12}
                  sx={{ mb: "24px" }}
                >
                  {profileState.status === "pending" ? (
                    <>
                      <Skeleton
                        variant="text"
                        width={"100px"}
                        sx={{ fontSize: "1.2rem" }}
                      />
                      <Skeleton variant="text" width={"150px"} />
                    </>
                  ) : (
                    <>
                      <Box sx={{ mb: "11px" }}>
                        <Typography
                          component={"h2"}
                          className={classes.subHeader}
                        >
                          {item.title}
                        </Typography>
                      </Box>
                      {item.title === "Description" ? (
                        <Box
                          sx={{
                            "& p": {
                              color: "#797A7B",
                              lineHeight: "1.2",
                              margin: 0,
                            },
                          }}
                          dangerouslySetInnerHTML={{
                            __html: item.info,
                          }}
                        ></Box>
                      ) : (
                        <Box>
                          <Typography className={classes.lightText}>
                            {item.info}
                          </Typography>
                        </Box>
                      )}
                    </>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </>
    </Box>
  );
};

export default Profile;
