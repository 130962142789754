import { Container } from "@mui/system";
// import Banner from "components/local/banner/Banner";
import Banner from "components/global/banner";
import Album from "components/local/gallery/album/Album";
import { getAlbum } from "components/local/gallery/redux/actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const Gallery = () => {
  const pathname = window.location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const dispatch = useDispatch();
  const { album, album_loading } = useSelector((state) => state.album);
  const [albumData, setAlbumData] = useState([]);

  console.log("tttttttttttttttttttttttttt", { albumData });

  // useEffect(() => {
  //   album?.data && setAlbumData(album?.data);
  // }, [album]);

  useEffect(() => {
    album?.data &&
      setAlbumData([
        ...(albumData || []),
        ...(album?.data?.album_data?.album || []),
      ]);
  }, [album?.data?.album_data?.pagination?.current_page]);

  useEffect(() => {
    dispatch(getAlbum());
  }, []);

  const breadcrumbData = [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "Gallery",
      link: "",
    },
  ];

  return (
    <div>
      {/* <Banner
        title="Gallery"
        image={album?.data?.album_banner}
        breadcrumbData={breadcrumbData}
        hasBorder={true}
      /> */}
      <Banner data={album?.data?.album_banner} title={"Gallery"} />
      <Container>
        <Album data={albumData} loading={album_loading} album={album} />
      </Container>
    </div>
  );
};

export default Gallery;
