export const GET_COURSES_BEGIN = "GET_COURSES_BEGIN";
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";
export const GET_COURSES_ERROR = "GET_COURSES_ERROR";

export const GET_COURSES_SINGLE_BEGIN = "GET_COURSES_SINGLE_BEGIN";
export const GET_COURSES_SINGLE_SUCCESS = "GET_COURSES_SINGLE_SUCCESS";
export const GET_COURSES_SINGLE_ERROR = "GET_COURSES_SINGLE_ERROR";

export const GET_COURSES_NOTE_BEGIN = "GET_COURSES_NOTE_BEGIN";
export const GET_COURSES_NOTE_SUCCESS = "GET_COURSES_NOTE_SUCCESS";
export const GET_COURSES_NOTE_ERROR = "GET_COURSES_NOTE_ERROR";

export const GET_COURSES_NOTE_SINGLE_BEGIN = "GET_COURSES_NOTE_SINGLE_BEGIN";
export const GET_COURSES_NOTE_SINGLE_SUCCESS =
  "GET_COURSES_NOTE_SINGLE_SUCCESS";
export const GET_COURSES_NOTE_SINGLE_ERROR = "GET_COURSES_NOTE_SINGLE_ERROR";

export const GET_COURSES_VIDEO_BEGIN = "GET_COURSES_VIDEO_BEGIN";
export const GET_COURSES_VIDEO_SUCCESS = "GET_COURSES_VIDEO_SUCCESS";
export const GET_COURSES_VIDEO_ERROR = "GET_COURSES_VIDEO_ERROR";

export const GET_COURSES_VIDEO_SINGLE_BEGIN = "GET_COURSES_VIDEO_SINGLE_BEGIN";
export const GET_COURSES_VIDEO_SINGLE_SUCCESS =
  "GET_COURSES_VIDEO_SINGLE_SUCCESS";
export const GET_COURSES_VIDEO_SINGLE_ERROR = "GET_COURSES_VIDEO_SINGLE_ERROR";
export const SET_COURSES_VIDEO_LINK = "SET_COURSES_VIDEO_LINK";
export const SET_COURSES_VIDEO_TITLE = "SET_COURSES_VIDEO_TITLE";