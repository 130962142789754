import moment from "moment";

export const TimeAgo = ({ time }) => {
  const timeago = moment(time).fromNow();
  return <>{timeago}</>;
};

// export const Date = ({ time }) => {
//   const getFormattedDate = (date) => {
//     const dates = new Date(date);
//     return moment(dates)?.format("MMM-DD");
//   };
//   return <>{getFormattedDate(time)}</>;
// };
