import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    gap: "42px",
    padding: "24px 34px",
    marginBottom: "28px",
    border: "solid 1px #F0EFEF",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      flexDirection: "column",
      gap: "20px",
    },
  },
  headerImage: {
    width: "112px",
    height: "101.5px",
  },
  headerTitle: {
    fontSize: "22px !important",
    lineHeight: "32.38px !important",
    fontWeight: "600 !important",
    color: theme.palette.grey[7],
  },
  icon: {
    width: "12px",
    height: "12px",
  },
  headerBody: {
    display: "list-item",
    textTransform: "uppercase",
    color: "#EF4444",
    fontSize: "17px !important",
    fontWeight: "600 !important",
    "&::marker": {
      color: "#EF4444",
      fontSize: "24px",
    },
  },
  contentTitle: {
    fontSize: "18px !important",
    lineHeight: "42.24px !important",
    fontWeight: "600 !important",
    color: "#1D1D22",
  },
  contentParagraph: {
    fontSize: "15px !important",
    lineHeight: "31px !important",
    color: "#201F37",
  },
  formContainer: {
    padding: "22px 35px 50px 30px",
    boxShadow: "0px 4px 19px rgba(0, 0, 0, 0.07)",
  },
  title: {
    fontFamily: ["Sofia sans", "sans-serif", "!important"],
    color: theme.palette.red,
    fontWeight: "700 !important",
    fontSize: "18px !important",
    lineHeight: "1.3 !important",
    marginBottom: "14px !important",
  },
  bigTitle: {
    fontFamily: ["Sofia sans", "sans-serif", "!important"],
    color: "#EF4444",
    fontWeight: "800 !important",
    fontSize: "30px !important",
    lineHeight: "1 !important",
    marginBottom: "10px !important",
  },
  lightText: {
    fontFamily: ["Sofia sans", "sans-serif", "!important"],
    color: "#8D8D8D",
    fontSize: "14px !important",
    lineHeight: "1.4 !important",
    maxWidth: "412px",
  },
  inputField: {
    marginBottom: "20px",
  },
  label: {
    fontFamily: ["Sofia sans", "sans-serif"],
    fontWeight: "600",
    display: "block",
    lineHeight: "1.4",
    marginBottom: "10px",
  },
  input: {
    padding: "6px 12px",
    fontFamily: ["Sofia sans", "sans-serif"],
    fontSize: "14px",
    lineHeight: "1.4",
    width: "100%",
    border: "solid 1px #D6D5D5s",
  },
  button: {
    width: "100%",
    backgroundColor: '#EF4444 !important',
    color: "#fff !important",
    textAlign: "center",
    padding: "6px 0",
    border: "transparent",
    fontFamily: ["Sofia sans", "sans-serif"],
    fontSize: "15px",
    lineHeight: "1.2",
    fontWeight: "700",
    cursor: "pointer",
  },
  buttonDisable: {
    width: "100%",
    backgroundColor: '#BFBFBF !important',
    color: "#fff !important",
    textAlign: "center",
    padding: "6px 0",
    border: "transparent",
    fontFamily: ["Sofia sans", "sans-serif"],
    fontSize: "15px",
    lineHeight: "1.2",
    fontWeight: "700",
    cursor: "pointer",
  },
}));

export default style;
