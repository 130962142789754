import { getSiteSettingApi } from "apis";
import * as actions from "layouts/redux/types";

export const getSiteSetting = (Data) => async (dispatch) => {
dispatch({ type: actions.GET_SITE_SETTING_BEGIN });

try {
   const { data } = await getSiteSettingApi(Data);
   dispatch({
      type:  actions.GET_SITE_SETTING_SUCCESS,
      payload: data,
   });
} catch (error) {
   dispatch({ type:  actions.GET_SITE_SETTING_ERROR });
   console.log(error);
}
};