import { Box, Container, Typography } from "@mui/material";
import ButtonPrimary from "components/global/buttons/buttonPrimary/ButtonPrimary";

import style from "./style";

const HeroSection = ({ data }) => {
  const classes = style();

  return (
    <>
      <Box className={classes.heroWrap}>
        <Box className={classes.heroImgContainer}>
          <Box className={classes.heroTextWrap}>
            <Container maxWidth="lg">
              <Box sx={{ marginLeft: "50px" }}>
                <Typography className={classes.heroText}>
                  {data?.title}
                </Typography>
                <Typography className={classes.heroSubtext}>
                  {data?.subtitle}
                </Typography>
                {data?.link && (
                  <a
                    style={{ textDecoration: "none" }}
                    href={data?.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ButtonPrimary
                      title={data?.button_text}
                      link={data?.link}
                    />
                  </a>
                )}
              </Box>
            </Container>
          </Box>
          <img src={data?.image} alt="" className={classes.heroImg} />
        </Box>
      </Box>
    </>
  );
};

export default HeroSection;
