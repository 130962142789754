import PublicationsSingle from 'components/local/publications/PublicationsSingle'
import React from 'react'

const PublicationSinglePage = () => {
  return (
   <>
    <PublicationsSingle/>
   </>
  )
}

export default PublicationSinglePage