import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  textContainer: {
    marginInline: "auto",
    marginBottom: "37px",
    textAlign: "center",
  },
  title: {
    fontFamily: ["Sofia sans", "sans-serif", "!important"],
    color: "#000",
    fontWeight: "700 !important",
    fontSize: "28px !important",
    lineHeight: "1.4 !important",
  },
  lightText: {
    fontFamily: ["Sofia sans", "sans-serif", "!important"],
    color: "#8B8B96",
    fontSize: "14px !important",
    lineHeight: "1.4 !important",
    maxWidth: "400px",
    marginInline: "auto !important",
  },
}));

export default style;
