import * as actions from "layouts/footer/redux/types";
const initialState = {
  newsletter: [],
  newsletter_loading: false,
};

const newsletterReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.POST_NEWSLETTER_BEGIN:
      return { ...state, newsletter_loading: true };

    case actions.POST_NEWSLETTER_SUCCESS:
      return {
        ...state,
        newsletter_loading: false,
        newsletter: action.payload,
      };

    case actions.POST_NEWSLETTER_ERROR:
      return { ...state, newsletter_loading: false };
    default:
      return state;
  }
};

export default newsletterReducer;
