import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  header: {
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    textTransform: "uppercase",
    fontWeight: "700 !important",
    fontSize: "22px !important",
    lineHeight: "1.4 !important",
    // backgroundColor: theme.palette.primary.main,
  },
  btn: {
    display: "flex !important",
    gap: "5px",
    padding: "4px 8px !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    fontSize: "14px !important",
    lineHeight: "1.8 !important",
    fontWeight: "400 !important",
    "&:active": {
      border: "solid 1px #D6D6D6",
    },
  },
  btnIcon: {
    width: "16px",
    height: "16px",
  },
}));

export default style;
