import { Box, Container, Grid, Typography } from "@mui/material";
import clock from "assets/home/clock.png";
import { TimeAgo } from "components/global/date/Date";
import Description from "components/global/description/Description";
import SkeletonNewsHomeLoader from "components/global/loader/skeletonLoader/SkeletonNewsHomeLoader";
import style from "components/local/home/news/style";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const News = ({ homeData }) => {
  const classes = style();
  const { homepage_loading } = useSelector((state) => state.home);
  console.log({ homepage_loading });

  return (
    <Box className={classes.homeNewsContainer}>
      <Container maxWidth="lg">
        {/* <Box className={[classes.homeNewsWrap, "container-wrap-secondary"]}> */}
        <Box className={classes.homeNewsTypographyMain}>
          <Box className={classes.homeNewsTypography}>
            <Typography className={classes.homeNewsTitle}>
              Get updated about us
            </Typography>
            <Typography className={classes.homeNewsHead}>
              Latest news
            </Typography>
          </Box>
          {/* {homeData?.news && (
            <Box className={classes.newsGridSeeBtnBox}>
              <NavLink to="/news" className={classes.newsGridSeeBtn}>
                see more
              </NavLink>
            </Box>
          )} */}
        </Box>

        <Box className={classes.homeNewsLinks}>
          <Grid container >
            {homepage_loading
              ? [...Array(2)]?.map((item) => (
                  <Grid item md={6} sm={6}>
                    <SkeletonNewsHomeLoader />
                  </Grid>
                ))
              : homeData?.news?.slice(0, 4)?.map((data) => {
                  return <NewsComponent key={data?.id} data={data} />;
                })}
          </Grid>
        </Box>

        {/* </Box> */}
      {homeData?.news && (
            <Box className={classes.newsGridSeeBtnBox}>
              <NavLink to="/news" className={classes.newsGridSeeBtn}>
                see more
              </NavLink>
            </Box>
          )}
      </Container>
    </Box>
  );
};
const NewsComponent = ({ data }) => {
  const classes = style();
  return (
    <>
      <Grid item md={6} sm={6} key={data?.id}>
        <Box className={classes.homeNewsLinkBox}>
          <Box className={classes.homeNewsLinkImgBox}>
            <img src={data?.image} alt="" className={classes.homeNewsLinkImg} />
          </Box>
          <Box className={classes.homeNewsLinkTypography}>
            <Typography className={classes.homeNewsTypoTitle}>
              {data?.title}
            </Typography>
            <Typography className={classes.homeNewsTypoSubtitle}>
              <Description length={false} description={data?.description} />
            </Typography>
            <Typography className={classes.homeNewsTime}>
              <Box className={classes.newsTimeBox}>
                <Box className={classes.clockImgBox}>
                  <img src={clock} alt="" className={classes.clockImg} />
                </Box>
                <TimeAgo time={data?.created_at} />
              </Box>
              <NavLink
                to={`/news/${data?.slug}`}
                className={classes.newsSeemoreBtn}
              >
                see more
              </NavLink>
            </Typography>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default News;
