import { Grid } from "@mui/material";
import Profile from "components/local/dashboard/profile";
import SideBar from "components/UI/SideBar/SideBar";
import React from "react";

const ProfilePage = () => {
  return (
    <Grid container sx={{ backgroundColor: "#F5F5F5" }}>
      <Grid item md={2}>
        <SideBar />
      </Grid>
      <Grid item md={10}>
        <Profile />
      </Grid>
    </Grid>
  );
};

export default ProfilePage;
