import {
  getCourseApi,
  getCourseNoteApi,
  getCourseSingleApi,
  getCourseSingleNoteApi,
  getCourseVideoApi,
  getSingleCourseVideoApi,
} from "apis/courses";
import * as actions from "components/local/dashboard/courses/redux/types";

export const getCourses = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_COURSES_BEGIN });

  try {
    const { data } = await getCourseApi(Data);
    dispatch({
      type: actions.GET_COURSES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_COURSES_ERROR });
    console.log(error);
  }
};

export const getCoursesSingle = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_COURSES_SINGLE_BEGIN });

  try {
    const { data } = await getCourseSingleApi(Data);
    dispatch({
      type: actions.GET_COURSES_SINGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_COURSES_SINGLE_ERROR });
    console.log(error);
  }
};

export const getCoursesNote = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_COURSES_NOTE_BEGIN });

  try {
    const { data } = await getCourseNoteApi(Data);
    dispatch({
      type: actions.GET_COURSES_NOTE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_COURSES_NOTE_ERROR });
    console.log(error);
  }
};

export const getCoursesSingleNote = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_COURSES_NOTE_SINGLE_BEGIN });

  try {
    const { data } = await getCourseSingleNoteApi(Data);
    dispatch({
      type: actions.GET_COURSES_NOTE_SINGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_COURSES_NOTE_SINGLE_ERROR });
    console.log(error);
  }
};

export const getCoursesVideo = (params) => async (dispatch) => {
  dispatch({ type: actions.GET_COURSES_VIDEO_BEGIN });

  try {
    const { data } = await getCourseVideoApi(params);
    dispatch({
      type: actions.GET_COURSES_VIDEO_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_COURSES_VIDEO_ERROR });
    console.log(error);
  }
};

export const getSingleCourseVideo = (videoId) => async (dispatch) => {
  dispatch({ type: actions.GET_COURSES_VIDEO_SINGLE_BEGIN });

  try {
    const { data } = await getSingleCourseVideoApi(videoId);
    dispatch({
      type: actions.GET_COURSES_VIDEO_SINGLE_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_COURSES_VIDEO_SINGLE_ERROR });
    console.log(error);
  }
};
