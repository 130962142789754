import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { Box, Typography } from "@mui/material";
import ContactInfoLoader from "components/global/loader/skeletonLoader/ContactInfoLoader";
import style from "components/local/contactUs/style";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ContactInfo = () => {
  const classes = style();

  const { siteSetting, siteSetting_loading } = useSelector(
    (state) => state.siteSetting
  );
  const [siteData, setSiteData] = useState([]);

  console.log({ siteData });

  useEffect(() => {
    siteSetting?.data && setSiteData(siteSetting?.data);
  }, [siteSetting]);

  return (
    <Box className={classes.contactInfoContainer}>
      <Box className={classes.contactInfoBox}>
        <Box className={classes.contactInfoHeading}>
          <Typography className={classes.contactInfoTitle}>
            Keep in touch
          </Typography>
          <Typography className={classes.contactInfoSubtitle}>
            Stay Connected with Us for the Latest Updates and Support{" "}
          </Typography>
        </Box>

        {siteSetting_loading ? (
          <ContactInfoLoader />
        ) : (
          <Box className={classes.contactInfoWrap}>
            <Box className={classes.contactInfoItems}>
              <MailOutlineIcon
                fontSize="small"
                className={classes.contactInfoIcon}
              />{" "}
              <Typography className={classes.contactInfoItemText}>
                {siteData?.email}
              </Typography>
            </Box>
            <Box className={classes.contactInfoItems}>
              <LocalPhoneOutlinedIcon
                fontSize="small"
                className={classes.contactInfoIcon}
              />{" "}
              <Typography className={classes.contactInfoItemText}>
                {siteData?.phone}
              </Typography>
            </Box>
            <Box className={classes.contactInfoItems}>
              <PlaceOutlinedIcon
                fontSize="small"
                className={classes.contactInfoIcon}
              />{" "}
              <Typography className={classes.contactInfoItemText}>
                {siteData?.address}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ContactInfo;
