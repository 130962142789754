import Publications from 'components/local/publications'
import React from 'react'

const PublicationPage = () => {
  return (
    <div>
      <Publications />
    </div>
  );
};

export default PublicationPage;
