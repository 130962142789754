import { Grid } from "@mui/material";
import Courses from "components/local/dashboard/courses";
import SideBar from "components/UI/SideBar/SideBar";
import React from "react";

const CoursesPage = () => {
  return (
    <Grid container sx={{ backgroundColor: "#F5F5F5" }}>
      <Grid item md={2}>
        <SideBar />
      </Grid>
      <Grid item md={10}>
        <Courses />
      </Grid>
    </Grid>
  );
};

export default CoursesPage;
