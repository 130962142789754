import BlogsSingle from 'components/local/blogs/blogsSingle'
import React from 'react'

const BlogsSinglePage = () => {
  return (
    <>
      <BlogsSingle/>
    </>
  )
}

export default BlogsSinglePage