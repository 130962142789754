import { KeyboardArrowUp } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { getAboutpage } from "components/local/aboutUs/redux/actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import style from "./style";

const MobileDropDown = ({ barOpen, setBarOpen, aboutMenuOpen, setAboutMenuOpen }) => {
  const { aboutPage } = useSelector((state) => state.about);
  const [aboutData, setAboutData] = useState([]);
  // console.log({ aboutPage });
  const dispatch = useDispatch();

  useEffect(() => {
    aboutPage?.data && setAboutData(aboutPage?.data);
  }, [aboutPage]);

  useEffect(() => {
    dispatch(getAboutpage());
  }, []);

  const handleDrawerClose = () => {
    setBarOpen(!barOpen);
    setAboutMenuOpen(false);
  };

  // console.log({ aboutData });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
//   const [aboutMenuOpen, setAboutMenuOpen] = useState(false);

  const classes = style();
  const location = useLocation();
  const locationArray = location.pathname.split("/");

  const handleClick = (event) => {
    setAboutMenuOpen(!aboutMenuOpen);
  };
  //   const handleClick = (event) => {
  //     setAnchorEl(event.currentTarget);
  //   };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const styles = {
    dropDown: {
      // color: "#121127",
      display: "flex",
      alignItems: "center",
      justifyContent: { xs: "center", md: "none" },
      padding: { xs: "", lg: "15px" },
      cursor: "pointer",
      fontFamily: ["Sofia Sans", "sans-serif", "!important"],
      fontSize: { xs: "18px !important", lg: "13px !important" },
      fontWeight: "400 !important",
      background: locationArray?.includes?.("about-us") && "#EF4444",
      color: locationArray?.includes?.("about-us") && "#fff",
    },
  };

  return (
    <>
      <Typography
        sx={styles.dropDown}
        variant="contained"
        id="basic-button"
        onClick={handleClick}
      >
        About us
        {!aboutMenuOpen ? (
          <KeyboardArrowDownIcon fontSize="small" />
        ) : (
          <KeyboardArrowUp fontSize="small" />
        )}
      </Typography>

      {aboutMenuOpen ? (
        <Box
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          // sx={{ paddingInline: 0 }}

          className={classes.aboutOpenMenuBox}
        >
          {aboutData?.about?.map((data) => (
            <>
              <Box onClick={handleClose} sx={{ padding: "2px 0" }}>
                <NavLink
                  to={`/about-us/${data?.slug}`}
                  className={classes.dropdownItemsLink}
                  style={{ width: "100%" }}
                  onClick={handleDrawerClose}
                >
                  <Typography className={classes.dropdownItems}>
                    {data?.title}
                  </Typography>
                </NavLink>
              </Box>
              <Divider className={classes.divider} />
            </>
          ))}
        </Box>
      ) : (
        ""
      )}
    </>
  );
};

export default MobileDropDown;
