import AboutUs from 'components/local/aboutUs/AboutUs'
import React from 'react'

const AboutUsSinglePage = () => {
  return (
    <>
      <AboutUs/>
    </>
  )
}

export default AboutUsSinglePage
