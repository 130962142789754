import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import ApplicationForm from "components/form/ApplicationForm";
import KnowMoreCard from "components/global/card/knowMore/KnowMoreCard";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchSingleEventData } from "redux/events/actions";
import EventInfo from "./EventInfo";

const EventDetails = () => {
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { eventId } = useParams();
  // console.log("evnet id", eventId);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchSingleEventData(eventId));
  }, [eventId]);

  return (
    <div>
      <Container maxWidth="lg">
        <Grid
          container
          spacing="5"
          sx={{
            paddingBlock: "55px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid item lg={8} md={6} xs={12}>
            <EventInfo />
          </Grid>
          <Grid lg={4} md={6} xs={12}>
            {authState.isUserLoggedIn ? (
              <ApplicationForm />
            ) : (
              <KnowMoreCard title={"Want to know more about events?"} />
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default EventDetails;
