import { axiosInstance } from "./_axios";

export const fetchBannerApi = (data) => {
  return axiosInstance().get(`/banner`, data);
};

export const getHomepageApi = (data) => {
  return axiosInstance().get(`/home`, data);
};

export const getAboutApi = (data) => {
  return axiosInstance().get(`/about`, data);
};

export const getAboutSingleApi = (data) => {
  return axiosInstance().get(`/about/${data?.slug}`);
};

export const getNewsApi = (data) => {
  const params = {
    limit: data?.limit ?? 8,
    page: data?.page,
  };
  return axiosInstance().get(`/news`, { params });
};

export const getNewsSingleApi = (data) => {
  return axiosInstance().get(`/news/${data?.slug}`);
};

export const getBlogsApi = (data) => {
  const params = {
    limit: data?.limit ?? 8,
    page: data?.page,
  };
  return axiosInstance().get(`/blog`, { params });
};

export const getBlogsSingleApi = (data) => {
  return axiosInstance().get(`/blog/${data?.slug}`);
};

export const getPublicationsApi = (data) => {
  const params = {
    limit: data?.limit ?? 8,
    page: data?.page,
  };
  return axiosInstance().get(`/magazine`, { params });
};

export const getPublicationsSingleApi = (data) => {
  return axiosInstance().get(`/magazine/${data?.slug}`);
};

export const getJobsApi = (data) => {
  const params = {
    limit: data?.limit ?? 8,
    page: data?.page,
  };
  return axiosInstance().get(`/job`, { params });
};

export const getJobsSingleApi = (data) => {
  return axiosInstance().get(`/job/${data?.slug}`);
};

export const applyJobsApi = (data, slug) => {
  return axiosInstance().post(`/job/${slug}/job-apply`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getContactUsApi = (data) => {
  return axiosInstance().get(`/contact-us`, data);
};
  
export const postContactUsApi = (data) => {
  return axiosInstance().post(`/contact-us`, data);
};

export const postNewsletterApi = (data) => {
  return axiosInstance().post(`/newsletter`, data);
};

export const getAppliedJobsApi = () => {
  return axiosInstance().get("/job/applied-by/user");
};

export const deleteAppliedJobsApi = (id) => {
  return axiosInstance().delete(`/job/applied-by/user/delete/${id}`);
};

export const getSiteSettingApi = (data) => {
  return axiosInstance().get(`/site-setting`, data);
};

export const getAlbumApi = (data) => {
  const params = {
    limit: data?.limit ?? 6,
    page: data?.page,
  };
  return axiosInstance().get(`/album`, { params });
};

// export const getAlbumSingleApi = (data) => {
//   return axiosInstance().get(`/album/${data?.id}/gallery`, data);
// };

export const getAlbumSingleApi = (data) => {
  const params = {
    limit: data?.limit ?? 6,
    page: data?.page,
  };
  return axiosInstance().get(`/album/${data?.id}/gallery`, { params });
};

export const postVolunteerApi = (data) => {
  return axiosInstance().post(`/volunteer`, data);
};

export const getVolunteerApi = (data) => {
  return axiosInstance().get(`/volunteer`, data);
};

export const getQuickLinksApi = (data) => {
  return axiosInstance().get(`/quick-link`, data);
};

export const getTeamsCategoryApi = (data) => {
  return axiosInstance().get(`/our-team-category`, data);
};

export const getTeamsApi = (data) => {
  return axiosInstance().get(`/our-team`, data);
};

export const getTeamsSingleApi = (data) => {
  return axiosInstance().get(`/our-team/${data?.slug}`);
};

export const postComplainApi = (data) => {
  return axiosInstance().post(`/complain`, data);
};

export const getComplainApi = (data) => {
  return axiosInstance().get(`/complain`, data);
};

