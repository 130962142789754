import { Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CustomTable from "components/UI/Table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { deleteAppliedEvent, getAppliedEvents } from "redux/events/actions";

const BookedEvent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appliedEventState = useSelector((state) => state?.event);
  const rows = appliedEventState?.data;
  const [status, setStatus] = useState("idle");

  console.log({ rows });

  useEffect(() => {
    if (status === "idle") {
      dispatch(getAppliedEvents());
      setStatus("completed");
    }
  }, [status]);

  const handleClick = (row) => {
    console.log({ row });
    navigate(`/events/${row?.value?.event?.id ?? ""}`);
  };

  const columns = [
    { accessor: "event", label: "Event" },
    { accessor: "location", label: "Location" },
    { accessor: "date", label: "Date" },
    { accessor: "time", label: "Time" },
    { accessor: "name", label: "Applicant Name" },
    { accessor: "email", label: "Applicant Email" },
    { accessor: "phone", label: "Applicant Phone" },
    { accessor: "delete", label: "", onClick: "" },
  ];

  const handleDelete = (id) => {
    dispatch(deleteAppliedEvent(id));
    setStatus("idle");
  };

  return (
    <Box
      component="section"
      sx={{
        bgcolor: "#F5F5F5",
        padding: { xs: "10px 10px", md: "44px 45px", lg: "44px 65px" },
        height: "100%",
      }}
    >
      <Box sx={{ mb: "30px" }}>
        <Typography
          component="h1"
          sx={{
            fontWeight: "500 !important",
            fontSize: "26px !important",
            lineHeight: "1.2 !important",
            color: "#201F37",
          }}
        >
          My Events
        </Typography>
      </Box>
      {appliedEventState.status === "pending" ? (
        <Skeleton variant="rectangular" height={400} />
      ) : (
        <Box sx={{ cursor: "pointer" }}>
          <CustomTable
            handleClick={handleClick}
            columns={columns}
            rows={rows}
            handleDelete={handleDelete}
          />
        </Box>
      )}
    </Box>
  );
};

export default BookedEvent;
