import CloseIcon from "@mui/icons-material/Close";
import { Avatar, SwipeableDrawer, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import LoginForm from "components/form/loginForm/LoginForm";
import ButtonSecondary from "components/global/buttons/buttonsSecondary/ButtonSecondary";
import * as actions from "components/local/home/redux/types.js";
import DropDown from "layouts/navbar/dropdown/Dropdown";
import style from "layouts/navbar/style";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { logout } from "redux/auth/actions";
import MobileDropDown from "./dropdown/MobileDropDown";

const MobileNavbar = ({ barOpen, setBarOpen }) => {
  const classes = style();

  const authState = useSelector((state) => state.auth);
  const profileState = useSelector((state) => state.profile);
  const homeState = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const [aboutMenuOpen, setAboutMenuOpen] = useState(false);

  const handleLogOut = () => {
    dispatch(logout());
    setBarOpen(!barOpen);
  };

  const loginModalDisplay = () => {
    setBarOpen(!barOpen);
    dispatch({ type: actions.SHOW_LOGIN_FORM });
  };

  const handleDrawerClose = () => {
    setBarOpen(!barOpen);
    setAboutMenuOpen(false)
  };
  const handleCloseIcon = () => {
    setBarOpen(false);
    setAboutMenuOpen(false)
  }

  return (
    <>
      <div style={{ display: "none" }}>
        <SwipeableDrawer
          className={classes.sideDrawer}
          anchor="right"
          open={barOpen}
          onOpen={() => setBarOpen(true)}
          onClose={() => setBarOpen(false)}
          PaperProps={{
            sx: {
              width: "75%",
              padding: "1rem",
              display: "flex",
              gap: 0.5,
            },
          }}

          // handleClose={openFunction}
        >
          <CloseIcon
            fontSize="medium"
            sx={{ p: 1 }}
            onClick={handleCloseIcon}
            className={classes.sidebarClose}
          />
          <Box className={classes.navMenuMobile}>
            <NavLink
              to="/"
              className={classes.sidebarLinks}
              onClick={handleDrawerClose}
            >
              <Typography className={classes.sidebarTexts}>Home</Typography>
            </NavLink>

            <MobileDropDown
              handleDrawerClose={handleDrawerClose}
              barOpen={barOpen}
              setBarOpen={setBarOpen}
              aboutMenuOpen={aboutMenuOpen}
              setAboutMenuOpen={setAboutMenuOpen}
            />

            <NavLink
              to="/our-team"
              className={classes.sidebarLinks}
              onClick={handleDrawerClose}
            >
              <Typography className={classes.sidebarTexts}>
                Our Teams
              </Typography>
            </NavLink>

            <NavLink
              to="/events"
              className={classes.sidebarLinks}
              onClick={handleDrawerClose}
            >
              <Typography className={classes.sidebarTexts}>Events</Typography>
            </NavLink>

            <NavLink
              to="/news"
              className={classes.sidebarLinks}
              onClick={handleDrawerClose}
            >
              <Typography className={classes.sidebarTexts}>News</Typography>
            </NavLink>

            <NavLink
              to="/gallery"
              className={classes.sidebarLinks}
              onClick={handleDrawerClose}
            >
              <Typography className={classes.sidebarTexts}>Gallery</Typography>
            </NavLink>

            <NavLink
              to="/publications"
              className={classes.sidebarLinks}
              onClick={handleDrawerClose}
            >
              <Typography className={classes.sidebarTexts}>
                Publications
              </Typography>
            </NavLink>

            <NavLink
              to="/jobs"
              className={classes.sidebarLinks}
              onClick={handleDrawerClose}
            >
              <Typography className={classes.sidebarTexts}>Jobs</Typography>
            </NavLink>

            <NavLink
              to="/blogs"
              className={classes.sidebarLinks}
              onClick={handleDrawerClose}
            >
              <Typography className={classes.sidebarTexts}>Blogs</Typography>
            </NavLink>

            <NavLink
              to="/volunteer"
              className={classes.sidebarLinks}
              onClick={handleDrawerClose}
            >
              <Typography className={classes.sidebarTexts}>
                Volunteer
              </Typography>
            </NavLink>

            <NavLink
              to="/contact-us"
              className={classes.sidebarLinks}
              onClick={handleDrawerClose}
            >
              <Typography className={classes.sidebarTexts}>
                Contact us
              </Typography>
            </NavLink>
            {authState.isUserLoggedIn ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "7px",
                }}
              >
                <Box>
                  <Typography
                    fontSize={"18px"}
                    lineHeight="1.2"
                    fontWeight={"500"}
                    color="#1E1919"
                  >
                    {authState?.user?.name}
                  </Typography>
                  {/* <Button
                  onClick={handleLogOut}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    color: "#7C7C7C",
                    background: "transparent",
                    border: "none",
                    padding: "0",
                    fontSize: "12px",
                    lineHeight: "1.2",
                  }}
                >
                  <Login
                    fontSize="small"
                    sx={{ width: "15px", height: "15px" }}
                  />
                  Logout
                </Button> */}
                </Box>

                <Box>
                  <Link to="/dashboard/profile" style={{ padding: 0 }}>
                    <Avatar
                      src={profileState?.userImage}
                      alt=""
                      onClick={handleDrawerClose}
                      sx={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        objectFit: "cover",
                        background: "#EF4444",
                        color: "#fff",
                      }}
                    >
                      {authState?.user?.name?.charAt(0)}
                    </Avatar>
                  </Link>
                </Box>
              </Box>
            ) : (
              <Box
                display="flex"
                alignItems="center"
                className={classes.navLoginBtn}
              >
                <ButtonSecondary
                  className={classes.navMemberLoginBtn}
                  title={"Member Login"}
                  // onClick={() => dispatch({ type: actions.SHOW_LOGIN_FORM })}
                  onClick={loginModalDisplay}
                />
              </Box>
            )}
          </Box>
        </SwipeableDrawer>
      </div>
      {homeState.showLoginForm && <LoginForm />}
    </>
  );
};

export default MobileNavbar;
