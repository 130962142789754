import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  btn: {
    display: "flex",
    gap: "5px",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    fontSize: "14px !important",
    lineHeight: "1.8 !important",
    fontWeight: "400 !important",
    color: "#858689 !important",
    padding: "0 !important",
  },
  btnIcon: {
    width: "16px",
    height: "16px",
  },
}));

export default style;
