import Jobs from "components/local/jobs";

const JobsPage = () => {
  return (
    <div>
      <Jobs />
    </div>
  );
};

export default JobsPage;
