import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  eventGridContainer: {
    height: "467px",

    [theme.breakpoints.down("lg")]: {
      height: "auto",
    },
  },
  blogsGridSeeBtnBox: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "15px 0",
    width: "95%",
    [theme.breakpoints.down("lg")]: {
      width: "90%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  blogsGridSeeBtn: {
    border: "none",
    color: "#4C7CE5",
    backgroundColor: "#F9FAFB !important",
    fontSize: "13px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    textTransform: "capitalized !important",
    cursor: "pointer",
  },
  // no events

  // noEventsContainer:{
  //   display: 'flex',
  //   alignItems: 'center'
  // },
  noEventsBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // height: '200px',
    // width: '200px'
    // justifyContent:
    height: "100%",
    width: "100%",
  },
  noEventsImg: {
    height: "200px",
    width: "200px",
    // justifyContent:
  },
  noEventsText: {
    color: "#aaa",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    marginTop: "1rem !important",
  },
  eventBoxWrapper: {
    // display: "flex",
    // alignItems: "center",
    // flexDirection: "column",
  },
}));

export default style;
