import { CssBaseline } from "@mui/material";
import { getHomepage } from "components/local/home/redux/actions";
import { getSiteSetting } from "layouts/redux/actions";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RouteList from "routes";
import "themes/index.scss";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHomepage());
    dispatch(getSiteSetting());
  }, []);

  return (
    <>
      <ToastContainer autoClose={4000} limit={3} draggablePercent={50} />
      <CssBaseline />
      <RouteList />
    </>
  );
}

export default App;
