import { Box, Typography } from "@mui/material";
import SkeletonJobMain from "components/global/loader/skeletonLoader/SkeletonJobMain";
import { useSelector } from "react-redux";
import style from "./style";

const Content = ({ data }) => {
  const classes = style();
  const { jobsSingle_loading } = useSelector((state) => state.jobSingle);

  return (
    <Box
      sx={{
        padding: { xs: "20px", md: "24px 50px" },
        border: "solid 1px #F0EFEF",
      }}
    >
      {jobsSingle_loading ? (
        <SkeletonJobMain jobMain={"jobMain"} />
      ) : (
        <>
          <Box sx={{ marginBottom: "30px" }}>
            <Box sx={{ marginBottom: "17px" }}>
              <Typography component={"h2"} className={classes.contentTitle}>
                Job Summary
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.contentParagraph}>
                <div
                  className={classes.lineClampDesc}
                  dangerouslySetInnerHTML={{
                    __html: data?.description,
                  }}
                />
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Content;
