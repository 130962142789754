import NewsSingle from 'components/local/news/newsSingle'
import React from 'react'

const NewsSinglePage = () => {
  return (
    <>
    <NewsSingle/>
    </>
  )
}

export default NewsSinglePage