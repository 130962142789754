import { Button } from "@mui/material";
import rightArrow from "../../../../assets/home/rightArrow.png";
import style from "./style";

const ButtonPrimary = ({ title, border, link, onClick }) => {
  const classes = style();

  return (
    <>
      <Button
        // onClick={handleClick}
        onClick={onClick}
        className={` ${
          !border ? classes.btnPrimary : classes.btnPrimaryBorder
        }`}
      >
        {title}
        <img className={classes.rightArrow} src={rightArrow} alt="" />
      </Button>
    </>
  );
};

export default ButtonPrimary;
