import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  homeAboutContainer: {
    height: "auto",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    padding: "20px 0 60px",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      margin: "20px 0",
    },
  },
  imageContainerMain: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      marginLeft: "-1rem",
    },
  },
  imageContainer: {
    position: "relative",
    // height: "250px",
    // width: "220px",
  },
  borderBox: {
    // border: "2px solid #FCA5A5",
    height: "310px",
    width: "270px",
    // position: "absolute",
    [theme.breakpoints.down("md")]: {
      height: "200px",
      width: "280px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "180px",
      width: "250px",
    },
  },
  borderBoxImg: {
    // height: "100%",
    // width: "100%",
    height: "410px",
    width: "300px",
    zIndex: "10",
    left: "22px",
    top: "17px",
    // position: "absolute",
    display: "flex",
    alignItems: "center",
    // [theme.breakpoints.down("md")]: {
    //   left: "72px",
    // top: "17px",
    // },
  },
  boxImg: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
    },
  },
  aboutBox: {
    padding: "0 100px",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    marginTop: "3rem !important",

    [theme.breakpoints.down("md")]: {
      padding: "0 80px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px",
    },
  },
  aboutTitle: {
    color: "#DC2626",
    fontSize: "14px !important",
    fontWeight: "500 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    textTransform: "uppercase !important",
    // marginBottom: "8px !important",
  },
  aboutHead: {
    color: "#201F37",
    fontSize: "25px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    textTransform: "uppercase !important",
    // marginBottom: "10px !important",
  },
  aboutPara: {
    color: "#4C4B63",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    marginBottom: "18px !important",
    lineHeight: "2 !important",
  },

  // aboutBtn:{
  //   width: '100%'
  // },

  grid2: {
    margin: "auto 0 !important",
  },
  aboutImg2Box: {
    height: "auto",
    position: "relative !important",
    width: "100%",
    display: "flex !important",
    justifyContent: "center",
    aliginItems: "center !important",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      width: "80%",
      margin: "auto",
      marginTop: "1rem",
      maxHeight: "300px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "auto",
      marginTop: "1rem",
    },
  },
  homeAboutTextWrap: {
    height: "100%",
    width: "100%",
    background: "rgb(229, 229, 235, .85)",
    position: "absolute !important",
    top: 0,
    left: 0,
    right: 0,
    padding: "0 1rem !important",
    display: "flex !important",
    aliginItems: "center !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    textAlign: "center !important",
  },
  homeAboutText: {
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    fontSize: "18px !important",
    fontWeight: "700 !important",
    marginBottom: "1.8rem !important",
  },
  orText: {
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    color: "#535151",
    fontSize: "12px !important",
    fontWeight: "700 !important",
    margin: "0.3rem 0 !important  ",
  },
  aboutImg2: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

export default style;
