import { Grid } from "@mui/material";
import Vacancy from "components/local/dashboard/vacany";
import SideBar from "components/UI/SideBar/SideBar";
import React from "react";

const VacancyPage = () => {
  return (
    <Grid container sx={{ backgroundColor: "#F5F5F5" }}>
      <Grid item md={2}>
        <SideBar />
      </Grid>
      <Grid
        item
        md={10}
        sm={6}
        xs={12}
        sx={{ marginInline: { sm: "auto", md: "initial" } }}
      >
        <Vacancy />
      </Grid>
    </Grid>
  );
};

export default VacancyPage;
