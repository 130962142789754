export const POST_JOB_BEGIN = "POST_JOB_BEGIN";
export const POST_JOB_SUCCESS = "POST_JOB_SUCCESS";
export const POST_JOB_ERROR = "POST_JOB_ERROR";

export const GET_JOB_BEGIN = "GET_JOB_BEGIN";
export const GET_JOB_SUCCESS = "GET_JOB_SUCCESS";
export const GET_JOB_ERROR = "GET_JOB_ERROR";

export const PUT_JOB_BEGIN = "PUT_JOB_BEGIN";
export const PUT_JOB_SUCCESS = "PUT_JOB_SUCCESS";
export const PUT_JOB_ERROR = "PUT_JOB_ERROR";

export const DELETE_JOB_BEGIN = "DELETE_JOB_BEGIN";
export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS";
export const DELETE_JOB_ERROR = "DELETE_JOB_ERROR";

export const GET_JOB_APPLICATIONS_BEGIN = "GET_JOB_APPLICATIONS_BEGIN";
export const GET_JOB_APPLICATIONS_SUCCESS = "GET_JOB_APPLICATIONS_SUCCESS";
export const GET_JOB_APPLICATIONS_ERROR = "GET_JOB_APPLICATIONS_ERROR";
