import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  header: {
    fontWeight: "500 !important",
    fontSize: "26px !important",
    lineHeight: "1.2 !important",
    color: "#201F37",
  },
  formContainer: {
    maxWidth: "324px",
  },
  inputField: {
    marginBottom: "15px",
  },
  label: {
    fontFamily: ["Sofia sans", "sans-serif"],
    fontWeight: "500",
    display: "block",
    lineHeight: "1.3",
    marginBottom: "5px",
  },
  input: {
    padding: "10px 15px",
    fontFamily: ["Sofia sans", "sans-serif"],
    fontSize: "14px",
    lineHeight: "1.4",
    border: "solid 1px #B2B2B8",
    width: "100%",
    "&[aria-invalid = true]": {
      border: "solid 1px red",
    },
  },
  button: {
    width: "100%",
    backgroundColor: "#EF4444",
    color: "#fff",
    textAlign: "center",
    padding: "7.5px 0",
    border: "transparent",
    borderRadius: "4px",
    fontFamily: ["Sofia sans", "sans-serif"],
    fontSize: "16px",
    lineHeight: "1.2",
    fontWeight: "700",
    cursor: "pointer",
  },
  toggleIcon: {
    width: "15px !important",
    height: "15px !important",
    position: "absolute",
    right: "20px",
    top: "15px",
    color: "#6C6B80",
    cursor: "pointer",
  },
}));

export default style;
