import { Box, Typography } from "@mui/material";
import Breadcrumbs from "components/UI/Breadcrumbs";
import style from "./style";

const Banner = ({ data, title }) => {
  const classes = style();

  console.log(data?.blog_banner);

  return (
    <Box className={classes.bannerWrap}>
      <Box className={classes.bannerImgContainer}>
        <Breadcrumbs
          sx={{ position: "absolute" }}
          className={classes.breadcrumbsBox}
        />
        <Box className={classes.bannerTextWrap}>
          {title?.data?.title ? (
            <Typography className={classes.bannerText}>
              {title?.data?.title}
            </Typography>
          ) : (
            <Typography className={classes.bannerText}>{title}</Typography>
          )}
        </Box>
        {data?.about_banner ? (
          <img src={data?.about_banner} alt="" className={classes.bannerImg} />
        ) : (
          <img src={data} alt="" className={classes.bannerImg} />
        )}
      </Box>
    </Box>
  );
};

export default Banner;
