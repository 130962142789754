import PresidentSection from 'components/local/presidentSection/PresidentSection';
import React from 'react'

const PresidentSectionPage = () => {
  return (
    <>
      <PresidentSection />
    </>
  );
};

export default PresidentSectionPage;
