import { signUpApi } from "apis/signUp";
import { errorToast,successToast } from "utils/toast";
import * as actions from "./types";

export const signUpAction = (data) => (dispatch) => {
  dispatch({ type: actions.SIGNUP_PENDING });
  signUpApi(data)
    .then((response) => {
      console.log("response", response);
      dispatch({ type: actions.SIGNUP_SUCCESS });
     successToast(response.data.message) 
    })
    .catch((error) => {
      console.log("error", error);
      dispatch({ type: actions.SIGNUP_ERROR });
      errorToast(error);
    });
};
