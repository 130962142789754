import { Box, CircularProgress, Grid } from "@mui/material";
import { Container } from "@mui/system";
import BackButton from "components/global/buttons/backButton/BackButton";
// import NextButton from "components/global/buttons/prevNextButton/NextButton";
// import PrevButton from "components/global/buttons/prevNextButton/PrevButton";
import React, { useEffect } from "react";
import MainContent from "./MainContent";
import VideoList from "./VideoList";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCoursesVideo,
  getSingleCourseVideo,
} from "../courses/redux/actions";

const Video = () => {
  const { id: courseSlug } = useParams();
  const dispatch = useDispatch();
  const { coursesVideo, coursesVideoLoading, coursesSingleVideoLoading } =
    useSelector((state) => state.courses);
  const navigate = useNavigate();

  useEffect(() => {
    const params = { course_slug: courseSlug };
    dispatch(getCoursesVideo(params));
  }, [dispatch, courseSlug]);

  useEffect(() => {
    if (coursesVideo?.length) {
      dispatch(getSingleCourseVideo(coursesVideo[0]?.slug));
    }
  }, [coursesVideo, dispatch]);

  return (
    <Box sx={{ backgroundColor: "#F5F5F5" }}>
      <Container>
        <Box sx={{ pt: "17px", pb: "9px" }}>
          <BackButton onClick={() => navigate(-1)} />
        </Box>
        <Grid container spacing={3}>
          <Grid item lg={8} xs={12}>
            {coursesSingleVideoLoading ? (
              <Box
                sx={{
                  height: "55vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <MainContent />
            )}
          </Grid>
          <Grid item lg={4} xs={12}>
            <VideoList data={coursesVideo} loading={coursesVideoLoading} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Video;
