import { Navigate, Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// import { isLoggedIn } from '../../../utils';

const Protected = ({ children }) => {
  const state = useSelector((state) => state.auth);

  if (state.isUserLoggedIn) {
    return <Outlet />;
  }
  return <Navigate to="/" replace />;
};

export default Protected;

Protected.propTypes = {
  children: PropTypes.element,
};
