import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  formContainer: {
    padding: "21px 24px 24px",
    boxShadow: "0px 4px 19px rgba(0, 0, 0, 0.07)",
  },
  title: {
    fontFamily: ["Sofia sans", "sans-serif", "!important"],
    color: "#DC2626",
    fontWeight: "700 !important",
    fontSize: "18px !important",
    lineHeight: "1.3 !important",
    marginBottom: "14px !important",
  },
  bigTitle: {
    fontFamily: ["Sofia sans", "sans-serif", "!important"],
    color: "#EF4444",
    fontWeight: "800 !important",
    fontSize: "30px !important",
    lineHeight: "1 !important",
    marginBottom: "10px !important",
  },
  lightText: {
    fontFamily: ["Sofia sans", "sans-serif", "!important"],
    color: "#8D8D8D",
    fontSize: "14px !important",
    lineHeight: "1.4 !important",
    maxWidth: "412px",
  },
  inputField: {
    marginBottom: "15px",
  },
  label: {
    fontFamily: ["Sofia sans", "sans-serif"],
    fontWeight: "600",
    display: "block",
    lineHeight: "1.4",
  },
  input: {
    padding: "6px 12px",
    fontFamily: ["Sofia sans", "sans-serif"],
    fontSize: "14px",
    lineHeight: "1.4",
    width: "274px",
    "&[aria-invalid = true]": {
      border: "solid 1px red",
    },
  },
  textArea: {
    width: "100%",
    padding: "12px 17px",
    fontSize: "14px",
    height: "84px",
  },
  // button: {
  //   width: "100%",
  //   backgroundColor: "#DC2626",
  //   color: "#fff",
  //   textAlign: "center",
  //   padding: "6px 0",
  //   border: "transparent",
  //   fontFamily: ["Sofia sans", "sans-serif"],
  //   fontSize: "15px",
  //   lineHeight: "1.2",
  //   fontWeight: "700",
  //   cursor: "pointer",
  // },
  button: {
    width: "100%",
    backgroundColor: '#EF4444 !important',
    color: "#fff !important",
    textAlign: "center",
    padding: "2px 0",
    border: "transparent",
    fontFamily: ["Sofia sans", "sans-serif"],
    fontSize: "15px",
    lineHeight: "1.2",
    fontWeight: "700",
    cursor: "pointer",
  },
  buttonDisable: {
    width: "100%",
    backgroundColor: '#BFBFBF !important',
    color: "#fff !important",
    textAlign: "center",
    padding: "6px 0",
    border: "transparent",
    fontFamily: ["Sofia sans", "sans-serif"],
    fontSize: "15px",
    lineHeight: "1.2",
    fontWeight: "700",
    cursor: "pointer",
  },
}));

export default style;
