import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import backArrow from "assets/news/backArrow.png";
import facebookIcon from "assets/ourTeams/facebookIcon.png";
import instaIcon from "assets/ourTeams/instaIcon.png";
import twitterIcon from "assets/ourTeams/twitterIcon.png";
import SkeletonSliderLoader from "components/global/loader/skeletonLoader/SliderLoader";
import TeamSingleLoading from "components/global/loader/skeletonLoader/TeamSingleLoader";
import style from "components/local/ourTeams/style";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CardSlider from "./CardSlider";
import { getTeams, getTeamsSingle } from "./redux/actions";

const MemberSingle = () => {
  const pathname = window.location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const dispatch = useDispatch();

  const { slug } = useParams();

  console.log(slug);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(`/our-team`);
  };

  // team single

  const { teamsSingle, teamsSingle_loading } = useSelector(
    (state) => state.team
  );
  const [teamsSingleData, setTeamsSingleData] = useState([]);

  console.log({ teamsSingleData });

  useEffect(() => {
    teamsSingle?.data && setTeamsSingleData(teamsSingle?.data);
  }, [teamsSingle]);

  useEffect(() => {
    const data = {
      slug: slug ?? "",
    };
    dispatch(getTeamsSingle(data));
  }, [slug]);

  // get teams

  const { teams, teams_loading } = useSelector((state) => state.team);
  const [teamsData, setTeamsData] = useState([]);

  console.log({ teamsData });

  useEffect(() => {
    teams?.data && setTeamsData(teams?.data);
  }, [teams]);

  useEffect(() => {
    dispatch(getTeams());
  }, []);

  // get teams category

  const { teamsCategory, teamsCategory_loading } = useSelector(
    (state) => state.team
  );
  const [teamsCategoryData, setTeamsCategoryData] = useState([]);

  console.log({ teamsCategoryData });

  useEffect(() => {
    teamsCategory?.data && setTeamsCategoryData(teamsCategory?.data);
  }, [teamsCategory]);

  useEffect(() => {
    const data = {
      slug: slug ?? "",
    };
    dispatch(getTeamsSingle(data));
  }, [slug]);

  const getTeamMembers = (category_id) => {
    const filterData = teamsData?.our_team_data?.filter(
      (item) => item?.our_team_category?.id === category_id
    );

    console.log({ filterData });

    return filterData || [];
  };

  const classes = style();
  return (
    <Box className={classes.memberSigleBox}>
      <Container maxWidth="lg">
        <Box className={classes.singleMemberHeadding}>
          <Grid container>
            <Grid item md={2}>
              <Box className={classes.backBtnBox}>
                <button
                  onClick={goBack}
                  className={classes.backBtn}
                  style={{ textDecoration: "none" }}
                >
                  <img src={backArrow} alt="" className={classes.backArrow} />
                  Back
                </button>
              </Box>
            </Grid>
            <Grid item md={8}>
              <Box className={classes.teamHeadingBox}>
                <Typography className={classes.teamHeading}>
                  {/* Committee Members */}
                  {teamsSingleData?.our_team_category?.title}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={2}>
              {" "}
            </Grid>
          </Grid>
        </Box>

        {teamsSingle_loading ? (
          <TeamSingleLoading />
        ) : (
          <Box className={classes.memberInfoBox}>
            <Box>
              <Grid container>
                <Grid item md={3}>
                  <Box className={classes.singleMemberImgBox}>
                    <img
                      src={teamsSingleData?.image}
                      alt=""
                      className={classes.singleMemberImg}
                    />
                  </Box>
                </Grid>
                <Grid item md={9}>
                  <Box className={classes.memberInfoBox}>
                    <Box className={classes.memberName}>
                      {teamsSingleData?.member_name}
                    </Box>
                    <Box className={classes.memberPosition}>
                      {teamsSingleData?.designation}
                    </Box>
                    <Box className={classes.memberContactInfo}>
                      <Box className={classes.memberPhoneBox}>
                        <LocalPhoneOutlinedIcon
                          className={classes.memberInfoIcon}
                        />
                        <Box className={classes.memberPhone}>
                          {teamsSingleData?.phone}
                        </Box>
                      </Box>
                      <Box className={classes.memberPhoneBox}>
                        <EmailOutlinedIcon className={classes.memberInfoIcon} />
                        <Box className={classes.memberPhone}>
                          {teamsSingleData?.member_email}
                        </Box>
                      </Box>
                    </Box>
                    <Box className={classes.memberSocialLinks}>
                      {teamsSingleData?.facebook && (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={teamsSingleData?.facebook}
                        >
                          <img
                            src={facebookIcon}
                            alt=""
                            className={classes.memberSocialIcon}
                          />
                        </a>
                      )}
                      {teamsSingleData?.twitter && (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={teamsSingleData?.twitter}
                        >
                          <img
                            src={twitterIcon}
                            alt=""
                            className={classes.memberSocialIcon}
                          />
                        </a>
                      )}
                      {teamsSingleData?.instagram && (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={teamsSingleData?.instagram}
                        >
                          <img
                            src={instaIcon}
                            alt=""
                            className={classes.memberSocialIcon}
                          />
                        </a>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box className={classes.memberInfoDescription}>
              <Typography className={classes.memberInfoDescriptionTitle}>
                Description
              </Typography>
              <Typography className={classes.memberInfoDescriptionText}>
                {teamsSingleData?.description}
              </Typography>
            </Box>
          </Box>
        )}

        <Divider sx={{ marginBottom: "30px" }} />
        {teams_loading ? (
          <SkeletonSliderLoader />
        ) : (
          <Box className={classes.teamBoxContainer}>
            <Box className={classes.teamHeadingBox}>
              <Typography className={classes.teamHeading}>
                {/* Committee Members */}
                Other {teamsSingleData?.our_team_category?.title}
              </Typography>
            </Box>
            {/* <CardSlider teamsData={teamsData?.our_team_data} /> */}
            {/* <CardSlider teamsData={getTeamMembers(item?.id) || []} /> */}
            <CardSlider
              teamsData={
                getTeamMembers(teamsSingleData?.our_team_category?.id) || []
              }
            />
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default MemberSingle;
