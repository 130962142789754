import React from "react";

const Clipboard = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5324 4.12891H5.72288C5.48292 4.12891 5.25279 4.21921 5.08311 4.37996C4.91344 4.5407 4.81812 4.75872 4.81812 4.98605V14.4146C4.81812 14.6419 4.91344 14.86 5.08311 15.0207C5.25279 15.1815 5.48292 15.2718 5.72288 15.2718H14.7705C15.0105 15.2718 15.2406 15.1815 15.4103 15.0207C15.5799 14.86 15.6753 14.6419 15.6753 14.4146V4.98605C15.6753 4.75872 15.5799 4.5407 15.4103 4.37996C15.2406 4.21921 15.0105 4.12891 14.7705 4.12891H12.961"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.34197 7.55915H13.8658M9.34197 10.1306H13.8658M9.34197 12.702H13.8658M6.62769 7.55915H7.53245M6.62769 10.1306H7.53245M6.62769 12.702H7.53245M8.43721 3.27344H12.0563C12.2962 3.27344 12.5263 3.36374 12.696 3.52449C12.8657 3.68523 12.961 3.90325 12.961 4.13058C12.961 4.35791 12.8657 4.57593 12.696 4.73667C12.5263 4.89742 12.2962 4.98772 12.0563 4.98772H8.43721C8.19725 4.98772 7.96712 4.89742 7.79745 4.73667C7.62777 4.57593 7.53245 4.35791 7.53245 4.13058C7.53245 3.90325 7.62777 3.68523 7.79745 3.52449C7.96712 3.36374 8.19725 3.27344 8.43721 3.27344Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Clipboard;
