import { yupResolver } from "@hookform/resolvers/yup";
import { Avatar, Box, Button, Grid, TextField } from "@mui/material";
import Loader from "components/global/loader/Loader";
import style from "components/local/dashboard/community/style";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { getThread, postThread, putThread } from "./redux/actions";

const TextFieldForm = ({
  setThreadListData,
  commentData,
  edit,
  handleClose,
}) => {
  const classes = style();
  const dispatch = useDispatch();
  const profileState = useSelector((state) => state.profile);
  const authState = useSelector((state) => state.auth);

  console.log({ commentData });

  const { thread_loading } = useSelector((state) => state.thread);

  const validationSchema = yup.object().shape({
    title: yup.string().required("Title is required"),
    description: yup.string().required("Description is required"),
  });

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: commentData,
  });

  console.log("errrrrrrrrr", errors);
  const refetch = async () => {
    const array = ["title", "description"];
    handleClose && handleClose();
    array?.map((item) => setValue(item, ""));
    !edit && (await setThreadListData([]));
    dispatch(getThread());
  };

  const onSubmit = (values) => {
    const data = {
      ...values,
    };

    edit
      ? dispatch(putThread(data, refetch))
      : dispatch(postThread(data, refetch));
  };

  console.log({ watch: watch() });

  return (
    <Box className={classes.textFieldBox}>
      {/* <HookFormProvider>
        <HookForm> */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} md={12} className={classes.searchWrapper}>
            <TextField
              className={classes.searchField}
              id="standard-multiline-flexible"
              placeholder="Title"
              variant="standard"
              required={true}
              type="text"
              name="title"
              multiline
              // rows={1}
              control={control}
              errors={errors}
              {...register("title")}
            />
            <TextField
              className={classes.searchField}
              id="standard-multiline-flexible"
              placeholder="Write Something."
              variant="standard"
              required={true}
              type="text"
              name="description"
              multiline
              rows={4}
              control={control}
              errors={errors}
              {...register("description")}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Box className={classes.buttonBox}>
              <Box className={classes.profileBox}>
                <Box>
                  <Avatar
                    src={profileState?.data?.image}
                    alt=""
                    sx={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      objectFit: "cover",
                      background: "#EF4444",
                      color: "#fff",
                    }}
                  >
                    {profileState?.data?.image
                      ? profileState?.data?.image
                      : authState?.user?.name?.charAt(0)}
                  </Avatar>
                </Box>
                {/* <Box className={classes.profileImgBox}>
                  <img
                    src={tankaProfile}
                    alt=""
                    className={classes.profileImg}
                  />
                </Box> */}
                {/* <Typography className={classes.profileName}>
                  Tanka Jahari
                </Typography> */}
              </Box>
              <Button type="submit" className={classes.submitBtn}>
                {thread_loading ? <Loader /> : edit ? "Update" : "Submit"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
      {/* </HookForm>
      </HookFormProvider> */}
    </Box>
  );
};

export default TextFieldForm;
