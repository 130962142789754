/* Build in libraries */
import useStyles from "components/UI/Breadcrumbs/styles";

/* Third party libraries */
import { ChevronRightOutlined } from "@mui/icons-material";
import { Container } from "@mui/material";
import { Link, NavLink, useLocation } from "react-router-dom";

const JobsBreadCrumbs = ({ job }) => {
  const classes = useStyles();
  const location = useLocation();
  const locationArray = location.pathname.split("/");
  locationArray.splice(0, 1);

  const pages = [];

  let pathConstruct = "";
  locationArray.forEach((loc, index) => {
    pathConstruct += "/" + loc;
    pages.push({ name: loc, path: pathConstruct });
  });

  console.log("pages", pages);
  const disabledRoutes = ["about us"];

  return (
    <>
      {/* <nav aria-label="Breadcrumb" className={`${job} ? ${classes.navWrapperJob} : ${classes.navWrapper}`}> */}
      <nav aria-label="Breadcrumb" className={classes.navWrapperJob}>
        <Container maxWidth="lg">
          <ol>
            <li>
              <div>
                <Link to="/" style={{ textDecoration: "none" }}>
                  <span className="text">Home</span>
                </Link>
              </div>
            </li>
            {pages.map((singlePage, index) => {
              let page = {
                ...singlePage,
                name: singlePage?.name?.replaceAll("-", " "),
              };
              if (pages.length === index + 1) {
                return (
                  <li key={index}>
                    {job ? (
                      <div className="flex items-center">
                        <ChevronRightOutlined
                          sx={{ padding: "0.2rem !important", fill: "black" }}
                        />

                        <span
                          className="lastElement"
                          style={{ color: "black" }}
                        >
                          {" "}
                          {page.name}
                        </span>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <ChevronRightOutlined
                          sx={{ padding: "0.2rem !important", fill: "#fff" }}
                        />

                        <span className="lastElement"> {page.name}</span>
                      </div>
                    )}
                  </li>
                );
              }
              return (
                <li key={index}>
                  {job ? (
                    <div className="flex items-center">
                      <ChevronRightOutlined
                        sx={{ padding: "0.2rem !important", fill: "black" }}
                      />
                      <span
                        className="lastElement"
                        style={{
                          color: "black",
                          textDecoration: "none !important",
                        }}
                      >
                        <NavLink
                          to={`/jobs`}
                          style={{
                            textDecoration: "none",
                            color: "#A3A3A3",
                          }}
                        >
                          {" "}
                          {page.name}
                        </NavLink>
                      </span>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <ChevronRightOutlined
                        sx={{ padding: "0.2rem !important", fill: "black" }}
                      />
                      <span className="lastElement"> {page.name}</span>
                    </div>
                  )}
                </li>
              );
            })}
          </ol>
        </Container>
      </nav>
    </>
  );
};

export default JobsBreadCrumbs;
