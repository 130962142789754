import React from "react";

const Unlock = () => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 6.45703H3C2.44772 6.45703 2 6.94206 2 7.54036V11.332C2 11.9303 2.44772 12.4154 3 12.4154H10C10.5523 12.4154 11 11.9303 11 11.332V7.54036C11 6.94206 10.5523 6.45703 10 6.45703Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 6.45976V4.29309C3.99938 3.62145 4.22914 2.97352 4.64469 2.47508C5.06023 1.97664 5.63192 1.66325 6.24875 1.59575C6.86559 1.52825 7.48358 1.71146 7.98274 2.1098C8.4819 2.50815 8.82663 3.09322 8.95 3.75143"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Unlock;
