import * as actions from "components/local/dashboard/courses/redux/types";
const initialState = {
  courses: [],
  courses_loading: false,
  coursesSingle: [],
  coursesSingle_loading: false,
  coursesNote: [],
  coursesNote_loading: false,
  coursesSingleNote: [],
  coursesSingleNote_loading: false,
  coursesVideo: [],
  coursesVideoLoading: false,
  coursesSingleVideo: [],
  coursesSingleVideoLoading: false,
  
};

const coursesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_COURSES_BEGIN:
      return {
        ...state,
        courses_loading: true,
        coursesVideo: [],
        coursesSingleVideo: [],
      };

    case actions.GET_COURSES_SUCCESS:
      return {
        ...state,
        courses_loading: false,
        courses: action.payload,
      };

    case actions.GET_COURSES_ERROR:
      return { ...state, courses_loading: false };

    // coursesSingle

    case actions.GET_COURSES_SINGLE_BEGIN:
      return { ...state, coursesSingle_loading: true };

    case actions.GET_COURSES_SINGLE_SUCCESS:
      return {
        ...state,
        coursesSingle_loading: false,
        coursesSingle: action.payload,
      };
    case actions.GET_COURSES_SINGLE_ERROR:
      return { ...state, coursesSingle_loading: false };

    // coursesNote

    case actions.GET_COURSES_NOTE_BEGIN:
      return { ...state, coursesNote_loading: true };

    case actions.GET_COURSES_NOTE_SUCCESS:
      return {
        ...state,
        coursesNote_loading: false,
        coursesNote: action.payload,
      };
    case actions.GET_COURSES_NOTE_ERROR:
      return { ...state, coursesNote_loading: false };

    // coursesSingleNote

    case actions.GET_COURSES_NOTE_SINGLE_BEGIN:
      return { ...state, coursesSingleNote_loading: true };

    case actions.GET_COURSES_NOTE_SINGLE_SUCCESS:
      return {
        ...state,
        coursesSingleNote_loading: false,
        coursesSingleNote: action.payload,
      };
    case actions.GET_COURSES_NOTE_SINGLE_ERROR:
      return { ...state, coursesSingleNote_loading: false };

      case actions.GET_COURSES_VIDEO_BEGIN:
        return { ...state, coursesVideoLoading: true };
  
      case actions.GET_COURSES_VIDEO_SUCCESS:
        return {
          ...state,
          coursesVideoLoading: false,
          coursesVideo: action.payload,
        };
      case actions.GET_COURSES_VIDEO_ERROR:
        return { ...state, coursesVideoLoading: false };
  
      // coursesSingleVideo
  
   // coursesSingleVideo

   case actions.GET_COURSES_VIDEO_SINGLE_BEGIN:
    return {
      ...state,
      coursesSingleVideoLoading: true,
      coursesSingleVideo: [],
    };

  case actions.GET_COURSES_VIDEO_SINGLE_SUCCESS:
    return {
      ...state,
      coursesSingleVideoLoading: false,
      coursesSingleVideo: action.payload,
    };
  case actions.GET_COURSES_VIDEO_SINGLE_ERROR:
    return { ...state, coursesSingleVideoLoading: false };

    

    default:
      return state;
  }
};

export { coursesReducer };
