export const CHANGE_PASSWORD_PENDING = "CHANGE_PASSWORD_PENDING";

export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";

export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const RESET_PASSWORD_MAIL_SUCCESS = "RESET_PASSWORD_MAIL_SUCCESS";

export const RESET_PASSWORD_MAIL_PENDING = "RESET_PASSWORD_MAIL_PENDING";

export const RESET_PASSWORD_MAIL_ERROR = "RESET_PASSWORD_MAIL_ERROR";
