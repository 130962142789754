import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box, Grid, Typography } from "@mui/material";
import rightBtnArrow from "assets/news/rightBtnArrow.png";
import style from "components/common/style";
import { TimeAgo } from "components/global/date/Date";
import Description from "components/global/description/Description";
import moment from "moment";
import { useState } from "react";
import { NavLink, useParams } from "react-router-dom";

const CardMainComponent = ({ narrowCard, item, name }) => {

  const getFormattedDate = (date) => {
    const dates = new Date(date);
    return moment(dates)?.format("MMM-DD-YYYY");
  };

  const classes = style();
  const { id } = useParams();
  console.log(item);

  // const timeago = moment(item?.created_by?.created_at).fromNow();

  return (
    <Grid item lg={3} md={4} sm={6} xs={12}>
      <Box className={classes.newsSectionCardBox}>
        <NavLink
          to={`/${name}/${item?.slug}`}
          style={{ textDecoration: "none" }}
        >
          <Box>
            <Box
              className={`${
                narrowCard
                  ? classes.newsSectionImgBoxNarrow
                  : classes.newsSectionImgBox
              } `}
            >
              {/* <img src={`${item.image} && ${item.image} ||  ${item.cover_image} && ${item.cover_image}`} alt="" className={classes.newsSectionImg} /> */}
              {item?.image && (
                <img
                  src={item?.image}
                  alt=""
                  className={classes.newsSectionImg}
                />
              )}

              {item?.cover_image && (
                <img
                  src={item?.cover_image}
                  alt=""
                  className={classes.newsSectionImg}
                />
              )}
            </Box>
            <Box className={classes.newsSectionInfoWrap}>
              {item?.type && (
                <Typography className={classes.newsSectionInfoMetaTitle}>
                  {item?.type}
                </Typography>
              )}

              <Typography className={classes.newsSectionInfoTitle}>
                {item?.title}
              </Typography>
              <Typography className={classes.newsSectionSubTitle}>
                <Description length={false} description={item?.description} />
              </Typography>
            </Box>
          </Box>
          {item?.created_by?.name && item?.created_by?.updated_at && (
            <Box className={classes.bottomElement}>
              <>
                <Box className={classes.bottomNarrowElement}>
                  <Box className={classes.bottomNarrowNameSection}>
                    <Box className={classes.newsSectionName}>
                      {item?.created_by?.name}
                    </Box>
                    <Box className={classes.newsSectionInfoDateBox}>
                      <Typography className={classes.newsSectionInfoDate}>
                        
                        {getFormattedDate(item?.updated_at)}
                      </Typography>

                      <FiberManualRecordIcon
                        className={classes.dayTimeDot}
                        fontSize="small"
                      />
                      <Typography className={classes.newsSectionInfoTime}>
                        {/* {item.created_by?.updated_at} */}
                        {/* {timeago} */}
                        <TimeAgo time={item?.updated_at} />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            </Box>
          )}
          {!item?.type && (
            <>
              <Box className={classes.newsSectionBtnBox}>
                <button variant="text" className={classes.newsSectionBtn}>
                  Read more
                  <img
                    src={rightBtnArrow}
                    alt=""
                    className={classes.newsSectionBtnIcon}
                  />
                </button>


                {/* <Box className={classes.newsSectionDate}>{getFormattedDate(item.created_by?.created_at)}</Box> */}
              </Box>
            </>
          )}
        </NavLink>
      </Box>
    </Grid>
  );
};

export default CardMainComponent;
