import {
  deleteJobApi,
  getJobApi,
  getJobApplicationsApi,
  postJobApi,
  putJobApi,
} from "apis/job";
import * as actions from "components/local/dashboard/job/redux/types";
import { errorToast, successToast } from "utils/toast";

export const postJob =
  (Data, refetch = () => {}) =>
  async (dispatch) => {
    dispatch({ type: actions.POST_JOB_BEGIN });

    try {
      await postJobApi(Data);
      console.log("dataaa", Data);
      dispatch({
        type: actions.POST_JOB_SUCCESS,
        payload: "",
      });
      successToast();
      refetch && refetch();
    } catch (error) {
      dispatch({ type: actions.POST_JOB_ERROR });
      console.log(error);
      errorToast(error);
    }
  };

export const getJob = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_JOB_BEGIN });

  try {
    const { data } = await getJobApi(Data);
    dispatch({
      type: actions.GET_JOB_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_JOB_ERROR });
    console.log(error);
    errorToast(error);
  }
};

export const putJob =
  (Data, slug, refetch = () => {}) =>
  async (dispatch) => {
    dispatch({ type: actions.PUT_JOB_BEGIN });

    try {
      await putJobApi(Data, slug);
      console.log("dataaa", Data);
      refetch && refetch();
      dispatch({
        type: actions.PUT_JOB_SUCCESS,
        payload: "",
      });
      successToast();
    } catch (error) {
      dispatch({ type: actions.PUT_JOB_ERROR });
      console.log(error);
      errorToast(error);
    }
  };

export const deleteJob =
  (Data, refetch = () => {}) =>
  async (dispatch) => {
    dispatch({ type: actions.DELETE_JOB_BEGIN });

    try {
      await deleteJobApi(Data);
      console.log("dataaa", Data);
      refetch && refetch();
      dispatch({
        type: actions.DELETE_JOB_SUCCESS,
        payload: "",
      });
      successToast();
    } catch (error) {
      dispatch({ type: actions.DELETE_JOB_ERROR });
      console.log(error);
      errorToast(error);
    }
  };

export const getJobApplications = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_JOB_APPLICATIONS_BEGIN });

  try {
    const { data } = await getJobApplicationsApi(Data);
    dispatch({
      type: actions.GET_JOB_APPLICATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_JOB_APPLICATIONS_ERROR });
    console.log(error);
    errorToast(error);
  }
};
