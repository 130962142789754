import * as actions from "components/local/news/redux/types";
const initialState = {
  newsPage: [],
  news_loading: false,
};

const initialStateSingle = {
  newsSinglePage: [],
  newsSingle_loading: false,
};

const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_NEWS_BEGIN:
      return { ...state, news_loading: true };

    case actions.GET_NEWS_SUCCESS:
      return { ...state, news_loading: false, newsPage: action.payload };

    case actions.GET_NEWS_ERROR:
      return { ...state, news_loading: false };
    default:
      return state;
  }
};

const newsSingleReducer = (state = initialStateSingle, action) => {
  switch (action.type) {
    case actions.GET_NEWS_SINGLE_BEGIN:
      return { ...state, newsSingle_loading: true };

    case actions.GET_NEWS_SINGLE_SUCCESS:
      return {
        ...state,
        newsSingle_loading: false,
        newsSinglePage: action.payload,
      };

    case actions.GET_NEWS_SINGLE_ERROR:
      return { ...state, newsSingle_loading: false };
    default:
      return state;
  }
};

export { newsReducer, newsSingleReducer };
