import Gallery from "components/local/gallery";

const GalleryPage = () => {
  return (
    <div>
      <Gallery />
    </div>
  );
};

export default GalleryPage;
