import { axiosInstance } from "apis/_axios";

export const postBlogApi = (data) => {
  return axiosInstance().post(`/user-blog`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getBlogApi = (data) => {
  return axiosInstance().get(`/user-blog`, data);
};

export const deleteBlogApi = (data) => {
  return axiosInstance().delete(`/user-blog/${data}`);
};

// export const putBlogApi = (data) => {
//   return axiosInstance().put(`/user-blog/${data?.slug}`, {
//     headers: { "Content-Type": "multipart/form-data" },
//   });
// };

export const putBlogApi = (data, slug) => {
  return axiosInstance().post(`/user-blog/${slug}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
