import { Box, Grid, Typography } from "@mui/material";
import rightBtnArrow from "assets/news/rightBtnArrow.png";
import Description from "components/global/description/Description";
import { NavLink } from "react-router-dom";
import style from "./style";

const BlogsCard = ({ data }) => {
  const classes = style();

  return (
    <>
      <Grid item md={6} sm={6} xs={12} lg={4}>
        <Box className={classes.blogsCardBox}>
          <Box className={classes.blogsCardImgBox}>
            <img src={data?.image} alt="" className={classes.blogsCardImg} />
          </Box>
          <Typography className={classes.blogsCardMetaTitle}>
            Article
          </Typography>
          <Typography className={classes.blogsCardTitle}>
            {data?.title}
          </Typography>
          <Typography className={classes.blogsCardSubTitle}>
            <Description length={false} description={data?.description} />
          </Typography>
          <Box className={classes.blogsCardBtnBox}>
            <NavLink
              to={`/blogs/${data?.slug}`}
              variant="text"
              className={classes.blogsCardBtn}
              style={{ textDecoration: "none" }}
            >
              Read more
              <img
                src={rightBtnArrow}
                alt=""
                className={classes.newsSectionBtnIcon}
              />
            </NavLink>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default BlogsCard;
