import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  jobsContainer: {
    backgroundColor: "#F9FAFB",
    height: "auto",
    width: "100%",
    display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      padding: "20px 0",
    },
  },
  jobsWrap: {
    width: "100%",
  },
  jobsCardBox: {
    marginTop: "11px",
  },
  jobsTypography: {
    display: "flex",
    flexDirection: "column ",
    justifyContent: "center",
    // alignItems: "center",
    marginBottom: "5px !important",
  },
  jobsTitle: {
    color: " #000000",
    fontSize: "28px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    textTransform: "uppercase",
    marginBottom: "8px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  jobsGridSeeBtnBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
  jobsGridSeeBtn: {
    border: "none",
    color: "#4C7CE5",
    backgroundColor: "#F9FAFB",
    fontSize: "13px !important",
    fontWeight: "700 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    textTransform: "capitalized !important",
    cursor: "pointer",
  },
  newsSectionBtnIcon: {
    width: "15px",
    height: "13px",
  },
  jobsCardPaper: {
    height: "90px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    // textAlign: 'center',
    padding: "12px",
    marginBottom: "12px",
    // boxShadaow: '(0px 7px 16px rgba(18, 17, 39, 0.1))'
  },
  jobsCardPaperWrap: {},
  jobsCardImgBox: {
    height: "65px",
    width: "65px",
  },
  jobsCardImg: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  jobsCardTypography: {
    padding: "12px",
    paddingRight: "0px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    // justifyContent: 'center'
  },
  jobsCardTitle: {
    fontFamily: ["Inter", "!important"],
    color: "#121127",
    fontSize: "16px !important",
    fontWeight: "600 !important",
  },
  loactionIcon: {
    width: "8px",
    height: "10px",
    marginRight: "4px",
  },
  jobsCardHead: {
    fontFamily: ["Inter", "!important"],
    color: "rgba(18, 17, 39, 0.56)",
    fontSize: "11px !important",
    fontWeight: "400 !important",
  },
  jobsCardDot: {
    width: "8px",
    height: "8px",
    marginRight: "4px",
  },
  jobsCardSubTitle: {
    fontFamily: ["Inter", "!important"],
    color: "rgba(18, 17, 39, 0.9)",
    fontSize: "12px !important",
    fontWeight: "400 !important",
  },
}));

export default style;
