import * as actions from "./types";

const initialState = {
  status: "idle",
  resetStatus: "idle",
};

const passwordReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CHANGE_PASSWORD_PENDING:
      return { ...state, status: "pending" };
    case actions.CHANGE_PASSWORD_SUCCESS:
      return { ...state, status: "completed" };
    case actions.CHANGE_PASSWORD_ERROR:
      return { ...state, status: "failed" };
    case actions.RESET_PASSWORD_MAIL_SUCCESS:
      return { ...state, resetStatus: "completed" };
    case actions.RESET_PASSWORD_MAIL_PENDING:
      return { ...state, resetStatus: "pending" };
    case actions.RESET_PASSWORD_MAIL_ERROR:
      return { ...state, resetStatus: "failed" };
    default:
      return state;
  }
};

export default passwordReducer;
