import { Box, Grid, Typography } from "@mui/material";
import BackButton from "components/global/buttons/backButton/BackButton";
import { useNavigate } from "react-router-dom";
// import ButtonTertiary from "components/global/buttons/buttonTertiary/ButtonTertiary";
// import CourseCard from "components/global/card/courseCard/CourseCard";
// import SkeletonArticle from "components/global/loader/skeletonLoader/SkeletonArticle";
// import SkeletonLoader from "components/global/loader/skeletonLoader/SkeletonCard";
// import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { getCourses } from "./redux/actions";
import style from "./style";

const CourseLevel = () => {
  const classes = style();

  // const dispatch = useDispatch();
  const navigate = useNavigate();

  // const { courses, courses_loading } = useSelector((state) => state.courses);

  // const [coursesData, setCoursesData] = useState([]);

  // console.log({ coursesData });

  // useEffect(() => {
  //   dispatch(getCourses());
  // }, []);

  // useEffect(() => {
  //   courses?.data && setCoursesData(courses?.data);
  // }, [courses]);

  const capCourseNavigate = (value) => {
    navigate(`/dashboard/courses/cap/${value}`);
  };

  const capData = [
    { value: "cap_i", name: "Cap I" },
    { value: "cap_ii", name: "Cap II" },
    { value: "cap_iii", name: "Cap III" },
  ];

  return (
    <Box
      component="section"
      sx={{
        bgcolor: "#F5F5F5",
        padding: { xs: "50px", md: "44px 75px", lg: "44px 124px" },
        height: "100%",
      }}
    >
      
      <Box sx={{ mb: "19px" }}>
        <Typography component={"h1"} className={classes.header}>
          Choose Cap Level
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {capData?.map((item) => {
          return (
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Box
                className={classes.capBox}
                onClick={() => capCourseNavigate(item?.value)}
              >
                <Typography className={classes.capBoxText}>
                  {item?.name}
                </Typography>
              </Box>
            </Grid>
          );
        })}

        {/* {courses_loading
          ? [...Array(4)]?.map((item) => (
              <Grid item lg={3} md={4} sm={4} xs={12} mb="1rem">
                <SkeletonLoader course={true}/>
              </Grid>
            ))
          : coursesData.map((course) => {
              return (
                <Grid key={course.id} item lg={3} md={4} sm={4} xs={12}>
                  <CourseCard course={course} />
                </Grid>
              );
            })} */}
      </Grid>
      {/* <ButtonTertiary>See More</ButtonTertiary> */}
    </Box>
  );
};

export default CourseLevel;
