import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/system";

export default function SkeletonSliderLoader() {
  return (
    <Stack spacing={1}>
      {/* For variant="text", adjust the height via font-size */}
      <Box
        sx={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}
      >
        <Skeleton variant="text" width="250px" />
      </Box>
      <Box sx={{ display: "flex", gap: 10, justifyContent: "center" }}>
        <Skeleton variant="rectangular" width="200px" height="200px" />
        <Skeleton variant="rectangular" width="200px" height="200px" />
        <Skeleton variant="rectangular" width="200px" height="200px" />
        <Skeleton variant="rectangular" width="200px" height="200px" />
      </Box>
      {/* <Skeleton variant="rectangular" width="100%" />
      <Skeleton variant="rectangular" width="100%" /> */}
    </Stack>
  );
}
