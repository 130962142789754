import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  header: {
    fontWeight: "700 !important",
    fontSize: "15px !important",
    lineHeight: "1.2 !important",
    color: "#1A1717",
  },
  subHeader: {
    display: "list-item",
    fontSize: "12px !important",
    lineHeight: "1.2 !important",
    color: "#8D8888",
  },
  btnContainer: {
    paddingBlock: "12px",
    display: "flex",
    gap: "11px",
    marginInline: "auto",
    "& a": {
      textDecoration: "none",
    },
  },
  btn: {
    padding: "6px 8px !important",
    fontSize: "11px !important",
    lineHeight: "1 !important",
    color: "#CC3939 !important",
    // color: "black !important",
    border: "solid 1px #D4D4D4 !important",
    borderRadius: "3px !important",
    display: "flex",
    gap: "4px",
  },
  btnIcon: {
    color: "#CC3939 !important",
    fontSize: "13px !important",
  },
  btnSecondary: {
    padding: "6px 8px !important",
    fontSize: "11px !important",
    lineHeight: "1 !important",
    color: "#D4D4D4 !important",
    // color: "black !important",
    border: "solid 1px #D4D4D4 !important",
    borderRadius: "3px !important",
    display: "flex",
    gap: "4px",
  },
  btnIconSecondary: {
    color: "#D4D4D4 !important",
    fontSize: "13px !important",
  },
}));

export default style;
