import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  navbarContainer: {
    boxShadow: " 0px 8px 20px rgba(18, 17, 39, 0.1)",
  },
  navbarWrap: {
    display: "flex !important",
    justifyContent: "space-between !important",
    flexDirection: "row !important",
    height: "70px",
    margin: "0 auto !important",
    alignItems: "center",
  },

  navMenu: {
    height: "100%",
    // backgroundColor: "red !important",
    display: "flex",
    flexDirection: "row !important",
    gap: 2,
    justifyContent: "space-around !important",
    [theme.breakpoints.down("lg")]: {
      display: "none ",
    },
    // display: "none",
    // alignItems: "center !important",
    "& a.active": {
      // height: "100%",?
      padding: "0px 15px",
      // padding: '100% 10px',
      backgroundColor: "#EF4444",
      color: "#fff !important",
      textDecoration: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "14px !important",
      fontWeight: "400 !important",
      fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    },
    "& a:-webkit-any-link": {
      color: "#121127",
      // backgroundColor: "#fff",
      textDecoration: "none",
      padding: "0 15px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "14px !important",
      fontWeight: "400 !important",
      fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    },
  },

  navMenuMobile: {
    height: "100%",
    // backgroundColor: "red !important",
    display: "flex",
    flexDirection: "row !important",
    gap: 5,
    justifyContent: "space-around !important",
    [theme.breakpoints.down("lg")]: {
      display: "block ",
    },

    // display: "none",
    // alignItems: "center !important",
    "& a.active": {
      // height: "100%",?
      padding: "0px 5px",
      // padding: '100% 10px',
      backgroundColor: "#EF4444",
      color: "#fff !important",
      textDecoration: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "14px !important",
      fontWeight: "400 !important",
      fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    },
    "& a:-webkit-any-link": {
      color: "#121127",
      // backgroundColor: "#fff",
      textDecoration: "none",
      padding: "0 5px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "14px !important",
      fontWeight: "400 !important",
      fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    },
  },
  navLoginBtn: {
    marginLeft: "2rem",
    [theme.breakpoints.down("lg")]: {
      marginLeft: "0",
    },
  },
  hamburgerMenuIcon: {
    display: "none",
    [theme.breakpoints.down("lg")]: {
      display: "flex",
    },
  },

  // sidedrawer
  sideDrawer: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  sidebarLinks: {
    margin: "19px",
  },
  sidebarTexts: {
    fontSize: "18px !important",
    fontWeight: "400 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },
  navTrackAppLink: {
    fontSize: "18px !important",
    fontWeight: "700 !important",
    color: "#4C4B63",
    letterSpacing: "normal !important",
  },
  navBtnGroup: {
    display: "flex",
    flexDirection: "column",
    gap: 15,
  },

  sidebarClose: {
    color: "#393B3B",
    height: "38px !important",
    width: "38px !important",
    backgroundColor: "#f6f0f0",
    borderRadius: "50%",
    fontWeight: "400",
  },
}));

export default style;
