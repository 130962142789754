import { Box, Container, Grid } from "@mui/material";
import backArrow from "assets/news/backArrow.png";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCoursesNote } from "../courses/redux/actions";
import MainContent from "./MainContent";
import NoteList from "./NoteList";
import style from "./style";

const Note = () => {
  const classes = style();
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/dashboard/courses");
  };
  const dispatch = useDispatch();

  const { id } = useParams();
  const { coursesNote, coursesNote_loading } = useSelector(
    (state) => state.courses
  );

  const [coursesNoteData, setCourseNoteData] = useState([]);
  const [notesMain, setNotesMain] = useState();

  useEffect(() => {
    coursesNoteData?.length && setNotesMain(coursesNoteData?.[0]);
  }, [coursesNoteData]);

  console.log({ coursesNoteData, notesMain });

  const handleNoteLink = (data) => {
    setNotesMain(data);
  };

  useEffect(() => {
    coursesNote?.data && setCourseNoteData(coursesNote?.data);
  }, [coursesNote]);

  useEffect(() => {
    const data = {
      slug: id ?? "",
    };
    id && dispatch(getCoursesNote(data));
  }, [id]);

  return (
    <Box sx={{ backgroundColor: "#F5F5F5" }}>
      <Container>
        <Box className={classes.backBtnBox}>
          <button
            onClick={goBack}
            className={classes.backBtn}
            style={{ textDecoration: "none" }}
          >
            <img src={backArrow} alt="" className={classes.backArrow} />
            Back
          </button>
        </Box>
        {/* <Box sx={{ paddingBlock: "15px" }}>
          <BackButton click={goBack}}/>
        </Box> */}
        <Grid container spacing={3}>
          <Grid item lg={8} xs={12}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <MainContent data={notesMain} />
            </Box>

            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                pt: "13px",
                pb: "37px",
              }}
            >
              <PrevButton />
              <NextButton />
            </Box> */}
          </Grid>
          {/* {coursesNoteData?.map((data) => ( */}
          <Grid item lg={4} xs={12}>
            <NoteList
              loader={coursesNote_loading}
              data={notesMain}
              handleNoteLink={handleNoteLink}
              coursesNoteData={coursesNoteData}
            />
          </Grid>
          {/* ))} */}
        </Grid>
      </Container>
    </Box>
  );
};

export default Note;
