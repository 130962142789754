import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Banner from "components/global/banner";
import SkeletonSliderLoader from "components/global/loader/skeletonLoader/SliderLoader";
import style from "components/local/ourTeams/style";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardSlider from "./CardSlider";
import { getTeams, getTeamsCategory } from "./redux/actions";

const OurTeams = () => {
  const classes = style();

  const dispatch = useDispatch();

  // get teams

  const { teams, teams_loading } = useSelector((state) => state.team);
  const [teamsData, setTeamsData] = useState([]);

  console.log("teammsssss", { teamsData });

  useEffect(() => {
    teams?.data && setTeamsData(teams?.data);
  }, [teams]);

  useEffect(() => {
    dispatch(getTeams({ slug: teamsData?.our_team_category?.slug }));
  }, []);

  // get teams category

  const { teamsCategory, teamsCategory_loading } = useSelector(
    (state) => state.team
  );
  const [teamsCategoryData, setTeamsCategoryData] = useState([]);

  console.log("eeeeeeeewrreee", { teamsCategoryData });
  console.log({ teamsCategory });

  // const filter = new Set(
  //   teamsData.map((dataa) => {
  //     return dataa?.our_team_category?.id;
  //   })
  // );

  // console.log({ filter });

  useEffect(() => {
    teamsCategory?.data && setTeamsCategoryData(teamsCategory?.data);
  }, [teamsCategory]);

  useEffect(() => {
    dispatch(getTeamsCategory());
  }, []);
  console.log({ aaaaaaaaa: teamsData?.our_team_data });

  return (
    <>
      <Banner data={teamsCategoryData?.our_team_banner} title={"Our Teams"} />
      <Box className={classes.ourTeamsContainer}>
        <Container maxWidth="lg">
          {teams_loading && teamsCategory_loading ? (
            <SkeletonSliderLoader />
          ) : (
            teamsCategoryData?.our_team_category_data?.map((item) => {
              return (
                <OurTeamsComponent
                  key={item?.id}
                  item={item}
                  teamsData={teamsData}
                />
              );
            })
          )}
        </Container>
      </Box>
    </>
  );
};

const OurTeamsComponent = ({ item, teamsData }) => {
  const classes = style();
  const getTeamMembers = (category_id) => {
    const filterData = teamsData?.our_team_data?.filter(
      (item) => item?.our_team_category?.id === category_id
    );

    console.log({ filterData });

    return filterData || [];
  };

  return (
    <>
      {getTeamMembers(item?.id)?.length ? (
        <Box className={classes.teamBoxContainer}>
          <Box className={classes.teamHeadingBox}>
            <Typography className={classes.teamHeading}>
              {item?.title}
            </Typography>
          </Box>
          {console.log({ item })}
          <CardSlider item={item} teamsData={getTeamMembers(item?.id) || []} />
        </Box>
      ) : (
        ""
      )}
    </>
  );
};

export default OurTeams;
