import PageNotFound from "pages/404";
import { Route, Routes } from "react-router-dom";
import homeRoutes from "./homeRoutes";
import dashboardRoutes from "./dashboardRoutes";
import Protected from "components/global/protected";

const RouteList = () => {
  return (
    <Routes>
      {homeRoutes?.home?.map((item) => {
        return (
          <Route key={item?.path} path={item?.path} element={item?.component} />
        );
      })}

      {/* <Route path="/about-us">
        {homeRoutes?.aboutUs?.map((item) => (
          <Route
            key={item?.path}
            path={item?.path}
            element={item?.component}
          ></Route>
        ))}
      </Route> */}
      <Route path="/" element={<Protected />}>
        <Route path="/dashboard">
          {dashboardRoutes?.map((item) => {
            return (
              <Route
                key={item?.path}
                path={item?.path}
                element={item?.component}
              />
            );
          })}
        </Route>
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default RouteList;
