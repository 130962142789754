import { CloseOutlined } from "@mui/icons-material";
import { Box, Button, Skeleton, Typography } from "@mui/material";
import style from "components/local/dashboard/job/style";
import DashboardTitle from "components/UI/DashboardTitle";
import CustomTable from "components/UI/Table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteJob, getJob, getJobApplications } from "./redux/actions";

const Job = () => {
  const classes = style();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { job, job_loading } = useSelector((state) => state.jobDash);

  const [jobData, setJobData] = useState([]);
  const [jobApps, setJobApps] = useState([]);

  console.log("jjjjjjj", jobData?.job_data?.job?.length);
  console.log({ jobData });

  useEffect(() => {
    job?.data && setJobData(job?.data);
  }, [job]);

  useEffect(() => {
    dispatch(getJob());
  }, []);

  // get job applications

  // const { jobApplications, jobApplications_loading } = useSelector(
  //   (state) => state.jobDash
  // );

  const [jobAppData, setJobAppData] = useState([]);
// 
  // console.log({ jobAppData });

  // useEffect(() => {
  //   jobApplications?.data && setJobAppData(jobApplications?.data);
  // }, [jobApplications]);

  // useEffect(() => {
  //   dispatch(getJobApplications());
  // }, []);

  const rows = [
    {
      id: 1,
      job: "Accountant",
      location: "Ktm",
      date: "08-09-2023",
      applicant: 3,
    },
    {
      id: 2,
      job: "IT Officer",
      location: "Ktm",
      date: "08-09-2023",
      applicant: 6,
    },
  ];

  const columns = [
    { accessor: "position", label: "Job" },
    { accessor: "location", label: "Location" },
    { accessor: "created_at", label: "Date" },
    { accessor: "applicant", label: "Applicants", onClick: "" },
    { accessor: "edit", label: "Edit", onClick: "" },
    { accessor: "delete", label: "Delete", onClick: "" },
  ];
  const userRows = [
    {
      id: 1,
      name: "Jack",
      job: "Accountant",
      location: "Ktm",
      date: "08-09-2023",
      email: "jack@gmail.com",
      applicant: 3,
    },
    {
      id: 2,
      name: "Jill",
      job: "IT Officer",
      location: "Ktm",
      date: "08-09-2023",
      email: "jill@gmail.com",
      applicant: 6,
    },
  ];

  const userColumns = [
    { accessor: "name", label: "Name" },
    // { accessor: "job", label: "Job" },
    // { accessor: "location", label: "Location" },
    { accessor: "phone", label: "Phone" },
    { accessor: "email", label: "Email" },
    { accessor: "created_at", label: "Date" },
    { accessor: "cv", label: "CV", onClick: "" },
    // { accessor: "edit", label: "Edit", onClick: "" },
    // { accessor: "delete", label: "Delete", onClick: "" },
  ];

  const [showTable, setShowTable] = useState(false);

  console.log("showTable", showTable);

  const handleCvOpen = (row) => {
    setJobApps(row);
  };

  const handleOpen = (row) => {
    setShowTable(true);
    setJobApps(row);
    // dispatch(getJob());
  };

  const handleClose = () => {
    setShowTable(false);
    window.scrollTo(0, 0);
  };

  const handleEdit = (row) => {
    navigate(`/dashboard/job/${row?.slug}`, { state: row });
  };

  const refetch = () => {
    dispatch(getJob());
  };

  const handleDelete = (id) => {
    dispatch(deleteJob(id, refetch));
  };

  // const getApplicants = (id) => {
  //   const filterData = jobAppData?.filter(
  //     (item) => item?.job_applications?.job?.company?.id === id
  //   );
  //   console.log({ filterData });

  //   return filterData || [];
  // };

  // console.log({ getApplicants });

  return (
    <>
      <Box className={classes.dashboardTitleBox}>
        <DashboardTitle title={"Job"} link={"add-job"} />
      </Box>
      <Box
        component="section"
        sx={{
          bgcolor: "#F5F5F5",
          padding: { xs: "10px 10px", md: "44px 45px", lg: "44px 65px" },
          height: "auto",
        }}
      >
        {job_loading ? (
          <Skeleton variant="rectangular" width="100%" height={400} />
        ) : (
          <Box sx={{ cursor: "pointer" }}>
            <CustomTable
              // handleClick={handleClick}
              columns={columns}
              // rows={rows}
              rows={jobData?.job_data?.job}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              handleOpen={handleOpen}
              jobSlug
            />
          </Box>
        )}
        {showTable && (
          <Box className={classes.applicantTable}>
            <Box className={classes.applicantHeader}>
              <Typography className={classes.applicantTableHead}>
                Applicant's Information - <b> {jobApps?.position}</b>
              </Typography>
              <Button className={classes.closeBtn} onClick={handleClose}>
                Close
                <CloseOutlined className={classes.closeIcon} />
              </Button>
            </Box>
            <Box sx={{ cursor: "pointer" }}>
              <CustomTable
                // handleClick={handleClick}
                columns={userColumns}
                // rows={userRows}
                rows={jobApps?.job_applications || []}
                handleDelete={handleDelete}
                // data={jobApps}
                // handleEdit={handleEdit}
                // handleOpenTable={handleOpenTable}
                handleCvOpen={handleCvOpen}
                handleOpen={handleOpen}
                applicant
              />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Job;
