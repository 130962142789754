import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  dropZone: {
    height: "100px",
    border: "solid 1px #D6D5D5",
    cursor: "pointer",
    // padding: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& p": {
      fontFamily: ["Sofia Sans", "sans-serif"],
      fontSize: "13px",
      color: "#99999E",
    },
  },
  dropZoneImage: {
    height: "180px",
    border: "solid 1px #D6D5D5",
    cursor: "pointer",
    padding: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& p": {
      fontFamily: ["Sofia Sans", "sans-serif"],
      fontSize: "13px",
      color: "#99999E",
    },
  },
  fileTextBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column-reverse",
    color: "#99999E",
  },
  dragDropLabel: {
    color: "#383751",
    fontWeight: "700 !important",
    fontSize: "14px",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },

  // image view btn
  imageViewBtnBox: {
    order: 2,
  },

  imageViewBtn: {
    padding: "0px !important",
    color: "#1B55E2 !important",
    fontSize: "14px !important",
    display: "flex",
    flexDirection: "space-between",
  },
  imageViewLabelBox:{
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export default useStyles;
