import MemberSingle from 'components/local/ourTeams/MemberSingle';
import React from 'react'

const OurTeamSinglePage = () => {
  return (
    <div>
      <MemberSingle />
    </div>
  );
};

export default OurTeamSinglePage;
