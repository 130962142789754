import { getAlbumApi, getAlbumSingleApi } from "apis";
import * as actions from "components/local/gallery/redux/types";

export const getAlbum = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_ALBUM_BEGIN });

  try {
    const { data } = await getAlbumApi(Data);
    dispatch({
      type: actions.GET_ALBUM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_ALBUM_ERROR });
    console.log(error);
  }
};

export const getAlbumSingle= (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_ALBUM_SINGLE_BEGIN });

  try {
    const { data } = await getAlbumSingleApi(Data);
    dispatch({
      type: actions.GET_ALBUM_SINGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_ALBUM_SINGLE_ERROR });
    console.log(error);
  }
};
