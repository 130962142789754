import { applyMiddleware, legacy_createStore as createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import ReduxThunk from "redux-thunk";
import rootReducer from "./rootReducer";

const Store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(ReduxThunk))
);
  
export default Store;
