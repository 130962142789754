import { Close } from "@mui/icons-material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box } from "@mui/system";
import style from "./style";

const ImageModal = ({ image, setImage, data }) => {
  const classes = style();
  console.log({ image, data });
  const handleNext = () => {
    const findData = data?.find((row, INDEX) => image?.index + 1 === INDEX);
    console.log({ findData });
    setImage({ index: image?.index + 1, ...findData });
  };
  const handlePrev = () => {
    const findData = data?.find((row, INDEX) => image?.index - 1 === INDEX);
    console.log({ findData });
    setImage({ index: image?.index - 1, ...findData });
  };
  return (
    <Box
      sx={{
        position: "fixed",
        backgroundColor: "rgba(0,0,0,0.8)",
        width: "100%",
        height: "100%",
        zIndex: "20",
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 10px",
        // position: "fixed",
        // zIndex: "1300",
        // inset: "0px",
      }}
      // onClick={() => setImage({})}
    >
      <Box
        sx={{
          position: "fixed",
          display: "flex",
          "-webkitBox-align": "center",
          alignItems: "center",
          "-webkit-box-pack": "center",
          justifyContent: "center",
          inset: "0px",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          "-webkit-tap-highlight-color": "transparent",
          zIndex: "-1",
        }}
      >
        <img
          src={image?.image}
          alt=""
          style={{
            aspectRatio: "16/9",
            objectFit: "cover",
            maxHeight: "500px",
            width: "70%",
            // width: {xs: '90%', md: '50%'}
          }}
          onClick={(e) => e.stopPropagation()}
        />
        <Close
          sx={{
            position: "absolute",
            top: "5%",
            right: "5%",
            color: "#fff",
            cursor: "pointer",
          }}
          fontSize="large"
          onClick={() => setImage({})}
        />
        {image?.index !== 0 && (
          <Box onClick={() => handlePrev()}>
            <KeyboardArrowLeftIcon className={classes.arrowIconLeft} />
          </Box>
        )}
        {data?.length - 1 !== image?.index && (
          <Box onClick={() => handleNext()}>
            <KeyboardArrowRightIcon className={classes.arrowIconRight} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ImageModal;
