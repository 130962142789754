import { Box, Container, Grid, Typography } from "@mui/material";
import backArrow from "assets/news/backArrow.png";
import SingleCardArticle from "components/common/singlePageSection/SingleCardArticle";
import SingleCardMain from "components/common/singlePageSection/SingleCardMain";
import SkeletonArticle from "components/global/loader/skeletonLoader/SkeletonArticle";
import SkeletonSingleArticle from "components/global/loader/skeletonLoader/SkeletonSingleArticle";
import {
  getNewspage,
  getNewsSinglepage,
} from "components/local/news/redux/actions";
import style from "components/local/news/style";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const NewsSingle = ({ bannerData }) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const classes = style();
  const { slug } = useParams();
  console.log(slug);

  const pathname = window.location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // const handleBack = () => {
  //   setSingleNews(!singleNews);
  // };

  const { newsSinglePage, newsSingle_loading } = useSelector(
    (state) => state.newsSingle
  );
  const [newsSingleData, setNewsSingleData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    newsSinglePage?.data && setNewsSingleData(newsSinglePage?.data);
  }, [newsSinglePage]);

  console.log({ newsSingleData });
  
  useEffect(() => {
    const data = {
      slug: slug ?? "",
    };
    dispatch(getNewsSinglepage(data));
  }, [slug]);

  // articles data

  const { newsPage, news_loading } = useSelector((state) => state.news);
  const [newsData, setNewsData] = useState([]);

  console.log(newsData?.news_data?.news);

  useEffect(() => {
    newsPage?.data && setNewsData(newsPage?.data);
  }, [newsPage]);

  useEffect(() => {
    dispatch(getNewspage());
  }, []);

  return (
    <Box className={classes.newsSingleContainer}>
      <Container maxWidth="lg">
        <Box className={classes.newsSingleWrap}>
          <Box className={classes.backBtnBox}>
            <button
              onClick={goBack}
              className={classes.backBtn}
              style={{ textDecoration: "none" }}
            >
              <img src={backArrow} alt="" className={classes.backArrow} />
              Back
            </button>
          </Box>
          <Grid container>
            <Grid item md={8} xs={12}>
              {news_loading ? (
                <SkeletonSingleArticle image={true} />
              ) : (
                <SingleCardMain time={false} data={newsSingleData} />
              )}
            </Grid>
            <Grid item md={4} xs={12}>
              <Box className={classes.newsMoreSectionWrap}>
                <Box className={classes.newsMoreSection}>
                  <Typography className={classes.newsMoreSectionTitle}>
                    See more news here
                  </Typography>
                  {/* )} */}
                  <Grid container spacing={1}>
                    {news_loading
                      ? [...Array(3)]?.map((item) => (
                          <Grid item xs={12} sm={6} md={12} mb="1rem">
                            <SkeletonArticle image={true} />
                          </Grid>
                        ))
                      : newsData?.news_data?.news?.slice(0, 5)?.map((item) => (
                          <>
                            <SingleCardArticle
                              data={item}
                              key={item.id}
                              name={"news"}
                            />
                          </>
                        ))}
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default NewsSingle;
