import Blogs from 'components/local/blogs'
import React from 'react'

const BlogPage = () => {
  return (
    <>
      <Blogs />
    </>
  );
};

export default BlogPage;
