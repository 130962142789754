import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  btnPrimary: {
    padding: "3px 12px !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    backgroundColor: "#fff !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center !important",
    gap: 8,
    color: "#DC2626 !important",
    fontSize: "14px !important",
    fontWeight: "600 !Important",
    borderRadius: "0px !important",
    width: "150px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
      width: "auto",
      height: "auto",
    },
  },
  btnPrimaryBorder: {
    padding: "3px 10px !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    backgroundColor: "#fff !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center !important",
    gap: 8,
    color: "#DC2626 !important",
    fontSize: "14px !important",
    fontWeight: "600 !Important",
    borderRadius: "0px !important",
    border: "1px solid #DC2626 !important",
    width: "30% !important",
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px !important",
      width: "40% !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "12px !important",
      width: "25% !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
      width: "45% !important",
    },
  },
  rightArrow: {
    height: "9px !important",
    width: "11px !important",
    [theme.breakpoints.down("sm")]: {
      height: "7px !important",
      width: "9px !important",
    },
  },
}));

export default style;
