import { Grid } from "@mui/material";
import { Box, Container } from "@mui/system";
import KnowMoreCard from "components/global/card/knowMore/KnowMoreCard";
import JobsBreadCrumbs from "components/UI/JobsBreadCrumbs/JobsBreadCrumbs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getJobsSinglepage } from "../jobs/redux/actions";
import ApplicationForm from "./ApplicationForm";
import Content from "./Content";
import Header from "./Header";

const JobsSingle = () => {
  const { slug } = useParams();
  console.log({ slug });
  const authState = useSelector((state) => state.auth);
  const { jobs_loading } = useSelector((state) => state.jobs);

  console.log("dsadasdasweq",{authState})

  const pathname = window.location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  

  // const handleBack = () => {
  //   setSingleNews(!singleNews);
  // };

  const { jobsSinglePage, jobsSingle_loading } = useSelector(
    (state) => state.jobSingle
  );

  console.log({ jobsSingle_loading });
  const [jobsSingleData, setJobsSingleData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    jobsSinglePage?.data && setJobsSingleData(jobsSinglePage?.data);
  }, [jobsSinglePage]);

  console.log({ jobsSinglePage });

  useEffect(() => {
    const data = {
      slug: slug ?? "",
    };
    dispatch(getJobsSinglepage(data));
  }, [slug]);

  const breadcrumbData = [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "Jobs",
      link: "",
    },
  ];

  const { user } = useSelector((state) => state.auth);

  return (
    <div>
      <Container maxWidth="lg" sx={{ paddingBlock: "25px" }}>
        {/* <Box className="breadcrumb breadcrumb-secondary"> */}
        {/* {breadcrumbData &&
            breadcrumbData.map((item, index) => {
              return (
                <li key={index}>
                  {item.link ? (
                    <Link to={item.link}>{item.title}</Link>
                  ) : (
                    item.title
                  )}
                </li>
              );
            })} */}
        {/* <Banner /> */}

        <Box>
          <JobsBreadCrumbs job sx={{ color: "black" }} />
          {/* <Breadcrumbs /> */}
        </Box>
        {/* </Box> */}
        <Grid
          sx={{ marginTop: "1rem" }}
          container
          spacing={5}
          component={"section"}
        >
          <Grid item md={8}>
            <Header data={jobsSingleData} />
            <Content data={jobsSingleData} />
          </Grid>
          <Grid
            item
            lg={4}
            md={5}
            sm={7}
            sx={{ marginInline: { xs: "auto", md: "0" } }}
          >
            {authState.isUserLoggedIn ? (
              <ApplicationForm />
            ) : (
              <KnowMoreCard title={"Want to apply for job?"} />
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default JobsSingle;
