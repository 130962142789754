import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  sidebar: {
    "& a.active": {
      backgroundColor: "#EF4444",
      color: "#fff !important",
      fontWeight: "700 !important",
      "& svg": {
        "& path": {
          stroke: "#fff",
        },
      },
    },
    "& a:-webkit-any-link": {
      color: "#121127",
      textDecoration: "none",
      padding: "8px 15px",
      display: "flex",
      gap: "7px",
      // justifyContent: "center",
      alignItems: "center",
      fontSize: "14px !important",
    },
  },
}));

export default style;
