import { Box, Container, Grid, Typography } from "@mui/material";
import backArrow from "assets/news/backArrow.png";
import SingleCardArticle from "components/common/singlePageSection/SingleCardArticle";
import SingleCardMain from "components/common/singlePageSection/SingleCardMain";
import SkeletonArticle from "components/global/loader/skeletonLoader/SkeletonArticle";
import SkeletonSingleArticle from "components/global/loader/skeletonLoader/SkeletonSingleArticle";
import style from "components/local/news/style";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getPublicatioinSinglepage,
  getPublicatioinspage,
} from "../redux/actions";

const PublicationsSingle = ({ setSingleNews, singleNews }) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const classes = style();
  const { slug } = useParams();
  console.log(slug);

  const pathname = window.location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // const handleBack = () => {
  //   setSingleNews(!singleNews);
  // };

  const { publicationsSinglePage, publicationsSingle_loading } = useSelector(
    (state) => state.publicationsSingle
  );
  const [publicationsSingleData, setPublicationsSingleData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    publicationsSinglePage?.data &&
      setPublicationsSingleData(publicationsSinglePage?.data);
  }, [publicationsSinglePage]);

  console.log({ publicationsSingleData });

  useEffect(() => {
    const data = {
      slug: slug ?? "",
    };
    dispatch(getPublicatioinSinglepage(data));
  }, [slug]);

  // articles data

  const { publicationsPage, publications_loading } = useSelector(
    (state) => state.publications
  );
  const [publicationsData, setPublicationsData] = useState([]);

  console.log({publicationsData});

  useEffect(() => {
    publicationsPage?.data && setPublicationsData(publicationsPage?.data);
  }, [publicationsPage]);

  useEffect(() => {
    dispatch(getPublicatioinspage());
  }, []);

  return (
    <Box className={classes.newsSingleContainer}>
      <Container maxWidth="lg">
        <Box className={classes.newsSingleWrap}>
          <Box className={classes.backBtnBox}>
            <button
              onClick={goBack}
              className={classes.backBtn}
              style={{ textDecoration: "none" }}
            >
              <img src={backArrow} alt="" className={classes.backArrow} />
              Back
            </button>
          </Box>
          <Grid container>
            <Grid item md={8} xs={12}>
              {publications_loading ? (
                <SkeletonSingleArticle image={false} />
              ) : (
                <SingleCardMain data={publicationsSingleData} />
              )}
            </Grid>
            <Grid item md={4} xs={12}>
              <Box className={classes.newsMoreSectionWrap}>
                <Box className={classes.newsMoreSection}>
                  {/*  */}
                  <Typography className={classes.sectionTitlePublication}>
                    Top Publicaitons
                  </Typography>
                  {/* )} */}
                  <Grid container spacing={1}>
                    {publications_loading
                      ? [...Array(3)]?.map((item) => (
                          <Grid item xs={12} sm={6} md={12} mb="1rem">
                            <SkeletonArticle image={false} />
                          </Grid>
                        ))
                      : publicationsData?.magazine_data?.magazine?.slice(0,5)?.map(
                          (item) => (
                            <>
                              <SingleCardArticle
                                data={item}
                                key={item.id}
                                name={"publications"}
                              />
                            </>
                          )
                        )}
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default PublicationsSingle;
