import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  ourTeamsContainer: {
    padding: "40px 0",
  },
  teamHeadingBox: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  teamHeading: {
    fontSize: "27px !important",
    fontWeight: "700 !important",
    marginBottom: "20px !important",
  },

  // team card
  teamBoxContainer: {
    marginBottom: "40px",
    width: "100%",
  },
  teamCardWrap: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px !important",
    marginTop: "20px !important",
    cursor: "pointer",
  },
  teamCardContainer: {
    boxShadow: " 0px 8px 20px rgba(18, 17, 39, 0.1)",
  },
  cardImgBox: {
    width: "210px",
    height: "170px",
  },
  cardImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  textBox: {
    padding: "5px 15px",
  },
  teamName: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#2E2F34",
  },
  teamPosition: {
    fontSize: "11px",
    fontWeight: "500",
    color: "#848793",
  },
  teamPhone: {
    fontSize: "12px",
    fontWeight: "500",
    color: "#848793",
  },
  teamPhoneSpan: {
    color: "#4A4C53",
  },

  // committee size 2

  cardImgBox2: {
    width: "320px",
    height: "290px",
  },
  teamName2: {
    fontSize: "20px",
    fontWeight: "600",
    color: "#2E2F34",
  },
  teamPosition2: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#848793",
  },
  teamPhone2: {
    fontSize: "15px",
    fontWeight: "500",
    color: "#848793",
  },
  // committee size 3

  cardImgBox3: {
    width: "260px",
    height: "220px",
  },
  teamName3: {
    fontSize: "18px",
    fontWeight: "600",
    color: "#2E2F34",
  },
  teamPosition3: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#848793",
  },
  teamPhone3: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#848793",
  },

  // card slider

  arrowIconLeft: {
    left: "-4%",
    display: "block",
    // background: "red",
    border: "1px solid #D1D1DB",
    position: "absolute",
    zIndex: 10,
    borderRadius: "50%",
    top: "40%",
    bottom: "50%",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "none !important",
    },
  },
  arrowIconRight: {
    right: "-4%",
    display: "block",
    // background: "red",
    border: "1px solid #D1D1DB",
    position: "absolute",
    zIndex: 10,
    borderRadius: "50%",
    top: "40%",
    bottom: "50%",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "none !important",
    },
  },

  // single member

  memberSigleBox: {
    padding: "40px 0",
  },

  singleMemberHeadding: {
    alignItems: "center",
  },

  memberInfoBox: {
    marginBottom: "40px",
  },

  singleMemberImgBox: {
    width: "195px",
    height: "170px",
  },
  singleMemberImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },

  memberName: {
    fontSize: "18px",
    fontWeight: "600",
    color: "#121127",
  },
  memberPosition: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#848793",
  },
  memberContactInfo: {
    marginTop: "15px",
  },
  memberPhoneBox: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#4A4C53",
    display: "flex",
    alignItems: "center",
  },
  memberInfoIcon: {
    color: "#6C6B80",
    width: "15px !important",
    height: "15px !important",
    marginRight: "10px !important",
  },
  memberSocialLinks: {
    marginTop: "35px",
    display: "flex",
    gap: 20,
  },
  memberSocialIcon: {
    cursor: "pointer",
    width: "15px",
    height: "15px",
  },
  memberInfoDescription: {
    marginTop: "15px",
  },
  memberInfoDescriptionTitle: {
    fontSize: "17px !important",
    fontWeight: "600 !important",
    color: "#201F37 ",
  },
  memberInfoDescriptionText: {
    fontSize: "15px !important",
    fontWeight: "400 !important",
    color: "#201F37",
    marginTop: "10px !important",
  },

  // back btn

  backBtnBox: {
    marginBottom: "20px",
  },
  backBtn: {
    border: "none",
    backgroundColor: "rgb(255, 255, 255, .0)",
    display: "flex",
    alignItems: "center",
    gap: 3,
    padding: "5px",
    cursor: "pointer",
    color: "#858689",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
  },
  backArrow: {
    width: "16px",
    height: "16px",
  },
}));

export default style;
