export const POST_BLOG_BEGIN = "POST_BLOG_BEGIN";
export const POST_BLOG_SUCCESS = "POST_BLOG_SUCCESS";
export const POST_BLOG_ERROR = "POST_BLOG_ERROR";

export const GET_BLOG_BEGIN = "GET_BLOG_BEGIN";
export const GET_BLOG_SUCCESS = "GET_BLOG_SUCCESS";
export const GET_BLOG_ERROR = "GET_BLOG_ERROR";

export const PUT_BLOG_BEGIN = "PUT_BLOG_BEGIN";
export const PUT_BLOG_SUCCESS = "PUT_BLOG_SUCCESS";
export const PUT_BLOG_ERROR = "PUT_BLOG_ERROR";

export const DELETE_BLOG_BEGIN = "DELETE_BLOG_BEGIN";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";
export const DELETE_BLOG_ERROR = "DELETE_BLOG_ERROR";

export const GET_BLOG_APPLICATIONS_BEGIN = "GET_BLOG_APPLICATIONS_BEGIN";
export const GET_BLOG_APPLICATIONS_SUCCESS = "GET_BLOG_APPLICATIONS_SUCCESS";
export const GET_BLOG_APPLICATIONS_ERROR = "GET_BLOG_APPLICATIONS_ERROR";
