import moment from "moment";
import { useMemo } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentDate } from "redux/events/actions";
import "themes/index.scss";
import styles from "./styles";

const Calendar = ({ style, eventData }) => {
  const classes = styles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { event_loading } = useSelector((state) => state.event);

  console.log("sddsawrewreeeee", { event_loading });

  // get highlightDates array made up of instance of Date object
  const highlightDates = useMemo(() => {
    return eventData?.currentMonthEvent?.map((item) => new Date(item?.date));
  }, [eventData]);
  const findEvent = (date) => {
    const data = eventData?.currentMonthEvent?.find(
      (item) =>
        moment(date)?.format("DD-MM-YYYY") ===
        moment(item?.date)?.format("DD-MM-YYYY")
    );
    return data;
  };
  const handleNavigate = (date) => {
    findEvent(date)?.id && navigate(`/events/${findEvent(date)?.id}`);
  };
  const renderDayContents = (day, date) => {
    // console.log({ date });
    const tooltipText = `${findEvent(date)?.title ?? ""}`;
    return (
      <div onClick={() => handleNavigate(date)} title={tooltipText}>
        {moment(date)?.format("D")}
      </div>
    );
  };
  return (
    <>
      <div style={style} className={classes.calanderBox}>
        <ReactDatePicker
          highlightDates={highlightDates}
          inline
          disabledKeyboardNavigation
          renderDayContents={renderDayContents}
          onMonthChange={(date) => dispatch(setCurrentDate(date))}
        />
      </div>
    </>
  );
};

export default Calendar;
