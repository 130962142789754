import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Paper } from "@mui/material";
import backArrow from "assets/news/backArrow.png";
import CustomEditor from "components/global/customEditor";
import Loader from "components/global/loader/Loader";
import style from "components/local/dashboard/blog/style";
import DropZone from "components/UI/FileDrop/DropZone";
import { HookForm } from "components/UI/Form/HookForm";
import { HookFormInput } from "components/UI/Form/HookFormInput";
import { HookFormProvider } from "components/UI/Form/HookFormProvider";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { postBlog, putBlog } from "./redux/actions";

const BlogAdd = ({ props, edit }) => {
  const classes = style();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const dispatch = useDispatch();
  const { slug } = useParams();
  const [file, setFile] = useState();
  const { blog_loading } = useSelector((state) => state.blogDash);

  console.log({ filesssssssssssssssss: file });
  const validationSchema = yup.object().shape({
    title: yup.string().required("Title is required"),
    // status: yup.string().required("Status is required"),
    description: yup.string().required("Description is required"),
    // resume: yup.string().required("Resume is required"),
    image: !edit && yup.mixed().required("File is required"),
  });

  const defaultValues = {
    ...props,
  };

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  console.log("errrrrrrrrr", errors);
  console.log("defaultValues", defaultValues);

  const refetch = () => {
    const array = ["title", "image", "description"];
    array?.map((item) => setValue(item, ""));
    reset();
    // edit && dispatch(getBlog());
    navigate(`/dashboard/blog`);
  };

  const onSubmit = (data) => {
    const values = {
      ...data,
      image: data?.image,
      delete_file: edit && 1,
    };
    console.log({ values });
    const formData = new FormData();
    Object.keys(values)?.map((keys) => formData.append(keys, values[keys]));
    edit && formData.append("_method", "put");
    // formData.append("cv", values.cv);
    for (const value of formData.values()) {
      console.log("Hello", value);
    }
    // refetch();
    edit
      ? dispatch(putBlog(formData, slug, refetch))
      : dispatch(postBlog(formData, refetch));
  };

  console.log({ watch: watch() });

  return (
    <Box className={classes.dashboardTitleBox}>
      <Box className={classes.backBtnBox}>
        <button
          onClick={goBack}
          className={classes.backBtn}
          style={{ textDecoration: "none" }}
        >
          <img src={backArrow} alt="" className={classes.backArrow} />
          Back
        </button>
      </Box>
      <Paper elevation={0} className={classes.addForm}>
        <Box>
          <HookFormProvider>
            <HookForm onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <HookFormInput
                    // required={true}
                    type="text"
                    name="title"
                    label="Title"
                    placeholder="Title"
                    control={control}
                    errors={errors}
                  />
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <HookFormInput
                    // required={true}
                    type="text"
                    name="status"
                    label="Status"
                    placeholder="Status"
                    control={control}
                    errors={errors}
                  />
                </Grid> */}

                {/* <Grid item xs={12} md={6}>
                  <HookFormInput
                    required={true}
                    type="text"
                    name="description"
                    label="Desctiption"
                    placeholder="Desctiption"
                    control={control}
                    errors={errors}
                    multiline
                    rows={4}
                    sx={{
                      "& .css-17cvodc-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          height: "82px",
                        },
                    }}
                  />
                </Grid> */}
                <Grid item xs={12} md={6}>
                  <CustomEditor
                    watch={watch}
                    setValue={setValue}
                    name="description"
                    errors={errors}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DropZone
                    watch={watch}
                    setValue={setValue}
                    name="image"
                    errors={errors}
                    key={"image"}
                    image
                    link={
                      watch()?.image?.name
                        ? watch()?.image?.name &&
                          URL.createObjectURL(watch()?.image)
                        : defaultValues?.image
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box className={classes.submitButton}>
                    <Button className={classes.formSubmitBtn} type="submit">
                      {blog_loading ? <Loader /> : edit ? "Update" : "Submit"}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </HookForm>
          </HookFormProvider>
        </Box>
      </Paper>
    </Box>
  );
};

export default BlogAdd;
