import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function SkeletonLoaderTernory() {
  return (
    <Stack spacing={1} >
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="rectangular" width='100%' height={240} />
      <Skeleton variant="text" width='50%' />
      <Skeleton variant="text" width='100%' />
      <Skeleton variant="text" width='100%' />

      {/* For other variants, adjust the size with `width` and `height` */}
      {/* <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rounded" width={210} height={60} /> */}
    </Stack>
  );
}
