import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  logoBox: {
    // height: "100%",
    display: "flex",
    alignItems: "center",
  },
  logo: {
    width: "52px",
    height: "52px",
    objectFit: "contain",
  },
  logoWrap: {
    position: "relative",
  },
  beta: {
    top: "0",
    position: "absolute",
    right: "-21px",
    fontSize: "14px",
    color: "#ef4444",
    borderRadius: "4px",
    padding: "0 4px",
  },
  betaFooter: {
    top: "0",
    position: "absolute",
    left: "40px",
    fontSize: "14px",
    color: "#ef4444",
    borderRadius: "4px",
    padding: "0 4px",
  },
}));

export default style;
