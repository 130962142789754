import Home from "components/local/home";

const Homepage = () => {
  return (
    <>
      <Home />
    </>
  );
};

export default Homepage;
