import * as actions from "components/local/volunteer/redux/types";
const initialState = {
  volunteer: [],
  volunteer_loading: false,
  postVolunteer_loading: false,
};

const volunteerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.POST_VOLUNTEER_BEGIN:
      return { ...state, postVolunteer_loading: true };

    case actions.POST_VOLUNTEER_SUCCESS:
      return { ...state, postVolunteer_loading: false };

    case actions.POST_VOLUNTEER_ERROR:
      return { ...state, postVolunteer_loading: false };

    // get volunteer

    case actions.GET_VOLUNTEER_BEGIN:
      return { ...state, volunteer_loading: true };

    case actions.GET_VOLUNTEER_SUCCESS:
      return { ...state, volunteer_loading: false, volunteer: action.payload };

    case actions.GET_VOLUNTEER_ERROR:
      return { ...state, volunteer_loading: false };
    default:
      return state;
  }
};

export default volunteerReducer;
