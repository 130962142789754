import { Box } from "@mui/material";
import facebook from "../../../assets/home/facebook.png";
import instagram from "../../../assets/home/instagram.png";
import twitter from "../../../assets/home/twitter.png";
import style from "./style";

const SocialLinks = ({ data }) => {
  const classes = style();
  return (
    <Box className={classes.SocialLinksContainer}>
      <a href={data?.facebook} target="_blank" rel="noreferrer">
        <img src={facebook} alt="" className={classes.socialIcons} />
      </a>
      <a href={data?.linkedin} target="_blank" rel="noreferrer">
        <img src={twitter} alt="" className={classes.socialIcons} />
      </a>
      <a href={data?.instagram} target="_blank" rel="noreferrer">
        <img src={instagram} alt="" className={classes.socialIcons} />
      </a>
    </Box>
  );
};

export default SocialLinks;
