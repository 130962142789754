import { Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import style from "./style";
// import eventImage from "assets/event/event-detail.png";
import clockIcon from "assets/event/clock.svg";
import location from "assets/event/location.svg";
import backArrow from "assets/news/backArrow.png";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const EventInfo = () => {
  const eventData = useSelector((state) => state.event.singleEventData);
  const classes = style();
  const navigate = useNavigate();
  // console.log("evetn data", eventData);
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Box className={classes.backBtnBox}>
        <button
          onClick={goBack}
          className={classes.backBtn}
          style={{ textDecoration: "none" }}
        >
          <img src={backArrow} alt="" className={classes.backArrow} />
          Back
        </button>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "34px",
          flexDirection: { sm: "row", xs: "column" },
        }}
      >
        <Box className={classes.imageContainer}>
          {eventData ? (
            <img
              src={eventData?.banner_image}
              alt=""
              style={{ width: "100%", height: "auto" }}
            />
          ) : (
            <Skeleton variant="rectangular" width={"100%"} height="100%" />
          )}
        </Box>
        <Box sx={{ maxWidth: "544px" }}>
          <Box sx={{ marginBottom: "20px" }}>
            {eventData.title ? (
              <Typography className={classes.header} component="h1">
                {eventData?.title}
              </Typography>
            ) : (
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            )}
          </Box>
          <Box sx={{ marginBottom: "23px" }}>
            {eventData.time ? (
              <Box sx={{ display: "flex", gap: "8px", marginBottom: "6px" }}>
                <img src={clockIcon} alt="" />
                <Typography className={classes.text}>
                  {moment(eventData?.time, ["HH:mm"]).format("hh:mm a")}
                </Typography>
              </Box>
            ) : (
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            )}
            {eventData?.venue ? (
              <Box sx={{ display: "flex", gap: "8px", marginBottom: "6px" }}>
                <img src={location} alt="" />
                <Typography className={classes.text}>
                  {`${eventData?.venue}, ${eventData?.location}`}
                </Typography>
              </Box>
            ) : (
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            )}
          </Box>
          <Box sx={{ marginBottom: "23px" }}>
            {eventData?.venue ? (
              <Box sx={{ display: "flex" }}>
                <Typography
                  className={classes.textSmall}
                  sx={{ fontWeight: "700 !important", width: "50px" }}
                >
                  Venue
                </Typography>
                <Typography
                  className={classes.textSmall}
                >{`: ${eventData?.venue}`}</Typography>
              </Box>
            ) : (
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "200px" }}
              />
            )}

            {eventData.date ? (
              <Box sx={{ display: "flex" }}>
                <Typography
                  className={classes.textSmall}
                  sx={{ fontWeight: "700 !important" }}
                >
                  Date
                </Typography>
                <Typography className={classes.textSmall}>{`: ${new Date(
                  eventData?.date
                ).toLocaleString("en-UK", {
                  dateStyle: "medium",
                })}`}</Typography>
              </Box>
            ) : (
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            )}
            {eventData.event_status ? (
              <Box sx={{ display: "flex" }}>
                <Typography
                  className={classes.textSmall}
                  sx={{ fontWeight: "700 !important" }}
                >
                  Event Status:
                </Typography>
                {/* <Typography className={classes.textSmall}> {?.event_status}</Typography> */}
                <Typography
                  className={classes.status}
                  sx={{
                    marginLeft: "5px",
                    color:
                      eventData.event_status === "Closed"
                        ? "#FF1818"
                        : eventData.event_status === "Open"
                        ? "#2DB83E"
                        : eventData.event_status === "Upcoming"
                        ? "#3893D0"
                        : "#3893D1",
                  }}
                >
                  {eventData?.event_status}
                </Typography>
              </Box>
            ) : (
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            )}
          </Box>

          {eventData.description ? (
            <Box
              dangerouslySetInnerHTML={{ __html: eventData?.description }}
            ></Box>
          ) : (
            <Skeleton variant="rectangular" width={500} height={200} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default EventInfo;
