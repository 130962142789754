import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  contentContainer: {
    padding: "19px 24.5px",
    // borderRadius: "20px",
    // background: "#fff",
    // border: "1px solid #F1F0F0",
    "& iframe": {
      width: "100%",
      height: "auto",
      aspectRatio: "16/9",
      border: "none",
    },
  },
  // imageContainer: {
  //   maxWidth: "718px",
  //   marginBottom: "22px",
  //   "& img": {
  //     width: "100%",
  //     height: "auto",
  //   },
  // },
  header: {
    fontWeight: "900 !important",
    fontSize: "20px !important",
    lineHeight: "1.2 !important",
    color: "#383751",
    textTransform: "capitalize",
  },
  courseTitle: {
    display: "flex",
    gap: "8px",
    "& img": {
      width: "37px",
      height: "37px",
    },
    "& h2": {
      textTransform: "uppercase",
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "1.2",
      color: "#000",
    },
    "& p": {
      fontWeight: "500",
      fontSize: "14px",
      color: "#898989",
    },
  },
  textContainer: {
    // maxWidth: "100%",
    "& p, span, div": {
      fontWeight: "600",
      fontSize: "14px !important",
      lineHeight: "30px",
      color: "#5A5551 !important",
    },
  },
}));

export default style;
