import { Grid } from "@mui/material";
import BlogAdd from "components/local/dashboard/blog/BlogAdd";
import SideBar from "components/UI/SideBar/SideBar";
import { useLocation } from "react-router-dom";

const BlogEditPage = () => {
  const location = useLocation();
  console.log({ location });
  return (
    <Grid container sx={{ backgroundColor: "#F5F5F5" }}>
      <Grid item md={2}>
        <SideBar />
      </Grid>
      <Grid
        item
        md={10}
        sm={6}
        xs={12}
        sx={{ marginInline: { sm: "auto", md: "initial" } }}
      >
        {/* <JobAdd props={location?.state} edit /> */}
        <BlogAdd props={location?.state} edit />
      </Grid>
    </Grid>
  );
};

export default BlogEditPage;
