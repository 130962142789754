import * as actions from "components/local/home/redux/types";
const initialState = {
  homepage: [],
  homepage_loading: false,
  quickLinks: [],
  quickLinks_loading: false,
  showLoginForm: false,
  showSignUpForm: false,
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_HOMEPAGE_BEGIN:
      return { ...state, homepage_loading: true };

    case actions.GET_HOMEPAGE_SUCCESS:
      return { ...state, homepage_loading: false, homepage: action.payload };

    case actions.GET_HOMEPAGE_ERROR:
      return { ...state, homepage_loading: false };

    // login & logout

    case actions.SHOW_LOGIN_FORM:
      return { ...state, showLoginForm: true };
    case actions.HIDE_LOGIN_FORM:
      return { ...state, showLoginForm: false };
    case actions.SHOW_SIGNUP_FORM:
      return { ...state, showLoginForm: false, showSignUpForm: true };
    case actions.HIDE_SIGNUP_FORM:
      return { ...state, showSignUpForm: false };
    default:
      return state;
  }
};

export default homeReducer;
