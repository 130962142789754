import { Box, Grid } from "@mui/material";
import style from "components/local/ourTeams/style";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const TeamCard = ({ item, president, committee, }) => {
  const pathname = window.location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/our-team/${item?.slug}`);
  };

  const classes = style();
  return (
    <>
      <Grid
        onClick={handleNavigate}
        item
        lg={3}
        md={4}
        sm={6}
        xs={12}
        className={classes.teamCardWrap}
      >
        {item?.our_team_category?.title === "Executive Committee"
        ?
        ( <Box className={classes.teamCardContainer}>
          <Box
            className={`${president ? classes.cardImgBox2 : committee ? classes.cardImgBox3 : classes.cardImgBox  }`}
            
          >
            <img src={item?.image} alt="" className={classes.cardImg} />
          </Box>

          <Box className={classes.textBox}>
            {/* <Box className={classes.teamName} > */}
            <Box className={`${president ? classes.teamName2 : committee ? classes.teamName3 : classes.teamName  }`} >
              {item?.member_name}
            </Box>

            <Box className={`${president ? classes.teamPosition2 : committee ? classes.teamPosition3 : classes.teamPosition  }`} >
              {item?.designation}
            </Box>

            <Box className={`${president ? classes.teamPhone2 : committee ? classes.teamPhone3 : classes.teamPhone  }`} >
              {/* (+977)  */}
              <span className={classes.teamPhoneSpan}>{item?.phone}</span>
            </Box>
          </Box>
        </Box>)
        :
        ( <Box className={classes.teamCardContainer}>
          <Box
            className={classes.cardImgBox}
          >
            <img src={item?.image} alt="" className={classes.cardImg} />
          </Box>

          <Box className={classes.textBox}>
            <Box className={classes.teamName} >
              {item?.member_name}
            </Box>

            <Box className={classes.teamPosition} >
              {item?.designation}
            </Box>

            <Box className={classes.teamPhone} >
              {/* (+977)  */}
              <span className={classes.teamPhoneSpan}>{item?.phone}</span>
            </Box>
          </Box>
        </Box>)
        }
        {/* <Box className={classes.teamCardContainer}>
          <Box
            className={classes.cardImgBox}
            sx={{ width: "240px", height: "220px" }}
          >
            <img src={item?.image} alt="" className={classes.cardImg} />
          </Box>

          <Box className={classes.textBox}>
            <Box className={classes.teamName} sx={{ fontSize: "18px" }}>
              {item?.member_name}
            </Box>

            <Box className={classes.teamPosition} sx={{ fontSize: "15px" }}>
              {item?.designation}
            </Box>

            <Box className={classes.teamPhone} sx={{ fontSize: "14px" }}>
              (+977) 
              <span className={classes.teamPhoneSpan}>{item?.phone}</span>
            </Box>
          </Box>
        </Box> */}
      </Grid>
    </>
  );
};

export default TeamCard;
