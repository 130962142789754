import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  jobsCardPaper: {
    height: "90px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "12px",
    marginBottom: "12px",
    boxShadow: "0px 4px 19px rgba(0, 0, 0, 0.07) !important",
  },
  jobsCardImgBox: {
    height: "65px",
    width: "65px",
  },
  jobsCardTypography: {
    padding: "12px",
    paddingRight: "0px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  jobsCardTitle: {
    fontFamily: ["Inter", "!important"],
    color: "#121127",
    fontSize: "16px !important",
    fontWeight: "600 !important",
  },
  jobsCardHead: {
    fontFamily: ["Inter", "!important"],
    color: "rgba(18, 17, 39, 0.56)",
    fontSize: "11px !important",
    fontWeight: "400 !important",
  },
  loactionIcon: {
    width: "8px",
    height: "10px",
    marginRight: "4px",
  },
  jobsCardDot: {
    width: "8px",
    height: "8px",
    marginRight: "4px",
  },
  jobsCardSubTitle: {
    fontFamily: ["Inter", "!important"],
    color: "rgba(18, 17, 39, 0.9)",
    fontSize: "12px !important",
    fontWeight: "400 !important",
  },
}));

export default style;
