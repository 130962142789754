import {
  BorderColorOutlined,
  DeleteOutlined,
  InsertDriveFileOutlined,
} from "@mui/icons-material";
import {
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import Description from "components/global/description/Description";
import moment from "moment";
import { useState } from "react";

const CustomTable = ({
  columns,
  rows = [],
  handleDelete,
  handleEdit = () => {},
  handleOpen = () => {},
  handleClick = () => {},
  handleCvOpen = () => {},
  jobSlug,
  data,
  applicant,
}) => {
  const [deletedRowId, setDeletedRowId] = useState("");

  console.log("jjjjjjjjjjjjjjjjjjjjjjj", rows?.length);
  console.log("ddddddddddddddddddddddd", rows);

  const getFormattedDate = (rows) => {
    const dates = new Date(rows);
    return moment(dates)?.format("MMM-DD-YYYY");
  };

  if (rows.length === 0) {
    return (
      <Box
        sx={{
          height: "400px",
          backgroundColor: "#E5E5EB",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "20px",
          color: "#6C6B80",
        }}
      >
        No data available
      </Box>
    );
  }

  return (
    <>
      <TableContainer sx={{ overflowX: "scroll", width: "100%" }}>
        <Table sx={{ background: "white" }}>
          <TableHead>
            <TableRow>
              {columns?.map((column) => {
                return (
                  <TableCell key={column?.accessor} sx={{ fontSize: "16px" }}>
                    {column?.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => {
              return (
                <TableRow key={row.id}>
                  {columns?.map((column) => {
                    if (column?.accessor === "edit") {
                      return (
                        <TableCell
                          key={column?.accessor}
                          sx={{ cursor: "pointer", color: "#1b55e2" }}
                          onClick={() => handleEdit(row)}
                        >
                          <BorderColorOutlined />
                        </TableCell>
                      );
                    }
                    if (column?.accessor === "applicant") {
                      return (
                        <>
                          {row?.job_applications?.length !== 0 ? (
                            <TableCell
                              key={column.accessor}
                              onClick={() => handleOpen(row)}
                            >
                              <Button
                                // disabled={row?.job_applications?.length === 0}
                                // disabled={row?.job_applications?.length === 0 && true}
                                sx={
                                  row?.job_applications?.length > 0
                                    ? {
                                        cursor: "pointer",
                                        // color: "#1b55e2",
                                        backgroundColor: "#EF4444 !important",
                                        color: "#fff !important",
                                        padding: "5px 10px !important",
                                        fontSize: "12px !important",
                                      }
                                    : {
                                        cursor: "no-drop",
                                        // pointerEvents: 'none',

                                        // color: "#1b55e2",
                                        backgroundColor: "#f29f9f !important",
                                        color: "#fff !important",
                                        padding: "5px 10px !important",
                                        fontSize: "12px !important",
                                      }
                                }
                              >
                                <span sx={{ marginLeft: "1rem !important" }}>
                                  Applicants
                                </span>
                                ({row?.job_applications?.length})
                              </Button>
                            </TableCell>
                          ) : (
                            <TableCell
                              key={column.accessor}
                              // onClick={() => handleOpen()}
                            >
                              <Tooltip title="No applicants for this job">
                                <Button
                                  // disabled={row?.job_applications?.length === 0}
                                  // disabled={row?.job_applications?.length === 0 && true}
                                  sx={
                                    row?.job_applications?.length > 0
                                      ? {
                                          cursor: "pointer",
                                          // color: "#1b55e2",
                                          backgroundColor: "#1b55e2 !important",
                                          color: "#fff !important",
                                          padding: "5px 10px !important",
                                          fontSize: "12px !important",
                                        }
                                      : {
                                          cursor: "no-drop",
                                          // pointerEvents: 'none',

                                          // color: "#1b55e2",
                                          backgroundColor: "#f29f9f !important",
                                          color: "#fff !important",
                                          padding: "5px 10px !important",
                                          fontSize: "12px !important",
                                        }
                                  }
                                >
                                  <span sx={{ marginLeft: "1rem !important" }}>
                                    Applicants
                                  </span>
                                  ({row?.job_applications?.length})
                                </Button>
                              </Tooltip>
                            </TableCell>
                          )}
                        </>
                      );
                    }
                    if (column?.accessor === "cv") {
                      return (
                        <TableCell
                          key={column?.accessor}
                          sx={{ cursor: "pointer", color: "#1b55e2" }}
                          // onClick={() => handleEdit(row)}
                        >
                          <a
                            href={row[column?.accessor]}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <InsertDriveFileOutlined />

                          </a>
                        </TableCell>
                      );
                    }
                    if (column?.accessor === "delete") {
                      return (
                        <TableCell
                          key={column.accessor}
                          sx={{ cursor: "pointer", color: "#EF4444" }}
                          onClick={() => {
                            jobSlug
                              ? setDeletedRowId(row?.slug)
                              : setDeletedRowId(row?.id);
                          }}
                        >
                          <DeleteOutlined />
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell
                        key={column?.accessor}
                        onClick={() => handleClick(row)}
                      >
                        {column?.accessor === "created_at" &&
                          getFormattedDate(row[column?.accessor])}

                        {column?.accessor === "description" &&
                          <Description description={row[column?.accessor]}/>}

                        {column?.accessor !== "created_at" && column?.accessor !== "description" &&
                          row[column?.accessor]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal open={deletedRowId} onClose={() => setDeletedRowId("")}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            backgroundColor: "white",
            borderRadius: "4px",
            p: "15px",
            "&: focus-visible": {
              outline: "transparent",
            },
          }}
        >
          <Box sx={{ mb: "15px" }}>Are you sure you want to delete?</Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "15px",
              "& button": {
                padding: "5px 10px",
                fontSize: "16px",
                lineHeight: "1.2",
              },
            }}
          >
            <Button variant="contained" onClick={() => setDeletedRowId("")}>
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleDelete(deletedRowId)}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CustomTable;
