import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Paper } from "@mui/material";
import backArrow from "assets/news/backArrow.png";
import CustomEditor from "components/global/customEditor";
import Loader from "components/global/loader/Loader";
import style from "components/local/dashboard/job/style";
import DropZone from "components/UI/FileDrop/DropZone";
import { HookForm } from "components/UI/Form/HookForm";
import { HookFormInput } from "components/UI/Form/HookFormInput";
import { HookFormProvider } from "components/UI/Form/HookFormProvider";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { getJob, postJob, putJob } from "./redux/actions";

const JobAdd = ({ props, edit }) => {
  const classes = style();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  console.log("sssssssssssssssss", props);

  const dispatch = useDispatch();
  const { slug } = useParams();
  const [file, setFile] = useState();
  const { job, job_loading } = useSelector((state) => state.jobDash);

  console.log({ filesssssssssssssssss: file });
  const validationSchema = yup.object().shape({
    title: yup.string().required("Name is required"),
    position: yup.string().required("Position is required"),
    job_type: yup.string().required("Job Type is required"),
    location: yup.string().required("Location is required"),
    description: yup.string().required("Description is required"),
    image: !edit && yup.mixed().required("File is required"),
  });

  const dropData = [
    { value: "Articleship", label: "Articleship" },
    { value: "Pre-articleship", label: "Pre-articleship" },
  ];

  const defaultValues = {
    job_type: dropData?.[0]?.value,
    ...props,
  };

  console.log({ props });

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  console.log("defaultValues", defaultValues);

  const refetch = () => {
    const array = ["title", "image", "description", "position", "job_type"];
    array?.map((item) => setValue(item, ""));
    reset();
    edit && dispatch(getJob());
    navigate(`/dashboard/job`);
  };

  // useEffect(() => {
  //   dispatch(getJob());
  // }, []);

  // const navigate = useNavigate();

  const onSubmit = (data) => {
    const values = {
      ...data,
      image: data?.image,
      delete_file: edit && 1,
    };
    console.log({ values });
    const formData = new FormData();
    Object.keys(values)?.map((keys) => formData.append(keys, values[keys]));
    edit && formData.append("_method", "put");
    // edit && formData.append("image", values?.image ? values?.image : null);

    for (const value of formData.values()) {
      console.log("Hello", value);
    }
    // refetch();

    edit
      ? dispatch(putJob(formData, slug, refetch))
      : dispatch(postJob(formData, refetch));
  };

  console.log({
    watch: watch(),
    // , meowmoewnigga: watch()?.image
  });

  return (
    <Box className={classes.dashboardTitleBox}>
      <Box className={classes.backBtnBox}>
        <button
          onClick={goBack}
          className={classes.backBtn}
          style={{ textDecoration: "none" }}
        >
          <img src={backArrow} alt="" className={classes.backArrow} />
          Back
        </button>
      </Box>
      <Paper elevation={0} className={classes.addForm}>
        <Box>
          <HookFormProvider>
            <HookForm onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <HookFormInput
                    // required={true}
                    type="text"
                    name="title"
                    label="Title"
                    placeholder="Title"
                    control={control}
                    errors={errors}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <HookFormInput
                    // required={true}
                    type="text"
                    name="position"
                    label="Position"
                    placeholder="Position"
                    control={control}
                    errors={errors}
                  />
                </Grid>

                {/* <Grid item xs={12} md={6}>
                  <HookFormInput
                    required={true}
                    type="text"
                    name="description"
                    label="Desctiption"
                    placeholder="Desctiption"
                    control={control}
                    errors={errors}
                    multiline
                    rows={4}
                    sx={{
                      "& .css-17cvodc-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          height: "82px",
                        },
                    }}
                  />
                </Grid> */}
                <Grid item xs={12} md={6}>
                  <CustomEditor
                    watch={watch}
                    setValue={setValue}
                    name="description"
                    errors={errors}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DropZone
                    watch={watch}
                    setValue={setValue}
                    name="image"
                    errors={errors}
                    key={"image"}
                    image
                    link={
                      watch()?.image?.name
                        ? watch()?.image?.name &&
                          URL.createObjectURL(watch()?.image)
                        : defaultValues?.image
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <HookFormInput
                    select
                    name="job_type"
                    label="Job Type"
                    data={dropData ?? []}
                    control={control}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <HookFormInput
                    // required={true}
                    type="text"
                    name="location"
                    label="Location"
                    placeholder="Location"
                    control={control}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box className={classes.submitButton}>
                    <Button className={classes.formSubmitBtn} type="submit">
                      {job_loading ? <Loader /> : edit ? "Update" : "Submit"}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </HookForm>
          </HookFormProvider>
        </Box>
      </Paper>
    </Box>
  );
};

export default JobAdd;
