import { Box, Button, Typography } from "@mui/material";
import { default as CommentSection } from "components/local/dashboard/community/CommentSection";
import style from "components/local/dashboard/community/style";
import TextFieldForm from "components/local/dashboard/community/TextFieldForm";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getThread } from "./redux/actions";

const Community = () => {
  const classes = style();

  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);

  const { threadList, threadList_loading } = useSelector(
    (state) => state.thread
  );

  console.log({ threadList });

  const [threadListData, setThreadListData] = useState([]);

  console.log({ threadListData, threadList });

  useEffect(() => {
    threadList?.data &&
      setThreadListData([...(threadListData || []), ...(threadList?.data || []),]);
  }, [threadList?.data]);

  useEffect(() => {
    dispatch(getThread());
  }, []);

  const handleMore = () => {
    const data = {
      page: threadList?.data?.current_page + 1,
    };
    dispatch(getThread(data));
  };

  console.log({ threadListData });

  return (
    <Box className={classes.communityContainer}>
      <Box className={classes.communityTitleBox}>
        <Typography className={classes.communityTitle}>Community</Typography>
      </Box>
      <Box>
        <TextFieldForm setThreadListData={setThreadListData} />
      </Box>

      <Box className={classes.commentSectionContainer}>
        <Box>
          <CommentSection
            data={threadList?.data}
            loading={threadList_loading}
            setThreadListData={setThreadListData}
          />
        </Box>

        {!threadList_loading &&
          threadListData?.length < threadList?.data?.total && (
            <Box className={classes.seeMoreBtnBox}>
              <Button onClick={handleMore} className={classes.seeMoreBtn}>
                See more
              </Button>
            </Box>
          )}
      </Box>
    </Box>
  );
};

export default Community;
