import { Box, Grid, Typography } from "@mui/material";
import BackButton from "components/global/buttons/backButton/BackButton";
import CourseCard from "components/global/card/courseCard/CourseCard";
import SkeletonLoader from "components/global/loader/skeletonLoader/SkeletonCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCourses } from "./redux/actions";
import style from "./style";

const Courses = () => {
  const classes = style();

  const { id } = useParams();

  const dispatch = useDispatch();

  const { courses, courses_loading } = useSelector((state) => state.courses);

  const [coursesData, setCoursesData] = useState([]);

  console.log({ coursesData });

  useEffect(() => {
    dispatch(getCourses({ cap_level: id }));
  }, [id]);

  useEffect(() => {
    courses?.data && setCoursesData(courses?.data);
  }, [courses]);

  return (
    <Box
      component="section"
      sx={{
        bgcolor: "#F5F5F5",
        padding: { xs: "50px", md: "44px 75px", lg: "44px 124px" },
        height: "100%",
      }}
    >
      <Box sx={{ mb: "19px" }}>
      <Box>
        <BackButton/>
      </Box>
        <Typography component={"h1"} className={classes.header}>
          Courses
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {courses_loading
          ? [...Array(4)]?.map((item) => (
              <Grid item lg={3} md={4} sm={4} xs={12} mb="1rem">
                <SkeletonLoader course={true} />
              </Grid>
            ))
          : coursesData.map((course) => {
              return (
                <Grid key={course.id} item lg={3} md={4} sm={4} xs={12}>
                  <CourseCard course={course} />
                </Grid>
              );
            })}
      </Grid>
      {/* <ButtonTertiary>See More</ButtonTertiary> */}
    </Box>
  );
};

export default Courses;
