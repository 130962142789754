import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  calanderBox: {
    "& .react-datepicker__month-container": {
      padding: "1rem 0",
    },
    "& .react-datepicker": {
      height: "467px",
      [theme.breakpoints.down("sm")]: {
        height: '370px',
      },
      "& .react-datepicker__week":{
        [theme.breakpoints.down("sm")]: {
          padding: 0,
        },
      }
    },
  },
}));

export default styles;
