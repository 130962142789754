import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Container, Grid } from "@mui/material";
import style from "components/local/ourTeams/style";
import { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import TeamCard from "./TeamCard";

const CardSlider = ({ loading, teamsData, item }) => {
  function SamplePrevArrow(props) {
    const { style, onClick } = props;
    return (
      <Container maxWidth="lg">
        <KeyboardArrowLeftIcon
          fontSize="large"
          className={`${classes.arrowIconLeft}`}
          style={{
            ...style,
            color: "#9D9CAF",
          }}
          onClick={onClick}
        />
      </Container>
    );
  }
  function SampleNextArrow(props) {
    const { style, onClick } = props;
    return (
      <Container maxWidth="lg">
        <KeyboardArrowRightIcon
          fontSize="large"
          className={`${classes.arrowIconRight}`}
          style={{
            ...style,
            color: "#9D9CAF",
          }}
          onClick={onClick}
        />
      </Container>
    );
  }
  const classes = style();
  const sliderRef = useRef();

  console.log("sliderrrrrr", sliderRef.current);

  console.log("trtrtrtrtr", { teamsData });

  //   const [numItems, setNumItems] = useState(0);
  // const width = window.innerWidth ;
  // useEffect(() => {

  //   if (width >= 1200) {
  //     if (numItems >= 4) {
  //      setNumItems(4);
  //     }
  //   } else if (width > 900) {
  //     if (numItems >= 3) {
  //       setNumItems(3);
  //     }
  //   }
  //   else if (width > 650) {
  //     if (numItems >= 2) {
  //       setNumItems(2);
  //     }
  //   }
  //   else {
  //     if (numItems >= 1) {
  //       setNumItems(1);
  //     }
  //   }
  // }, [width])

  // console.log({numItems})

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: teamsData?.length >= 4 ? 4 : teamsData?.length,
    // slidesToShow: 4,
    // slidesToShow: numItems,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 2000,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: teamsData?.length >= 3 ? 3 : teamsData?.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: teamsData?.length >= 2 ? 2 : teamsData?.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: teamsData?.length >= 1 ? 1 : teamsData?.length,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  var settingsSecond = {
    infinite: false,
    speed: 0,
    slidesToShow: teamsData?.length >= 4 ? 4 : teamsData?.length,
    // slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    pauseOnHover: true,
    autoplaySpeed: 2000,
    draggable: false,
    // prevArrow: <SamplePrevArrow />,
    // nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: teamsData?.length >= 2 ? 2 : teamsData?.length,
          slidesToScroll: 1,
          speed: 500,
          autoplay: true,
          infinite: true,
        },
      },
      // {
      //   breakpoint: 600,
      //   settings: {
      //     slidesToShow: teamsData?.length >= 2 ? 2 : teamsData?.length,
      //     slidesToScroll: 1,
      //     speed: 500,
      // autoplay: true,
      //   },
      // },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: teamsData?.length >= 1 ? 1 : teamsData?.length,
          slidesToScroll: 1,
          speed: 500,
          autoplay: true,
          infinite: true,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  console.log("rrrrrrrrrrrrr", { teamsData });

  return (
    <>
      {/* <Slider {...settings} ref={sliderRef}> */}
      {item?.title === "Executive Committee" && (
        <Box>
          <TeamCard item={teamsData?.[0]} president />
        </Box>
      )}
      {item?.title === "Executive Committee" && (
        <Slider {...settingsSecond} ref={sliderRef}>
          {teamsData?.slice(1, 5)?.map((item) => {
            return (
              <>
                <Grid
                  container
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <TeamCard key={item?.id} item={item} committee />
                </Grid>
              </>
            );
          })}
        </Slider>
      )}
      {/* <Slider {...settingsSecond} ref={sliderRef}> */}
      {item?.title === "Executive Committee" && (
        <Box>
          <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            {teamsData?.slice(5)?.map((item) => {
              return (
                <>
                  <TeamCard key={item?.id} item={item} />
                </>
              );
            })}
          </Grid>
        </Box>
      )}
      {item?.title !== "Executive Committee" && (
        <Slider {...settings} ref={sliderRef}>
          {teamsData?.map((item) => {
            console.log("eeereere", { teamsData });
            return (
              <>
                <Grid
                  container
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <TeamCard key={item?.id} item={item} />
                </Grid>
              </>
            );
          })}
        </Slider>
      )}
      {/* </Slider> */}
    </>
  );
};

export default CardSlider;
