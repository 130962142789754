import { Container, Grid } from "@mui/material";
import newsImg from "assets/news/newsImg.png";
import Banner from "components/global/banner";
import ContactForm from "components/local/contactUs/ContactForm";
import ContactInfo from "components/local/contactUs/ContactInfo";
import MapsSection from "components/local/contactUs/MapsSection";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getContactus } from "./redux/actions";

const ContactUs = () => {
  const pathname = window.location.pathname;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { getcontactus, getcontactus_loading } = useSelector(
    (state) => state.getContact
  );
  const [getcontactusData, setGetcontactusData] = useState([]);

  console.log({ getcontactusData });

  const dispatch = useDispatch();

  useEffect(() => {
    getcontactus?.data && setGetcontactusData(getcontactus?.data);
  }, [getcontactus]);

  useEffect(() => {
    dispatch(getContactus());
  }, []);

  const bannerData = {
    title: "Contact us",
    picture: newsImg,
  };

  return (
    <>
      <Banner data={getcontactusData?.contact_us_banner} title={"Contact us"} />
      <Container maxWidth="lg">
        <Grid container>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ContactInfo />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ContactForm />
          </Grid>
        </Grid>
      </Container>

      <MapsSection />
    </>
  );
};

export default ContactUs;
