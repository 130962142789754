import { Box, Container, Typography } from "@mui/material";
import style from "components/local/contactUs/style";
import Maps from "components/local/maps/Maps";

const MapsSection = () => {
  const classes = style();

  return (
    <>
      <Maps />
    </>
  );
};

export default MapsSection;
