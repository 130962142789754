import React from "react";

const User = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8334 13.625V12.375C13.8334 11.712 13.5525 11.0761 13.0524 10.6072C12.5523 10.1384 11.874 9.875 11.1667 9.875H5.83341C5.12617 9.875 4.44789 10.1384 3.9478 10.6072C3.4477 11.0761 3.16675 11.712 3.16675 12.375V13.625"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.4987 7.375C9.97146 7.375 11.1654 6.25571 11.1654 4.875C11.1654 3.49429 9.97146 2.375 8.4987 2.375C7.02594 2.375 5.83203 3.49429 5.83203 4.875C5.83203 6.25571 7.02594 7.375 8.4987 7.375Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default User;
