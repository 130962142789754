import * as actions from "components/local/dashboard/community/redux/types";
const initialState = {
  thread: [],
  thread_loading: false,
  threadList: [],
  threadList_loading: false,
  comment: [],
  comment_loading: false,
  vote_loading: false,
};

const threadReducer = (state = initialState, action) => {
  switch (action.type) {
    // post, put and delete thread

    case actions.POST_THREAD_BEGIN:
    case actions.PUT_THREAD_BEGIN:
    case actions.DELETE_THREAD_BEGIN:
      return { ...state, thread_loading: true };

    case actions.POST_THREAD_SUCCESS:
    case actions.PUT_THREAD_SUCCESS:
    case actions.DELETE_THREAD_SUCCESS:
      return { ...state, thread_loading: false };

    case actions.POST_THREAD_ERROR:
    case actions.PUT_THREAD_ERROR:
    case actions.DELETE_THREAD_ERROR:
      return { ...state, thread_loading: false };

    // vote

    case actions.POST_UPVOTE_BEGIN:
    case actions.POST_DOWNVOTE_BEGIN:
    case actions.DELETE_UPVOTE_BEGIN:
    case actions.DELETE_DOWNVOTE_BEGIN:
      return { ...state, vote_loading: true };

    case actions.POST_UPVOTE_SUCCESS:
    case actions.POST_DOWNVOTE_SUCCESS:
    case actions.DELETE_UPVOTE_SUCCESS:
    case actions.DELETE_DOWNVOTE_SUCCESS:
      return { ...state, vote_loading: false };

    case actions.POST_UPVOTE_ERROR:
    case actions.POST_DOWNVOTE_ERROR:
    case actions.DELETE_UPVOTE_ERROR:
    case actions.DELETE_DOWNVOTE_ERROR:
      return { ...state, vote_loading: false };

    // comment
    case actions.POST_COMMENT_BEGIN:
    case actions.PUT_COMMENT_BEGIN:
    case actions.DELETE_COMMENT_BEGIN:
      return { ...state, comment_loading: true };

    case actions.POST_COMMENT_SUCCESS:
    case actions.PUT_COMMENT_SUCCESS:
    case actions.DELETE_COMMENT_SUCCESS:
      return { ...state, comment_loading: false };

    case actions.POST_COMMENT_ERROR:
    case actions.PUT_COMMENT_ERROR:
    case actions.DELETE_COMMENT_ERROR:
      return { ...state, comment_loading: false };

    // get thread

    case actions.GET_THREAD_BEGIN:
      return { ...state, threadList_loading: true };

    case actions.GET_THREAD_SUCCESS:
      console.log({ dactionc: action.payload });
      return {
        ...state,
        threadList_loading: false,
        threadList: action.payload,
      };

    case actions.GET_THREAD_ERROR:
      return { ...state, threadList_loading: false };

    default:
      return state;
  }
};

export default threadReducer;
