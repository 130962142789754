import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  dashboardTitleBox: {
    padding: "5rem",
  },
  titleSection: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "1.4rem !important",
    fontWeight: "600 !important",
  },
  addButton: {
    backgroundColor: "#EF4444 !important",
    color: "#fff !important",
    fontSize: "14px !important",
    padding: "5px 10px  !important",
  },
  buttonIcon: {
    width: "16px !important",
    height: "16px !important",
    color: "#fff !important",
  },
}));

export default style;
