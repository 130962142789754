import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import style from "./style";

const Logo = ({ data, loader }) => {
  const classes = style();
  return (
    <Box className={classes.logoWrap}>
      <Link to="/">
        <Box className={classes.logoBox}>
          <img className={classes.logo} src={data?.profile_picture} alt="" />
        </Box>
      </Link>
      {/* <Box className={classes.beta}>Beta</Box> */}
    </Box>
  );
};

export default Logo;
