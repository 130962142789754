import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
   

    newsSingleContainer:{
      // backgroundColor: 'pink',
      padding: '20px 0'
    },
    backBtnBox:{
      marginBottom: '20px'
    },
    backBtn:{
      border: 'none',
      backgroundColor: 'rgb(255, 255, 255, .0)',
      display: 'flex',
      alignItems: 'center',
      gap: 3,
      padding: '5px',
      cursor: 'pointer',
      color: '#858689',
      fontSize: "14px !important",
      fontWeight: "400 !important",
      fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    },
    backArrow:{
      width: '16px',
      height: '16px',
    },
   
    newsMoreSectionWrap: {
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
      },
    },
    newsMoreSection: {
      width: "70%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    newsMoreSectionTitle: {
      display: "flex",
      justifyContent: "center",
      color: "#726464",
      fontSize: "15px !important",
      fontWeight: "600 !important",
      fontFamily: ["Sofia Sans", "sans-serif", "!important"],
      marginBottom: "7px !important",
    },


}));

export default style;
