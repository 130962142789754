import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  navWrapper: {
    position: "absolute",
    zIndex: "10",
    width: "100%",
    bottom: 0,
    left: 0,
    display: "flex !important",
    justifyContent: "center",

    "& li": {
      listStyleType: "none",
      display: "inline",
    },
    "& div": {
      display: "inline",
      margin: "6px 13px 6px 0px",
      position: "relative",
      fontFamily: ["Sofia Sans", "sans-serif", "!important"],
      fontSize: "11px",
      fontWeight: "500",
    },
    "& .text": {
      fontSize: "14px",
      color: "#A3A3A3",
      textTransform: "capitalize",
    },
    "& .lastElement": {
      fontSize: "14px",
      color: "#fff",
      textTransform: "capitalize",
      
    },
    "& svg": {
      position: "absolute",
      left: "-18px",
      top: "-7px",
      color: "#A3A3A3",
    },
    "& ol": {
      padding: 0,
      margin: 0,
    },
  },
  navWrapperJob: {
    position: "absolute",
    zIndex: "10",
    width: "100%",
    top: "95px",
    left:"0",
    // backgroundColor: 'red',
    display: "flex !important",
    justifyContent: "center",

    "& li": {
      listStyleType: "none",
      display: "inline",
    },
    "& div": {
      display: "inline",
      margin: "6px 13px 6px 0px",
      position: "relative",
      fontFamily: ["Sofia Sans", "sans-serif", "!important"],
      fontSize: "11px",
      fontWeight: "500",
    },
    "& .text": {
      fontSize: "14px",
      color: "#A3A3A3",
      textTransform: "capitalize",
    },
    "& .lastElement": {
      fontSize: "14px",
      color: "#fff",
      textTransform: "capitalize",
    },
    "& svg": {
      position: "absolute",
      left: "-18px",
      top: "-7px",
      color: "#A3A3A3",
    },
    "& ol": {
      padding: 0,
      margin: 0,
    },
  },
}));

export default useStyles;
