import EventDetails from "components/local/eventDetails/EventDetails";

const EventDetailsPage = () => {
  return (
    <div>
      <EventDetails />
    </div>
  );
};

export default EventDetailsPage;
