import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Avatar, Box, Grid, Tooltip, Typography } from "@mui/material";
import { ReactComponent as DownIcon } from "assets/dashboard/community/downIcon.svg";
import { ReactComponent as UpIcon } from "assets/dashboard/community/upIcon.svg";
import { TimeAgo } from "components/global/date/Date";
import ReadMoreBtn from "components/global/readMoreBtn";
import ModalMenu from "components/local/dashboard/community/ModalMenu";
import style from "components/local/dashboard/community/style";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDownvote,
  deleteUpvote,
  getThread,
  postDownvote,
  postUpvote
} from "./redux/actions";

const ReplySection = ({ data, commentModal }) => {
  const classes = style();
  

  console.log("bbbbbbbbbbbbbbbbbbbb", { data });

  return (
    <Box>
      {!commentModal
        ? data?.comment?.slice(0, 3)?.map((item) => {
            return <ReplySectionComponent item={item} />;
          })
        : data?.comment?.map((item) => {
            return <ReplySectionComponent item={item} />;
          })}
    </Box>
  );
};

const ReplySectionComponent = ({ item }) => {
  const dispatch = useDispatch();
  const profileState = useSelector((state) => state.profile);
  const authState = useSelector((state) => state.auth);
  const classes = style();
  const [isLike, setIsLike] = useState(false);
  const [isDislike, setIsDislike] = useState(false);

  // const [likeNo, setLikeNo] = useState(96);

  console.log("ccccccccccccccc", { item });

  const refetch = () => {
    dispatch(getThread());
  };

  const handleLike = () => {
    setIsLike(!isLike);
    setIsDislike(false);

    item?.current_user_upvote
      ? dispatch(deleteUpvote({ comment_id: item?.id }, refetch))
      : dispatch(postUpvote({ comment_id: item?.id }, refetch));
  };

  const handleDislike = () => {
    setIsDislike(!isDislike);
    setIsLike(false);

    item?.current_user_downvote
      ? dispatch(deleteDownvote({ comment_id: item?.id }, refetch))
      : dispatch(postDownvote({ comment_id: item?.id }, refetch));
  };

  return (
    <Box className={classes.replySectionBox}>
      <Grid container>
        <Grid item md={1}>
          <Box>
            <Avatar
              src={item?.user?.image}
              alt=""
              className={classes.profileImg}
              sx={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                objectFit: "cover",
                background: "#EF4444",
                color: "#fff",
              }}
            >
              {item?.user?.image
                ? item?.user?.image
                : item?.user?.name?.charAt(0)}
            </Avatar>
          </Box>
          {/* <Box className={classes.replyProfileImgBox}>
            <img src={tankaProfile} alt="" className={classes.profileImg} />
          </Box> */}
        </Grid>
        <Grid item md={11}>
          <Box className={classes.userCommmentBox}>
            <Box className={classes.userNameMenu}>
              <Box className={classes.timeAgo}>
                <Typography className={classes.userProfileName}>
                  {item?.user?.name}
                </Typography>

                <FiberManualRecordIcon
                  fontSize="small"
                  className={classes.dotTime}
                />

                <Typography className={classes.userProfileName}>
                  <TimeAgo time={item?.updated_at} />
                </Typography>
              </Box>
              {/* <Typography className={classes.userProfileName}>
                {item?.user?.name}
              </Typography> */}
              {authState?.user?.id === item?.user?.id ? (
                <ModalMenu data={item} comment row={item} />
              ) : (
                ""
              )}

              {/* <MoreHorizIcon sx={{ color: "#616161", cursor: 'pointer' }} onClick={handleMenu}/> */}
            </Box>
            <Typography className={classes.userCommentBody}>
              {item?.description?.substring().length < 69 ? (
                item?.description
              ) : (
                <ReadMoreBtn data={item?.description} length={70} />
              )}
            </Typography>
            <Box className={classes.userReplyBtnBox}>
              <Tooltip title={`${Number(item?.upvote)} Upvotes`}>
                <Box className={classes.upBtn} onClick={handleLike}>
                  <UpIcon
                    fill={
                      item?.current_user_upvote || isLike ? "#FF4500" : "#fff"
                    }
                  />{" "}
                </Box>
              </Tooltip>
              <Box className={classes.linkNumBox}>
                <Typography className={classes.linkNum}>
                  {console.log({ item })}
                  {Number(item?.upvote)}
                </Typography>
              </Box>
              <Tooltip title={`${Number(item?.downvote)} Downvotes`}>
                <Box className={classes.downBtn} onClick={handleDislike}>
                  <DownIcon
                    fill={
                      item?.current_user_downvote || isDislike
                        ? "#7193FF"
                        : "#fff"
                    }
                  />{" "}
                </Box>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReplySection;
