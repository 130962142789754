import { Box, Container, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import AboutDescription from "../description/AboutDescription";
import SkeletonAboutImage from "../loader/skeletonLoader/AboutImage";
import SkeletonAboutText from "../loader/skeletonLoader/AboutText";
import style from "./style";

const AboutComponent = ({ data }) => {
  const isEven = (n) => {
    return n % 2 === 0;
  };

  const classes = style();
  const { aboutSingle_loading } = useSelector((state) => state.aboutSingle);
  return (
    <Box className={classes.aboutCompContainer}>
      <Container maxWidth="lg">
        {/* <Box className={[classes.aboutCompWrap, "container-wrap-secondary"]}> */}
        <Grid
          container
          spacing={1}
          sx={
            data?.data?.id === 2 &&
            1 && {
              display: "flex",
              justifyContent: "space-between",
            }
          }
        >
          <>
            {aboutSingle_loading ? (
              <>
                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  // sx={data?.data?.id === 1 && { order: "2" }}
                >
                  <SkeletonAboutText />
                </Grid>
                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  // sx={data?.data?.id === 1 && { order: "1" }}

                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <SkeletonAboutImage />
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  // className={classes.aboutSectionGrid1}
                  sx={isEven(data?.data?.id) ? { order: "2" } : { order: "1" }}
                >
                  <Box className={classes.aboutCompTypography}>
                    {data?.data?.title && (
                      <Typography className={classes.aboutCompTitle}>
                        {data?.message}
                      </Typography>
                    )}
                    <Typography className={classes.aboutCompHead}>
                      {data?.data?.title}
                    </Typography>
                    <Typography className={classes.aboutCompPara}>
                      {/* {paragraph} */}
                      <AboutDescription description={data?.data?.description} />
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  className={classes.aboutSectionGrid2}
                  sx={isEven(data?.data?.id) ? { order: "1" } : { order: "2" }}
                >
                  <Box
                    className={classes.imageGrid}
                    // sx={data?.data?.id === 2 && { alignItems: "flex-start" }}
                    sx={isEven(data?.data?.id) && { alignItems: "flex-start" }}
                  >
                    <Box className={classes.imageBox}>
                      <img
                        src={data?.data?.image}
                        alt=""
                        className={classes.gridImg}
                      />
                    </Box>
                    {/* <Box className={classes.imageGridCaption}>caption</Box> */}
                  </Box>
                </Grid>
              </>
            )}
          </>
        </Grid>
      </Container>
    </Box>
  );
};

export default AboutComponent;
