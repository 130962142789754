import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: "#F5F5F5",
    minHeight: "calc(100vh - 70px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "40px",
  },
  formContainer: {
    backgroundColor: "#fff",
    padding: "47.5px 37.5px 47.5px 42.5px",
    width: "410px",
  },
  header: {
    fontSize: "26px !important",
    fontWeight: "500 !important",
    color: "#201F37",
  },

  btnContainer: {
    "& button": {
      padding: "10px 19px",
      borderRadius: "5px",
      fontSize: "16px",
      fontWeight: "600",
      lineHeight: "1",
      backgroundColor: "#DC2626 !important",
      color: "#fff !important",
      width: "100%",
    },
  },
  label: {
    fontFamily: ["Sofia sans", "sans-serif"],
    fontWeight: "500",
    display: "block",
    lineHeight: "1.2",
    marginBottom: "3px",
  },
  inputField: {
    marginBottom: "15px",
  },
  input: {
    padding: "8px 12px",
    fontFamily: ["Sofia sans", "sans-serif"],
    fontSize: "14px",
    lineHeight: "1.4",
    border: "solid 1px #E5E5EB",
    borderRadius: "4px",
    width: "100%",
    "&[aria-invalid = true]": {
      border: "solid 1px red",
    },
  },
  toggleIcon: {
    position: "absolute",
    right: "11.5px",
    top: "11.5px",
    color: "#6C6B80",
    cursor: "pointer",
  },
}));

export default style;
