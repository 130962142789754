import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import chevronDown from "assets/home/chevronDown.png";
import { useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { getAlbum } from "../redux/actions";
import style from "./style";

const Album = ({ data, loading, album }) => {
  const dispatch = useDispatch();
  const classes = style();
  console.log("dataaaaaa", data);
  const handleMore = () => {
    const data = {
      page: album?.data?.album_data?.pagination?.current_page + 1,
    };
    dispatch(getAlbum(data));
  };

  return (
    <Box component="section" sx={{ padding: "40px 0" }}>
      <Box sx={{ paddingLeft: "22px", paddingBottom: "40px" }}>
        <Typography component="h1" className={classes.header}>
          Albums
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {/* {loading
          ? [...Array(3)]?.map((item) => (
              <Grid item md={4} sm={6} xs={12} mb="1rem">
                <Skeleton variant="rectangular" width="100%" height={250} />
              </Grid>
            ))
          : data?.map((item) => {
              return <AlbumComponent item={item} />;
            })} */}
        {data?.map((item) => {
          return <AlbumComponent item={item} />;
        })}
        {loading &&
          [...Array(3)]?.map((item) => (
            <Grid item md={4} sm={6} xs={12} mb="1rem">
              <Skeleton variant="rectangular" width="100%" height={250} />
            </Grid>
          ))}
      </Grid>
      {/* <ButtonTertiary>See More</ButtonTertiary> */}
      {!loading &&
        data?.length < album?.data?.album_data?.pagination?.total && (
          <Box className={classes.newsSeeMoreBtnBox} onClick={handleMore}>
            <NavLink className={classes.newsSeeMoreBtn}>See More</NavLink>
            <Box className={classes.newsSeeMoreArrow}>
              <img src={chevronDown} alt="" />
            </Box>
          </Box>
        )}
    </Box>
  );
};

const AlbumComponent = ({ item }) => {
  const classes = style();

  return (
    <>
      <Grid key={item.id} item md={4} sm={6} xs={12}>
        <Link to={`/gallery/${item.id}`} style={{ textDecoration: "none" }}>
          <Card className={classes.card} elevation={0} square>
            <CardMedia image={item.image} sx={{ height: "240px" }} />
            <CardContent className={classes.titleContainer}>
              <Typography className={classes.cardTitle}>
                {item?.title?.substring().length < 39
                  ? item?.title
                  : `${item?.title?.substring(0, 40)}...`}
              </Typography>
              <Box className={classes.cardPhotoCountBox}>
                <Typography className={classes.cardPhotoCount}>
                  Photos: {item?.gallery?.length}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Link>
      </Grid>
    </>
  );
};

export default Album;
