export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGIN_ERROR = "LOGIN_ERROR";

export const LOGIN_PENDING = "LOGIN_PENDING";

export const CHANGE_STATUS = "CHANGE_STATUS";

export const CHECK_LOGIN_STATUS = "CHECK_LOGIN_STATUS";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const VERIFICATION_SUCCESS = "VERIFICATION_SUCCESS";

export const VERIFICATION_ERROR = "VERIFICATION_ERROR";

export const VERIFICATION_PENDING = "VERIFICATION_PENDING";
