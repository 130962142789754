import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { Container } from "@mui/system";
import calendar from "assets/home/calendar.png";
import Calendar from "components/local/calendar/Calendar";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getEventsOfCurrentMonth } from "redux/events/actions";
import Card from "./card/EventCard";
import style from "./style";

const Events = ({ eventData, eventPage }) => {
  // const eventDates = useMemo(() => {
  //   return eventData?.map((event) => event.date);
  // }, [eventData]);
  const dispatch = useDispatch();
  const eventState = useSelector((state) => state.event);
  const { event_loading } = useSelector((state) => state.event);

  console.log("eventLoading", { event_loading });
  const data = {
    date: eventState?.date,
  };
  useEffect(() => {
    dispatch(getEventsOfCurrentMonth(data));
  }, [eventState?.date]);

  const classes = style();

  return (
    <Box
      className={classes.eventMainContainer}
      sx={{ backgroundColor: "#F9F9FB" }}
    >
      <Container>
        <Box sx={{ paddingTop: "45px", paddingBottom: "56px" }}>
          <Box
            sx={{
              marginBottom: "21px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" },
              "& a": {
                alignSelf: { xs: "flex-end" },
              },
            }}
          >
            <Typography
              component="h2"
              textTransform="uppercase"
              fontSize="22px"
              fontWeight="700"
              width="100%"
              sx={{ alignSelf: { xs: "center" } }}
            >
              Upcoming events
            </Typography>
            {!eventPage && (
              <Box className={classes.blogsGridSeeBtnBox}>
                <NavLink to="/events" className={classes.blogsGridSeeBtn}>
                  see more
                </NavLink>
              </Box>
            )}

            {/* <Link to="" style={{ color: "#4C7CE5" }}>
              See more
            </Link> */}
          </Box>

          <Grid
            container
            // sx={eventPage ? { height: "467px" } : { height: "467px" }}
            className={classes.eventGridContainer}
          >
            <Grid
              item
              lg={5}
              md={12}
              xs={12}
              sx={{
                textAlign: { xs: "center" },
                mb: { xs: "20px", md: "0" },
                height: "100%",
              }}
            >
              <Calendar eventData={eventState} />
            </Grid>

            <Grid
              item
              lg={7}
              md={12}
              xs={12}
              sx={{
                height: "100%",
                overflowY: "auto",
                padding: "22px 42px 21px 32px",
                backgroundColor: "#fff",
                "&::-webkit-scrollbar-track": {
                  background: "#E5E5EB",
                },
                "&::-webkit-scrollbar": {
                  width: "7px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#4C4B63",
                  borderRadius: "16px",
                },
              }}
            >
              <Box className={classes.eventBoxWrapper}>
                {event_loading ? (
                  [...Array(4)]?.map((item) => {
                    return (
                      <Box>
                        <Skeleton
                          variant="rounded"
                          width="100%"
                          height={95}
                          sx={{ marginBottom: "10px" }}
                        />
                      </Box>
                    );
                  })
                ) : (
                  <>
                    {eventState?.currentMonthEvent?.length ? (
                      eventState?.currentMonthEvent?.map((data, index) => {
                        return (
                          <Card key={data.id} data={data} order={index + 1} />
                        );
                      })
                    ) : (
                      <Box className={classes.noEventsContainer}>
                        <Box className={classes.noEventsBox}>
                          <img
                            src={calendar}
                            alt=""
                            className={classes.noEventsImg}
                          />
                          <Typography className={classes.noEventsText}>
                            No events this month
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Events;
