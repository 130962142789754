import Video from "components/local/dashboard/video";
import React from "react";

const VideoPage = () => {
  return (
    <>
      <Video />
    </>
  );
};

export default VideoPage;
