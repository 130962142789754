import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  header: {
    fontWeight: "700 !important",
    fontSize: "27px !important",
    lineHeight: "1.2 !important",
    color: "#000",
    textAlign: "center",
  },
}));

export default style;
