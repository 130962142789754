import { getBlogsApi, getBlogsSingleApi} from "apis";
import * as actions from "components/local/blogs/redux/types";

export const getBlogspage = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_BLOGS_BEGIN });

  try {
    const { data } = await getBlogsApi(Data);
    dispatch({
      type: actions.GET_BLOGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_BLOGS_ERROR });
    console.log(error);
  }
};

export const getBlogsSinglepage = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_BLOGS_SINGLE_BEGIN });

  try {
    const { data } = await getBlogsSingleApi(Data);
    dispatch({
      type: actions.GET_BLOGS_SINGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_BLOGS_SINGLE_ERROR });
    console.log(error);
  }
};
