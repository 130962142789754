import { Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import chevronDown from "assets/home/chevronDown.png";
import Banner from "components/global/banner";
import Card from "components/global/card/jobsCard/JobsCard";
import SkeletonCardJob from "components/global/loader/skeletonLoader/SkeletonCardJob";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { getJobspage } from "./redux/actions";
import style from "./style";

const Jobs = () => {
  const pathname = window.location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const classes = style();

  const { jobsPage, jobs_loading } = useSelector((state) => state.jobs);
  const [jobsData, setjobsData] = useState([]);

  console.log("qqqqqqqqqqqqqqqqq", jobsData, jobsPage);

  const dispatch = useDispatch();

  useEffect(() => {
    jobsPage?.data &&
      setjobsData([
        ...(jobsData || []),
        ...(jobsPage?.data?.job_data?.job || []),
      ]);
  }, [jobsPage?.data?.job_data?.pagination?.current_page]);

  useEffect(() => {
    dispatch(getJobspage());
  }, []);

  const handleMore = () => {
    const data = {
      page: jobsPage?.data?.job_data?.pagination?.current_page + 1,
    };
    dispatch(getJobspage(data));
  };

  const unique = [...new Set(jobsData?.map((item) => item?.job_type))];

  const options = unique?.map((item) => {
    return {
      label: item,
      value: item,
    };
  });

  return (
    <div>
      <Banner data={jobsPage?.data?.job_banner} title={"Jobs"} />
      <Container maxWidth="lg">
        <Box
          className={classes.jobHeader}
          // sx={{
          //   display: "flex",
          //   justifyContent: "space-between",
          //   paddingTop: "81px",
          //   paddingBottom: "30px",
          // }}
        >
          <Box className={classes.titleBox}>
            <Typography component={"h1"} className={classes.title}>
              All Posts
            </Typography>
          </Box>
          {/* <Box sx={{ display: "flex", gap: "21px" }}>
            <Autocomplete
              className={classes.label}
              disablePortal
              id="combo-box-demo"
              options={options || []}
              sx={{ width: 158 }}
              renderInput={(params) => (
                <TextField {...params} label="Categories" />
              )}
            />
            <Autocomplete
              className={classes.label}
              disablePortal
              id="combo-box-demo"
              options={[{ label: "Most Popular" }, { label: "Least Popular" }]}
              sx={{ width: 158 }}
              renderInput={(params) => (
                <TextField {...params} label="Sort By" />
              )}
            />
          </Box> */}
        </Box>
        <Grid container spacing={4} sx={{ marginBottom: "1rem" }}>
          {jobsData?.map((data) => (
            <>
              <Grid key={data?.id} item md={3} sm={6} xs={12}>
                <Link
                  to={`/jobs/${data?.slug}`}
                  style={{ textDecoration: "none" }}
                >
                  <Card data={data} />
                </Link>
              </Grid>
            </>
          ))}
          {jobs_loading &&
            [...Array(4)]?.map((item) => (
              <Grid item md={3} sm={6} xs={12}>
                <SkeletonCardJob />
              </Grid>
            ))}

          {/* {jobs_loading
            ? [...Array(4)]?.map((item) => (
                <Grid item md={3} sm={6} xs={12}>
                  <SkeletonCardJob />
                </Grid>
              ))
            : jobsData?.map((data) => {
                return (
                  <Grid key={data?.id} item md={3} sm={6} xs={12}>
                    <Link
                      to={`/jobs/${data?.slug}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Card data={data} />
                    </Link>
                  </Grid>
                );
              })} */}
        </Grid>
        {!jobs_loading &&
          jobsData?.length < jobsPage?.data?.job_data?.pagination?.total && (
            <Box className={classes.newsSeeMoreBtnBox} onClick={handleMore}>
              <NavLink className={classes.newsSeeMoreBtn}>See More</NavLink>
              <Box className={classes.newsSeeMoreArrow}>
                <img src={chevronDown} alt="" />
              </Box>
            </Box>
          )}
      </Container>
    </div>
  );
};

export default Jobs;
