import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, Divider, Menu, MenuItem } from "@mui/material";
import Typography from "@mui/material/Typography";
import CustomModal from "components/global/modal";
import useToggle from "hooks/useToggle";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import CommentBoxSecondary from "./CommentBoxSecondary";
import { deleteComment, deleteThread, getThread } from "./redux/actions";
import style from "./style";
import TextFieldForm from "./TextFieldForm";

const ModalMenu = ({ data, comment, row, setRow, setThreadListData }) => {
  console.log("ddddddddddddddddddddd", { data });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const [commentData, setCommentData] = useState({});

  const classes = style();
  const location = useLocation();
  const locationArray = location.pathname.split("/");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  console.log({ row });
  const refetch = () => {
    // await setThreadListData([]);
    dispatch(getThread());
  };

  // const handleDelete = (id) => {
  //   comment
  //     ? dispatch(deleteComment(id, refetch))
  //     : dispatch(deleteThread(id, refetch));
  // };

  const handleDeleteSlug = (slug) => {
    comment
      ? dispatch(deleteComment(slug, refetch))
      : dispatch(deleteThread(slug, refetch));
  };

  const [openModal, openFunction] = useToggle();

  const handleEdit = () => {
    openFunction();
    setCommentData(data);
  };

  const styles = {
    dropDown: {
      // color: "#121127",
      display: "flex",
      alignItems: "center",
      justifyContent: { xs: "center", md: "none" },
      cursor: "pointer",
      fontFamily: ["Sofia Sans", "sans-serif", "!important"],
      fontSize: { xs: "18px !important", lg: "13px !important" },
      fontWeight: "400 !important",
      background: locationArray?.includes?.("about-us") && "#EF4444",
      color: locationArray?.includes?.("about-us") && "#fff",
    },
  };

  return (
    <>
      <Typography
        sx={styles.dropDown}
        variant="contained"
        id="basic-button"
        onClick={handleClick}
      >
        <MoreHorizIcon sx={{ color: "#616161", cursor: "pointer" }} />
      </Typography>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ paddingInline: 0 }}
      >
        <>
          <MenuItem onClick={handleClose} className={classes.dropdownEditHover}>
            <Box
              className={classes.dropdownItemsLink}
              style={{ width: "100%" }}
              onClick={handleEdit}
            >
              <Typography className={classes.dropdownItems}>
                <BorderColorOutlinedIcon
                  sx={{ color: "#4C4B63", height: "16px", width: "16px" }}
                />
                Edit
              </Typography>
            </Box>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={handleClose}
            className={classes.dropdownDeleteHover}
          >
            <Box
              className={classes.dropdownItemsLink}
              style={{ width: "100%" }}
              // onClick={() => handleDelete(data?.id)}
              onClick={() => handleDeleteSlug(comment ? data?.id : data?.slug)}
            >
              <Typography className={classes.dropdownItems}>
                <DeleteOutlineOutlinedIcon
                  sx={{ color: "#4C4B63", height: "16px", width: "16px" }}
                />
                Delete
              </Typography>
            </Box>
          </MenuItem>
        </>
      </Menu>
      <CustomModal
        open={openModal}
        handleClose={openFunction}
        height="auto"
        // width
        variant="light"
        className={classes.formSubmitModal}
      >
        <Box sx={{ padding: "20px" }}>
          {comment ? (
            <CommentBoxSecondary
              commentData={commentData}
              handleClose={openFunction}
              edit
              row={row}
            />
          ) : (
            <TextFieldForm
              commentData={commentData}
              handleClose={openFunction}
              edit
            />
          )}
        </Box>
      </CustomModal>
    </>
  );
};

export default ModalMenu;
