import Publications from 'components/local/publications'
import Volunteer from 'components/local/volunteer';
import React from 'react'

const VolunteerPage = () => {
  return (
    <div>
      <Volunteer />
    </div>
  );
};

export default VolunteerPage;
