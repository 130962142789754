import { Button, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { postEventData } from "redux/events/actions";
import * as actions from "redux/events/types";
import * as yup from "yup";
import style from "./style";

const ApplicationForm = () => {
  const classes = style();
  const eventState = useSelector((state) => state.event);
  const eventData = eventState.singleEventData;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  console.log({ eventState });

  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup.string().email().required("Email is required"),
    phone: yup
      .string()
      .typeError("It must be a number")
      .required("Phone number is required")
      .min(10)
      .max(10),
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({ resolver: useYupValidationResolver(validationSchema) });

  useEffect(() => {
    if (eventState.submitStatus === "completed" || "error") {
      reset({ name: "", email: "", phone: "" });
      dispatch({ type: actions.RESET_STATUS });
    }
  }, [eventState.submitStatus]);

  const applyEvent = (data) => {
    console.log("data", data);
    dispatch(
      postEventData(
        { ...data, status: "Active", event_id: eventData.id },
        eventData.slug
      )
    );
  };

  console.log("errors", errors);

  return (
    <form
      className={classes.formContainer}
      style={{ maxWidth: "325px" }}
      onSubmit={handleSubmit(applyEvent)}
    >
      <Typography component="h2" className={classes.title}>
        Application Form
      </Typography>
      <Box className={classes.inputField}>
        <label htmlFor="name" className={classes.label}>
          Name
        </label>
        <input
          id="name"
          type="text"
          placeholder="Esther Howard"
          className={classes.input}
          {...register("name")}
          aria-invalid={errors.name ? true : false}
        />
        {errors.name && (
          <Typography color="#DC2626">{errors.name?.message}</Typography>
        )}
      </Box>

      <Box className={classes.inputField}>
        <label htmlFor="email" className={classes.label}>
          Email
        </label>
        <input
          id="email"
          type="email"
          placeholder="tim.jennings@example.com"
          className={classes.input}
          {...register("email")}
          aria-invalid={errors.name ? true : false}
        />
        {errors.email && (
          <Typography color="#DC2626">{errors.email?.message}</Typography>
        )}
      </Box>

      <Box className={classes.inputField}>
        <label htmlFor="phone" className={classes.label}>
          Phone
        </label>
        <input
          id="phone"
          type="number"
          placeholder="(406) 555-0120"
          className={classes.input}
          {...register("phone")}
          aria-invalid={errors.phone ? true : false}
        />
        {errors.phone && (
          <Typography color="#DC2626">{errors.phone?.message}</Typography>
        )}
      </Box>
      <Box>
        {user?.role?.includes("Company") ? (
          <Tooltip
            style={{ fontSize: "12px !important", color: "red" }}
            title="Company cannot apply for Events"
          >
            <Box>
              <Button disabled type="submit" className={classes.buttonDisable}>
                Submit
              </Button>
            </Box>
          </Tooltip>
        ) : (
          <Button type="submit" className={classes.button}>
            {/* {applyJobs_loading ? <Loader /> : "Submit"} */}
            {eventState.submitStatus === "pending" ? "Submitting..." : "Submit"}
          </Button>
        )}
        {/* {user?.role?.includes("Company") ? (
  <Button className={classes.button}>
  Submit
  </Button>
) : (

  <button className={classes.button}>
  {eventState.submitStatus === "pending" ? "Submitting..." : "Submit"}
  </button>
  )} */}
      </Box>
    </form>
  );
};

export default ApplicationForm;
