import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  lineClamp: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    minHeight: "48px",
    //  color: "#6C6B80 !important",
    "& > *, >*>*,>*>*>*,>*>*>*>*,>*>*>*>*>*": {
      color: "#6C6B80 !important",
      fontSize: "12px !important",
    },
  },
  lineClampDesc: {
    display: "-webkit-box",
    //  "-webkit-line-clamp": 8,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    "& > *, >*>*,>*>*>*,>*>*>*>*,>*>*>*>*>*": {
      color: "#6C6B80 !important",
      fontSize: "16px !important",
    },
  },
  lineClampDescAbout: {
    display: "-webkit-box",
    "-webkit-line-clamp": 20,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    "& > *, >*>*,>*>*>*,>*>*>*>*,>*>*>*>*>*": {
      color: "#6C6B80 !important",
      fontSize: "14px !important",
    },
  },
  lineClampDescAboutHome: {
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    "& > *, >*>*,>*>*>*,>*>*>*>*,>*>*>*>*>*": {
      color: "#6C6B80 !important",
      fontSize: "14px !important",
    },
  },
  lineClampDescAboutPrHome: {
    display: "-webkit-box",
    "-webkit-line-clamp": 6,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    "& > *, >*>*,>*>*>*,>*>*>*>*,>*>*>*>*>*": {
      color: "#6C6B80 !important",
      fontSize: "14px !important",
    },
  },
  lineClampDescTerms: {
    padding: "12px",
    display: "-webkit-box",
    "-webkit-line-clamp": 2000,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    "& > *, >*>*,>*>*>*,>*>*>*>*,>*>*>*>*>*": {
      color: "#6C6B80 !important",
      fontSize: "14px !important",
    },
  },
}));

export default style;
