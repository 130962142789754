import { deleteBlogApi, getBlogApi, postBlogApi, putBlogApi } from "apis/blog";
import * as actions from "components/local/dashboard/blog/redux/types";
import { errorToast, successToast } from "utils/toast";

export const postBlog =
  (Data, refetch = () => {}) =>
  async (dispatch) => {
    dispatch({ type: actions.POST_BLOG_BEGIN });

    try {
      await postBlogApi(Data);
      console.log("dataaa", Data);
      refetch && refetch();
      dispatch({
        type: actions.POST_BLOG_SUCCESS,
        payload: "",
      });
      successToast();
    } catch (error) {
      dispatch({ type: actions.POST_BLOG_ERROR });
      console.log(error);
      errorToast(error);
    }
  };

export const getBlog = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_BLOG_BEGIN });

  try {
    const { data } = await getBlogApi(Data);
    dispatch({
      type: actions.GET_BLOG_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_BLOG_ERROR });
    console.log(error);
    errorToast(error);
  }
};

export const putBlog =
  (Data, slug, refetch = () => {}) =>
  async (dispatch) => {
    dispatch({ type: actions.PUT_BLOG_BEGIN });

    try {
      await putBlogApi(Data, slug);
      console.log("dataaa", Data);
      refetch && refetch();
      dispatch({
        type: actions.PUT_BLOG_SUCCESS,
        payload: "",
      });
      successToast();
    } catch (error) {
      dispatch({ type: actions.PUT_BLOG_ERROR });
      console.log(error);
      errorToast(error);
    }
  };

export const deleteBlog =
  (Data, refetch = () => {}) =>
  async (dispatch) => {
    dispatch({ type: actions.DELETE_BLOG_BEGIN });

    try {
      await deleteBlogApi(Data);
      console.log("dataaa", Data);
      refetch && refetch();
      dispatch({
        type: actions.DELETE_BLOG_SUCCESS,
        payload: "",
      });
      successToast();
    } catch (error) {
      dispatch({ type: actions.DELETE_BLOG_ERROR });
      console.log(error);
      errorToast(error);
    }
  };

// export const getJobApplications = (Data) => async (dispatch) => {
//   dispatch({ type: actions.GET_JOB_APPLICATIONS_BEGIN });

//   try {
//     const { data } = await getJobApplicationsApi(Data);
//     dispatch({
//       type: actions.GET_JOB_APPLICATIONS_SUCCESS,
//       payload: data,
//     });
//   } catch (error) {
//     dispatch({ type: actions.GET_JOB_APPLICATIONS_ERROR });
//     console.log(error);
//     errorToast(error);
//   }
// };
