import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import useStyles from "./styles";

import PropTypes from "prop-types";

const CustomModal = ({
  headerText,
  open,
  handleClose,
  children,
  width,
  height,
  // maxHeight
}) => {
  const classes = useStyles();

  const customModalStyles = {
    // width: width || "37rem",
    minWidth: width || { xs: "20rem", md: "37rem" },
    width: width || { xs: "100%", md: "60%" },
    height: height || "34rem",
    // maxHeight: maxHeight || "60px",
    display: "flex",
    flexDirection: "column",
    background: "#fff !important",
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...customModalStyles }} className={classes.root}>
        {/* <Box className={classes.modalHeader}> */}
        <Box className={classes.modalHeaderText}>{headerText}</Box>
        {/* <CancelIcon onClick={handleClose} className={classes.cancelItem} /> */}
        {/* </Box> */}
        <Box className={classes.modalBody}>{children}</Box>
      </Box>
    </Modal>
  );
};

export default CustomModal;

CustomModal.propTypes = {
  // headerText: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  children: PropTypes.element,
  width: PropTypes.string,
  height: PropTypes.string,
};

// CustomModal.defaultProps = {
//   headerText: 'This is a default header'
// };
