import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function ContactInfoLoader() {
  return (
    <Stack spacing={1}>
      <>
        <Skeleton variant="rectangular" width={250} />
        <br />
        <Skeleton variant="rectangular" width={250} />
        <br />
        <Skeleton variant="rectangular" width={250} />
        {/* <Skeleton variant="text" width={270} /> */}
      </>
    </Stack>
  );
}
