import News from "components/local/news";

const NewsPage = () => {
  return (
    <div>
      <News />
    </div>
  );
};

export default NewsPage;
