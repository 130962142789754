import { getComplainApi, postComplainApi } from "apis";
import * as actions from "components/local/complain/redux/types";
import { errorToast, successToast } from "utils/toast";

export const postComplain =
  (Data, refetch = () => {}) =>
  async (dispatch) => {
    dispatch({ type: actions.POST_COMPLAIN_BEGIN });

    postComplainApi(Data)
      .then((res) => {
        console.log("dataaa", Data);
        refetch && refetch();
        dispatch({
          type: actions.POST_COMPLAIN_SUCCESS,
          payload: "",
        });
        successToast(res?.data?.message);
      })
      .catch((error) => {
        dispatch({ type: actions.POST_COMPLAIN_ERROR });
        console.log(error);
        errorToast(error);
      });
  };

export const getComplain = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_COMPLAIN_BEGIN });

  try {
    const { data } = await getComplainApi(Data);
    dispatch({
      type: actions.GET_COMPLAIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: actions.GET_COMPLAIN_ERROR });
    console.log(error);
    errorToast(error);
  }
};
