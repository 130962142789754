import { Box, Container, Grid, Typography } from "@mui/material";
import AboutDescription from "components/global/description/AboutDescription";
import SkeletonAboutImage from "components/global/loader/skeletonLoader/AboutImage";
import SkeletonAboutText from "components/global/loader/skeletonLoader/AboutText";
import { getSiteSetting } from "layouts/redux/actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./style";

const PresidentSection = ({ data }) => {

  const pathname = window.location.pathname;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  const {siteSetting, siteSetting_loading} = useSelector((state) => state.siteSetting);
  const [siteSettingData, setSiteSettingData] = useState([])

  useEffect(() => {
    siteSetting?.data && setSiteSettingData(siteSetting?.data)
  },[siteSetting?.data])

  const classes = style();
  const { aboutSingle_loading } = useSelector((state) => state.aboutSingle);
  return (
    <Box className={classes.aboutCompContainer}>
      <Container maxWidth="lg">
        {/* <Box className={[classes.aboutCompWrap, "container-wrap-secondary"]}> */}
        <Grid
          container
          spacing={1}
          sx={
            data?.data?.id === 2 &&
            1 && {
              display: "flex",
              justifyContent: "space-between",
            }
          }
        >
          <>
            {siteSetting_loading ? (
              <>
                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  // sx={data?.data?.id === 1 && { order: "2" }}
                >
                  <SkeletonAboutText />
                </Grid>
                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  // sx={data?.data?.id === 1 && { order: "1" }}

                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <SkeletonAboutImage />
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  // className={classes.aboutSectionGrid1}
                  // sx={isEven(data?.data?.id) ? { order: "2" } : { order: "1" }}
                >
                  <Box className={classes.aboutCompTypography}>
                    
                      <Typography className={classes.aboutCompTitle}>
                        Our President
                      </Typography>
                    <Typography className={classes.aboutCompHead} sx={{width: '100%'}}>
                      Message from our President
                    </Typography>
                    <Typography className={classes.aboutCompPara}>
                      {/* {paragraph} */}
                      <AboutDescription description={siteSettingData?.president_message} />
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  className={classes.aboutSectionGrid2}
                  // sx={isEven(data?.data?.id) ? { order: "1" } : { order: "2" }}
                >
                  <Box
                    className={classes.imageGrid}
                    // sx={data?.data?.id === 2 && { alignItems: "flex-start" }}
                    // sx={isEven(data?.data?.id) && { alignItems: "flex-start" }}
                  >
                    <Box className={classes.imageBox}>
                      <img
                        src={siteSettingData?.president_image}
                        alt=""
                        className={classes.gridImg}
                      />
                    </Box>
                    {/* <Box className={classes.imageGridCaption}>caption</Box> */}
                  </Box>
                </Grid>
              </>
            )}
          </>
        </Grid>
      </Container>
    </Box>
  );
};

export default PresidentSection;
