import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  heroWrap: {
    width: "100% !important",
    height: "calc(100vh - 105px)",
    [theme.breakpoints.down("md")]: {
      height: "540px !important",
    },
  },
  heroImgContainer: {
    height: "100%",
    width: "100%",
    position: "relative",
    background: "#201F37",
    top: 0,
    left: 0,
    display: "flex",
    aliginItems: "center",
  },
  heroTextWrap: {
    color: "#fff",
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex !important",
    // paddingTop: "100px !important",
    alignItems: "center !important",
    justifyContent: "center",
    // text
    top: 0,
    left: 0,
    textAlign: "left",
    background: "rgb(55, 55, 72, .65)",
    flexDirection: "column",
  },
  heroText: {
    color: "#fff",
    width: "90%",
    textItems: "center",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    fontSize: "70px !important",
    display: "flex !important",
    lineHeight: "normal !important",
    fontWeight: "800 !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px !important",
    },
  },
  heroSubtext: {
    color: "#fff",
    width: "65%",
    textItems: "center",
    fontFamily: ["Sofia Sans", "sans-serif", "!important"],
    fontSize: "14px !important",
    fontWeight: "500 !important",
    paddingTop: "10px",
    marginBottom: "30px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
    },
  },

  heroImg: {
    width: "100% !important",
    height: "100% !important",
    objectFit: "cover",
    objectPosition: "center",
  },

  heroLoading: {
    height: "600px !important",
    [theme.breakpoints.down("md")]: {
      height: "540px !important",
    },
  },

  // slider

  sliderBoxWrap: {
    position: "relative",
  },
  arrowIcon: {
    backgroundColor: "rgba(255, 255, 255, 0.81) !important",
    color: "#2E3A59",
    height: "25px !important",
    width: "25px !important",
    margin: "0 1px",
    borderRadius: "50%",
    cursor: "pointer",
  },
  // arrowIconLeft: {
  //   left:"5%",
  //   top: "50px",
  //   position: "absolute",
  //   zIndex: 10,
  // },
  arrowIconLeft: {
    left: "5%",
    top: "50%",
    zIndex: 10,
    display: "block",
    background: "#fff",
    position: "absolute",
    borderRadius: "50%",
    bottom: "50%",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "none !important",
    },
  },
  arrowIconRight: {
    right: "5%",
    display: "block",
    background: "#fff",
    position: "absolute",
    zIndex: 10,
    borderRadius: "50%",
    top: "50%",
    bottom: "50%",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "none !important",
    },
  },
}));

export default style;
