// import { Box } from "@mui/system";
// import heroImage from "assets/dashboard/noteCourses/hero.png";
import style from "./style";
import { Box} from "@mui/material";

const MainContent = ({ data, }) => {
  const classes = style();

  return (
    <Box sx={{ marginBottom: "10px" }}>
      

        <iframe
        className={classes.pdfIframe}
        title="notes"
        src={data?.file}
        
        ></iframe>
       

      {/*   <Box className={classes.contentContainer}>
        <Box className={classes.imageContainer}>
          <img src={heroImage} alt="" />
        </Box>
        <Box>
          <Typography component={"h1"} className={classes.header}>
            PRINCIPLES & PRACTICE OF ACCOUNTING
          </Typography>

          <Typography>
            Like the Cornell Method, divide your page into two halves. On the
            left, you have your main ideas, and on the right, you have your
            related and secondary ideas. Place the most important notes on the
            left-hand column, and develop or connect with similar ideas on the
            right.
          </Typography>
        </Box>
        <Box>
          <Typography component={"h2"} className={classes.subHeader}>
            Is it necessary to make notes for CA?{" "}
          </Typography>
          <Typography>
            In CA exams, note-making plays a very important role in passing the
            exam with a good score. It helps you to gain marks in your internal
            assessments and also write your answers faster during examination
            time. So every student must know how to do note-making properly.
          </Typography>
        </Box>
        <Box>
          <Typography component={"h2"} className={classes.subHeader}>
            The CA course has three levels{" "}
          </Typography>
          <ul>
            <li>CA Foundation</li>
            <li>CA Foundation</li>
            <li>CA Foundation</li>
          </ul>
        </Box>
      </Box> */}
    </Box>
  );
};

export default MainContent;
