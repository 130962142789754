import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  dayText: {
    fontFamily: ["Sofia sans", "sans-serif", "!important"],
    fontSize: "25px !important",
    color: "#DC2626",
    fontWeight: "700 !important",
    textAlign: "center",
  },
  monthText: {
    fontFamily: ["Sofia sans", "sans-serif", "!important"],
    fontSize: "15px !important",
    color: "#DC2626",
    fontWeight: "500 !important",
    textAlign: "center",
  },
  header: {
    fontFamily: ["Sofia sans", "sans-serif", "!important"],
    fontSize: "17px !important",
    fontWeight: "700 !important",
    color: "#383751",
    lineHeight: "1 !important",
  },
  text: {
    fontFamily: ["Sofia sans", "sans-serif", "!important"],
    fontSize: "14px !important",
    fontWeight: "500 !important",
    color: theme.palette.grey[1],
    lineHeight: "1.3 !important",
  },
  status: {
    fontFamily: ["Sofia sans", "sans-serif", "!important"],
    fontSize: "11px !important",
    fontWeight: "500 !important",
    color: "#FF1818",
    lineHeight: "1.6 !important",
  },
  imageContainer: {
    width: "94px",
    "& img": {
      width: "100%",
      height: "auto",
    },
  },
}));

export default style;
