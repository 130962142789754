import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  header: {
    fontWeight: "700 !important",
    fontSize: "27px !important",
    lineHeight: "1.2 !important",
    color: "#000",
    textAlign: "center",
    marginBottom: "1rem",
  },
  capBox: {
    cursor: "pointer",
    display: "flex",
    padding: "45px 10px",
    alignItems: "center",
    borderRadius: "6px",
    justifyContent: "center",
    backgroundColor: "#f95b5b",
  },
  capBoxText: {
    fontSize: "20px !important",
    color: "#fff",
  },
}));

export default style;
