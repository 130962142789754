import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    gap: "38px",
    padding: "11px 36px",
    backgroundColor: "#F3F3F6",
    marginBottom: "9px",
    borderLeft: "solid 8px #4C7CE5",
  },
  dayText: {
    fontSize: "25px !important",
    color: "#121127",
    fontWeight: "700 !important",
    textAlign: "center",
  },
  monthText: {
    fontSize: "15px !important",
    color: "#4C4B63",
    fontWeight: "500 !important",
    lineHeight: "1.3 !important",
    textAlign: "center",
  },
  header: {
    fontFamily: ["Sofia sans", "sans-serif", "!important"],
    fontSize: "17px !important",
    fontWeight: "700 !important",
    color: "#383751",
    lineHeight: "1 !important",
  },
  text: {
    fontFamily: ["Sofia sans", "sans-serif", "!important"],
    fontSize: "14px !important",
    fontWeight: "500 !important",
    color: theme.palette.grey[1],
    lineHeight: "1.3 !important",
  },
  status: {
    fontFamily: ["Sofia sans", "sans-serif", "!important"],
    fontSize: "11px !important",
    fontWeight: "500 !important",
    color: "#FF1818",
    lineHeight: "1.6 !important",
  },
}));

export default style;
