import AboutUsSinglePage from "pages/public/aboutUsSinglePage/AboutUsSinglePage";
import BlogPage from "pages/public/blogsPage/BlogPage";
import BlogsSinglePage from "pages/public/blogsSinglePage/BlogsSinglePage";
import ComplainPage from "pages/public/complainPage/ComplainPage";
import ContactUsPage from "pages/public/contactUsPage/ContactUsPage";
import EventDetailsPage from "pages/public/eventsDetail/EventsDetailsPage";
import EventPage from "pages/public/eventsPage/EventPage";
import GalleryPage from "pages/public/galleryPage/GalleryPage";
import GallerySinglePage from "pages/public/gallerySinglePage/GallerySinglePage";
import Homepage from "pages/public/homePage/Homepage";
import JobsPage from "pages/public/jobsPage/JobsPage";
import JobsSinglePage from "pages/public/jobsSinglePage/JobsSinglePage";
import NewsPage from "pages/public/newsPage/NewsPage";
import NewsSinglePage from "pages/public/newsSinglePage/NewsSinglePage";
import OurTeamSinglePage from "pages/public/ourTeamSinglePage/OurTeamSinglePage";
import OurTeamsPage from "pages/public/ourTeamsPage/OurTeamsPage";
import ForgotPasswordPage from "pages/public/password/ForgotPasswordPage";
import ResetPasswordPage from "pages/public/password/ResetPasswordPage";
import PresidentSectionPage from "pages/public/presidentSectionPage/PresidentSectionPage";
import PrivacyPolicy from "pages/public/privacyPolicy/PrivacyPolicy";
import PublicationSinglePage from "pages/public/publicationSinglePage/PublicationSinglePage";
import PublicationPage from "pages/public/publicationsPage/PublicationPage";
import TermsAndConditionsPage from "pages/public/termsAndConditionsPage/TermsAndCondition";
import VerificationPage from "pages/public/verificationPage/VerificationPage";
import VolunteerPage from "pages/public/volunteerPage/VolunteerPage";

const homeRoutes = {
  home: [
    {
      path: "/",
      component: <Homepage />,
    },
    {
      path: "/gallery",
      component: <GalleryPage />,
    },
    { path: "/gallery/:id", component: <GallerySinglePage /> },
    {
      path: "/blogs",
      component: <BlogPage />,
    },
    {
      path: "/blogs/:slug",
      component: <BlogsSinglePage />,
    },
    {
      path: "/contact-us",
      component: <ContactUsPage />,
    },
    {
      path: "/events",
      component: <EventPage />,
    },
    {
      path: "/events/:eventId",
      component: <EventDetailsPage />,
    },
    {
      path: "/news",
      component: <NewsPage />,
    },
    {
      path: "/news/:slug",
      component: <NewsSinglePage />,
    },
    {
      path: "/publications",
      component: <PublicationPage />,
    },
    {
      path: "/publications/:slug",
      component: <PublicationSinglePage />,
    },
    {
      path: "/jobs",
      component: <JobsPage />,
    },
    {
      path: "/volunteer",
      component: <VolunteerPage />,
    },
    {
      path: "/jobs/:slug",
      component: <JobsSinglePage />,
    },
    {
      path: "/forgot-password",
      component: <ForgotPasswordPage />,
    },
    {
      path: "/reset-password",
      component: <ResetPasswordPage />,
    },
    {
      path: "/about-us/:slug",
      component: <AboutUsSinglePage />,
    },
    {
      path: "/account-verification-email",
      component: <VerificationPage />,
    },
    {
      path: "/our-team",
      component: <OurTeamsPage />,
    },
    {
      path: "/our-team/:slug",
      component: <OurTeamSinglePage />,
    },
    {
      path: "/terms-and-conditions",
      component: <TermsAndConditionsPage />,
    },
    {
      path: "/privacy-policy",
      component: <PrivacyPolicy />,
    },
    {
      path: "/complain",
      component: <ComplainPage />,
    },
    {
      path: "/president-message",
      component: <PresidentSectionPage />,
    },
  ],
};

// { path: '/forgot-password', component: <Forgot /> },
// { path: '/reset-password', component: <ResetPassword /> },
// { path: '/verify-mail', component: <VerifyEmail /> }

export default homeRoutes;
